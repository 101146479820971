import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'underscore';
import { processSearchResultsPage as sendAnalytics } from 'app/blocks/analytics/analytics';
import { Layout, Title } from 'app/blocks/blocks';
import { ID, withCodes } from 'app/blocks/common/codes';
import { eventContainsKey, KeyCode } from 'app/blocks/common/key-utils';
import * as middlewareSearch from 'app/blocks/middleware/search';
import PageSeeMore from 'app/blocks/page/__see-more/page__see-more';
import ResultItems from 'app/pages/search/search_result';
import searchUtils from 'app/pages/search/search_utils';
import SearchFilter from '../../search-filter';

const labels = {
    doi: 'Article DOI',
    editorialRefCode: 'Editorial Reference Code',
    journalId: 'Journal',
};

const stringifyTerms = fields => {
    const arr = [];
    for (const [key, value] of Object.entries(fields)) {
        if (value) {
            arr.push(`${labels[key]} - ${value}`);
        }
    }
    return arr.join(': ');
};

class SearchAdvancedResult extends Component {
    static defaultProps = {
        jid: undefined,
    };

    static propTypes = {
        form: PropTypes.shape({}).isRequired,
        journalForm: PropTypes.shape({}).isRequired,
        articleForm: PropTypes.shape({}).isRequired,
        codes: PropTypes.shape({}).isRequired,
        l: PropTypes.func.isRequired,
        goBack: PropTypes.func.isRequired,
        notfoundRender: PropTypes.func.isRequired,
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        jid: PropTypes.string,
    };

    state = {
        ...searchUtils.getInitialState(),
        filter: new Set((this.props.form && this.props.form.getValue(this.getFilterName())) || []),
    };

    componentDidMount() {
        searchUtils.onFirstLoad.bind(this)();
    }

    componentDidUpdate() {
        if (!this.state.isLoading) {
            const {
                form: { fields },
            } = this.props;

            const {
                data: { total: totalResult },
                filter,
            } = this.state;

            sendAnalytics({
                'advanced-search-term': stringifyTerms(fields),
                filters: filter?.size ? Array.from(filter).join(': ') : undefined,
                totalResult,
            });
        }
    }

    getFilterName() {
        return 'revenueModel';
    }

    createQuery = () => {
        let filter = [...this.state.filter];
        if (_.isEmpty(filter)) {
            filter = this.props.form.getValue(this.getFilterName()); // fall back to what we started from
        }
        return this.props.form.append(this.getFilterName(), filter).buildQuery();
    };

    getContext = () => ({
        data: this.state.data,
        form: this.props.form,
        pageIndex: this.state.pageIndex,
        journalForm: this.props.journalForm,
        articleForm: this.props.articleForm,
        jid: this.props.jid,
    });

    // eslint-disable-next-line react/no-unused-class-component-methods
    search = page => middlewareSearch.search(this.createQuery(), page, this.props.type);

    seeMore = () => searchUtils.seeMore.bind(this)();

    filter = type => searchUtils.filter.bind(this)(type);

    render() {
        const {
            l,
            codes,

            title,
            goBack,
            notfoundRender,
        } = this.props;

        const {
            data,
            facet,

            isLoading,
            isLoadingMore,
        } = this.state;

        const hasResults = data && data.total > 0;

        return (
            <div className="container">
                {hasResults && (
                    <div className="row">
                        <div className="col-xl-9 offset-xl-3">
                            <Title>{title}</Title>

                            <div>
                                <div className="pull-left p-top_micro" data-seleniumid="search-results-total-amount">
                                    {hasResults && `About ${data.total} results`}
                                </div>
                                <div className="pull-right p-top_micro">
                                    <a
                                        data-seleniumid="search-results-advanced-search-link"
                                        onClick={goBack}
                                        onKeyDown={event => {
                                            if (eventContainsKey(event, KeyCode.ENTER)) {
                                                goBack();
                                            }
                                        }}
                                        role="button"
                                        tabIndex="0"
                                    >
                                        <span className="bold_text">{l('SEARCH_CODES.GO_BACK_BUTTON')}</span>
                                    </a>
                                </div>

                                <span className="clearfix" />
                            </div>
                        </div>
                    </div>
                )}
                <div className="row mt-2x">
                    {hasResults && (
                        <SearchFilter
                            facet={facet}
                            filterConfig={codes[ID.SEARCH_CODES].filters[this.getFilterName()]}
                            onFilter={this.filter}
                            selectedFilters={this.state.filter}
                        />
                    )}
                    <div className={`${hasResults ? 'col-xl-9' : 'col-12'}`}>
                        <Layout isLoading={isLoading}>
                            {!hasResults ? (
                                notfoundRender(this.getContext)
                            ) : (
                                <ResultItems getContext={this.getContext} items={data.items} />
                            )}
                            {data.hasMore && (
                                <Layout isLoading={isLoadingMore}>
                                    <PageSeeMore
                                        data-seleniumid="search-results"
                                        onClick={this.seeMore}
                                        text="See more"
                                    />
                                </Layout>
                            )}
                        </Layout>
                    </div>
                </div>
            </div>
        );
    }
}

export default withCodes(SearchAdvancedResult, ID.SEARCH_CODES);
