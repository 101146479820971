import { AxiosProgressEvent } from 'axios';
import cn from 'classnames';
import { autobind } from 'core-decorators';
import filesize from 'filesize';
import update from 'immutability-helper';
import isEmpty from 'lodash.isempty';
import React from 'react';
import _ from 'underscore';
import { PrimaryButton } from 'app/blocks/buttons/buttons';
import { l } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import CustomDropZone from 'app/blocks/dropzone/custom-dropzone';
import ErrorLabel from 'app/blocks/ErrorLabel/ErrorLabel';
import {
    filterAllDuplicatesOnDelete,
    validateFileItem,
    validateFileName,
    validateFilesCount,
    validateFileSizeExceeded,
    validateFileType,
    validateFileTypes,
    validateTotalSize,
} from 'app/blocks/file-uploader/file-uploader.validation';
import UploadMiddleware from 'app/blocks/middleware/upload';
import FileList from './FileList';

import './FileUploader.scss';

function moveUp(array, value, by = undefined) {
    const index = array.indexOf(value);
    let newPos = index - (by || 1);

    if (index === -1) {
        throw new Error('Element not found in array');
    }

    if (newPos < 0) {
        newPos = 0;
    }

    array.splice(index, 1);
    array.splice(newPos, 0, value);

    return [...array];
}

function moveDown(array, value, by = undefined) {
    const index = array.indexOf(value);
    let newPos = index + (by || 1);

    if (index === -1) {
        throw new Error('Element not found in array');
    }

    if (newPos >= array.length) {
        newPos = array.length;
    }

    array.splice(index, 1);
    array.splice(newPos, 0, value);

    return [...array];
}

export type FileItem = {
    _id: string;
    id: string;
    file: File;
    error?: Error;
    hasCorrectFileType: boolean;
    hasCorrectFileName: boolean;
    isLoaded?: boolean;
    isLoading?: boolean;
    timeStarted?: number;
    percentComplete?: number;
    rate?: number;
};

type State = {
    fileItems: Array<FileItem>;
    onDropEnterConfig: {
        enabledOnDropEnter: boolean;
        typeErrMsg?: string;
        countErrMsg?: string;
    };
    isTotalSizeCorrect?: boolean;
    isDropEnabled?: boolean;
    maxFilesCountIsUploaded?: boolean;
    allFilesUploadingSuccessful?: boolean;
    allTypesCorrect?: boolean;
    noDuplicateFileNames?: boolean;
    totalFilesSize?: number;
    maxFileSize?: number;
    canConfirm?: boolean;
};

@autobind
class FileUploader extends React.PureComponent<
    {
        config: object;
        initialFileItems: Array<FileItem>;
        onFileItemsChanged: (canConfirm, fileItems) => void;
        externalError?: boolean;
        filesCaption?: string;
    },
    State
> {
    state: State = {
        fileItems: this.props.initialFileItems || [],
        onDropEnterConfig: {
            enabledOnDropEnter: true,
            typeErrMsg: '',
            countErrMsg: '',
        },
    };

    static getDerivedStateFromProps(props, state) {
        const isUploading = !state.fileItems.every(x => x.isLoaded || !x.isLoading);
        const isTotalSizeCorrect = validateTotalSize(state.fileItems, props.config);

        const isDropEnabled = isTotalSizeCorrect && state.onDropEnterConfig.enabledOnDropEnter;
        const maxFilesCountIsUploaded = state.fileItems.length >= props.config.maxFilesNumber;
        const allFilesUploadingSuccessful = state.fileItems.every(item => isEmpty(item.error));
        const allTypesCorrect = state.fileItems.every(item => item.hasCorrectFileType);
        const noDuplicateFileNames = state.fileItems.every(item => item.hasCorrectFileName);

        const totalFilesSize = filesize(
            state.fileItems.map(i => i.file.size).reduce((s, v) => s + v, 0),
            { base: 2 },
        );
        const maxFileSize = filesize(props.config.maxTotalFilesSize, { base: 2 });

        const hasErrors =
            !isTotalSizeCorrect || !allTypesCorrect || !allFilesUploadingSuccessful || !noDuplicateFileNames;

        const canConfirm =
            state.fileItems.length !== 0 &&
            !hasErrors &&
            !isUploading &&
            !state.onDropEnterConfig.typeErrMsg &&
            !state.onDropEnterConfig.countErrMsg;

        return {
            isTotalSizeCorrect,
            isDropEnabled,
            maxFilesCountIsUploaded,
            allFilesUploadingSuccessful,
            allTypesCorrect,
            noDuplicateFileNames,
            totalFilesSize,
            maxFileSize,
            canConfirm,
        };
    }

    componentDidUpdate(prevProps, prevState: Readonly<State>) {
        const { canConfirm, fileItems } = this.state;
        if (canConfirm !== prevState.canConfirm || fileItems !== prevState.fileItems) {
            this.props.onFileItemsChanged(canConfirm, fileItems);
        }
    }

    async setStateAsync(patch) {
        await new Promise<void>(resolve => this.setState(patch, () => resolve()));
    }

    addFileItems(items) {
        this.setState(state => ({ fileItems: [...state.fileItems, ...items] }));
    }

    startUpload(fileItems) {
        (fileItems || this.state.fileItems)
            .filter(item => !item.isAborted && !item.isLoaded && !item.isLoading)
            .forEach(item => this.uploadFile(item));
    }

    async uploadFile(item) {
        try {
            const timeStarted = Date.now();

            if (this.getItemIndex(item._id) !== -1) {
                await this.setStateAsync({
                    fileItems: update(this.state.fileItems, {
                        [this.getItemIndex(item._id)]: {
                            error: { $set: undefined },
                            isLoaded: { $set: false },
                            isLoading: { $set: true },
                            timeStarted: { $set: timeStarted },
                        },
                    }),
                });
            }

            const result = await UploadMiddleware.uploadFile(item, (event: AxiosProgressEvent) =>
                this.notifyProgressLoad(item._id, timeStarted, event),
            );

            this.setState(state => {
                const index = this.getItemIndex(item._id);

                return index !== -1
                    ? {
                          fileItems: update(state.fileItems, {
                              [index]: {
                                  id: { $set: result },
                                  isLoaded: { $set: true },
                                  isLoading: { $set: false },
                              },
                          }),
                      }
                    : null;
            });
        } catch (error) {
            this.setState(state => {
                const index = this.getItemIndex(item._id);

                return index !== -1
                    ? {
                          fileItems: update(state.fileItems, {
                              [index]: {
                                  error: { $set: error },
                                  isLoading: { $set: false },
                              },
                          }),
                      }
                    : null;
            });
        } finally {
            this.setState(state => {
                const index = this.getItemIndex(item._id);

                return index !== -1
                    ? {
                          fileItems: update(state.fileItems, {
                              [index]: { $set: validateFileItem(state.fileItems[index]) },
                          }),
                      }
                    : null;
            });
        }
    }

    onMoveItemUp(file) {
        this.setState(state => ({ fileItems: moveUp(state.fileItems, file) }));
    }

    onMoveItemDown(file) {
        this.setState(state => ({ fileItems: moveDown(state.fileItems, file) }));
    }

    getItemIndex(_id) {
        return this.state.fileItems.findIndex(x => x._id === _id);
    }

    notifyProgressLoad(_id, timeStarted, event: AxiosProgressEvent) {
        this.setState(state => {
            const index = this.getItemIndex(_id);

            return index !== -1
                ? {
                      fileItems: update(state.fileItems, {
                          [index]: {
                              percentComplete: { $set: (event.loaded / event.total) * 100 },
                              rate: {
                                  $set: Math.round((event.loaded * 1000) / ((Date.now() - timeStarted) * 1024)),
                              },
                          },
                      }),
                  }
                : null;
        });
    }

    async onDeleteItem(item) {
        await this.setStateAsync({
            fileItems: filterAllDuplicatesOnDelete(item, _.without(this.state.fileItems, item)),
        });
        this.startUpload(this.state.fileItems);
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    markFilesAsCorruptedByNames(fileIdList) {
        const mapFileItems = item => {
            if (fileIdList.includes(item.id)) {
                return {
                    ...item,
                    error: new Error(l('ERROR.FILE_CORRUPTED')),
                    isAborted: true,
                };
            }

            return item;
        };

        this.setState(state => ({ fileItems: state.fileItems.map(mapFileItems) }));
    }

    setValidationDataByTransferItems(items) {
        const { config } = this.props;
        const { fileItems } = this.state;

        const allTypesValid = validateFileTypes(
            _.map(items, x => x.type),
            config,
        );
        const filesCountValid = validateFilesCount(items.length, fileItems, config);

        this.setState({
            onDropEnterConfig: {
                enabledOnDropEnter: allTypesValid && filesCountValid,
                typeErrMsg: !allTypesValid ? l('ERROR.UPLOAD_LICENSE_DROP_ERRORS_FILES_TYPES_WRONG') : '',
                countErrMsg: !filesCountValid ? l('ERROR.UPLOAD_LICENSE_DROP_ERRORS_FILES_COUNT_WILL_EXCEED') : '',
            },
        });
    }

    onDragEnter(e) {
        const items = e.dataTransfer.items || e.dataTransfer.files;

        if (items) {
            this.setValidationDataByTransferItems(items);
        }
    }

    onDrop(files) {
        const { config } = this.props;
        const { fileItems } = this.state;

        const items = files
            .map(file => validateFileSizeExceeded({ file, _id: _.uniqueId('file') }, config))
            .map(item => validateFileType(item, config))
            .map((item, index, array) => validateFileName(item, [...fileItems, ...array.filter((x, i) => i < index)]))
            .map(validateFileItem);

        const filesCountValid = validateFilesCount(items.length, fileItems, config);

        if (filesCountValid) {
            this.addFileItems(items);
            this.startUpload(items);
        } else {
            this.setState({
                onDropEnterConfig: {
                    enabledOnDropEnter: false,
                    typeErrMsg: '',
                    countErrMsg: l('ERROR.UPLOAD_LICENSE_DROP_ERRORS_FILES_COUNT_WILL_EXCEED'),
                },
            });
        }
    }

    onTryUploadAgain() {
        this.setState({ onDropEnterConfig: { enabledOnDropEnter: true, typeErrMsg: '', countErrMsg: '' } });
    }

    render() {
        const { config, externalError, filesCaption } = this.props;
        const {
            onDropEnterConfig,
            isTotalSizeCorrect,
            isDropEnabled,
            maxFilesCountIsUploaded,
            allFilesUploadingSuccessful,
            allTypesCorrect,
            noDuplicateFileNames,
            totalFilesSize,
            maxFileSize,
            fileItems,
        } = this.state;

        const firstText = l('LICENSE_SUBMISSION.LABELS.UPLOAD.FIRST_TEXT', {
            filesLabel: filesCaption || l('LICENSE_SUBMISSION.LABELS.UPLOAD.DEFAULT_FILES_LABEL'),
        });
        const secondText = l(
            `LICENSE_SUBMISSION.LABELS.UPLOAD.${filesCaption ? 'SECOND_TEXT_NO_SIZE' : 'SECOND_TEXT'}`,
            { totalFilesSize, maxFileSize },
        );

        return (
            <div className="SingleAuthorUpload">
                {!maxFilesCountIsUploaded && (
                    <CustomDropZone
                        actions={{ onDragEnter: this.onDragEnter, onDrop: this.onDrop }}
                        enabledOnDropEnter={onDropEnterConfig.enabledOnDropEnter}
                        isDropEnabled={isDropEnabled && !externalError}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div
                                {...getRootProps({
                                    onClick: event => {
                                        if (
                                            !externalError &&
                                            (!isDropEnabled || !onDropEnterConfig.enabledOnDropEnter)
                                        ) {
                                            event.preventDefault();
                                        }
                                    },
                                })}
                                className={cn('dropzone_wrapped LicenseDropZone', {
                                    'LicenseDropZone--error': !onDropEnterConfig.enabledOnDropEnter,
                                })}
                                data-enabled={
                                    onDropEnterConfig.enabledOnDropEnter && isDropEnabled && !externalError
                                        ? 'yes'
                                        : 'no'
                                }
                                data-seleniumid="upload-license-dropzone"
                            >
                                <div className="LicenseDropZone-Container">
                                    <input {...getInputProps()} data-seleniumid="upload-file-element" />

                                    {isDropEnabled && !externalError && (
                                        <div className="LicenseDropZone-TextContainer">
                                            <div className="Icon">
                                                <SvgIcon.up iconTitle="Upload Icon" />
                                            </div>

                                            <div className="FirstText">
                                                <span dangerouslySetInnerHTML={{ __html: firstText }} />
                                            </div>
                                            <div className="SecondText">{secondText}</div>
                                        </div>
                                    )}

                                    {(!onDropEnterConfig.enabledOnDropEnter || externalError) && (
                                        <div className="LicenseDropZone-errors">
                                            <SvgIcon.cancel iconTitle="Error Icon" />

                                            <div className="mt-base">
                                                {onDropEnterConfig.typeErrMsg && (
                                                    <ErrorLabel text={onDropEnterConfig.typeErrMsg} />
                                                )}
                                                {onDropEnterConfig.countErrMsg && (
                                                    <ErrorLabel text={onDropEnterConfig.countErrMsg} />
                                                )}
                                                {externalError && (
                                                    <ErrorLabel
                                                        text={l('LICENSE_SUBMISSION.LABELS.UPLOAD.TOTAL_EXCEED_ERROR')}
                                                    />
                                                )}
                                            </div>

                                            {!externalError && (
                                                <PrimaryButton className="mt-base" onClick={this.onTryUploadAgain}>
                                                    {l('LICENSE_SUBMISSION.LABELS.UPLOAD.TRY_AGAIN')}
                                                </PrimaryButton>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </CustomDropZone>
                )}
                {fileItems.length > 0 && (
                    <FileList
                        actions={{
                            onDeleteItem: this.onDeleteItem,
                            onMoveItemDown: this.onMoveItemDown,
                            onMoveItemUp: this.onMoveItemUp,
                        }}
                        allFilesUploadingSuccessfull={allFilesUploadingSuccessful}
                        allTypesCorrect={allTypesCorrect}
                        config={config}
                        fileItems={fileItems}
                        isTotalSizeCorrect={isTotalSizeCorrect}
                        noDuplicateFileNames={noDuplicateFileNames}
                    />
                )}
            </div>
        );
    }
}

export default FileUploader;
