import React from 'react';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';

type Props = {
    children: React.ReactNode;
    className?: string;
    title: string;
};

export default function SuccessMessage({ children, className, title }: Props) {
    return (
        <div className={className}>
            <div className="row row_mini">
                <div className="col-12">
                    <SvgIcon.ok className="tick-blue-icon pull-left" iconTitle="Mark Success Icon" />
                    <h6 className="pull-left thank-you">{title}</h6>
                    <div className="thankYou-block">{children}</div>
                </div>
            </div>
        </div>
    );
}
