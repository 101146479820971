/* eslint-disable react/jsx-no-target-blank */
import cn from 'classnames';
import React from 'react';
import { LinkButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import { MainTitle } from 'app/blocks/titles/titles';
import { useArticlePage, ContextInterface } from '../ArticlePage.context';

import 'app/blocks/views/WidgetView.scss';

function renterCitation(citation: ContextInterface['article']['citations']['items'][number], number) {
    return (
        <>
            <div className="Number" data-seleniumid="citation-number">{`${number + 1}.`}</div>
            <div className="CitationContent" data-seleniumid="citation-content">
                {citation.authors &&
                    citation.authors.map(author =>
                        author.orcid ? (
                            <React.Fragment key={author.orcid}>
                                <a
                                    data-seleniumid={`citation-author-${author.name}`}
                                    href={author.orcid}
                                    target="_blank"
                                >
                                    {author.name}
                                </a>
                                {', '}
                            </React.Fragment>
                        ) : (
                            <span
                                key={author.name}
                                data-seleniumid={`citation-author-${author.name}`}
                            >{`${author.name}, `}</span>
                        ),
                    )}
                {citation.year && <span data-seleniumid="citation-year">{`(${citation.year}) `}</span>}
                {citation.articleTitle && (
                    <span data-seleniumid="citation-article-title">{`${citation.articleTitle}, `}</span>
                )}
                {citation.journalTitle && (
                    <i data-seleniumid="citation-journal-title">{`${citation.journalTitle}, `}</i>
                )}
                {citation.volumeNumber && (
                    <b data-seleniumid="citation-volume-number">{`${citation.volumeNumber}, `}</b>
                )}
                {citation.issueNumber && (
                    <span data-seleniumid="citation-issue-number">{`${citation.issueNumber}, `}</span>
                )}
                {citation.firstPage && <span data-seleniumid="citation-page-range">{`${citation.firstPage}.`}</span>}
                {citation.doi && (
                    <div className="Doi" data-seleniumid="citation-doi">
                        <a data-seleniumid="citation-doi-link" href={`https://doi.org/${citation.doi}`} target="_blank">
                            {`https://doi.org/${citation.doi}`}
                        </a>
                    </div>
                )}
            </div>
        </>
    );
}

function Citations({ l }: { l: l }): React.ReactElement {
    const { article, requiredActions } = useArticlePage();
    const hasCitations = !!article?.citations?.total;
    const showViewAllButton = hasCitations && article.citations.total > 10;

    return (
        <div className={cn('Widget Citations', { WithRequired: requiredActions })} data-seleniumid="citations">
            <div className="CitationsTitle" data-seleniumid="citations-title">
                <MainTitle data-seleniumid="citations-title-text">{l('WIDGETS.CITATIONS.TITLE')}</MainTitle>
                {showViewAllButton && (
                    <LinkButton
                        data-seleniumid="citations-view-full-list"
                        href={`https://doi.org/${article.doi}`}
                        isLinkTag
                        target="_blank"
                    >
                        {l('WIDGETS.CITATIONS.VIEW_FULL_LIST')}
                    </LinkButton>
                )}
            </div>
            {hasCitations && (
                <div className="TotalNumber" data-seleniumid="citations-total-number">
                    {l('WIDGETS.CITATIONS.SUBTITLE', { total: article.citations.total })}
                </div>
            )}
            <div className="CitationsList" data-seleniumid="citations-list">
                {hasCitations ? (
                    article.citations.items.map((citation, i) => (
                        <div key={citation.doi} className="Citation" data-seleniumid="citation">
                            {renterCitation(citation, i)}
                        </div>
                    ))
                ) : (
                    <div className="EmptyCitations" data-seleniumid="citations-empty">
                        <MainTitle data-seleniumid="citations-empty-text">
                            {l('WIDGETS.CITATIONS.EMPTY_CITATIONS')}
                        </MainTitle>
                    </div>
                )}
            </div>
        </div>
    );
}

export default withCodes(Citations, ID.WIDGETS);
