import React from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import PaymentAmountBreakdown from 'app/pages/license-signing-process/Payment/Review/PaymentAmountBreakdown';
import flow from 'app/pages/orders/orders.flow';
import OrdersPanelPriceInfo from 'app/pages/orders/panel/price-info';
import CheckboxButton from 'app/ui/form/CheckboxButton';

export default withCodes(function OrdersOAAmountPayable(props) {
    const {
        l,
        DataItemAndAuxInfo,
        isShowTax,
        handleAgreeToPayChange,
        isAgreeToPay,
        handleAgreeToSocopayPolicy,
        isAgreeToSocopayPolicy,
        isCommonAmountPayablePanel,
        isPaidOAAlipayOrder,
    } = props;

    return (
        <div className="row">
            <div className="col-12 p-md m-btm_md">
                <div className="m-btm_md">
                    <h5 data-seleniumid={isPaidOAAlipayOrder ? 'amount-paid' : 'amount-payable'}>
                        {isPaidOAAlipayOrder
                            ? l('ORDER_PANELS.OA_AMOUNT_PAYABLE.AMOUNT_PAID')
                            : l('ORDER_PANELS.OA_AMOUNT_PAYABLE.TITLE')}
                    </h5>
                </div>
                {flow.isPaymentMethodAlipay(DataItemAndAuxInfo.auxInfo.order) &&
                DataItemAndAuxInfo.auxInfo.order.chinaPayPricingDetails ? (
                    <PaymentAmountBreakdown
                        chinaPayDetails={DataItemAndAuxInfo.auxInfo.order.chinaPayPricingDetails}
                        isAlipayDataCorrect={isAgreeToSocopayPolicy}
                        isOrderHistory={isCommonAmountPayablePanel}
                        l={l}
                        onAlipayAgreeChange={handleAgreeToSocopayPolicy}
                        order={DataItemAndAuxInfo.auxInfo.order}
                        panelName="PaymentAmountPanel"
                    />
                ) : (
                    <OrdersPanelPriceInfo
                        isShowTax={isPaidOAAlipayOrder ? !isShowTax : isShowTax}
                        labels={{ amountPayable: l('ORDER_PANELS.OA_AMOUNT_PAYABLE.AMOUNT_PAYABLE') }}
                        prices={DataItemAndAuxInfo.auxInfo.order.pricingDetails}
                    />
                )}
            </div>

            {handleAgreeToPayChange && (
                <div className="col-12 m-top_none">
                    <CheckboxButton
                        checked={isAgreeToPay}
                        data-seleniumid="agree-to-pay"
                        id="orders__panel_price-info-pay-mode"
                        label={l('LICENSE_SIGNING.PAYMENT.LABEL.DATA_CORRECT_CHECKBOX')}
                        onChange={handleAgreeToPayChange}
                    />
                    <div
                        className="payment-AcceptCheckBoxTip"
                        dangerouslySetInnerHTML={{ __html: l('LICENSE_SIGNING.PAYMENT.LABEL.DATA_CORRECT_TIP') }}
                        data-seleniumid="paymentDataCorrect-text"
                    />
                </div>
            )}
        </div>
    );
}, ID.ORDER_PANELS);
