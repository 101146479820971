import cn from 'classnames';
import React from 'react';
import { LinkButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';

import './StepTracker.scss';

function StepTracker({ l, stepTracker, isPdf, setCurrentStep, currentStep }): React.ReactElement {
    const { signingLicenseStep, obtainOrDownloadLicenseStep, signingInstructionsStep } = stepTracker;

    function StepTrackerColumn({ isConfirmed, isGreen, stepNumber, children }) {
        const isLink = currentStep !== stepNumber && isGreen;

        return (
            <div className="StepTracker-Column">
                <div
                    className={cn('StepTracker-CircleContainer', {
                        GreenLeft: isGreen,
                        GreenRight: isConfirmed,
                    })}
                >
                    {isConfirmed &&
                        (isLink ? (
                            <button
                                className="StepTracker-ConfirmedCircle"
                                data-seleniumid={`step-tracker-${stepNumber}-confirmed-circle`}
                                onClick={() => setCurrentStep(stepNumber)}
                                type="button"
                            >
                                <SvgIcon.ok />
                            </button>
                        ) : (
                            <div
                                className="StepTracker-ConfirmedCircle"
                                data-seleniumid={`step-tracker-${stepNumber}-confirmed-circle`}
                            >
                                <SvgIcon.ok />
                            </div>
                        ))}

                    {!isConfirmed &&
                        (isLink ? (
                            <button
                                className={cn('StepTracker-Circle', { Green: isGreen })}
                                data-seleniumid={`step-tracker-${stepNumber}-circle`}
                                onClick={() => setCurrentStep(stepNumber)}
                                type="button"
                            >
                                {stepNumber}
                            </button>
                        ) : (
                            <div
                                className={cn('StepTracker-Circle', { Green: isGreen })}
                                data-seleniumid={`step-tracker-${stepNumber}-circle`}
                            >
                                {stepNumber}
                            </div>
                        ))}
                </div>
                {isLink ? (
                    <LinkButton
                        className={cn('StepTracker-StepText Underlined', {
                            Green: isGreen,
                        })}
                        data-seleniumid={`step-tracker-${stepNumber}-text`}
                        onClick={() => setCurrentStep(stepNumber)}
                    >
                        {children}
                    </LinkButton>
                ) : (
                    <span
                        className={cn('StepTracker-StepText', {
                            Green: isGreen,
                        })}
                        data-seleniumid={`step-tracker-${stepNumber}-text`}
                    >
                        {children}
                    </span>
                )}
            </div>
        );
    }

    return (
        <div className="StepTracker" data-seleniumid="step-tracker-container">
            <div className="StepTracker-Container">
                <StepTrackerColumn
                    isConfirmed={obtainOrDownloadLicenseStep?.confirmed}
                    isGreen={signingLicenseStep?.confirmed}
                    stepNumber={1}
                >
                    {l(`LICENSE_SUBMISSION.LABELS.STEPS.${isPdf ? 'DOWNLOAD' : 'OBTAIN'}`)}
                </StepTrackerColumn>
                <StepTrackerColumn
                    isConfirmed={signingInstructionsStep?.confirmed}
                    isGreen={obtainOrDownloadLicenseStep?.confirmed}
                    stepNumber={2}
                >
                    {l('LICENSE_SUBMISSION.LABELS.STEPS.INSTRUCTIONS')}
                </StepTrackerColumn>
                <StepTrackerColumn isConfirmed={false} isGreen={signingInstructionsStep?.confirmed} stepNumber={3}>
                    {l('LICENSE_SUBMISSION.LABELS.STEPS.UPLOAD')}
                </StepTrackerColumn>
                <div className="StepTracker-Column">
                    <div className="StepTracker-CircleContainer">
                        <div className="StepTracker-SmallCircle" />
                    </div>
                    <span className="StepTracker-StepText">{l('LICENSE_SUBMISSION.LABELS.STEPS.REVIEW')}</span>
                </div>
            </div>
        </div>
    );
}

export default withCodes2(ID.LICENSE_SUBMISSION)(StepTracker);
