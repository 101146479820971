import validation from 'app/blocks/common/validation';

const DEFAULT_TAB = 'INDIVIDUAL';

function getContactState(order) {
    if (validation.notEmpty(order.correspondenceAddress)) {
        return {
            mainAddress: { ...order.billingAddress, billToType: order.billingAddress?.billToType || DEFAULT_TAB },
        };
    }

    return {
        mainAddress: { billToType: DEFAULT_TAB },
    };
}

// eslint-disable-next-line import/prefer-default-export
export { getContactState };
