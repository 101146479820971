import React from 'react';
import './BlockingLoader.scss';

export default function BlockingLoader({ seleniumId = 'blocking-loader' }): React.ReactElement {
    return (
        <div className="BlockingLoader" data-seleniumid={seleniumId}>
            <div className="BlockingLoader--loading" />
        </div>
    );
}
