import cn from 'classnames';
import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import OrdersDataItem from 'app/pages/orders/data-item';
import payment from 'app/pages/orders/orders.payment';
import ReviewPanelHeader from './ReviewPanelHeader';

function PaymentReviewPanel(props) {
    const DataItemAndAuxInfo = React.useMemo(
        () => OrdersDataItem.create(props.order, props.initOrder),
        [props.order, props.initOrder],
    );

    const paymentMethod = DataItemAndAuxInfo.auxInfo.order?.paymentDetails?.paymentMethod;

    return (
        <section className={cn('payment-panel', props.panelName)} data-seleniumid="order-payment-panel-review">
            <ReviewPanelHeader onEdit={props.onEdit} panelName={props.panelName}>
                {props.l('ORDER_PANELS.PANEL_PAYMENT_REVIEW.TITLE')}
            </ReviewPanelHeader>

            <DataItemAndAuxInfo.OrdersDataItem
                className={`${props.panelName}-ContentRow`}
                innerInfo={[
                    [
                        'paymentDetails.paymentMethod',
                        '',
                        '',
                        function (value) {
                            return payment.getMethodByKey(value).text;
                        },
                    ],
                ]}
                seleniumid="order-review-payment-method"
            />

            {paymentMethod === payment.method.CREDIT_CARD && (
                <>
                    <DataItemAndAuxInfo.OrdersDataItem
                        className={`${props.panelName}-ContentRow`}
                        innerInfo={[['paymentDetails.displayedCreditCardNumber']]}
                        seleniumid="order-review-payment-maskedCardNumber"
                        titleCls="order-article"
                        titleTxt="Credit card number "
                    />
                    <DataItemAndAuxInfo.OrdersDataItem
                        className={`${props.panelName}-ContentRow`}
                        innerInfo={[['paymentDetails.displayedCreditCardExpiry']]}
                        seleniumid="order-review-payment-cardExpire"
                        titleCls="order-article"
                        titleTxt="Expiration date "
                    />
                </>
            )}

            {paymentMethod !== payment.method.CREDIT_CARD && (
                <DataItemAndAuxInfo.OrdersDataItem
                    className={`${props.panelName}-ContentRow`}
                    innerInfo={[['paymentDetails.poNumber']]}
                    seleniumid="order-review-payment-poNumber"
                    titleCls="order-article"
                    titleTxt="Purchase order number "
                />
            )}
        </section>
    );
}

export default withCodes2(ID.ORDER_PANELS)(PaymentReviewPanel);
