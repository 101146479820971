import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { numbers } from 'app/blocks/common/formatters';
import currency from 'app/pages/orders/orders.currency';

function PaymentAmountRow(props) {
    return (
        <div
            className={cn('amount-row', {
                blueRow: props.blue,
                boldRow: props.bold,
                grayedRow: props.grayed,
            })}
        >
            <div className="ar-wrapper">
                <span className="ar-title" data-seleniumid={`order-price-panel-review-row-${props.seleniumid}-title`}>
                    {props.title}
                </span>
                <span
                    className="ar-header"
                    data-seleniumid={`order-price-panel-review-row-${props.seleniumid}-sub-header`}
                >
                    {props.subHeader}
                </span>
            </div>
            <span className="ar-amount" data-seleniumid={`order-price-panel-review-row-${props.seleniumid}-amount`}>
                {(props.currency && currency.getCurrencySign(props.currency)) + numbers.formatNumber(props.amount)}
            </span>
        </div>
    );
}

PaymentAmountRow.propTypes = {
    amount: PropTypes.number.isRequired,
    blue: PropTypes.bool,
    bold: PropTypes.bool,
    currency: PropTypes.string,
    grayed: PropTypes.bool,
    subHeader: PropTypes.string,
    title: PropTypes.string.isRequired,
};

PaymentAmountRow.defaultProps = {
    blue: false,
    bold: false,
    currency: 'USD',
    grayed: false,
    subHeader: '',
};

export default PaymentAmountRow;
