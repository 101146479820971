import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './Article.scss';

function Article({ article, className, journal }) {
    return (
        <div className={cn('article', className)}>
            <div className="article-journalName">{journal.name}</div>
            <div className="article-name">{article.name}</div>
            <div className="article-doi">
                DOI:
                {article.doi}
            </div>
        </div>
    );
}

Article.propTypes = {
    article: PropTypes.shape({
        name: PropTypes.string,
        doi: PropTypes.string,
    }).isRequired,
    className: PropTypes.string,
    journal: PropTypes.shape({
        name: PropTypes.string,
    }).isRequired,
};

Article.defaultProps = {
    className: undefined,
};

export default React.memo(Article);
