// eslint-disable-next-line max-classes-per-file
import PropTypes from 'prop-types';
import React from 'react';
import { Layout } from 'app/blocks/blocks';
import { Button, LinkButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { processing, tryCatch } from 'app/blocks/common/decorators';
import PageEnum from 'app/blocks/common/PageEnum';
import { Handlers, compose } from 'app/blocks/common/utils';
import withScrollToTop from 'app/blocks/common/withScrollToTop';
import EligibilityMessage from 'app/blocks/EligibilityMessage/EligibilityMessage';
import * as licenseMiddleware from 'app/blocks/middleware/license';
import Delimiter from 'app/blocks/or-delimiter/or-delimiter';
import { MainTitle } from 'app/blocks/titles/titles';
import PricesSection from 'app/pages/AuthorPaidOrder/ConfirmationPage/PricesSection';
import OrderCurrencyInfo from 'app/pages/AuthorPaidOrder/ConfirmationPage/PricesSection/OrderCurrencyInfo';
import { BackButton, ForwardButton } from 'app/pages/license-signing-process/buttons';
import Context from 'app/pages/license-signing-process/context/Context';
import { SectionPrompt, SectionTitle } from 'app/pages/license-signing-process/styles';

import './OpenAccessOptions.scss';

const handlers = new Handlers();

class OpenAccessOptions extends React.PureComponent {
    static propTypes = {
        article: PropTypes.shape({ id: PropTypes.string }).isRequired,
        authorSelectedOnlineOpen: PropTypes.bool,
        confirmOnlineOpenOption: PropTypes.func.isRequired,
        confirmSubscriptionOption: PropTypes.func.isRequired,
        eligibility: PropTypes.shape({}),
        getPrevStep: PropTypes.func.isRequired,
        goBack: PropTypes.func.isRequired,
        isChosenOpenAccessOptionLeaving: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isInstitutionalDiscount: PropTypes.bool.isRequired,
        isOnlineOpenOptionConfirming: PropTypes.bool.isRequired,
        isProcessing: PropTypes.bool.isRequired,
        isReadOnly: PropTypes.bool.isRequired,
        isSubscriptionOptionAllowed: PropTypes.bool.isRequired,
        isSubscriptionOptionConfirming: PropTypes.bool.isRequired,
        l: PropTypes.func.isRequired,
        leaveChosenOpenAccessOption: PropTypes.func.isRequired,
    };

    static defaultProps = {
        authorSelectedOnlineOpen: undefined,
        eligibility: undefined,
    };

    renderBackButton() {
        return <BackButton onClick={this.props.goBack}>{this.props.getPrevStep().name}</BackButton>;
    }

    renderContinueButton(isOnlineOpenAlreadyChosen, isReadOnly = false) {
        return (
            <ForwardButton
                data-seleniumid="leaveChosenOptionButton"
                disabled={this.props.isProcessing}
                isProcessing={this.props.isChosenOpenAccessOptionLeaving}
                onClick={this.props.leaveChosenOpenAccessOption}
            >
                {/* eslint-disable-next-line no-nested-ternary */}
                {isReadOnly
                    ? this.props.l('BUTTONS.CONTINUE')
                    : isOnlineOpenAlreadyChosen
                    ? this.props.l('LICENSE_SIGNING.BUTTON.KEEP_ONLINE_OPEN')
                    : this.props.l('LICENSE_SIGNING.BUTTON.KEEP_SUBSCRIPTION')}
            </ForwardButton>
        );
    }

    renderSubscriptionButton(useEligibility, isOnlineOpenAlreadyChosen) {
        const { eligibility, l } = this.props;
        let Component;

        if (useEligibility ? !eligibility.defaultOptInForOo : !isOnlineOpenAlreadyChosen) {
            Component = ForwardButton;
        } else {
            Component = props => <LinkButton {...props} isUnderline />;
        }

        return (
            <Component
                data-seleniumid="makeSubsciptionButton"
                disabled={this.props.isProcessing}
                isProcessing={this.props.isSubscriptionOptionConfirming}
                onClick={this.props.confirmSubscriptionOption}
                processingLabel={l('BUTTONS.PROCESSING')}
            >
                {isOnlineOpenAlreadyChosen
                    ? l('LICENSE_SIGNING.BUTTON.CHANGE_TO_SUBSCRIPTION')
                    : l('LICENSE_SIGNING.BUTTON.MAKE_SUBSCRIPTION')}
            </Component>
        );
    }

    renderMakeOnlineOpenButton(isSubscriptionAlreadyChosen) {
        const { l } = this.props;
        const Component = isSubscriptionAlreadyChosen ? Button : ForwardButton;

        return (
            <Component
                data-seleniumid="makeOnlineOpenButton"
                disabled={this.props.isProcessing}
                isProcessing={this.props.isOnlineOpenOptionConfirming}
                onClick={this.props.confirmOnlineOpenOption}
                processingLabel={l('BUTTONS.PROCESSING')}
            >
                {isSubscriptionAlreadyChosen
                    ? l('LICENSE_SIGNING.BUTTON.CHANGE_TO_ONLINE_OPEN')
                    : l('LICENSE_SIGNING.BUTTON.MAKE_ONLINE_OPEN')}
            </Component>
        );
    }

    renderEligibleNothingChosen() {
        const { eligibility, isSubscriptionOptionAllowed, l } = this.props;

        return (
            <>
                <section className="openAccessOptions">
                    <SectionTitle>{l('LICENSE_SIGNING.OPEN_ACCESS.TITLE.DEFAULT')}</SectionTitle>

                    <EligibilityMessage
                        message={
                            eligibility.eligibilityMessage ||
                            l('LICENSE_SIGNING.OPEN_ACCESS.PROMPT.ELIGIBILITY_MESSAGE')
                        }
                        title={l('LICENSE_SIGNING.OPEN_ACCESS.ELIGIBILITY_MESSAGE.TITLE')}
                    />

                    <SectionPrompt className="openAccessOptions-Prompt">
                        {l('LICENSE_SIGNING.OPEN_ACCESS.PROMPT.ELIGIBILITY_FOUND')}
                    </SectionPrompt>
                </section>

                <div className="licenseSigningProcessPage-backAndForth licenseSigningProcessPage-backAndForth--openAccessOptions">
                    <div className="page-controls">
                        {this.renderBackButton()}

                        {isSubscriptionOptionAllowed && this.renderSubscriptionButton(true, false)}
                        {this.renderMakeOnlineOpenButton(false)}
                    </div>
                </div>
            </>
        );
    }

    renderEligibleOnlineOpenChosen(isReadOnly = false) {
        const { eligibility, isSubscriptionOptionAllowed, l } = this.props;

        return (
            <>
                <section className="openAccessOptions">
                    {isReadOnly ? (
                        <SectionTitle>{l('LICENSE_SIGNING.OPEN_ACCESS.TITLE.ONLINE_OPEN_ORDER_PLACED')}</SectionTitle>
                    ) : (
                        <SectionTitle>{l('LICENSE_SIGNING.OPEN_ACCESS.TITLE.ONLINE_OPEN_CHOSEN')}</SectionTitle>
                    )}

                    <EligibilityMessage
                        message={
                            eligibility.eligibilityMessage ||
                            l('LICENSE_SIGNING.OPEN_ACCESS.PROMPT.ELIGIBILITY_MESSAGE')
                        }
                        title={l('LICENSE_SIGNING.OPEN_ACCESS.ELIGIBILITY_MESSAGE.TITLE')}
                    />

                    <SectionPrompt className="openAccessOptions-Prompt">
                        {l('LICENSE_SIGNING.OPEN_ACCESS.PROMPT.ONLINE_OPEN_CHOSEN')}
                    </SectionPrompt>
                </section>

                <div className="licenseSigningProcessPage-backAndForth licenseSigningProcessPage-backAndForth--openAccessOptions">
                    <div className="page-controls">
                        {this.renderBackButton()}

                        {isReadOnly ? (
                            this.renderContinueButton(true, true)
                        ) : (
                            <>
                                {isSubscriptionOptionAllowed && this.renderSubscriptionButton(true, true)}
                                {this.renderContinueButton(true)}
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    }

    renderEligibleSubscriptionChosen() {
        const { eligibility, l } = this.props;

        return (
            <>
                <section className="openAccessOptions">
                    <SectionTitle>{l('LICENSE_SIGNING.OPEN_ACCESS.TITLE.SUBSCRIPTION_CHOSEN')}</SectionTitle>

                    <SectionPrompt>{l('LICENSE_SIGNING.OPEN_ACCESS.PROMPT.SUBSCRIPTION_CHOSEN')}</SectionPrompt>

                    <MainTitle className="licenseSigningProcessPage-sectionText" component="h3">
                        {l('LICENSE_SIGNING.OPEN_ACCESS.TITLE.DEFAULT')}
                    </MainTitle>

                    <EligibilityMessage
                        message={
                            eligibility.eligibilityMessage ||
                            l('LICENSE_SIGNING.OPEN_ACCESS.PROMPT.ELIGIBILITY_MESSAGE')
                        }
                        title={l('LICENSE_SIGNING.OPEN_ACCESS.ELIGIBILITY_MESSAGE.TITLE')}
                    />

                    <p
                        className="openAccessOptions-Prompt"
                        dangerouslySetInnerHTML={{ __html: l('LICENSE_SIGNING.OPEN_ACCESS.PROMPT.ONLINE_OPEN_CHOSEN') }}
                    />
                </section>

                <div className="licenseSigningProcessPage-backAndForth licenseSigningProcessPage-backAndForth--openAccessOptions">
                    <div className="page-controls">
                        {this.renderBackButton()}

                        {this.renderMakeOnlineOpenButton(true)}
                        {this.renderContinueButton(false)}
                    </div>
                </div>
            </>
        );
    }

    renderNonEligibleNothingChosen() {
        const { isSubscriptionOptionAllowed, l, prices } = this.props;

        return (
            <>
                <section className="openAccessOptions">
                    <SectionTitle>{l('LICENSE_SIGNING.OPEN_ACCESS.TITLE.DEFAULT')}</SectionTitle>

                    <SectionPrompt>{l('LICENSE_SIGNING.OPEN_ACCESS.PROMPT.ELIGIBILITY_NOT_FOUND')}</SectionPrompt>

                    <div className="openAccessOptions-options OpenAccessOptionsContainer">
                        <div className="openAccessOptions-option">
                            <div className="openAccessOptions-optionContent">
                                <MainTitle component="h3">
                                    {l('LICENSE_SIGNING.OPEN_ACCESS.LABEL.OPTION_ONLINE_OPEN')}
                                </MainTitle>
                                <div className="openAccessOptions-optionBody">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: l('LICENSE_SIGNING.OPEN_ACCESS.PROMPT.OPTION_ONLINE_OPEN'),
                                        }}
                                    />
                                    <PricesSection
                                        className="m-top_mini"
                                        isVertical
                                        prices={prices}
                                        title={l('LICENSE_SIGNING.OPEN_ACCESS.LABEL.CURRENCIES_CARD')}
                                    />
                                </div>
                                <OrderCurrencyInfo isStandalone />
                            </div>
                            {this.renderMakeOnlineOpenButton(false)}
                        </div>
                        {isSubscriptionOptionAllowed && (
                            <>
                                <Delimiter className="openAccessOptions-Delimiter" />
                                <div className="openAccessOptions-option">
                                    <div className="openAccessOptions-optionContent">
                                        <MainTitle component="h3">
                                            {l('LICENSE_SIGNING.OPEN_ACCESS.LABEL.OPTION_SUBSCRIPTION')}
                                        </MainTitle>
                                        <div
                                            className="openAccessOptions-optionBody"
                                            dangerouslySetInnerHTML={{
                                                __html: l('LICENSE_SIGNING.OPEN_ACCESS.PROMPT.OPTION_SUBSCRIPTION'),
                                            }}
                                        />
                                    </div>
                                    {this.renderSubscriptionButton(false, false)}
                                </div>
                            </>
                        )}
                    </div>
                </section>
                <div className="licenseSigningProcessPage-backAndForth licenseSigningProcessPage-backAndForth--openAccessOptions">
                    <div className="page-controls">{this.renderBackButton()}</div>
                </div>
            </>
        );
    }

    renderNonEligibleOnlineOpenChosen(isReadOnly = false) {
        const { isSubscriptionOptionAllowed, l, prices } = this.props;

        return (
            <>
                <section className="openAccessOptions">
                    {isReadOnly ? (
                        <SectionTitle>{l('LICENSE_SIGNING.OPEN_ACCESS.TITLE.ONLINE_OPEN_ORDER_PLACED')}</SectionTitle>
                    ) : (
                        <SectionTitle>{l('LICENSE_SIGNING.OPEN_ACCESS.TITLE.ONLINE_OPEN_CHOSEN')}</SectionTitle>
                    )}

                    <SectionPrompt>{l('LICENSE_SIGNING.OPEN_ACCESS.PROMPT.ELIGIBILITY_NOT_FOUND')}</SectionPrompt>

                    <SectionPrompt className="openAccessOptions-Prompt">
                        {l('LICENSE_SIGNING.OPEN_ACCESS.PROMPT.NON_ELIGIBLE_ONLINE_OPEN_CHOSEN')}
                    </SectionPrompt>

                    <PricesSection
                        className="m-top_mini"
                        isVertical
                        prices={prices}
                        title={l('LICENSE_SIGNING.OPEN_ACCESS.LABEL.CURRENCIES_CARD')}
                    />
                    <OrderCurrencyInfo isStandalone />
                </section>

                <div className="licenseSigningProcessPage-backAndForth licenseSigningProcessPage-backAndForth--openAccessOptions">
                    <div className="page-controls">
                        {this.renderBackButton()}

                        {isReadOnly ? (
                            this.renderContinueButton(true, true)
                        ) : (
                            <>
                                {isSubscriptionOptionAllowed && this.renderSubscriptionButton(false, true)}
                                {this.renderContinueButton(true)}
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    }

    renderNonEligibleSubscriptionChosen() {
        const { l, prices } = this.props;

        return (
            <>
                <section className="openAccessOptions">
                    <SectionTitle>{l('LICENSE_SIGNING.OPEN_ACCESS.TITLE.SUBSCRIPTION_CHOSEN')}</SectionTitle>

                    <SectionPrompt>{l('LICENSE_SIGNING.OPEN_ACCESS.PROMPT.SUBSCRIPTION_CHOSEN')}</SectionPrompt>

                    <SectionPrompt className="openAccessOptions-Prompt">
                        {l('LICENSE_SIGNING.OPEN_ACCESS.PROMPT.NON_ELIGIBLE_SUBSCRIPTION_CHOSEN')}
                    </SectionPrompt>

                    <PricesSection
                        className="m-top_mini"
                        isVertical
                        prices={prices}
                        title={l('LICENSE_SIGNING.OPEN_ACCESS.LABEL.CURRENCIES_CARD')}
                    />
                    <OrderCurrencyInfo isStandalone />
                </section>

                <div className="licenseSigningProcessPage-backAndForth licenseSigningProcessPage-backAndForth--openAccessOptions">
                    <div className="page-controls">
                        {this.renderBackButton()}

                        {this.renderMakeOnlineOpenButton(true)}
                        {this.renderContinueButton(false)}
                    </div>
                </div>
            </>
        );
    }

    renderInstitutionalDiscountEligibleNothingChosen() {
        const { eligibility, isSubscriptionOptionAllowed, l, prices } = this.props;

        return (
            <>
                <section className="openAccessOptions">
                    <MainTitle className="licenseSigningProcessPage-sectionTitle">
                        {l('LICENSE_SIGNING.OPEN_ACCESS.TITLE.DEFAULT')}
                    </MainTitle>
                    <EligibilityMessage
                        message={eligibility.eligibilityMessage}
                        title={l('LICENSE_SIGNING.OPEN_ACCESS.ELIGIBILITY_MESSAGE.INSTITUTIONAL_DISCOUNT.TITLE')}
                    />

                    <div className="openAccessOptions-options OpenAccessOptionsContainer">
                        <div className="openAccessOptions-option">
                            <div className="openAccessOptions-optionContent">
                                <MainTitle component="h3">
                                    {l('LICENSE_SIGNING.OPEN_ACCESS.LABEL.OPTION_ONLINE_OPEN')}
                                </MainTitle>
                                <div className="openAccessOptions-optionBody">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: l('LICENSE_SIGNING.OPEN_ACCESS.PROMPT.OPTION_ONLINE_OPEN'),
                                        }}
                                    />
                                    <PricesSection
                                        className="m-top_mini"
                                        isVertical
                                        prices={prices}
                                        title={l('LICENSE_SIGNING.OPEN_ACCESS.LABEL.CURRENCIES_CARD')}
                                    />
                                </div>
                                <OrderCurrencyInfo isStandalone />
                            </div>
                            {this.renderMakeOnlineOpenButton(false)}
                        </div>
                        {isSubscriptionOptionAllowed && (
                            <>
                                <Delimiter className="openAccessOptions-Delimiter" />
                                <div className="openAccessOptions-option">
                                    <div className="openAccessOptions-optionContent">
                                        <MainTitle component="h3">
                                            {l('LICENSE_SIGNING.OPEN_ACCESS.LABEL.OPTION_SUBSCRIPTION')}
                                        </MainTitle>
                                        <div
                                            className="openAccessOptions-optionBody"
                                            dangerouslySetInnerHTML={{
                                                __html: l('LICENSE_SIGNING.OPEN_ACCESS.PROMPT.OPTION_SUBSCRIPTION'),
                                            }}
                                        />
                                    </div>
                                    {this.renderSubscriptionButton(false, false)}
                                </div>
                            </>
                        )}
                    </div>
                </section>
                <div className="licenseSigningProcessPage-backAndForth licenseSigningProcessPage-backAndForth--openAccessOptions">
                    <div className="page-controls">{this.renderBackButton()}</div>
                </div>
            </>
        );
    }

    renderInstitutionalDiscountEligibleOnlineOpenChosen(isReadOnly = false) {
        const { eligibility, isSubscriptionOptionAllowed, l, prices } = this.props;

        return (
            <>
                <section className="openAccessOptions">
                    {isReadOnly ? (
                        <MainTitle className="licenseSigningProcessPage-sectionTitle">
                            {l('LICENSE_SIGNING.OPEN_ACCESS.TITLE.ONLINE_OPEN_ORDER_PLACED')}
                        </MainTitle>
                    ) : (
                        <MainTitle className="licenseSigningProcessPage-sectionTitle">
                            {l('LICENSE_SIGNING.OPEN_ACCESS.TITLE.ONLINE_OPEN_CHOSEN')}
                        </MainTitle>
                    )}
                    <EligibilityMessage
                        message={eligibility.eligibilityMessage}
                        title={l('LICENSE_SIGNING.OPEN_ACCESS.ELIGIBILITY_MESSAGE.INSTITUTIONAL_DISCOUNT.TITLE')}
                    />
                    <p
                        className="openAccessOptions-Prompt"
                        dangerouslySetInnerHTML={{
                            __html: l('LICENSE_SIGNING.OPEN_ACCESS.PROMPT.ELIGIBILITY_NOT_FOUND'),
                        }}
                    />

                    <p
                        className="openAccessOptions-Prompt"
                        dangerouslySetInnerHTML={{
                            __html: l('LICENSE_SIGNING.OPEN_ACCESS.PROMPT.NON_ELIGIBLE_ONLINE_OPEN_CHOSEN'),
                        }}
                    />

                    <PricesSection
                        className="m-top_mini"
                        isVertical
                        prices={prices}
                        title={l('LICENSE_SIGNING.OPEN_ACCESS.LABEL.CURRENCIES_CARD')}
                    />
                    <OrderCurrencyInfo isStandalone />
                </section>

                <div className="licenseSigningProcessPage-backAndForth licenseSigningProcessPage-backAndForth--openAccessOptions">
                    <div className="page-controls">
                        {this.renderBackButton()}

                        {isReadOnly ? (
                            this.renderContinueButton(true, true)
                        ) : (
                            <>
                                {isSubscriptionOptionAllowed && this.renderSubscriptionButton(true, true)}
                                {this.renderContinueButton(true)}
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    }

    renderInstitutionalDiscountEligibleNothingChosenAndDefaultOptInForOoTrue() {
        const { eligibility, isSubscriptionOptionAllowed, l, prices } = this.props;

        return (
            <>
                <section className="openAccessOptions">
                    <MainTitle className="licenseSigningProcessPage-sectionTitle">
                        {l('LICENSE_SIGNING.OPEN_ACCESS.TITLE.DEFAULT')}
                    </MainTitle>

                    <EligibilityMessage
                        message={eligibility.eligibilityMessage}
                        title={l('LICENSE_SIGNING.OPEN_ACCESS.ELIGIBILITY_MESSAGE.INSTITUTIONAL_DISCOUNT.TITLE')}
                    />
                    <p
                        className="openAccessOptions-Prompt"
                        dangerouslySetInnerHTML={{
                            __html: l('LICENSE_SIGNING.OPEN_ACCESS.PROMPT.ELIGIBILITY_NOT_FOUND'),
                        }}
                    />

                    <p
                        className="openAccessOptions-Prompt"
                        dangerouslySetInnerHTML={{
                            __html: l('LICENSE_SIGNING.OPEN_ACCESS.PROMPT.NON_ELIGIBLE_ONLINE_OPEN_CHOSEN'),
                        }}
                    />

                    <PricesSection
                        className="m-top_mini"
                        isVertical
                        prices={prices}
                        title={l('LICENSE_SIGNING.OPEN_ACCESS.LABEL.CURRENCIES_CARD')}
                    />
                    <OrderCurrencyInfo isStandalone />
                </section>

                <div className="licenseSigningProcessPage-backAndForth licenseSigningProcessPage-backAndForth--openAccessOptions">
                    <div className="page-controls">
                        {this.renderBackButton()}

                        {isSubscriptionOptionAllowed && this.renderSubscriptionButton(true, false)}
                        {this.renderMakeOnlineOpenButton(false)}
                    </div>
                </div>
            </>
        );
    }

    renderInstitutionalDiscountEligibleSubscriptionChosen() {
        const { l, prices } = this.props;

        return (
            <>
                <section className="openAccessOptions">
                    <MainTitle className="licenseSigningProcessPage-sectionTitle">
                        {l('LICENSE_SIGNING.OPEN_ACCESS.TITLE.SUBSCRIPTION_CHOSEN')}
                    </MainTitle>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: l('LICENSE_SIGNING.OPEN_ACCESS.TITLE.SUBSCRIPTION_CHOSEN'),
                        }}
                    />

                    <p
                        className="openAccessOptions-Prompt"
                        dangerouslySetInnerHTML={{
                            __html: l('LICENSE_SIGNING.OPEN_ACCESS.PROMPT.NON_ELIGIBLE_SUBSCRIPTION_CHOSEN'),
                        }}
                    />

                    <PricesSection
                        className="m-top_mini"
                        isVertical
                        prices={prices}
                        title={l('LICENSE_SIGNING.OPEN_ACCESS.LABEL.CURRENCIES_CARD')}
                    />
                    <OrderCurrencyInfo isStandalone />
                </section>

                <div className="licenseSigningProcessPage-backAndForth licenseSigningProcessPage-backAndForth--openAccessOptions">
                    <div className="page-controls">
                        {this.renderBackButton()}

                        {this.renderMakeOnlineOpenButton(true)}
                        {this.renderContinueButton(false)}
                    </div>
                </div>
            </>
        );
    }

    render() {
        const { authorSelectedOnlineOpen, eligibility, isInstitutionalDiscount, isLoading, isReadOnly } = this.props;

        if (isLoading) {
            return <Layout isLoading />;
        }

        if (isReadOnly) {
            if (isInstitutionalDiscount) {
                return this.renderInstitutionalDiscountEligibleOnlineOpenChosen(true);
            }

            if (eligibility) {
                return this.renderEligibleOnlineOpenChosen(true);
            }

            return this.renderNonEligibleOnlineOpenChosen(true);
        }

        if (isInstitutionalDiscount) {
            if (authorSelectedOnlineOpen === undefined) {
                if (eligibility.defaultOptInForOo) {
                    return this.renderInstitutionalDiscountEligibleNothingChosenAndDefaultOptInForOoTrue();
                }
                return this.renderInstitutionalDiscountEligibleNothingChosen();
            }
            if (authorSelectedOnlineOpen === true) {
                return this.renderInstitutionalDiscountEligibleOnlineOpenChosen();
            }

            return this.renderInstitutionalDiscountEligibleSubscriptionChosen();
        }

        if (eligibility) {
            if (authorSelectedOnlineOpen === undefined) {
                return this.renderEligibleNothingChosen();
            }
            if (authorSelectedOnlineOpen === true) {
                return this.renderEligibleOnlineOpenChosen();
            }

            return this.renderEligibleSubscriptionChosen();
        }

        if (authorSelectedOnlineOpen === undefined) {
            return this.renderNonEligibleNothingChosen();
        }
        if (authorSelectedOnlineOpen === true) {
            return this.renderNonEligibleOnlineOpenChosen();
        }

        return this.renderNonEligibleSubscriptionChosen();
    }
}

export { OpenAccessOptions };

export default compose(
    withScrollToTop,
    Context.withContext(state => ({
        article: state.article,
        authorSelectedOnlineOpen: state.all.openAccessOption.authorSelectedOnlineOpen,
        confirmOnlineOpenOption: state.confirmOnlineOpenOption,
        confirmSubscriptionOption: state.confirmSubscriptionOption,
        eligibility: state.all.openAccessOption.eligibility,
        getPrevStep: state.getPrevStep,
        goBack: state.goBack,
        isChosenOpenAccessOptionLeaving: !!state.isChosenOpenAccessOptionLeaving,
        isInstitutionalDiscount: state.all.openAccessOption.isInstitutionalDiscount,
        isOnlineOpenOptionConfirming: !!state.isOnlineOpenOptionConfirming,
        isProcessing:
            !!state.isOnlineOpenOptionConfirming ||
            !!state.isSubscriptionOptionConfirming ||
            !!state.isChosenOpenAccessOptionLeaving,
        isReadOnly: !!state.all.openAccessOption.readOnly,
        isSubscriptionOptionAllowed: !!state.all.openAccessOption.enableSubscription,
        isSubscriptionOptionConfirming: !!state.isSubscriptionOptionConfirming,
        leaveChosenOpenAccessOption: state.leaveChosenOpenAccessOption,
    })),
    withCodes2(ID.BUTTONS, ID.LICENSE_SIGNING),
)(
    class extends React.PureComponent {
        // eslint-disable-next-line react/no-unused-class-component-methods
        _ = handlers.link(this, 'onLoad');

        state = {
            isLoading: true,
            prices: undefined,
        };

        onLoad(error) {
            const { onLoadSubstep } = this.props;
            onLoadSubstep(null, PageEnum.OPEN_ACCESS_OPTIONS, error);

            if (error) {
                throw error;
            }
        }

        @processing('isLoading')
        @tryCatch({ errorHandler: e => handlers.onLoad(e) })
        async componentDidMount() {
            const { article } = this.props;

            this.setState({ prices: await licenseMiddleware.getBasePrices(article.id) });
            this.onLoad();
        }

        render() {
            return <OpenAccessOptions {...this.props} {...this.state} />;
        }
    },
);
