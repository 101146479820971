import React from 'react';
import ReactDOM from 'react-dom';
import { ID, l, withCodes } from 'app/blocks/common/codes';
import EligibilityMessage from 'app/blocks/EligibilityMessage/EligibilityMessage';
import StatusPopup, { StatusPopupTypeEnum } from 'app/blocks/StatusPopup/StatusPopup';
import PricesSection from 'app/pages/AuthorPaidOrder/ConfirmationPage/PricesSection';
import DiscountedPricesFootnote from 'app/pages/AuthorPaidOrder/ConfirmationPage/PricesSection/DiscountedPricesFootnote';

const defaultStatusPopupOptions = {
    onCloseStatusPopup: () => {},
    statusPopupError: null,
};

function InitiateStepTop({ isInstitutionalDiscount, prices, statusPopupOptions = defaultStatusPopupOptions }) {
    const errorRef = React.useRef(null);
    React.useEffect(() => {
        if (statusPopupOptions.statusPopupError) {
            setTimeout(() => {
                window.scrollTo(
                    0,
                    // eslint-disable-next-line react/no-find-dom-node
                    window.pageYOffset + ReactDOM.findDOMNode(errorRef.current).getBoundingClientRect().top,
                );
            }, 300);
        }
    }, [statusPopupOptions.statusPopupError]);

    return (
        <>
            {statusPopupOptions.statusPopupError && (
                <StatusPopup
                    ref={errorRef}
                    onCloseStatusPopup={statusPopupOptions.onCloseStatusPopup}
                    seleniumid="paymentInitiateError"
                    type={StatusPopupTypeEnum.ERROR}
                >
                    {statusPopupOptions.statusPopupError.message}
                </StatusPopup>
            )}
            {isInstitutionalDiscount && (
                <EligibilityMessage
                    className="InitiateStepEligibilityMessage"
                    message={l('ORDER_PANELS.INSTITUTIONAL_DISCOUNT.ELIGIBILITY_MESSAGE.DISCOUNT_APPLIED.MESSAGE')}
                    title={l('ORDER_PANELS.INSTITUTIONAL_DISCOUNT.ELIGIBILITY_MESSAGE.DISCOUNT_APPLIED.TITLE')}
                />
            )}

            <PricesSection className="payment-panel" isTransparent prices={prices} />
            {isInstitutionalDiscount && <DiscountedPricesFootnote />}
        </>
    );
}

export default withCodes(InitiateStepTop, ID.ORDER_PANELS);
