import React from 'react';
import appPages from 'app/appPages';
import { processPageLoad as sendAnalytics } from 'app/blocks/analytics/analytics';
import { LinkButton } from 'app/blocks/buttons/buttons';
import ASInfo from 'app/blocks/common/as-info';
import { ID, withCodes } from 'app/blocks/common/codes';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import PageEnum from 'app/blocks/common/PageEnum';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import * as middlewareDashboard from 'app/blocks/middleware/dashboard';
import ProgressIndicator from 'app/blocks/ProgressIndicator/ProgressIndicator';
import StaticContent from 'app/blocks/static-content/static-content';
import { MainTitle } from 'app/blocks/titles/titles';
import { DropdownContainer } from 'app/ui/dropdown';
import Dropdown from 'app/ui/dropdown/Dropdown';
import DashboardPage from './DashboardPage';
import MissingArticle from './MissingArticle';
import RightMenu from './RightMenu';
import './dashboard.scss';

function Dashboard({ l, ...props }: GetProps<typeof DashboardPage>) {
    const [isMissingOpen, setMissingOpen] = React.useState(false);
    const [isMissingArticleLinkHidden, setMissingArticleLinkHidden] = React.useState(true);

    React.useEffect(() => {
        sendAnalytics(appPages.DASHBOARD);
    }, []);

    return (
        <div className="DashboardPage container mt-4x">
            <div className="row">
                <div className="col-lg-8 m-btm_md">
                    <div className="DashboardTitle">
                        <MainTitle data-seleniumid="dashboard-blockTitle">
                            {l('DASHBOARD.DASHBOARD_TITLE_NEW')}
                        </MainTitle>
                        <div hidden={isMissingArticleLinkHidden}>
                            <DropdownContainer className="ArticleMissing">
                                <SvgIcon.articleSearch seleniumid="article-missing-icon" />
                                <LinkButton
                                    className="ArticleMissing-Button"
                                    data-seleniumid="dashboard-missing-article"
                                    onClick={() => setMissingOpen(!isMissingOpen)}
                                >
                                    Articles missing?
                                </LinkButton>
                                {isMissingOpen && (
                                    <Dropdown onClickOutside={() => setMissingOpen(false)}>
                                        <MissingArticle />
                                    </Dropdown>
                                )}
                            </DropdownContainer>
                        </div>
                    </div>
                    <ProgressIndicator<Awaited<ReturnType<typeof middlewareDashboard.getDashboard>>>
                        component={response => <DashboardPage response={response} {...props} />}
                        config={{ repeat: true }}
                        onError={error => {
                            showDialog.addErrorMessageDialog(error);
                            return l('DASHBOARD.PROGRESS_INDICATOR.ERROR_MESSAGE');
                        }}
                        onLoad={() => setMissingArticleLinkHidden(false)}
                        request={middlewareDashboard.getDashboard}
                        seleniumid="dashboard"
                    />
                </div>
                <RightMenu />
            </div>
            <StaticContent className="mt-2x" seleniumid="dashboard-promo-bottom" src="dashboard-promo-bottom.html" />
            <ASInfo page={PageEnum.DASHBOARD} />
        </div>
    );
}

export default withCodes(Dashboard, ID.DASHBOARD);
