import isEmpty from 'lodash.isempty';
import _ from 'underscore';

// Checks for email id pattern validity
function isEmailValid(email: string): boolean {
    let valid = true;
    try {
        let user = null;
        let domain = null;

        // AS-4408 forbid non-ASCII symbols
        // eslint-disable-next-line no-control-regex
        if (
            email.match(
                '^([\\w-+]+(?:\\.[\\w-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([a-z]{2,}(?:\\.[a-z]{2})?)$',
            ) === null
        )
            throw new Error();

        if (email.startsWith(' ') || email.endsWith(' ') || email.includes('\n')) {
            throw new Error();
        }

        let charUName = false;
        if (email.startsWith('"')) {
            user = email.substr(0, email.lastIndexOf('"') + 1);
            domain = email.substr(email.lastIndexOf('"') + 1);

            if (!domain.startsWith('@')) throw new Error();
            domain = domain.substr(1);
            user = user.substr(1, user.length - 2);

            charUName = true;
        } else {
            const emailParts = email.split('@');

            if (
                emailParts.length !== 2 ||
                (emailParts[0] && !emailParts[0].length) ||
                (emailParts[1] && !emailParts[1].length)
            ) {
                throw new Error();
            }

            [user, domain] = emailParts;
        }

        if (domain.match(/\.\./g) !== null) throw new Error();
        if (domain.match(/@/g)) throw new Error();
        if (domain.length < 1) throw new Error();
        // eslint-disable-next-line
        if (domain.match(/[! "'@#$%^&*()+=\{\}\[\]\\\/,`~<>\?]/g)) {
            if (!domain.match(/^\[IPv6:[:0-9a-f]*\]/g)) throw new Error();
        }

        if (!charUName && user.match(/[" ]/g) !== null) throw new Error();
        if (!charUName && user.match(/[" ]/g) !== null) throw new Error();
    } catch (error) {
        valid = false;
    }
    return valid;
}

function isNumeric(n) {
    return !Number.isNaN(parseFloat(n)) && Number.isFinite(n);
}

const validation = {
    VALIDATION_CNST: {
        CODE_FIELD_EMPTY: 'CODE_FIELD_EMPTY',
        CODE_FIELD_INVALID: 'CODE_FIELD_INVALID',
    },
    asFalseIfAllObjPropsEmpty(obj) {
        return !this.isAllKeysFalseOrEmpty(obj) && obj;
    },

    emptyField(message) {
        return this.failResult(this.VALIDATION_CNST.CODE_FIELD_EMPTY, message);
    },
    failResult(code, message) {
        return this.validationResult(code, message);
    },
    hasAnySymbol(value) {
        // eslint-disable-next-line no-control-regex
        return !/^[\s\u{00}-\u{2F}\u{3A}-\u{40}\u{5B}-\u{60}\u{7B}-\u{BF}\u{D7}\u{F7}]*$/iu.test(value);
    },
    isAllKeysFalseOrEmpty(obj) {
        return _.values(obj).every(
            propValue =>
                (typeof propValue !== 'boolean' && _.isEmpty(propValue)) ||
                propValue === false ||
                propValue == null ||
                propValue === '',
        );
    },
    isEmailValid(emailIdVal) {
        // eslint-disable-next-line no-use-before-define
        return isEmailValid(emailIdVal);
    },
    isPasswordValid(passwordValue) {
        const specialsASCII = '\u{21}-\u{2F}\u{3A}-\u{40}\u{5B}-\u{60}\u{7B}-\u{7E}';
        const charsCategories = ['a-z', 'A-Z', '0-9', specialsASCII];
        const lengthMinimum = 10;
        const lengthMaximum = 32;
        const categoriesMinimum = 2;

        const allowed = new RegExp(`^[${charsCategories.join('')}]{${lengthMinimum},${lengthMaximum}}$`);
        if (!passwordValue || !allowed.test(passwordValue)) return false;

        const checkCategories = charsCategories
            .map(chars => new RegExp(`[${chars}]`).test(passwordValue))
            .filter(el => el);
        return checkCategories.length >= categoriesMinimum;
    },
    isPhoneNumberValid(phoneNumberValue) {
        return /^(?!0+$)\d{7,13}$/.test(phoneNumberValue);
    },
    notEmpty(value) {
        if (typeof value === 'boolean') {
            return true;
        }
        if (typeof value === 'function') {
            return true;
        }
        if (value instanceof Date) {
            return true;
        }
        return isNumeric(value) ? true : !isEmpty(value);
    },
    validate: <T>(value: T, ar: Array<[(v?: T) => boolean, string]> = []): string => {
        let error = '';

        ar.some(([validator, message]) => {
            if (validator(value)) {
                return false;
            }

            error = message;
            return true;
        });

        return error;
    },
    validateCharacters(value) {
        return /^[a-zA-Z0-9\xc0-\xfd.,'\s-]*$/.test(value);
    },
    /**
     * Almost like validateInputFullLatinExtendedChars but without several special chars
     * @param value
     * @returns {boolean}
     */
    validateInputConstrainedLatinExtendedChars(value) {
        return /^[A-Za-z\u{C0}-\u{17F}0-9.;,'\s-]*$/u.test(value);
    },
    /**
     * based on
     * https://en.wikipedia.org/wiki/Basic_Latin_(Unicode_block)
     * https://en.wikipedia.org/wiki/Latin-1_Supplement_(Unicode_block)
     * https://en.wikipedia.org/wiki/Latin_Extended-A
     *
     * @param value
     * @returns {boolean}
     */
    validateInputFullLatinExtendedChars(value) {
        return /^[\u{20}-\u{7E}\u{A0}-\u{17F}]*$/u.test(value);
    },

    validatePostalCode(value, pattern) {
        if (pattern !== undefined) {
            const regex = new RegExp(pattern);
            return regex.test(value);
        }
        return true;
    },

    validateRequiredField(item, message) {
        return _.isEmpty(item) && this.emptyField(message);
    },

    validateRequiredPickItem(item, msg) {
        return !(item && (item.name || item.id)) ? this.emptyField(msg) : null;
    },

    validateVatIdNumber(value, pattern) {
        let regexValue: string | RegExp = '';

        if (pattern !== undefined) {
            // decoding base64 encoded regex
            regexValue = window.atob(pattern);
        } else {
            regexValue = /^[a-zA-Z0-9]*$/;
        }

        const regex = new RegExp(regexValue);
        return regex.test(value);
    },

    validationResult(code, message) {
        return {
            code,
            message,
        };
    },
};

async function scorePassword(pass, userDataArr) {
    const zxcvbn = (await import('zxcvbn/dist/zxcvbn')).default;
    const additionalDict = ['wiley'].concat(userDataArr);

    return zxcvbn(pass, additionalDict);
}

async function checkPassStrength(passwordValue, userDataArr = []) {
    const STRENGTH = [
        { color: 'Red', score: 0, text: 'Worst' },
        { color: 'Red', score: 1, text: 'Bad' },
        { color: 'Crimson', score: 2, text: 'Weak' },
        { color: 'DarkOrange', score: 3, text: 'Good' },
        { color: 'MediumSeaGreen', score: 4, text: 'Strong' },
    ];

    const { score } = await scorePassword(passwordValue, userDataArr);
    return STRENGTH.find(el => el.score === score);
}

export default validation;

export { checkPassStrength, isEmailValid, scorePassword };
