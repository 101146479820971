import React from 'react';
import withScrollToContent from 'app/blocks/common/withScrollToContent';
import SuccessMesage from 'app/blocks/confirmation/success-message';
import StaticContent from 'app/blocks/static-content/static-content';

type Props = {
    backToDashboardButton: React.ReactNode;
    name: string;
    seleniumid: string;
};

function BaseConfirmation({ backToDashboardButton, name, seleniumid }: Props) {
    return (
        <div className="row">
            <div className="col-xl-7">
                <SuccessMesage title="Thank you.">
                    <StaticContent key={name} seleniumid={seleniumid} src={`${name}.html`} />
                </SuccessMesage>

                <div className="base__i-block pull-right m-top_md m-l_mini">{backToDashboardButton}</div>
            </div>
        </div>
    );
}

export default withScrollToContent(BaseConfirmation);
