import cn from 'classnames';
import React from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import { asDate } from 'app/blocks/Panel/actions/buttons/panel_actions__buttons.utils';
import { PublicationHistoryView } from 'app/blocks/views/WidgetView';
import { useArticlePage } from 'app/pages/article/ArticlePage.context';

function PublicationHistoryWidget({ l }: { l: l }): React.ReactElement {
    const item = useArticlePage();
    const history = item.history || [];

    return (
        <PublicationHistoryView
            className="PublicationHistoryWidget"
            seleniumid="publicationHistory"
            title={l('WIDGETS.PUBLICATION_HISTORY.TITLE')}
        >
            {history.length ? (
                <ul className="PointList">
                    {history.map(({ date, status }) => (
                        <li key={status} className="Point" data-seleniumid={`point-${status}`}>
                            <div className="PointLine">
                                <div className="Circle Full" />
                                <div
                                    className={cn({
                                        Line: !item.status?.published,
                                        PublishedLine: item.status?.published,
                                    })}
                                />
                            </div>
                            <div className="PointContent" data-seleniumid={`point-${status}-content`}>
                                <b data-seleniumid="title">{l(`WIDGETS.PUBLICATION_HISTORY.DATES.${status}`)}</b>
                                <span data-seleniumid="text">{asDate(date)}</span>
                            </div>
                        </li>
                    ))}
                    {!item.status?.published && (
                        <li className="Point" data-seleniumid="point-IN_PRODUCTION">
                            <div className="PointLine">
                                <div className="Circle Empty" />
                            </div>
                            <div className="PointContent" data-seleniumid="point-IN_PRODUCTION-content">
                                <b data-seleniumid="title">{l('WIDGETS.PUBLICATION_HISTORY.IN_PRODUCTION_TITLE')}</b>
                                {item.requiredActions && (
                                    <span data-seleniumid="text">{l('WIDGETS.PUBLICATION_HISTORY.REQUIRED_TEXT')}</span>
                                )}
                            </div>
                        </li>
                    )}
                </ul>
            ) : (
                <div>{l('WIDGETS.PUBLICATION_HISTORY.EMPTY')}</div>
            )}
        </PublicationHistoryView>
    );
}

export default withCodes(PublicationHistoryWidget, ID.WIDGETS);
