import React, { useEffect, useState } from 'react';
import SearchInput from 'app/blocks/SearchInput/SearchInput';
import './MiniSearch.scss';

type Props = {
    children: React.ReactNode[];
    defaultValue?: string;
    focusedOut?: () => void;
    onInput: (value: string) => void;
    searchResults: 'none' | any[];
    seleniumId: string;
    withSub?: boolean;
};

function MiniSearch({ children, defaultValue, focusedOut, onInput, searchResults, seleniumId, withSub }: Props) {
    /**
     *  searchResults = this is only to handle the preloader
     *  if set to "none" this will not trigger the preloader
     *
     *  Use case article : assign-article_by-id.js
     */
    const [show, setShow] = useState(true);
    const [isLoading, setIsLoading] = useState<boolean | 'none'>(false);
    const [isTyping, setIsTyping] = useState(false);

    const checkLoadingSearchResults = res => {
        if (res && res !== 'none' && isTyping) {
            // Using switch statement for better readability of code rather than conditional statements
            switch (true) {
                case res.length + 1 > 1:
                    setIsLoading(false);
                    break;
                case res.length + 1 < 1:
                    setIsLoading(true);
                    break;
                case res === 'none':
                    setIsLoading('none');
                    break;
                default:
                    setIsLoading('none');
                    break;
            }
        } else if (!res && res !== 'none' && !isTyping) {
            setIsLoading(false);
        } else {
            setIsLoading(false);
            setIsTyping(false);
        }
    };

    useEffect(() => {
        if (defaultValue) {
            setShow(defaultValue.length > 1);
        }
        checkLoadingSearchResults(searchResults);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue, searchResults]);

    const onSearch = event => {
        setIsTyping(!!event.target.value);
        onInput(event.target.value);
    };

    const handleOnBlur = () => {
        if (focusedOut) {
            focusedOut();
        }
    };

    /**
     * This is a work around to hide the search
     * results when clicked.
     */
    const onContent = () => {
        setTimeout(() => {
            setShow(false);
        });
    };

    const handleOnFocus = () => {
        setShow(true);
    };

    const shouldRender = () => {
        return children && children.length + 1 !== 1 && show && isLoading !== 'none' && !isLoading;
    };

    return (
        <div className="MiniSearchWrap" onBlur={handleOnBlur} onFocus={handleOnFocus}>
            <SearchInput
                data-seleniumid={seleniumId || 'mini-search'}
                onSearchQueryChange={onSearch}
                text={defaultValue}
            />
            {isLoading && isLoading !== 'none' && (
                <div className="MiniSearchLoading">
                    <div className="loading">Searching ...</div>
                </div>
            )}

            {withSub && shouldRender() && (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                <ul className="MiniSearchContent" onClick={onContent}>
                    {children}
                </ul>
            )}

            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            {!withSub && shouldRender() && <div onClick={onContent}>{children}</div>}
        </div>
    );
}

export default React.memo(MiniSearch);
