import React from 'react';
import { Button } from 'app/blocks/buttons/buttons';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { compose } from 'app/blocks/common/utils';
import withScrollToTop from 'app/blocks/common/withScrollToTop';
import { useLicenseFlow } from 'app/pages/license-signing-process/context/Context';
import StepEnum from 'app/pages/license-signing-process/StepEnum';
import { SectionPrompt, SectionTitle } from 'app/pages/license-signing-process/styles';
import { navigate } from 'app/route/history';
import './ArticleReassigned.scss';

function ArticleReassigned({ l }: { l: l }) {
    const { all } = useLicenseFlow();

    const { rcaEmail, rcaName } = all[StepEnum.CONFIRMATION];

    return (
        <section className="ArticleReassigned">
            <SvgIcon.ok />

            <div>
                <SectionTitle>{l('LICENSE_SIGNING.CONFIRM_AUTHORS.CONFIRM_RCA.RCA_CHANGED_TITLE')}</SectionTitle>

                <SectionPrompt>
                    {l('LICENSE_SIGNING.CONFIRM_AUTHORS.CONFIRM_RCA.RCA_CHANGED_PROMPT', {
                        name: rcaName,
                        email: rcaEmail,
                    })}
                </SectionPrompt>

                <Button className="mt-2x" data-seleniumid="goToDashboardButton" onClick={() => navigate('/dashboard')}>
                    {l('BUTTONS.BACK_TO_DASHBOARD')}
                </Button>
            </div>
        </section>
    );
}

export default compose(withScrollToTop, withCodes2(ID.BUTTONS, ID.LICENSE_SIGNING))(ArticleReassigned);
