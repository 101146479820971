import moment from 'moment';

export const addressInputMaxLength = {
    FULL_NAME: 30,
    FIRST_NAME: 30,
    LAST_NAME: 30,
    DEPARTMENT: 100,
    STREET_ADDRESS: 30,
    CITY: 30,
    ZIP_CODE: 10,
    PHONE: 15,
    EMAIL: 50,
};

export const funderInputMaxLength = {
    GRANT_NUMBER: 100,
};

export const paymentInputMaxLength = {
    PO_NUMBER: 16,
};

export const vatTaxInputMaxLength = {
    VAT_ID: 25,
    VAT_EXEMPTION: 25,
    TAX_EXEMPTION: 20,
};

const INFINITY_DATE_STR = '2039-12-31';
const INFINITY_DATE_MS = moment(INFINITY_DATE_STR).valueOf();
export const vatTaxExpirations = {
    NOT_EXPIRE_VALUE: INFINITY_DATE_MS,
    IS_NOT_EXPIRE: date => date === INFINITY_DATE_MS || date === INFINITY_DATE_STR,
};
