import React from 'react';
import { Button } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import { viewShareableLink } from 'app/blocks/Panel/actions/buttons/panel_actions__buttons.utils';
import { GrayView, BasicView } from 'app/blocks/views/WidgetView';
import { useArticlePage } from 'app/pages/article/ArticlePage.context';

function ShareWidget({ l }: { l: l }): React.ReactElement {
    const item = useArticlePage();

    const View = item.status.published && item.helpers.hasCitations() ? GrayView : BasicView;
    return (
        <View
            actions={
                <Button data-seleniumid="SHARE_ARTICLE-BUTTON" onClick={() => viewShareableLink(item.article.doi)}>
                    {l('WIDGETS.SHARE_ARTICLE.BUTTON')}
                </Button>
            }
            fill={false}
            icon="share"
            seleniumid="share"
            title={l('WIDGETS.SHARE_ARTICLE.TITLE')}
        >
            <div dangerouslySetInnerHTML={{ __html: l('WIDGETS.SHARE_ARTICLE.CONTENT') }} />
        </View>
    );
}

export default withCodes(ShareWidget, ID.WIDGETS);
