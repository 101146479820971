/* eslint-disable react/no-array-index-key */
import cn from 'classnames';
import React, { Component } from 'react';
import { Button } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import DialogMessage from 'app/blocks/dialog/dialog__message';
import Modal from 'app/blocks/Modal/Modal';
import StaticContent from 'app/blocks/static-content/static-content';
import RadioButton from 'app/ui/form/RadioButton';
import './address-doctor.scss';

class AddressDoctor extends Component<
    {
        onCancel: () => void;
        onContinue: (address: AddressLocation) => void;
        l: l;
        userEntered: AddressLocation;
        suggestions: AddressLocation[];
    },
    { suggestionIndex: number | null }
> {
    static defaultProps = {
        onCancel: () => {},
    };

    state = {
        suggestionIndex: null,
    };

    onCancel = () => {
        this.props.onCancel();
    };

    onContinue = () => {
        const idx = this.state.suggestionIndex;

        if (idx < 0) {
            this.props.onContinue(this.props.userEntered);
        } else {
            this.props.onContinue(this.props.suggestions[idx]);
        }
    };

    onSuggestionChange = suggestionIndex => {
        this.setState({ suggestionIndex });
    };

    describeAddress(address) {
        const serial = [
            address.addressLine1,
            address.addressLine2,
            address.city,
            address.stateName,
            address.zipCode,
            address.countryName,
        ];

        return serial.filter(truthy => !!truthy).join(', ');
    }

    render() {
        const { l, suggestions, userEntered } = this.props;
        const { suggestionIndex } = this.state;

        return (
            <Modal className="Dialog" ignoreBackdropClicks onClose={this.onCancel} type="address-doctor">
                <div className="modal-body Dialog-Body" data-seleniumid="modal-body">
                    <DialogMessage
                        innerContent={
                            <StaticContent key="address-doctor__suggestions" src="address-doctor__suggestions.html" />
                        }
                        type="address-doctor"
                    />

                    <div>
                        <div className="modal-options-list">
                            {suggestions.map((currentSuggestion, idx) => (
                                <RadioButton
                                    key={idx}
                                    checked={suggestionIndex === idx}
                                    className={cn({ 'mt-two-thirds': idx !== 0 })}
                                    data-seleniumid={`suggestion_${idx}_radio`}
                                    id={`suggestion_${idx}_radio`}
                                    label={this.describeAddress(currentSuggestion)}
                                    name="suggestion-radio"
                                    onChange={() => this.onSuggestionChange(idx)}
                                    value={idx}
                                />
                            ))}
                        </div>
                        <div className="DialogAddressDoctor-UserEnteredAddressLabel">
                            {l('ADDRESS_DOCTOR.USER_SUGGESTION_LABEL')}
                        </div>
                        <div className="DialogAddressDoctor-UserEnteredAddressText">
                            <RadioButton
                                checked={suggestionIndex === -1}
                                data-seleniumid="suggestion_user_radio"
                                id="suggestion_user_radio"
                                label={this.describeAddress(userEntered)}
                                name="suggestion-radio"
                                onChange={() => this.onSuggestionChange(-1)}
                                value={-1}
                            />
                        </div>
                    </div>

                    <div className="Dialog-BodyActions Dialog-BodyActions-Row">
                        <Button data-seleniumid="suggestions_cancel_button" onClick={this.onCancel}>
                            {l('ADDRESS_DOCTOR.CANCEL')}
                        </Button>
                        <Button
                            data-seleniumid="suggestions_continue_button"
                            disabled={suggestionIndex == null}
                            onClick={this.onContinue}
                        >
                            {l('ADDRESS_DOCTOR.CONTINUE')}
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default withCodes(AddressDoctor, ID.ADDRESS_DOCTOR);
