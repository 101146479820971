import React, { Component } from 'react';
import _ from 'underscore';
import { processRegistration, pageEvents } from 'app/blocks/analytics/analytics';
import { LinkButton, PrimaryButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import ContentUtils from 'app/blocks/common/content-utils';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import { scrollToElementBySelector } from 'app/blocks/common/scroll';
import Alerts from 'app/blocks/common/spinner';
import { checkPassStrength } from 'app/blocks/common/validation';
import ErrorLabel from 'app/blocks/ErrorLabel/ErrorLabel';
import * as RegisterMiddleware from 'app/blocks/middleware/register';
import { getInvitation } from 'app/blocks/middleware/register';
import Password from 'app/blocks/Password/Password';
import PasswordStrength from 'app/blocks/password-strength';
import { SubTitle } from 'app/blocks/titles/titles';
import TOSConfirmations from 'app/pages/login/tos-confirmations';
import routes from 'app/pages/routes';
import { Input } from 'app/ui/form';
import { validate, validateEmail } from './registration-utils';
import UserExistsModal from './user-exists-modal/user-exists-modal';

function getCountryValue(country): Country {
    return country && country.countryCode ? { id: country.countryCode, name: country.countryName } : undefined;
}

type Props = {
    invitation: Awaited<ReturnType<typeof getInvitation>>;
    l: l;
    onSuccessfulRegistration: (verificationRequired: boolean, registeredEmail: string) => void;
};

type State = {
    isEmailExists: boolean;
    isSubmitting: boolean;
    passwordStrength?: Awaited<ReturnType<typeof checkPassStrength>>;
    validationErrors: Record<string, string>;
    values: {
        areaOfInterest?: string;
        country?: Country;
        email?: string;
        firstName?: string;
        lastName?: string;
        password?: string;
        repeatEmail?: string;
        repeatPassword?: string;
        sendEmail?: boolean;
        skipSecondaryValidation?: boolean;
        wileyTermsOfUseConsent: boolean;
        chinaPIPLConsent: boolean;
    };
};

export class RegistrationForm extends Component<Props, State> {
    state: State;

    options: Record<string, any> = {};

    subscriptions: Function[] = [];

    constructor(props) {
        super(props);

        let firstName = '';
        let lastName = '';
        let email = '';
        let country;

        if (props.invitation) {
            firstName = props.invitation.firstName;
            lastName = props.invitation.lastName;
            email = String(props.invitation.primaryEmailAddr || '').toLowerCase();
            country = getCountryValue(props.invitation.country);
        }

        // eslint-disable-next-line react/state-in-constructor
        this.state = {
            isEmailExists: false,
            isSubmitting: false,
            validationErrors: {},
            values: {
                chinaPIPLConsent: false,
                country,
                email,
                firstName,
                lastName,
                repeatEmail: email,
                skipSecondaryValidation: false,
                wileyTermsOfUseConsent: false,
            },
        };
    }

    componentDidMount() {
        // used to validate email address when visiting via invitation link
        if (this.state.values.email) {
            this.onBlurEmail();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.values.email !== this.state.values.email) {
            this.setState(state => ({ values: { ...state.values, skipSecondaryValidation: false } }));
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(x => x());
    }

    redirectToLogin = () => routes.toLogin();

    redirectToLoginWithEmail = email => routes.toLogin({ email });

    resetError(key) {
        const { validationErrors } = this.state;
        if (validationErrors[key]) {
            this.setState({ validationErrors: { ...validationErrors, [key]: null } });
        }
    }

    changeValue(key, value) {
        this.setState(state => ({ values: { ...state.values, [key]: value } }));
        this.resetError(key);
    }

    extractEmail = email => {
        try {
            return email.match(/\(([^)]+)\)/)[1];
        } catch (e) {
            return '';
        }
    };

    clearEmailValidation = () => {
        const { values } = this.state;
        this.setState({ isEmailExists: false, values: { ...values, skipSecondaryValidation: true } });
    };

    // true - exists, false - OK, text - error
    async serverEmailValidation(email) {
        const { l } = this.props;
        try {
            await RegisterMiddleware.verifyEmailExists(email);
        } catch (error) {
            error.payload = `<strong>${this.extractEmail(error.extendedMessage)}</strong>`;

            switch (error.code) {
                case 'REGISTRATION_EMAIL_ALREADY_REGISTERED_AS_SECONDARY':
                    showDialog.confirmation(error, {
                        cancelBtnLabel: `${l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_WITH`)} ${
                            this.state.values.email
                        }`,
                        confirmBtnLabel: l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_LOGIN_WITH_PRIMARY_EMAIL`),
                        message: error.message && error.message.replace('{0}', error.payload),
                        onApprove: () => this.redirectToLogin(),
                        onReject: this.clearEmailValidation,
                    });
                    return true;
                case 'REGISTRATION_EMAIL_ALREADY_REGISTERED_AS_PRIMARY':
                    showDialog.confirmation(error, {
                        CancelComponent: LinkButton,
                        confirmBtnLabel: l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_LOGIN_WITH_PRIMARY_EMAIL`),
                        onApprove: () => this.redirectToLoginWithEmail(email),
                    });
                    return true;
                case 'REGISTRATION_WILEY_USER':
                    showDialog.error(error);
                    return 'ERROR.REGISTRATION_WILEY_USER';
                case 'INCORRECT_EMAIL_FORMAT':
                    return 'ERROR.INCORRECT_EMAIL_FORMAT';
                case 'ALM_SERVICE_COMMUNICATION_ERROR':
                    return false;
                default:
                    showDialog.error(error, {
                        message: await ContentUtils.getServerErrorMessage(ID.REGISTRATION, error),
                    });
                    return false;
            }
        }

        return false;
    }

    registration = async (searchFullName = false) => {
        const { l, onSuccessfulRegistration } = this.props;
        const { options } = this;
        const { values } = this.state;
        Alerts.renderSpinner();
        try {
            const result = await RegisterMiddleware.register({
                areaOfInterest: values.sendEmail ? values.areaOfInterest : null,
                chinaPIPLConsent: values.chinaPIPLConsent,
                countryCode: values.country?.id,
                firstName: values.firstName.trim(),
                lastName: values.lastName.trim(),
                password: values.password,
                primaryEmailAddr: values.email?.toLowerCase(),
                searchFullName: !values.skipSecondaryValidation ? searchFullName : !values.skipSecondaryValidation,
                sendEmailFlag: values.sendEmail,
                skipSecondaryValidation: values.skipSecondaryValidation,
                wileyTermsOfUseConsent: values.wileyTermsOfUseConsent,
            });

            if (!result.registrationToken) {
                throw new Error(
                    l('REGISTRATION.serverContent.serverMessages.REGISTRATION_CREATE_USER_FAILED_ERR_TEXT'),
                );
            }

            if (typeof options.complete === 'function') {
                await options.complete(result.registrationToken);
            }

            this.options = {};
            onSuccessfulRegistration(result ? result.verificationRequired : false, values.email);
        } catch (error) {
            if (error.code === 'REGISTRATION_ANOTHER_USER_FOUND' && error.payload && error.payload.length > 0) {
                showDialog.modal(closeAnd => ({
                    onClose: closeAnd(),
                    onLogin: closeAnd(this.redirectToLogin),
                    onRegisterNewAccount: closeAnd(() => this.registration()),
                    type: UserExistsModal,
                    users: error.payload,
                }));
            } else {
                showDialog.error(error, {
                    message: await ContentUtils.getServerErrorMessage(ID.REGISTRATION, error),
                });
            }
        } finally {
            Alerts.removeSpinner();
        }
    };

    onBlurEmail = async () => {
        const {
            values: { email },
        } = this.state;

        if (!(email || '').trim()) {
            return;
        }

        const error = validateEmail(email?.toLowerCase());

        if (!error && (await this.serverEmailValidation(email)) === true) {
            this.setState({ isEmailExists: true });
            return;
        }

        this.setState(({ validationErrors }) => ({ validationErrors: { ...validationErrors, email: error } }));
    };

    onChangeInput = async e => {
        const { values } = this.state;
        const key = e.target.getAttribute('id');
        const value = ['email', 'repeatEmail'].includes(key) ? e.target.value.trim() : e.target.value;

        this.changeValue(key, value);

        if (key === 'password') {
            if (value.length === 0) {
                this.setState({ passwordStrength: null });
            } else {
                const strength = await checkPassStrength(value, [values.firstName, values.lastName]);
                this.setState({ passwordStrength: strength });
            }
        }
    };

    onFocusInput = e => {
        const key = e.target.getAttribute('id');
        if (key === 'email') this.setState({ isEmailExists: false });
        this.resetError(key);
    };

    onSubmitRegistration = async e => {
        const { isEmailExists, isSubmitting, values } = this.state;
        e.preventDefault();
        if (isSubmitting) return false;

        this.setState({ isSubmitting: true });
        const validateResult = validate(
            values.firstName || '',
            values.lastName || '',
            values.country?.id || '',
            values.email?.toLowerCase() || '',
            values.repeatEmail?.toLowerCase() || '',
            values.password || '',
            values.repeatPassword || '',
            values.sendEmail ? values.areaOfInterest : null,
            values.wileyTermsOfUseConsent || false,
            values.country?.id === 'CN' ? values.chinaPIPLConsent || false : false,
        );
        const isValid = !isEmailExists && Object.values(validateResult).every(el => _.isEmpty(el));

        this.setState({ validationErrors: validateResult }, () => !isValid && scrollToElementBySelector('.ErrorLabel'));

        if (isValid) {
            processRegistration({ step: 'registration form submitted' }, pageEvents.REGISTRATION_COMPLETED);
            await this.registration(true);
        } else {
            processRegistration(
                { step: 'registration form error', validateResult },
                pageEvents.REGISTRATION_FORM_ERROR,
            );
        }
        this.setState({ isSubmitting: false });
        return true;
    };

    onChangeSendEmail = checked => {
        this.setState(state => ({
            values: {
                ...state.values,

                areaOfInterest: undefined,
                sendEmail: checked,
            },
        }));

        this.resetError('areaOfInterest');
    };

    render() {
        const { l } = this.props;
        const {
            isEmailExists,
            isSubmitting,
            passwordStrength,
            validationErrors,
            values: {
                chinaPIPLConsent,
                country,
                email,
                firstName,
                lastName,
                password,
                repeatEmail,
                repeatPassword,
                sendEmail,
                wileyTermsOfUseConsent,
            },
        } = this.state;

        return (
            <form className="RegisterBlock-Form" data-seleniumid="register-form" onSubmit={this.onSubmitRegistration}>
                <div className="RegisterRow">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: l('REGISTRATION.uiLabelMessages.REQUIRED_FIELDS'),
                        }}
                    />
                </div>
                <SubTitle className="RegisterRow-Title FirstTitle">
                    {l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_ABOUT_YOU_LABEL`)}
                </SubTitle>
                <div className="AboutYouBlock">
                    <div className="RegisterRow">
                        <div className="RegisterRow-Label">
                            {l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_FIRST_NAME_LABEL`)}
                            <span className="Required">*</span>
                        </div>
                        <div className="RegisterRow-Input" data-seleniumid="regfirstNameDiv">
                            <Input
                                data-seleniumid="register-firstName"
                                id="firstName"
                                isError={!!validationErrors.firstName}
                                maxLength={100}
                                onChange={this.onChangeInput}
                                onFocus={this.onFocusInput}
                                placeholder={l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_FIRST_NAME_LABEL`)}
                                type="text"
                                value={firstName}
                            />
                            {validationErrors.firstName && <ErrorLabel text={l(validationErrors.firstName)} />}
                        </div>
                    </div>
                    <div className="RegisterRow">
                        <div className="RegisterRow-Label">
                            {l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_LAST_NAME_LABEL`)}
                            <span className="Required">*</span>
                        </div>
                        <div className="RegisterRow-Input" data-seleniumid="reglastNameDiv">
                            <Input
                                data-seleniumid="register-lastName"
                                id="lastName"
                                isError={!!validationErrors.lastName}
                                maxLength={100}
                                onChange={this.onChangeInput}
                                onFocus={this.onFocusInput}
                                placeholder={l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_LAST_NAME_PLACEHOLDER`)}
                                type="text"
                                value={lastName}
                            />
                            {validationErrors.lastName && <ErrorLabel text={l(validationErrors.lastName)} />}
                        </div>
                    </div>
                </div>

                <SubTitle className="RegisterRow-Title">
                    {l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_ACCNT_INFO_LABEL`)}
                </SubTitle>
                <div className="AccountInformationBlock">
                    <div className="RegisterRow">
                        <div className="RegisterRow-Label">
                            {l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_PRIMARY_EMAIL_ADDRESS_LABEL`)}
                            <span className="Required">*</span>
                        </div>
                        <div className="RegisterRow-Input" data-seleniumid="regEmailDiv">
                            <Input
                                data-seleniumid="register-email"
                                id="email"
                                isError={!!validationErrors.email || isEmailExists}
                                maxLength={100}
                                onBlur={this.onBlurEmail}
                                onChange={this.onChangeInput}
                                onFocus={this.onFocusInput}
                                placeholder={l(
                                    `${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_PRIMARY_EMAIL_PLACEHOLDER`,
                                )}
                                type="email"
                                value={email}
                            />
                            {validationErrors.email && <ErrorLabel text={l(validationErrors.email)} />}
                            {isEmailExists && (
                                <ErrorLabel
                                    data-seleniumid="emailExists"
                                    text={l(
                                        `${ID.REGISTRATION}.errorMessages.REGISTRATION_EMAIL_ALREADY_EXISTS_ERR_TEXT`,
                                    )}
                                />
                            )}
                        </div>
                        <div className="RegisterRow-SubText">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: l(
                                        `${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_PRIMARY_EMAIL_ADDRESS_LABEL_HELP`,
                                    ),
                                }}
                            />
                        </div>
                    </div>
                    <div className="RegisterRow">
                        <div className="RegisterRow-Label">
                            {l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_RETYPE_PRIMARY_EMAIL_ADDRESS_LABEL`)}
                            <span className="Required">*</span>
                        </div>
                        <div className="RegisterRow-Input" data-seleniumid="regRepeatEmailDiv">
                            <Input
                                data-seleniumid="register-repeatEmail"
                                id="repeatEmail"
                                isError={!!validationErrors.repeatEmail}
                                maxLength={100}
                                onChange={this.onChangeInput}
                                onFocus={this.onFocusInput}
                                placeholder={l(
                                    `${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_RETYPE_PRIMARY_EMAIL_PLACEHOLDER`,
                                )}
                                type="email"
                                value={repeatEmail}
                            />
                            {validationErrors.repeatEmail && <ErrorLabel text={l(validationErrors.repeatEmail)} />}
                        </div>
                    </div>
                    <div className="RegisterRow">
                        <div className="RegisterRow-Label">
                            {l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_PASSWORD_LABEL`)}
                            <span className="Required">*</span>
                        </div>
                        <div className="RegisterRow-Input" data-seleniumid="regPwdDiv">
                            <Password
                                autoComplete="new-password"
                                id="password"
                                isError={!!validationErrors.password}
                                maxLength={32}
                                onChange={this.onChangeInput}
                                onFocus={this.onFocusInput}
                                placeholder={l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_PASSWORD_PLACEHOLDER`)}
                                seleniumid="register-password"
                                value={password}
                            />
                            {validationErrors.password && (
                                <ErrorLabel
                                    data-seleniumid="password-alert-message"
                                    text={l(validationErrors.password)}
                                />
                            )}
                            {passwordStrength && <PasswordStrength {...passwordStrength} />}
                        </div>
                        <div className="RegisterRow-SubText">
                            <span>
                                {l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_STRICT_PASSWORD_LABEL_HELP`)}
                            </span>
                        </div>
                    </div>
                    <div className="RegisterRow">
                        <div className="RegisterRow-Label">
                            {l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_CONFIRM_PASSWORD_LABEL`)}
                            <span className="Required">*</span>
                        </div>
                        <div className="RegisterRow-Input" data-seleniumid="regRepeatPwdDiv">
                            <Password
                                id="repeatPassword"
                                isError={!!validationErrors.repeatPassword}
                                maxLength={32}
                                onChange={this.onChangeInput}
                                onFocus={this.onFocusInput}
                                placeholder={l(
                                    `${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_CONFIRM_PASSWORD_PLACEHOLDER`,
                                )}
                                seleniumid="register-repeatPassword"
                                value={repeatPassword}
                            />
                            {validationErrors.repeatPassword && (
                                <ErrorLabel text={l(validationErrors.repeatPassword)} />
                            )}
                        </div>
                    </div>
                </div>

                <TOSConfirmations
                    chinaPIPLConsent={chinaPIPLConsent}
                    country={country}
                    onChangeAreaOfInterest={selected => this.changeValue('areaOfInterest', selected)}
                    onChangeChinaPIPLConsent={checked => this.changeValue('chinaPIPLConsent', checked)}
                    onChangeCountry={pCountry => this.changeValue('country', pCountry)}
                    onChangeSendEmail={this.onChangeSendEmail}
                    onChangeWileyTermsOfUseConsent={checked => this.changeValue('wileyTermsOfUseConsent', checked)}
                    onFocusAreas={() => this.resetError('areaOfInterest')}
                    onFocusCountry={() => this.resetError('country')}
                    sendEmail={sendEmail}
                    showAreas
                    showConsents
                    showCountry
                    showPIPL
                    validationErrors={validationErrors}
                    wileyTermsOfUseConsent={wileyTermsOfUseConsent}
                />

                <div className="RegisterRow-Actions">
                    <PrimaryButton
                        data-seleniumid="register-registerBtn"
                        data-toggle="modal"
                        disabled={isSubmitting}
                        formNoValidate
                        name="button"
                        type="submit"
                    >
                        {isSubmitting
                            ? l('BUTTONS.LOADING')
                            : l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_REGISTER_BUTTON_LABEL`)}
                    </PrimaryButton>
                </div>
            </form>
        );
    }
}

export default withCodes2(ID.REGISTRATION, ID.ERROR, ID.PROFILE_ACCOUNT, ID.BUTTONS)(RegistrationForm);
