import get from 'lodash.get';
import React from 'react';
import { processSignLicenseClick as sendAnalyticsOnClick } from 'app/blocks/analytics/analytics';
import { removePaymentCheckout } from 'app/blocks/analytics/storage';
import { PrimaryButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import PageEnum from 'app/blocks/common/PageEnum';
import { compose } from 'app/blocks/common/utils';
import CoverImageWidget from 'app/pages/article/widgets/CoverImageWidget';
import ArticleForConfirmationPage from 'app/pages/license-signing-process/Article/ArticleForConfirmationPage';
import ConfirmationTypeEnum from 'app/pages/license-signing-process/Confirmation/ConfirmationTypeEnum';
import IncorrectListStop from 'app/pages/license-signing-process/ConfirmAuthors/ConfirmAuthorRoles/IncorrectListStop';
import ArticleReassigned from 'app/pages/license-signing-process/ConfirmAuthors/ConfirmRCA/ArticleReassigned';
import Context from 'app/pages/license-signing-process/context/Context';
import StepEnum from 'app/pages/license-signing-process/StepEnum';
import { SectionPrompt, SectionTitle } from 'app/pages/license-signing-process/styles';
import routes from 'app/pages/routes';
import './Confirmation.scss';

const pages = {
    [ConfirmationTypeEnum.COMBINED_CONFIRMATION]: PageEnum.CONFIRMATION_ONLINE_OPEN_ORDER_AND_LICENSE_SUBMITTED,
    [ConfirmationTypeEnum.COMBINED_CONFIRMATION]: PageEnum.CONFIRMATION_LICENSE_SUBMITTED,
    [ConfirmationTypeEnum.OAORDER_CONFIRMATION]: PageEnum.CONFIRMATION_OPEN_ACCESS_ORDER_SUBMITTED,
    [ConfirmationTypeEnum.OOORDER_CONFIRMATION]: PageEnum.CONFIRMATION_ONLINE_OPEN_ORDER_SUBMITTED,
};

function Template({ article, journal, l, prompt, title, seleniumid, woadMessage, coverWidget }) {
    return (
        <div className="licenseSigningProcessPage">
            <PrimaryButton
                data-seleniumid="goToDashboardButton"
                onClick={() => routes.navigateToUrl(`article/${article.id}`)}
            >
                {l('BUTTONS.BACK_TO_ARTICLE_PAGE')}
            </PrimaryButton>

            <section className="licenseSigningConfirmation mt-2x" data-seleniumid={seleniumid}>
                <SectionTitle>{title}</SectionTitle>

                <SectionPrompt className="licenseSigningConfirmation-prompt">{prompt}</SectionPrompt>

                {woadMessage && (
                    <p
                        className="licenseSigningConfirmation-woadMessage mt-2x"
                        dangerouslySetInnerHTML={{ __html: woadMessage }}
                        data-seleniumid="licenseSigningConfirmation-woadMessage"
                    />
                )}

                <ArticleForConfirmationPage article={article} className="mt-2x" isSmall journal={journal} />
                {coverWidget?.coverCharge ? (
                    (!coverWidget?.publishedStatus ||
                        (coverWidget?.publishedStatus && article?.pubStatus === 'EARLY_VIEW')) && (
                        <div className="licenseSigningConfirmation-coverWidget">
                            <CoverImageWidget />
                        </div>
                    )
                ) : (
                    <div />
                )}
            </section>
        </div>
    );
}

function getPostfix(confirmation) {
    if (confirmation.isWaiver) {
        return '.WAIVER';
    }

    if (confirmation.priceIsZero) {
        return '.ZERO_PRICE';
    }

    if (confirmation.isAuthorPaidOrder) {
        return '.AUTHOR_PAID';
    }

    return '.FUNDER_PAID';
}

function getSeleniumPostfix(confirmation) {
    if (confirmation.isWaiver) {
        return '--WaivedOrder';
    }

    if (confirmation.priceIsZero) {
        return '--ZeroPriceOrder';
    }

    if (confirmation.isAuthorPaidOrder) {
        return '--AuthorPaidOrder';
    }

    return '--FunderPaidOrder';
}

function OnlineOpenOrderAndLicenseSubmitted({ article, confirmation, journal, l, woadMessage, coverWidget }) {
    const postfix = getPostfix(confirmation);
    const seleniumidPostfix = getSeleniumPostfix(confirmation);

    return (
        <Template
            article={article}
            coverWidget={coverWidget}
            journal={journal}
            l={l}
            prompt={l(`LICENSE_SIGNING.CONFIRMATION.PROMPT.OO_ORDER_AND_LICENSE${postfix}`)}
            seleniumid={`licenseSigningConfirmation--OnlineOpenOrderAndLicenseSubmitted${seleniumidPostfix}`}
            title={l('LICENSE_SIGNING.CONFIRMATION.TITLE.OO_ORDER_AND_LICENSE')}
            woadMessage={woadMessage}
        />
    );
}

function LicenseSubmitted({ article, journal, l, woadMessage, coverWidget }) {
    return (
        <Template
            article={article}
            coverWidget={coverWidget}
            journal={journal}
            l={l}
            prompt={l('LICENSE_SIGNING.CONFIRMATION.PROMPT.LICENSE')}
            seleniumid="licenseSigningConfirmation--licenseSubmitted"
            title={l('LICENSE_SIGNING.CONFIRMATION.TITLE.LICENSE')}
            woadMessage={woadMessage}
        />
    );
}

function OpenAccessOrderSubmitted({ article, journal, l, coverWidget }) {
    return (
        <Template
            article={article}
            coverWidget={coverWidget}
            journal={journal}
            l={l}
            prompt={l('LICENSE_SIGNING.CONFIRMATION.PROMPT.OA_ORDER')}
            seleniumid="licenseSigningConfirmation--openAccessOrderSubmitted"
            title={l('LICENSE_SIGNING.CONFIRMATION.TITLE.OA_ORDER')}
        />
    );
}

function OnlineOpenOrderSubmitted({ article, confirmation, journal, l, woadMessage, coverWidget }) {
    const postfix = getPostfix(confirmation);
    const seleniumidPostfix = getSeleniumPostfix(confirmation);

    return (
        <Template
            article={article}
            coverWidget={coverWidget}
            journal={journal}
            l={l}
            prompt={l(`LICENSE_SIGNING.CONFIRMATION.PROMPT.OO_ORDER${postfix}`, {
                href: `#orders/focusOnArticle/${article.id}`,
            })}
            seleniumid={`licenseSigningConfirmation--OnlineOpenOrderSubmitted${seleniumidPostfix}`}
            title={l('LICENSE_SIGNING.CONFIRMATION.TITLE.OO_ORDER')}
            woadMessage={woadMessage}
        />
    );
}

function Confirmation({ type, onLoadSubstep, ...props }) {
    React.useEffect(() => {
        const data = removePaymentCheckout();
        if (data) {
            sendAnalyticsOnClick(data);
        }
        if (onLoadSubstep && typeof onLoadSubstep === 'function') {
            onLoadSubstep(null, pages[type]);
        }
    }, [onLoadSubstep, type]);

    if (type === ConfirmationTypeEnum.COMBINED_CONFIRMATION) {
        return <OnlineOpenOrderAndLicenseSubmitted {...props} />;
    }
    if (type === ConfirmationTypeEnum.LICENSE_CONFIRMATION) {
        return <LicenseSubmitted {...props} />;
    }
    if (type === ConfirmationTypeEnum.OAORDER_CONFIRMATION) {
        return <OpenAccessOrderSubmitted {...props} />;
    }
    if (type === ConfirmationTypeEnum.OOORDER_CONFIRMATION) {
        return <OnlineOpenOrderSubmitted {...props} />;
    }
    if (type === ConfirmationTypeEnum.AUTHOR_LIST_IS_INCORRECT) {
        return <IncorrectListStop />;
    }
    if (type === ConfirmationTypeEnum.RCA_CHANGED) {
        return <ArticleReassigned />;
    }

    return null;
}

export {
    Confirmation,
    LicenseSubmitted,
    OnlineOpenOrderAndLicenseSubmitted,
    OpenAccessOrderSubmitted,
    OnlineOpenOrderSubmitted,
};
export default compose(
    Context.withContext(state => ({
        article: state.article,
        journal: state.journal,
        confirmation: get(state, `all.${StepEnum.CONFIRMATION}`),
        type: get(state, `all.${StepEnum.CONFIRMATION}.page`),
        onLoadSubstep: state.onLoadSubstep,
        woadMessage: get(state, `all.${StepEnum.CONFIRMATION}.woadMessage`),
        coverWidget: get(state, `all.${StepEnum.CONFIRMATION}.coverWidget`),
    })),
    withCodes2(ID.LICENSE_SIGNING, ID.BUTTONS),
)(Confirmation);
