import { lAsync } from 'app/blocks/common/codes';
import { confirm, confirmType } from 'app/blocks/Modal/utils';
import ConfirmChangeToSubscriptionModal from './ConfirmChangeToSubscriptionModal';

async function confirmSubscriptionSelection() {
    return confirm(await lAsync('LICENSE_SIGNING.OPEN_ACCESS.CONFIRM_SUBSCRIPTION_POPUP.TEXT'));
}

function confirmChangeFromOnlineOpenToSubscription() {
    return confirmType(ConfirmChangeToSubscriptionModal);
}

export { confirmChangeFromOnlineOpenToSubscription, confirmSubscriptionSelection };
