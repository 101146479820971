import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { byDiscountVisibility } from 'app/pages/license-signing-process/DiscountType';
import OrdersPanelPriceInfo from 'app/pages/orders/panel/price-info';
import ReviewPanelHeader from './ReviewPanelHeader';

const mapToPrices = order => {
    const result = {
        basePrice: undefined,
        currency: undefined,
        discountAmount: undefined,
        taxAmount: undefined,
        totalAmount: undefined,
        totalAmountBeforeTax: undefined,
    };
    const { prices = [] } = order;

    if (!prices[0]) {
        return result;
    }

    const price = prices[0];
    const { appliedDiscounts = [] } = price;
    const discount = appliedDiscounts.filter(byDiscountVisibility)[0] || {};
    const discountAmount = discount?.absoluteDiscount || 0;

    result.basePrice = price.factoredAPC;
    result.currency = price.currency;
    result.discountAmount = discountAmount;
    result.taxAmount = price.calculatedTax || 0;
    result.totalAmount = price.finalAPC;
    result.totalAmountBeforeTax = price.preTaxAPC;

    return result;
};

function PaymentAmountReviewPanel({ l, order, panelName }) {
    const { pricingDetails = {} } = order;
    const isError = !!pricingDetails.sapError;

    return (
        <section className={cn('payment-panel', panelName)} data-seleniumid="order-price-panel-review">
            <ReviewPanelHeader isEditable={false} panelName={panelName}>
                {l('ORDER_PANELS.OO_AMOUNT_PAYABLE.TITLE')}
            </ReviewPanelHeader>
            {!isError && (
                <OrdersPanelPriceInfo
                    className="mt-base"
                    isNew
                    isShowTax
                    labels={{ amountPayable: l('ORDER_PANELS.OO_AMOUNT_PAYABLE.AMOUNT_PAYABLE') }}
                    prices={mapToPrices(order)}
                />
            )}
            {isError && (
                <div
                    className="mt-base price-simulation-unavailable-text"
                    data-seleniumid="price-simulation-unavailable-text"
                >
                    {' '}
                    {l('ORDER_PANELS.OO_AMOUNT_PAYABLE.UNAVAILABLE')}
                </div>
            )}
        </section>
    );
}

export default withCodes2(ID.ORDER_PANELS)(PaymentAmountReviewPanel);

PaymentAmountReviewPanel.propTypes = {
    l: PropTypes.func.isRequired,
    order: PropTypes.shape({}).isRequired,
    panelName: PropTypes.string.isRequired,
};
