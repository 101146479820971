import _ from 'underscore';
import { ValidationError } from 'app/blocks/common/utils-errortypes';

export default paymentDetails => {
    if (_.isEmpty(paymentDetails.paymentMethod)) {
        const error = new ValidationError('Payment method is empty.', { paymentMethod: 'Payment method is empty.' });
        return Promise.reject(error);
    }

    return Promise.resolve();
};
