import uniqBy from 'lodash.uniqby';
import showDialog from 'app/blocks/common/jsx/dialogModal';

const PAGE_SIZE = 5;

const page = i => {
    if (i <= 0) {
        return {
            offset: 0,
            size: PAGE_SIZE,
        };
    }
    return {
        offset: (i - 1) * PAGE_SIZE,
        size: PAGE_SIZE * 2,
    };
};

function showErrorAndGoBack(error) {
    showDialog.error(error, {
        onClose: () => window.history.back(),
    });
}

const Self = {
    async seeMore() {
        this.setState({ isLoadingMore: true });

        const { pageIndex } = this.state;

        try {
            const data = await this.search(page(pageIndex + 1));

            data.items = uniqBy([...this.state.data.items, ...data.items], e => e.id);

            this.setState({
                data,
                isLoadingMore: false,
                pageIndex: pageIndex + 1,
            });
        } catch (error) {
            showErrorAndGoBack(error);
        }
    },

    async filter(type) {
        this.setState({ isLoading: true });

        const { filter } = this.state;

        if (filter.has(type)) {
            filter.delete(type);
        } else {
            filter.add(type);
        }

        try {
            const data = await this.search(page(0));
            this.setState({
                data,
                isLoading: false,
                filter,
                pageIndex: 0,
            });
        } catch (error) {
            showErrorAndGoBack(error);
        }
    },

    getInitialState() {
        return {
            isLoading: true,
            data: { items: [] },
            pageIndex: 0,
            isLoadingMore: false,
            filter: new Set(),
            facet: {},
        };
    },

    async onFirstLoad() {
        if (this.props.context) {
            const { pageIndex } = this.props.context;

            this.setState({
                data: this.props.context.data,
                isLoading: false,
                pageIndex,
                facet: this.props.context.facet,
                filter: this.props.context.filter,
            });
            return;
        }

        try {
            const data = await this.search(page(0));

            this.setState({
                data,
                isLoading: false,
                facet: data.facets ? data.facets[this.getFilterName()] || [] : [],
            });
        } catch (error) {
            showErrorAndGoBack(error);
        }
    },
};

export default Self;
