import { autobind } from 'core-decorators';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import { Layout, Title, WhiteBlock } from 'app/blocks/blocks';
import ASInfo from 'app/blocks/common/as-info';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { tryCatch } from 'app/blocks/common/decorators';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import PageEnum from 'app/blocks/common/PageEnum';
import * as Utils from 'app/blocks/common/utils';
import { Handlers } from 'app/blocks/common/utils';
import { consumeAnalyticsContext } from 'app/blocks/common/withAnalytics';
import * as middlewareLicense from 'app/blocks/middleware/license';
import * as middleware from 'app/blocks/middleware/middleware';
import { showOutdatedDashboardModal } from 'app/blocks/Modal/OutdatedDashboardModal';
import { getStatusShort } from 'app/blocks/Panel/actions/buttons/panel_actions__buttons.utils';
import convertItem from 'app/pages/article/utils';
import StepEnum from 'app/pages/license-signing-process/StepEnum';
import { OAorOOFilter, TimeFilter } from 'app/pages/orders/filters';
import flow from 'app/pages/orders/orders.flow';
import OrdersSingleOA from 'app/pages/orders/single/oa';
import OrdersSingleOO from 'app/pages/orders/single/oo';
import routes from 'app/pages/routes';
import { navigate } from 'app/route/history';
import DashboardActionCodes from 'app/types/dashboard-action-codes';
import Select from 'app/ui/form/Select';
import OrderItem from './OrderItem';
import './orders.scss';

const goaUpdateActions = [DashboardActionCodes.EDIT_GOA_ORDER, DashboardActionCodes.CANCEL_GOA_ORDER];
const isGOA = dashboardItem => {
    const actions = dashboardItem?.OAOrder?.actions;
    return !!actions?.length && !!actions.find(action => goaUpdateActions.includes(action.code));
};

function FilterSelect({ filter, onFilterChange }) {
    const [value, setValue] = React.useState(undefined);

    return (
        <Select
            onChange={event => {
                setValue(event.target.value);
                onFilterChange(event.target.value, filter);
            }}
            value={value}
        >
            {filter.entries.map(entry => (
                <option key={entry.value} disabled={entry.disabled ? 'disabled' : ''} value={entry.value}>
                    {entry.name}
                </option>
            ))}
        </Select>
    );
}

const handlers = new Handlers();

@autobind
class Orders extends React.Component {
    // eslint-disable-next-line react/no-unused-class-component-methods
    _ = handlers.link(this.props, 'onLoad');

    static propTypes = {
        error: PropTypes.string,
        focusOnArticle: PropTypes.string,
        l: PropTypes.func.isRequired,
    };

    static defaultProps = {
        error: undefined,
        focusOnArticle: undefined,
    };

    state = {
        orders: [],
        isLoading: true,
        filters: {},
    };

    @tryCatch.popUpOnly
    async componentDidMount() {
        await this.requestOrders();

        const message = this.props.error;
        if (message && message.length !== 0) {
            showDialog.addErrorMessageDialog(message, this.getOrder);
        }
    }

    componentDidUpdate() {
        setTimeout(() => {
            if (this.state.selectedOrderId) {
                // eslint-disable-next-line react/no-string-refs
                const selectedOrder = this.refs[`order-row-${this.state.selectedOrderId}`];
                selectedOrder.scrollIntoView();
            }
        }, 300);
    }

    async onUpdateEditedOrder(order, type) {
        await order.edit(type);
    }

    @tryCatch.popUpOnly
    // @ts-ignore
    @tryCatch({
        errorHandler: e => {
            handlers.onLoad(e);
            throw e;
        },
    })
    async requestOrders() {
        const { codes, focusOnArticle } = this.props;

        let orders = await middleware.orders.getOrdersWithReducedDefaults();
        orders = orders.map(order => {
            const panelName = `${order.orderCategory}Order`;

            const dashboardItem = convertItem(order.dashboardItem);
            const isGOAFlow = isGOA(dashboardItem);
            const action = isGOAFlow ? DashboardActionCodes.EDIT_GOA_ORDER : DashboardActionCodes.EDIT_OO_ORDER;

            return {
                ...order,
                key: _.uniqueId(),
                Base__Order: order.orderCategory === 'OA' ? OrdersSingleOA : OrdersSingleOO,
                dashboardItem,
                edit: async function edit(type) {
                    try {
                        if (this.isOA && !isGOAFlow) {
                            navigate(`/orders/${this.order.article.id}?edit=${type}&type=openaccess`);
                        } else {
                            await middlewareLicense.initFlow(this.order.article.id, action);
                            routes.navigateToUrl(
                                `/license-signing/${this.order.article.id}/${StepEnum.DISCOUNT_AND_BILLING}`,
                            );
                        }
                    } catch (error) {
                        if (error.code === 'POSSIBLE_OUTDATED_DATA') {
                            showOutdatedDashboardModal();
                        } else {
                            showDialog.error(error, { onClose: routes.navigateToDashboard });
                        }
                    }
                },
                isOA: order.orderCategory === 'OA',
                isOO: order.orderCategory === 'OO',
                isShow: order.order.article.id === focusOnArticle,
                panelName: `${order.orderCategory}Order`,
                orderDateFormatted: Utils.isoFormattedDateString(order.order.orderDate),
                orderStatusString: getStatusShort(codes.PANEL_STATUS, panelName, order.orderStatusCode),
            };
        });

        this.setState({
            allOrders: orders,
            isLoading: false,
            orders,
            selectedOrderId: orders.find(x => x.isShow)?.key,
        });

        this.props.onLoad();
    }

    toggleOpen(index, e) {
        if (e.target.tagName === 'A') {
            return;
        }

        this.setState(state => ({
            orders: update(state.orders, { [index]: { isShow: { $set: !state.orders[index].isShow } } }),
            selectedOrderId: null,
        }));
    }

    onFilterChange(value, filter) {
        const { filters, allOrders } = this.state;
        filters[filter.id] = filter[value];

        const filteredOrders = _.reduce(filters, (orders, filterItem) => filterItem._apply(orders), allOrders);

        this.setState({ orders: filteredOrders, allOrders, filters });
    }

    isPaidOAAlipayOrder(order, all) {
        if (flow.isPaymentMethodAlipay(order) && order.paid && all.orderCategory === 'OA') {
            return true;
        }
        return false;
    }

    isPaidOOAlipayOrder(order, all) {
        if (flow.isPaymentMethodAlipay(order) && order.paid && all.orderCategory === 'OO') {
            return true;
        }
        return false;
    }

    isPaidAlipayOrder(order) {
        if (flow.isPaymentMethodAlipay(order) && order.paid) {
            return true;
        }
        return false;
    }

    isPaidCCReceiptPending(order) {
        return flow.isCreditCardPaid(order) && !order.billingAddress.receiptId;
    }

    renderOrder(order, index) {
        return (
            <OrderItem
                key={`order-${order.key}`}
                ref={`order-row-${order.key}`}
                index={index}
                isPaidAlipayOrder={this.isPaidAlipayOrder(order.order)}
                isPaidCCReceiptPending={this.isPaidCCReceiptPending(order.order)}
                isPaidOAAlipayOrder={this.isPaidOAAlipayOrder(order.order, order)}
                isPaidOOAlipayOrder={this.isPaidOOAlipayOrder(order.order, order)}
                onClick={e => this.toggleOpen(index, e)}
                onUpdateEditedOrder={type => this.onUpdateEditedOrder(order, type)}
                order={order}
            />
        );
    }

    render() {
        const { l } = this.props;
        const { isLoading, orders } = this.state;

        return (
            <div className="OrdersContainer container">
                <Title>{l('ORDERS.ORDERS_TITLE')}</Title>
                <Layout isLoading={isLoading}>
                    <WhiteBlock className="p_mini">
                        <div className="OrdersFilters row m-btm_normal">
                            <div className="OrdersFilters-FilterContainer col-lg-3 text-right text-right-mob">
                                <div className="OrdersFilters-Filter filter-field revert">
                                    <label htmlFor="select-type-id">{l('ORDERS.FILTERS.ORDERS_TYPE.LABEL')}</label>
                                    <FilterSelect
                                        filter={OAorOOFilter(l('ORDERS.FILTERS.ORDERS_TYPE.ITEMS'))}
                                        onFilterChange={this.onFilterChange}
                                    />
                                </div>
                            </div>
                            <div className="OrdersFilters-FilterContainer col-lg-3 text-right text-right-mob">
                                <div className="OrdersFilters-Filter filter-field">
                                    <label htmlFor="select-day-id">{l('ORDERS.FILTERS.TIME_PERIOD.LABEL')}</label>
                                    <FilterSelect
                                        filter={TimeFilter(l('ORDERS.FILTERS.TIME_PERIOD.ITEMS'))}
                                        onFilterChange={this.onFilterChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <table className="Orders-Table table table-custom with-divider">
                            <thead>
                                <tr className="Orders-TableHeader table-header-bg">
                                    <th>&nbsp;</th>
                                    <th>{l('ORDERS.COLUMNS.STATUS')}</th>
                                    <th>{l('ORDERS.COLUMNS.ARTICLE_DOI')}</th>
                                    <th>{l('ORDERS.COLUMNS.SUBMITTED_ON')}</th>
                                    <th>{l('ORDERS.COLUMNS.ARTICLE_TITLE')}</th>
                                    <th>{l('ORDERS.COLUMNS.AMOUNT_CHARGED')}</th>
                                    <th>{l('ORDERS.COLUMNS.ACTIONS')}</th>
                                </tr>
                            </thead>
                            <tbody className="Orders">{orders.map((...args) => this.renderOrder(...args))}</tbody>
                        </table>
                        <ASInfo page={PageEnum.VIEW_ALL_ORDERS} />
                    </WhiteBlock>
                </Layout>
            </div>
        );
    }
}

export default consumeAnalyticsContext(withCodes2(ID.ORDERS, ID.PANEL_STATUS)(Orders));
