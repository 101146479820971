import React from 'react';
import AffiliationsStepEnum from 'app/blocks/article-affiliations-editor/AffiliationsStepEnum';
import { ID, withCodes } from 'app/blocks/common/codes';
import CheckboxButton from 'app/ui/form/CheckboxButton';

const getInfo = (step: Values<typeof AffiliationsStepEnum>, l: l): string => {
    let text;

    switch (step) {
        case AffiliationsStepEnum.AFFILIATION_LIST:
            text = l('ARTICLE_AFFILIATION_EDITOR.NO_AFFILIATIONS_CHECKBOX');
            break;
        case AffiliationsStepEnum.SELECT_AFFILIATION_BY_COUNTRY:
            text = l('ARTICLE_AFFILIATION_EDITOR.NOT_AFFILIATED_CHECKBOX');
            break;
        default:
            text = l('ARTICLE_AFFILIATION_EDITOR.NO_AFFILIATIONS_CHECKBOX');
    }
    return text;
};

type Props = {
    l: l;
    noAffiliations: boolean;
    onNoAffiliationsChange: (value: boolean) => void;
    step?: Values<typeof AffiliationsStepEnum>;
};

function NoAffiliations({ l, step, noAffiliations, onNoAffiliationsChange }: Props) {
    return (
        <CheckboxButton
            checked={noAffiliations}
            className="NoAffiliated"
            data-seleniumid="no-affiliations-checkbox"
            label={getInfo(step, l)}
            onChange={onNoAffiliationsChange}
        />
    );
}

NoAffiliations.defaultProps = {
    step: AffiliationsStepEnum.AFFILIATION_LIST,
};

export default withCodes(NoAffiliations, ID.ARTICLE_AFFILIATION_EDITOR);
