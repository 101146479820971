import cn from 'classnames';
import React from 'react';
import { Layout } from 'app/blocks/blocks';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { compose } from 'app/blocks/common/utils';
import withInnerRef from 'app/blocks/common/withInnerRef';
import PanelActionsButtons from 'app/blocks/Panel/actions/buttons/panel_actions__buttons';
import OrdersValue from 'app/pages/orders/value';
import { isAuthorPaid, isFunderPaid } from './orders.flow';

function Cell({ children, seleniumId, title }) {
    return (
        <td className="textWrap cell-custom" data-title={title}>
            <span data-seleniumid={seleniumId}>{children}</span>
        </td>
    );
}

class OrderItem extends React.Component {
    render() {
        const {
            codes,
            index,
            l,
            onClick,
            onUpdateEditedOrder,
            order,
            isPaidOAAlipayOrder,
            isPaidOOAlipayOrder,
            isPaidAlipayOrder,
            isPaidCCReceiptPending,
            innerRef,
        } = this.props;
        const { order: orderItem, dashboardItem, panelName } = order;
        const panel = dashboardItem.helpers.getPanel(panelName) || {};
        const { article, journal } = orderItem;
        return (
            <>
                <tr
                    ref={innerRef}
                    className={cn('order-row cursor-pointer', { 'order-row-selected': order.isShow })}
                    data-seleniumid={`order-row-doi-${orderItem.article.doi}`}
                    onClick={onClick}
                >
                    <td className="expand">
                        <SvgIcon.chevron
                            className={`arrow-gray expand-icon-mobile ${order.isShow ? 'open' : ''}`}
                            data-seleniumid={`order-expand-${index}`}
                            iconDescr="Triangle symbol"
                            iconTitle="Opened/Closed"
                        />
                        <div className="expand-label">{order.isShow ? 'Hide details' : 'More details'}</div>
                    </td>
                    <Cell seleniumId={`order-status-${index}`} title={l('ORDERS.COLUMNS.STATUS')}>
                        {order.orderStatusString}
                    </Cell>
                    <Cell seleniumId={`order-doi-${index}`} title={l('ORDERS.COLUMNS.ARTICLE_DOI')}>
                        {orderItem.article.doi}
                    </Cell>
                    <Cell seleniumId={`order-date-${index}`} title={l('ORDERS.COLUMNS.SUBMITTED_ON')}>
                        {order.orderDateFormatted}
                    </Cell>
                    <Cell seleniumId={`order-article-name-${index}`} title={l('ORDERS.COLUMNS.ARTICLE_TITLE')}>
                        {orderItem.article.name}
                    </Cell>
                    <Cell seleniumId={`order-total-amount-${index}`} title={l('ORDERS.COLUMNS.AMOUNT_CHARGED')}>
                        {orderItem.pricingDetails && !isFunderPaid(orderItem) && (
                            <OrdersValue
                                currency={orderItem.pricingDetails.currency}
                                value={orderItem.pricingDetails.totalAmount}
                            />
                        )}
                    </Cell>
                    <Cell seleniumId={`order-actions-${index}`} title="Actions">
                        {!!panel.actions?.length && (
                            <div className="PanelActions" data-seleniumid={`dashboard-aticle__panel-${panelName}`}>
                                <PanelActionsButtons
                                    additionalInfo={{ article, journal }}
                                    items={panel.actions}
                                    name={panelName}
                                    params={panel.params}
                                    seleniumid={`order-panel-action-${index}${
                                        dashboardItem.journal?.openAccess ? `-OA` : ''
                                    }`}
                                    statusCodes={codes.PANEL_STATUS}
                                />
                            </div>
                        )}
                    </Cell>
                </tr>
                <tr
                    className={cn({ hidden: !order.isShow })}
                    data-seleniumid={`order-data-doi-${orderItem.article.doi}`}
                >
                    <td className="table-td" colSpan="7">
                        <div className="orders-content">
                            <Layout isLoading={order.isCanceling}>
                                <order.Base__Order
                                    dontAllowEdit={!dashboardItem.helpers.isEditable()}
                                    isAuthorPaidFlow={isAuthorPaid(orderItem)}
                                    isCommonAmountPayablePanel
                                    isFunderPaidFlow={isFunderPaid(orderItem)}
                                    isPaidAlipayOrder={isPaidAlipayOrder}
                                    isPaidCCReceiptPending={isPaidCCReceiptPending}
                                    isPaidFlowUndefined={!isAuthorPaid(orderItem) && !isFunderPaid(orderItem)}
                                    isPaidOAAlipayOrder={isPaidOAAlipayOrder}
                                    isPaidOOAlipayOrder={isPaidOOAlipayOrder}
                                    isZeroPrice={orderItem.pricingDetails.totalAmount === 0}
                                    onRequestEditOrder={onUpdateEditedOrder}
                                    order={orderItem}
                                    orderStatusString={order.orderStatusString}
                                    seleniumid={`order-detail-view-${index}`}
                                    smallJournalImage
                                />
                            </Layout>
                        </div>
                    </td>
                </tr>
            </>
        );
    }
}

export default compose(withInnerRef, withCodes2(ID.ORDERS, ID.PANEL_STATUS))(OrderItem);
