import React from 'react';
import StaticContent from 'app/blocks/static-content/static-content';

function OrderCurrencyInfo() {
    return (
        <span className="orderPrices--info">
            <StaticContent src="order_oo__currency_info_message.html" />
        </span>
    );
}

export default OrderCurrencyInfo;
