import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { PrimaryButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import withPreloading from 'app/blocks/common/with-preloading-hoc';
import * as ProfileMiddleware from 'app/blocks/middleware/profile';

class ProfileInformation extends Component {
    static propTypes = {
        l: PropTypes.func.isRequired,
        panelData: PropTypes.shape({}),
    };

    static defaultProps = {
        panelData: {},
    };

    getLanguage = code => {
        const lang = new Intl.DisplayNames(['en'], { type: 'language' });
        return lang.of(code);
    };

    render() {
        const { l, panelData } = this.props;
        const {
            countryNameNE,
            firstName,
            language,
            lastName,
            orcidId,
            primaryEmailAddr,
            profileRedirectionURL,
            suffixName,
            titleName,
        } = panelData;

        return (
            <div className="ProfilePage" data-seleniumid="profile-block" id="profile-information-panel-content">
                <div className="ProfileContent">
                    <div data-seleniumid="profile-connect-logo">
                        <SvgIcon.connectLogo className="ProfileContent-Logo" iconTitle="Connect Icon" />
                    </div>
                    <div className="ProfileContent-Link">
                        <a
                            className="ProfileContent-Text"
                            data-seleniumid="profile-myaccount-link"
                            href={profileRedirectionURL}
                            rel="noreferrer"
                            target="_blank"
                        >
                            {l(`${ID.PROFILE_ACCOUNT}.uiLabelMessages.PROFILE_CONNECT_MY_ACCNT_LINK`)}
                        </a>
                        &nbsp;{' '}
                        <SvgIcon.myAccountLink data-seleniumid="profile-myaccount-link-icon" iconTitle="link Icon" />
                        &nbsp; &nbsp;
                    </div>
                </div>
                <div
                    className="ProfileContent-SubHeader"
                    data-seleniumid="profile-content"
                    style={{ lineHeight: '17.5px' }}
                >
                    {l(`${ID.PROFILE_ACCOUNT}.uiLabelMessages.PROFILE_CONNECT_ACCNT`)}
                </div>
                <hr />
                <div className="ProfileUser">
                    <div className="ProfileUser-Profile" data-seleniumid="profile-connectAvator-icon">
                        <SvgIcon.connectAvator className="ProfileUser-AvatarLogo" iconTitle="avatar Icon" />
                    </div>
                    <div className="ProfileUser-Username">
                        <b data-seleniumid="profile-username" style={{ fontSize: '18px', fontWeight: '500' }}>
                            {`${titleName || ''}`} {`${firstName} ${lastName || ''}`}
                            {suffixName ? ', ' : ''} {`${suffixName || ''}`} {}{' '}
                        </b>
                        <br />
                        <div className="ProfileContent-Country" data-seleniumid="profile-country-locale">
                            <div data-seleniumid="profile-country-name">
                                {`${countryNameNE || ''}`}
                                {language ? ',' : ''}
                            </div>{' '}
                            <div data-seleniumid="profile-language">{language ? this.getLanguage(language) : ''}</div>
                        </div>
                    </div>
                </div>
                <div className="ProfileEmail">
                    <div>
                        {' '}
                        <SvgIcon.connectEmail
                            data-seleniumid="profile-mail-icon"
                            iconTitle="mail Icon"
                            style={{ height: '20px', width: '20px' }}
                        />
                    </div>{' '}
                    &nbsp;
                    <div data-seleniumid="profile-mail-id"> {`${primaryEmailAddr}`} </div> &nbsp;
                    <div>
                        {' '}
                        <SvgIcon.connectPrimary
                            data-seleniumid="profile-primary-icon"
                            iconTitle="primary Icon"
                            style={{ height: '50px', width: '50px' }}
                        />
                    </div>
                    <br />
                </div>
                <div className="OrcidLink" data-seleniumid="orcid-Id-Row">
                    {orcidId && (
                        <div>
                            <div
                                className="Orcid_idLink"
                                data-seleniumid="orcid-Id-Link"
                                style={{ width: 'max-content' }}
                            >
                                <SvgIcon.orcidLogoIcon
                                    className="orcid__imageIcon"
                                    iconTitle="ORCID logo Icon"
                                    style={{ height: '24px', width: '24px' }}
                                />
                                <a
                                    className="OrcidId"
                                    data-seleniumid="Orcid_Link"
                                    style={{ color: '#353535', textDecoration: 'none' }}
                                >
                                    https://orcid.org/{orcidId}
                                </a>
                            </div>
                        </div>
                    )}
                    {!orcidId && (
                        <a
                            data-seleniumid="profile-manageaccount-link"
                            href={profileRedirectionURL}
                            rel="noreferrer"
                            style={{ textDecoration: 'none' }}
                            target="_blank"
                        >
                            <SvgIcon.orcidLogoIcon
                                className="orcid__imageIcon"
                                iconTitle="ORCID logo Icon"
                                style={{ height: '24px', width: '24px' }}
                            />
                            <span className="OrcidId" data-seleniumid="Orcid_Add" style={{ color: '#006DCC' }}>
                                Add your ORCID iD
                            </span>
                        </a>
                    )}
                </div>
                <div className="ProfileEmail-ProfileButton">
                    <a
                        data-seleniumid="profile-manageaccount-link"
                        href={profileRedirectionURL}
                        rel="noreferrer"
                        target="_blank"
                    >
                        <PrimaryButton
                            className="Action"
                            data-seleniumid="profile-edit-button"
                            id="saveAlertsInfo"
                            style={{
                                background: '#006DCC',
                                borderRadius: '4px',
                                fontFamily: 'Montserrat',
                                fontWeight: 500,
                            }}
                        >
                            {l(`${ID.PROFILE_ACCOUNT}.uiLabelMessages.PROFILE_ACCNT_MANAGE_ACCOUNT_BUTTON_LABEL`)}
                        </PrimaryButton>
                    </a>
                </div>
            </div>
        );
    }
}

export default withCodes(
    withPreloading(ProfileInformation, { loadDataFunction: ProfileMiddleware.getProfileInformation }),
    ID.PROFILE_ACCOUNT,
);
