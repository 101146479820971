import React from 'react';
import Select, { components } from 'react-select';
import { ID, l, withCodes } from 'app/blocks/common/codes';
import Address from './Address';
import { InstitutionPickerItem } from './institution-picker.types';

const selectStyles = {
    control: (provided, state) => {
        const borderColor = state.isFocused ? '#66afe9' : '#595959';
        return {
            ...provided,
            '&:hover': {
                borderColor,
                cursor: 'pointer',
            },
            backgroundColor: '#ffffff',
            borderColor,
            borderRadius: '0',
            boxShadow: state.isFocused ? 'inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6)' : '',
            minHeight: '34px',
            paddingLeft: '2px',
        };
    },
    dropdownIndicator: provided => ({
        ...provided,
        color: '#1c1d1e',
    }),
    indicatorSeparator: provided => ({
        ...provided,
        display: 'none',
    }),
    option: (provided, { isFocused, isSelected }) => ({
        ...provided,
        '&:hover': {
            backgroundColor: '#e5f5ff',
            color: '#555555',
            cursor: 'pointer',
            transition: isSelected ? '0.5s' : 'none',
        },
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: isFocused ? '#e5f5ff' : isSelected ? '#0d659e' : 'inherit',
        borderTop: '1px solid #cccccc',
        // eslint-disable-next-line no-nested-ternary
        color: isFocused ? '#555555' : isSelected ? '#ffffff' : '#555555',
        fontSize: '12px',
    }),
};

function NoneOption({ data: item, selectProps: sp }) {
    return <div data-seleniumid={`${sp.seleniumid}__none-option`}>{item.label}</div>;
}

function RecognizedOption({ data: item, selectProps: sp }) {
    return (
        <div>
            <div>
                <strong>{item.label}</strong>
            </div>
            <section>
                <Address
                    data={{ ...item, country: (item.country || '').replace('(THE)', '') }}
                    seleniumId={`${sp.seleniumid}__address`}
                />
            </section>
        </div>
    );
}

function ErrorMessage() {
    return <div>{l('INSTITUTION_PICKER.ERROR_LOADING_DATA')}</div>;
}

function Option(props) {
    const { value } = props.data;

    return (
        <components.Option {...props}>
            {value === null ? <NoneOption {...props} /> : <RecognizedOption {...props} />}
        </components.Option>
    );
}

type Props = {
    isDisabled?: boolean;
    isError?: boolean;
    onSelectChange: (value: InstitutionPickerItem | null) => void;
    options: InstitutionPickerItem[];
    placeholder?: string;
    selectedItem: InstitutionPickerItem | null;
    seleniumid: string;
};

function InstitutionPicker({
    isDisabled,
    isError,
    onSelectChange,
    options,
    placeholder = 'Select institution',
    selectedItem,
    seleniumid,
}: Props) {
    return (
        <div data-seleniumid={seleniumid}>
            <Select
                className="institution_picker__container"
                classNamePrefix="institution_picker"
                components={{ Option }}
                controlShouldRenderValue
                isDisabled={isDisabled}
                isSearchable={false}
                menuShouldScrollIntoView
                onChange={onSelectChange}
                options={options}
                placeholder={placeholder}
                // @ts-ignore
                seleniumid={seleniumid}
                styles={selectStyles}
                value={selectedItem}
            />
            {isError && <ErrorMessage />}
        </div>
    );
}

export default withCodes(InstitutionPicker, ID.INSTITUTION_PICKER);
