import React from 'react';
import { Button } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import { BasicView } from 'app/blocks/views/WidgetView';

function CoverImageWidget({ l }: { l: l }): React.ReactElement {
    const button = (
        <Button data-seleniumid="COVER_IMAGE" href={l('WIDGETS.COVER_IMAGE.URL')} isLinkTag target="_blank">
            {l('WIDGETS.COVER_IMAGE.BUTTON')}
        </Button>
    );

    return (
        <BasicView actions={button} icon="cover" seleniumid="coverImage" title={l('WIDGETS.COVER_IMAGE.TITLE')}>
            <div dangerouslySetInnerHTML={{ __html: l('WIDGETS.COVER_IMAGE.CONTENT') }} />
        </BasicView>
    );
}

export default withCodes(CoverImageWidget, ID.WIDGETS);
