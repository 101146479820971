import cn from 'classnames';
import React from 'react';
import { Prompt } from 'app/blocks/blocks';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import { eventContainsKey, KeyCode } from 'app/blocks/common/key-utils';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import './prompt_action.scss';

type Props = Either<{
    getBody: () => Promise<string>;
    html: string;
    nodeId: string;
}> & {
    className?: string;
    isModal?: boolean;
    seleniumid?: string;
    stopPropogation?: boolean;
};

function PromptAction({
    className,
    getBody,
    html,
    isModal = true,
    nodeId,
    seleniumid,
    stopPropogation = false,
}: Props) {
    const displayDialog = (e: React.UIEvent) => {
        showDialog.info({
            // @ts-ignore
            innerContent: <Prompt getBody={getBody} html={html} isModal={isModal} nodeId={nodeId} />,
        });

        if (stopPropogation) {
            e.stopPropagation();
        }
    };

    return (
        <div
            className={cn('prompt_action', className)}
            data-seleniumid={seleniumid}
            onClick={displayDialog}
            onKeyDown={e => {
                if (eventContainsKey(e, KeyCode.ENTER)) {
                    displayDialog(e);
                }
            }}
            role="button"
            tabIndex={0}
        >
            <SvgIcon.help iconTitle="Help icon" />
        </div>
    );
}

export default PromptAction;
