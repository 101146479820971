import React from 'react';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import { confirmStaticContent } from 'app/blocks/Modal/utils';
import StaticContent from 'app/blocks/static-content/static-content';

function confirmOrderChanges(type: 'ONLINE_OPEN' | 'OPEN_ACCESS') {
    const html = {
        ONLINE_OPEN: 'order_oo_review__confirm',
        OPEN_ACCESS: 'order_oa_review__confirm',
    };

    return confirmStaticContent(html[type]);
}

function notifyUserThatVatTaxShouldBeReentered() {
    showDialog.modal(closeAnd => ({
        innerContent: (
            <StaticContent key="order_oo_initiate__changed-country" src="order_oo_initiate__changed-country.html" />
        ),
        onClose: closeAnd(),
        type: 'info',
    }));
}

async function notifyUserThatOrderIsSaved(
    type: 'ONLINE_OPEN' | 'OPEN_ACCESS',
    step: 'initiate' | 'review',
): Promise<void> {
    const html = {
        initiate: {
            ONLINE_OPEN: 'order_oo_initiate__saved',
            OPEN_ACCESS: 'order_oa_initiate__saved',
        },
        review: {
            ONLINE_OPEN: 'order_oo_review__saved',
            OPEN_ACCESS: 'order_oa_review__saved',
        },
    };

    return new Promise(resolve => {
        showDialog.modal(closeAnd => ({
            innerContent: <StaticContent key={html[step][type]} src={`${html[step][type]}.html`} />,
            onClose: closeAnd(() => resolve()),
            type: 'info',
        }));
    });
}

async function shouldLeaveOrdering(type: 'ONLINE_OPEN' | 'OPEN_ACCESS', step: 'initiate' | 'review') {
    const html = {
        initiate: {
            ONLINE_OPEN: 'order_oo_initiate__cancel',
            OPEN_ACCESS: 'order_oa_initiate__cancel',
        },
        review: {
            ONLINE_OPEN: 'order_oo_review__cancel',
            OPEN_ACCESS: 'order_oa_review__cancel',
        },
    };

    return confirmStaticContent(html[step][type]);
}

export { confirmOrderChanges, notifyUserThatOrderIsSaved, notifyUserThatVatTaxShouldBeReentered, shouldLeaveOrdering };
