import React from 'react';
import AffiliationsStepEnum from 'app/blocks/article-affiliations-editor/AffiliationsStepEnum';
import { ID, withCodes } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { MainTitle } from 'app/blocks/titles/titles';

import './header.scss';

type Props = {
    isEmptyAffiliations?: boolean;
    isOnlineOpenJournal?: boolean;
    l: l;
    newAffiliation?: boolean;
    step?: Values<typeof AffiliationsStepEnum>;
};
function getEmptyAffiliationsPrompt(l, isEmptyAffiliations, newAffiliation) {
    return isEmptyAffiliations && !newAffiliation ? (
        <div className="EmptyAffiliations-Info" data-seleniumid="affiliation-banner">
            <div className="EmptyAffiliations-Info-Icon">
                <SvgIcon.info className="InfoIcon" />
            </div>
            <div>{l('ARTICLE_AFFILIATION_EDITOR.EMPTY_AFFILIATIONS_INFO')}</div>
        </div>
    ) : null;
}

function Header({ isEmptyAffiliations, isOnlineOpenJournal, l, newAffiliation, step }: Props) {
    let title;
    let text;

    switch (step) {
        case AffiliationsStepEnum.SELECT_PRIMARY_AFFILIATION:
            title = l('ARTICLE_AFFILIATION_EDITOR.SELECT_PRIMARY_AFFILIATION_TITLE');
            text = l('ARTICLE_AFFILIATION_EDITOR.SELECT_PRIMARY_AFFILIATION_TEXT_SINGLE_AUTHOR');
            break;

        case AffiliationsStepEnum.SELECT_AFFILIATION_BY_COUNTRY:
            title = l('ARTICLE_AFFILIATION_EDITOR.SELECT_WOA_INSTITUTION_TITLE');
            text = l('ARTICLE_AFFILIATION_EDITOR.SELECT_WOA_INSTITUTION_TEXT_SINGLE_AUTHOR');
            break;

        case AffiliationsStepEnum.CONFIRM_COUNTRY:
            title = l('ARTICLE_AFFILIATION_EDITOR.CONFIRM_COUNTRY_TITLE');
            break;

        case AffiliationsStepEnum.AFFILIATION_LIST:
        default:
            title = l(`ARTICLE_AFFILIATION_EDITOR${isEmptyAffiliations ? '.SPECIFY' : '.CONFIRM'}_AFFILIATIONS_TITLE`);
            text = l(
                `ARTICLE_AFFILIATION_EDITOR${isEmptyAffiliations ? '.SPECIFY' : '.CONFIRM'}_AFFILIATIONS_TEXT${
                    isOnlineOpenJournal ? '_ONLINE_OPEN' : '_NON_ONLINE_OPEN'
                }_SINGLE_AUTHOR`,
            );
    }

    return (
        <div className="Header">
            <MainTitle className="Header-Title" data-seleniumid="article-affiliations-title">
                {title}
            </MainTitle>
            <p
                className="Header-Text"
                dangerouslySetInnerHTML={{ __html: text }}
                data-seleniumid="article-affiliations-hint"
            />
            {getEmptyAffiliationsPrompt(l, isEmptyAffiliations, newAffiliation)}
        </div>
    );
}

Header.defaultProps = {
    isEmptyAffiliations: false,
    isOnlineOpenJournal: false,
    step: AffiliationsStepEnum.AFFILIATION_LIST,
};

export { Header };
export default withCodes(Header, ID.ARTICLE_AFFILIATION_EDITOR);
