import { currencySymbols } from 'app/blocks/common/getCurrencySymbol';
import DiscountType from 'app/pages/license-signing-process/DiscountType';

enum DiscountError {
    INVALID_CODE = 'VIAX_ADD_DISCOUNT_INCORRECT_CODE',
    GENERAL_ERROR = 'VIAX_ADD_DISCOUNT_GENERAL_ERROR',
}

enum WaiverType {
    ArticleType = 'ArticleType',
    Geographical = 'Geographical',
    Editorial = 'Editorial',
    Custom = 'Custom',
}

type Discount = {
    discountCode: string;
    discountType: DiscountType;
    eligibleInstitution: {
        name: string;
    };
    discountDescription: string;
    absoluteDiscount: number;
    isStackedDiscount?: boolean;
    percentageDiscount: number;
};

export type JournalDiscount = {
    message: string;
    discountType: DiscountType;
};

type Price = {
    factoredAPC: number;
    finalAPC: number;
    preTaxAPC: number;
    appliedDiscounts: Discount[];
    currency: keyof typeof currencySymbols;
};

interface GOAOrder {
    articleTypeDisplayName?: string;
    prices?: Price[];
    [propName: string]: any;
    countryName?: string;
}

enum BillToType {
    INDIVIDUAL = 'INDIVIDUAL',
    ORGANIZATION = 'ORGANIZATION',
}

export { BillToType, Discount, DiscountError, GOAOrder, Price, WaiverType };
