import _ from 'underscore';
import { REST } from './utils';

const { GET } = REST('/payment');

function createWpgForm(url, wpgConfig) {
    const form = document.createElement('form');
    form.id = `paymentGatewayForm${_.uniqueId()}`;
    form.method = 'post';
    form.action = url;

    Object.entries(wpgConfig).forEach(([name, value]) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = name;
        // @ts-ignore
        input.value = value;
        form.appendChild(input);
    });

    document.body.appendChild(form);

    return form;
}

const getGatewayUrl = (): Promise<string> => GET('/wpg/url');

const getOnlineOpenGatewayConfig = (articleId: string): Promise<Record<string, string>> =>
    GET(`/wpg/config/ooorder/${articleId}`);

const getOnlineOpenWPGConfigAndSubmitForm = async (articleId: string): Promise<void> => {
    const [url, config] = await Promise.all([getGatewayUrl(), getOnlineOpenGatewayConfig(articleId)]);

    const form = createWpgForm(url, config);

    form.submit();
};

const getOpenAccessGatewayConfig = (correlationId: string) => GET(`/wpg/config/${correlationId}/`);

const getOpenAccessWPGConfigAndSubmitForm = async (correlationId: string): Promise<void> => {
    const [url, config] = await Promise.all([getGatewayUrl(), getOpenAccessGatewayConfig(correlationId)]);

    const form = createWpgForm(url, config);

    form.submit();
};

export default {
    getGatewayUrl,
    getOnlineOpenGatewayConfig,
    getOnlineOpenWPGConfigAndSubmitForm,
    getOpenAccessGatewayConfig,
    getOpenAccessWPGConfigAndSubmitForm,
};
