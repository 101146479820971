import React from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import { BannerView } from 'app/blocks/views/WidgetView';
import { useArticlePage } from '../ArticlePage.context';

const panel = 'banner';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function BannerWidget({ l }: { l: l }): React.ReactElement {
    const item = useArticlePage();
    const { getStatus, getTitle } = item.helpers;

    return (
        <BannerView icon="important" seleniumid="banner" title={getTitle(panel)}>
            {getStatus(panel)}
        </BannerView>
    );
}

export default withCodes(BannerWidget, ID.DASHBOARD, ID.BUTTONS, ID.WIDGETS, ID.PANEL_STATUS);
