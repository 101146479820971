import React from 'react';
import { compose } from 'app/blocks/common/utils';
import { SubTitle } from 'app/blocks/titles/titles';
import DiscountAndBillingContext from 'app/pages/license-signing-process/DiscountAndBillingDetails/GOA/Context';
import { GOAOrder, JournalDiscount } from 'app/pages/license-signing-process/DiscountAndBillingDetails/types';
import DiscountType from 'app/pages/license-signing-process/DiscountType';
import AppliedDiscount from './AppliedDiscount';
import DiscountsInput from './DiscountsInput';
import JournalDiscounts from './JournalDiscounts';
import JournalDiscountsError from './JournalDiscountsError';
import { useDiscountLabels } from './Labels';

import './Discounts.scss';

const extractDiscountsText = (discounts: JournalDiscount[], type: DiscountType) =>
    discounts.filter(({ discountType }) => discountType === type).map(discount => discount.message);

export type DiscountsProps = {
    order?: GOAOrder;

    journalDiscounts: { success: boolean; discounts?: JournalDiscount[] };
    totalPrice: number;
};
export const DiscountWidget: React.FC<DiscountsProps> = ({ journalDiscounts, order = {}, totalPrice }) => {
    const { discounts: journalDiscountsList = [], success: journalDiscountsSuccess } = journalDiscounts;

    const societyDiscounts = extractDiscountsText(journalDiscountsList, DiscountType.Society);
    const wileyDiscounts = extractDiscountsText(journalDiscountsList, DiscountType.WileyPromoCode);

    const { prices = [] } = order;
    const [price] = prices;
    const appliedDiscounts = price?.appliedDiscounts;
    const { countryName } = order;

    const labels = useDiscountLabels(appliedDiscounts, societyDiscounts, wileyDiscounts, journalDiscountsSuccess);

    if (!labels.isDiscountsWidgetVisible) {
        return null;
    }

    return (
        <section>
            <div className="discountPanel" data-seleniumid="discounts">
                <SubTitle data-seleniumid="discounts-header">{labels.header}</SubTitle>
                {!labels.isAppliedDiscountsVisible && (
                    <span className="discountPanel-anyDiscountTip" data-seleniumid="discounts-anyDiscountTip">
                        {labels.noAppliedDiscounts}
                    </span>
                )}
                {labels.isHighestDiscountVisible && (
                    <span className="discountPanel-highestDiscountTip" data-seleniumid="discounts-highestDiscountTip">
                        {labels.highestDiscount}
                    </span>
                )}
                <AppliedDiscount
                    appliedDiscounts={appliedDiscounts}
                    discountCountryName={countryName}
                    showNoPaymentRequired={totalPrice === 0}
                />
                {labels.isSpacerVisible && <div className="discountPanel-spacer" />}
                {labels.isDiscountDetailsSectionVisible && (
                    <>
                        <span data-seleniumid="discounts-availableTitle">
                            {labels.isJournalDiscountsVisible && labels.journalDiscounts}
                            {labels.isJournalAdditionalDiscountsVisible && labels.journalAdditionalDiscounts}
                            {labels.isDiscountsAdditionalInformationVisible && labels.discountsAdditionalInformation}
                        </span>

                        {labels.isAvailableDiscountsVisible && <JournalDiscounts discounts={societyDiscounts} />}

                        {labels.isProvideDiscountVisible && (
                            <span
                                className="discountPanel-eligibleDiscountTip"
                                data-seleniumid="discounts-eligibleDiscountTip"
                            >
                                {labels.provideDiscount}
                            </span>
                        )}

                        {labels.isMultipleDiscountsVisible && (
                            <span
                                className="discountPanel-multipleDiscountsTip"
                                data-seleniumid="discounts-multipleDiscountsTip"
                            >
                                {labels.multipleDiscounts}
                            </span>
                        )}

                        {labels.isDiscountsInputVisible && <DiscountsInput />}
                    </>
                )}

                {!journalDiscountsSuccess && <JournalDiscountsError />}
            </div>
        </section>
    );
};

export default compose(
    DiscountAndBillingContext.withContext(state => {
        return {
            journalDiscounts: state.journalDiscounts,
            order: state.order,
        };
    }),
)(DiscountWidget);
