import cn from 'classnames';
import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { byDiscountVisibility } from 'app/pages/license-signing-process/DiscountType';
import CheckboxButton from 'app/ui/form/CheckboxButton';
import PaymentAmountRow from './PaymentAmountRow';
import './PaymentAmountBreakdown.scss';

const AlipaymentAgreement = ({ l }) => (
    <div
        dangerouslySetInnerHTML={{ __html: l('LICENSE_SIGNING.PAYMENT.LABEL.SOCOPAY_POLICY_LINE') }}
        data-seleniumid="alipayPaymentAgreeCheckbox-label"
    />
);

function PaymentAmountBreakdown({
    chinaPayDetails = {},
    isAlipayDataCorrect,
    isOrderHistory,
    l = c => c,
    onAlipayAgreeChange,
    order = {},
    panelName,
}) {
    const { prices } = order;
    const price = prices[0] || {};
    const { appliedDiscounts = [] } = price;
    const discount = appliedDiscounts.filter(byDiscountVisibility)[0] || {};
    const discountValue = discount?.absoluteDiscount || 0;

    return (
        <section className={cn('payment-panel', panelName)} data-seleniumid="order-price-panel-review">
            <div className="amount-rows">
                <PaymentAmountRow
                    amount={price.factoredAPC}
                    bold
                    grayed={isOrderHistory}
                    seleniumid="base-price"
                    subHeader={`${l(
                        'ORDER_PANELS.PANEL_CHINA_PAY.PAYMENT_BREAKDOWN.ARTICLE_SUBMISSION_CHARGE_SUB_HEADER',
                    )}: ${order.articleType}`}
                    title={l('ORDER_PANELS.PANEL_CHINA_PAY.PAYMENT_BREAKDOWN.ARTICLE_SUBMISSION_CHARGE_HEADER')}
                />
                {!!discountValue && (
                    <PaymentAmountRow
                        amount={discountValue}
                        grayed={isOrderHistory}
                        seleniumid="discount"
                        title={l('ORDER_PANELS.PANEL_CHINA_PAY.PAYMENT_BREAKDOWN.DISCOUNT_HEADER')}
                    />
                )}
                <PaymentAmountRow
                    amount={price.preTaxAPC}
                    bold
                    grayed
                    seleniumid="sub-total"
                    title={l('ORDER_PANELS.PANEL_CHINA_PAY.PAYMENT_BREAKDOWN.SUBTOTAL_USD_HEADER')}
                />
                <PaymentAmountRow
                    amount={chinaPayDetails.subTotal}
                    currency="RMB"
                    grayed={isOrderHistory}
                    seleniumid="sub-total-rmb"
                    subHeader={`1 USD ≈ ${chinaPayDetails.conversionRate} CNY`}
                    title={l('ORDER_PANELS.PANEL_CHINA_PAY.PAYMENT_BREAKDOWN.SUBTOTAL_CNY_HEADER')}
                />
                <PaymentAmountRow
                    amount={chinaPayDetails.surcharge}
                    currency="RMB"
                    grayed={isOrderHistory}
                    seleniumid="surcharge-rmb"
                    title={l('ORDER_PANELS.PANEL_CHINA_PAY.PAYMENT_BREAKDOWN.SURCHARD_HEADER')}
                />
                <PaymentAmountRow
                    amount={chinaPayDetails.tax}
                    currency="RMB"
                    grayed={isOrderHistory}
                    seleniumid="tax-rmb"
                    title={l('ORDER_PANELS.PANEL_CHINA_PAY.PAYMENT_BREAKDOWN.TAX_HEADER')}
                />
                <PaymentAmountRow
                    amount={chinaPayDetails.finalPrice}
                    blue={!isOrderHistory}
                    bold
                    currency="RMB"
                    grayed={isOrderHistory}
                    seleniumid="final-price-rmb"
                    title={l('ORDER_PANELS.PANEL_CHINA_PAY.PAYMENT_BREAKDOWN.ORDER_TOTAL_HEADER')}
                />
                {!isOrderHistory && (
                    <div>
                        <div className="payment-PayViaAlipay">
                            <span data-seleniumid="payment-pay-via-alipay-label">
                                {l('ORDER_PANELS.PANEL_CHINA_PAY.ALIPAY_PAY_TITLE')}
                            </span>
                            <SvgIcon.alipay2 className="AlipayText-Icon" seleniumid="payment-pay-via-alipay-logo" />
                            <SvgIcon.alipay
                                className="AlipayText-Icon"
                                seleniumid="payment-pay-via-alipay-logo-with-name"
                            />
                        </div>
                        <div>
                            <span
                                className="payment-PayViaAlipay-verifyTxt"
                                data-seleniumid="alipay-payment-socopay-description"
                            >
                                {l('ORDER_PANELS.PANEL_CHINA_PAY.ALIPAY_PAY_SUBTITLE')}
                            </span>
                            <br /> <br />
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: l('ORDER_PANELS.PANEL_CHINA_PAY.ALIPAY_PAY_DESCRIPTION'),
                                }}
                                data-seleniumid="alipay-payment-mobile-description"
                            />
                        </div>
                        <CheckboxButton
                            checked={isAlipayDataCorrect}
                            className="payment-AcceptCheckBox"
                            data-seleniumid="alipayPaymentAgreeCheckbox"
                            id="alipayAgreeCheckbox"
                            label={<AlipaymentAgreement l={l} />}
                            onChange={event => onAlipayAgreeChange(event.target.checked)}
                        />
                    </div>
                )}
            </div>
        </section>
    );
}

export { PaymentAmountBreakdown };

export default withCodes2(ID.ORDER_PANELS, ID.LICENSE_SIGNING)(PaymentAmountBreakdown);
