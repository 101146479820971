import React from 'react';
import getCurrencySymbol, { amountWithCommas, currencySymbols } from 'app/blocks/common/getCurrencySymbol';
import { compose, template } from 'app/blocks/common/utils';
import { SubTitle } from 'app/blocks/titles/titles';
import { Discount, GOAOrder } from 'app/pages/license-signing-process/DiscountAndBillingDetails/types';
import DiscountType, { byDiscountVisibility } from 'app/pages/license-signing-process/DiscountType';
import DiscountAndBillingContext from '../Context';
import { roundDiscountValue } from '../formatters';

import './ArticlePublicationCharge.scss';

type ArticleTypeProps = {
    subtitle: string;
    amount: number;
    currency: keyof typeof currencySymbols | '';
    title: string;
};

const composeAppliedApcDiscount = ({
    discountType,
    percentageDiscount,
    discountDescription = '',
    eligibleInstitution,
    isStackedDiscount,
    l,
}: Discount & { l }): string => {
    const percentageValue = roundDiscountValue(percentageDiscount);
    switch (discountType) {
        case DiscountType.ArticleType: {
            return null;
        }
        case DiscountType.Institutional: {
            if (percentageValue !== undefined && !isStackedDiscount) {
                return template(
                    l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.INSTITUTIONAL_APC_PERCENTAGE_DISCOUNT'),
                    {
                        institutionName: eligibleInstitution.name,
                        percentageValue,
                    },
                );
            }
            return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.INSTITUTIONAL_APC_ABSOLUTE_DISCOUNT'), {
                institutionName: eligibleInstitution.name,
            });
        }
        case DiscountType.Society: {
            if (percentageValue !== undefined) {
                return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.SOCIETY_APC_PERCENTAGE_DISCOUNT'), {
                    percentageValue,
                });
            }
            return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.SOCIETY_APC_ABSOLUTE_DISCOUNT'));
        }
        case DiscountType.WileyPromoCode: {
            if (percentageValue !== undefined) {
                return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.WILEY_APC_PERCENTAGE_DISCOUNT'), {
                    percentageValue,
                });
            }
            return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.WILEY_APC_DISCOUNT'), {
                discountDescription,
            });
        }
        case DiscountType.ReferralDiscount:
        case DiscountType.TransferDiscount: {
            if (percentageValue !== undefined) {
                return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.REFERRAL_TRANSFER_APC_DISCOUNT'), {
                    percentageValue,
                });
            }
            return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.REFERRAL_TRANSFER_APC_DEFAULT_DISCOUNT'));
        }
        case DiscountType.Custom: {
            return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.CUSTOM_APC_DISCOUNT'));
        }
        case DiscountType.GeographicalDiscount: {
            if (percentageValue === 100) {
                return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.GEO_WAIVER'));
            }
            return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.GEO_DISCOUNT'), {
                percentageValue,
            });
        }
        case DiscountType.EditorialDiscount: {
            if (percentageValue === 100) {
                return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.EDITORIAL_WAIVER'));
            }
            return null;
        }
        default: {
            return '';
        }
    }
};

const ArticleType: React.FC<ArticleTypeProps> = ({ subtitle, amount, currency, title }) => {
    return (
        <div className="priceTable-row">
            <div className="priceTable-cell" data-seleniumid="article-publication-charge-type-title">
                <span className="priceTable-cellTitle">{title}</span>
                <span>{subtitle}</span>
            </div>
            <div className="priceTable-cell" data-seleniumid="article-publication-charge-type-value">
                <span>
                    {currency} {getCurrencySymbol(currency)}
                    {amountWithCommas(amount.toFixed(2))}
                </span>
            </div>
        </div>
    );
};

type DiscountRowProps = {
    title: string;
    absoluteDiscount: number;
    percentageDiscount: number;
    currency: keyof typeof currencySymbols | '';
};
const DiscountRow: React.FC<DiscountRowProps> = ({ title, absoluteDiscount, percentageDiscount, currency }) => {
    return (
        <div className="priceTable-row">
            <div className="priceTable-cell" data-seleniumid="article-publication-charge-discount-title">
                <span dangerouslySetInnerHTML={{ __html: title }} />
            </div>
            <div
                className="priceTable-cell priceTable-discountValue"
                data-seleniumid="article-publication-charge-discount-value"
            >
                {!!absoluteDiscount && (
                    <span>
                        {currency} -{getCurrencySymbol(currency)}
                        {amountWithCommas(absoluteDiscount.toFixed(2))}
                    </span>
                )}
                {!absoluteDiscount && !!percentageDiscount && <span>-{percentageDiscount}%</span>}
            </div>
        </div>
    );
};

type TotalRowProps = {
    title: string;
    subtitle: string;
    amount: number;
    currency: keyof typeof currencySymbols | '';
};
const TotalRow: React.FC<TotalRowProps> = ({ amount, currency, title, subtitle }) => {
    return (
        <div className="priceTable-row priceTable-total">
            <div className="priceTable-cell" data-seleniumid="article-publication-charge-total-title">
                <span className="priceTable-cellTitle">{title}</span>
                {!!subtitle && !!amount && <span>{subtitle}</span>}
            </div>
            <div
                className="priceTable-cell priceTable-cell--semiBold"
                data-seleniumid="article-publication-charge-total-value"
            >
                <span className="priceTable-totalValue">
                    {currency} {getCurrencySymbol(currency)}
                    {amountWithCommas(amount.toFixed(2))}
                </span>
            </div>
        </div>
    );
};

type ArticlePublicationChargeProps = {
    l: (code: string) => string;
    order: GOAOrder;
};
// @ts-ignore
const ArticlePublicationCharge: React.FC<ArticlePublicationChargeProps> = ({ l, order }) => {
    const { prices = [], articleTypeDisplayName } = order;
    const [price] = prices;
    const { currency = '', factoredAPC = 0, preTaxAPC = 0 } = price;
    return (
        <div className="articlePublicationCharge" data-seleniumid="article-publication-charge">
            <SubTitle data-seleniumid="article-publication-charge-header">
                {l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.CHARGE_TITLE')}
            </SubTitle>
            <div className="priceTable">
                <ArticleType
                    amount={factoredAPC}
                    currency={currency}
                    subtitle={articleTypeDisplayName}
                    title={l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.ARTICLE_TYPE')}
                />
                {price?.appliedDiscounts?.filter(byDiscountVisibility).map(discount => (
                    <DiscountRow
                        key={discount.discountCode}
                        absoluteDiscount={discount.absoluteDiscount}
                        currency={currency}
                        percentageDiscount={discount.percentageDiscount}
                        title={composeAppliedApcDiscount({ ...discount, l })}
                    />
                ))}
                <TotalRow
                    amount={preTaxAPC}
                    currency={currency}
                    subtitle={l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.TOTAL_BEFORE_TAXES')}
                    title={l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.TOTAL')}
                />
            </div>
        </div>
    );
};

export default compose(
    DiscountAndBillingContext.withProvider,
    DiscountAndBillingContext.withContext(state => ({
        l: state.l,
        order: state.order,
    })),
)(ArticlePublicationCharge);

export { ArticlePublicationCharge };
