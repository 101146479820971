import React, { Component } from 'react';
import { PrimaryButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import PromptAction from 'app/blocks/Prompt/_action/prompt_action';
import { ItemTitle } from 'app/blocks/titles/titles';
import { EditorAffiliation } from '../../../utils';
import AffiliationSelector from './AffiliationSelector';
import Header from './Header';
import SuggestionSelector from './SuggestionSelector';

import './notConfirmedInstitution.scss';

const NOTCONFIRMEDITEM_TEXTS_BASE = 'ARTICLE_AFFILIATION_EDITOR.NOTCONFIRMEDITEM_TEXTS';
const textsForNotConfirmedItem = {
    MAIN: {
        codePath: `${NOTCONFIRMEDITEM_TEXTS_BASE}.MAIN`,
        parameterName: 'name',
        variableName: 'institutionName',
    },
    EMAIL: {
        codePath: `${NOTCONFIRMEDITEM_TEXTS_BASE}.BY_EMAIL`,
    },
    NOT_FOUND: {
        codePath: `${NOTCONFIRMEDITEM_TEXTS_BASE}.NOT_FOUND`,
        parameterName: 'name',
        variableName: 'institutionName',
    },
    FALLBACK: {
        codePath: `${NOTCONFIRMEDITEM_TEXTS_BASE}.FALLBACK`,
    },
};

type Props = {
    affiliation: EditorAffiliation;
    blacklist: string[];
    institutionsIdFromProfile: string[];
    l: l;
    onChange: (id: string, item: any) => void;
    onDelete: (id: string) => void;
    seleniumid: string;
};

class NotConfirmedItem extends Component<Props> {
    state = {
        selectedItem: { id: null, name: null },
    };

    onSelectInstitution = institution => {
        if (this.state.selectedItem.id !== institution.id) {
            this.setState({ selectedItem: { ...institution } });
        }
    };

    onClickSaveHandler = () => {
        const { onChange, affiliation } = this.props;
        const { selectedItem } = this.state;
        onChange(affiliation.id, selectedItem);
    };

    getTextForNotConfirmedItem = type => {
        const {
            // FIXME there is no possibleMatches?
            // @ts-ignore
            affiliation: { institutionName, possibleMatches = {} },
            l,
        } = this.props;

        const variables = {
            institutionName,
        };

        let textObj = null;

        if (type in textsForNotConfirmedItem) {
            textObj = textsForNotConfirmedItem[type];
        } else if (possibleMatches.searchMode in textsForNotConfirmedItem) {
            textObj = textsForNotConfirmedItem[possibleMatches.searchMode];
        } else {
            textObj = textsForNotConfirmedItem.FALLBACK;
        }

        if (textObj.parameterName && textObj.variableName) {
            return l(textObj.codePath, { [textObj.parameterName]: variables[textObj.variableName] });
        }
        return l(textObj.codePath);
    };

    render() {
        const { selectedItem } = this.state;

        const { l, seleniumid, affiliation, blacklist, institutionsIdFromProfile, onDelete } = this.props;

        const matches = affiliation.suggestions || [];

        const selectorTitlePath = matches.length
            ? 'ARTICLE_AFFILIATION_EDITOR.SELECT_SUGGESTION_TITLE'
            : 'ARTICLE_AFFILIATION_EDITOR.CLARIFY_AFFILIATION_TITLE';

        return (
            <li className="NotConfirmedInstitution" data-seleniumid={`${seleniumid}-not-confirmed-institution-item`}>
                <ItemTitle className="NotConfirmedInstitution-Verification NotConfirmedInstitution-Verification--notVerified">
                    <span data-seleniumid="institution-verification">
                        {l('ARTICLE_AFFILIATION_EDITOR.AFFILIATION_NOT_VERIFIED_TEXT')}
                    </span>
                    <PromptAction
                        html={l('ARTICLE_AFFILIATION_EDITOR.AFFILIATION_NOT_VERIFIED_PROMPT_TEXT')}
                        seleniumid={`${seleniumid}-institution-item-verification__prompt_icon`}
                    />
                </ItemTitle>
                <Header
                    affiliation={affiliation}
                    onDelete={onDelete}
                    selectorTitlePath={selectorTitlePath}
                    seleniumid={seleniumid}
                />
                {matches.length ? (
                    <SuggestionSelector
                        affiliation={affiliation}
                        blacklist={blacklist}
                        getTextForNotConfirmedItem={this.getTextForNotConfirmedItem}
                        institutionsIdFromProfile={institutionsIdFromProfile}
                        onSelectInstitution={this.onSelectInstitution}
                        seleniumid={seleniumid}
                    />
                ) : (
                    <AffiliationSelector
                        blacklist={blacklist}
                        getTextForNotConfirmedItem={this.getTextForNotConfirmedItem}
                        institutionsIdFromProfile={institutionsIdFromProfile}
                        onSelectInstitution={this.onSelectInstitution}
                        selectedItem={selectedItem}
                        seleniumid={seleniumid}
                    />
                )}
                <PrimaryButton
                    className="NotConfirmedInstitution-SaveBtn"
                    data-seleniumid={`${seleniumid}-institution-save`}
                    disabled={!this.state.selectedItem.name}
                    onClick={this.onClickSaveHandler}
                >
                    {l('ARTICLE_AFFILIATION_EDITOR.AFFILIATION_SUGGESTIONS_SAVE_CHANGES_BUTTON')}
                </PrimaryButton>
            </li>
        );
    }
}

export default withCodes(NotConfirmedItem, ID.ARTICLE_AFFILIATION_EDITOR);
