import cn from 'classnames';
import React from 'react';
import { components } from 'react-select';
import Select from 'react-select/creatable';
import { l } from 'app/blocks/common/codes';
import { eventContainsKey, KeyCode } from 'app/blocks/common/key-utils';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { includesEachWord } from 'app/blocks/common/utils';
import ErrorLabel from 'app/blocks/ErrorLabel/ErrorLabel';
import Address from './Address';
import './institution-picker.scss';

const ENDLESS_SCROLL_MIN_TRESHOLD = 50;

export function customOptionData(inputValue) {
    return {
        custom: true,
        label: String(inputValue || '')
            .trim()
            .replace(/[ ]+/g, ' '),
        value: '',
    };
}

const selectStyles = {
    control: (provided, state) => {
        const borderColor = state.isFocused ? '#66afe9' : '#595959';
        return {
            ...provided,
            '&:hover': {
                borderColor,
                cursor: 'text',
            },
            backgroundColor: '#ffffff',
            borderColor,
            borderRadius: '0',
            boxShadow: state.isFocused ? 'inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6)' : '',
            minHeight: '34px',
            paddingLeft: '2px',
        };
    },
};

const NONE_VALUE = 'NONE';

// makes groups, if required
function transformOptions(rawOptions, { institutionsIdFromProfile }) {
    const fromProfile = rawOptions.filter(el => el.value && institutionsIdFromProfile.includes(el.value));
    const notFromProfile = rawOptions.filter(el => el.value && !institutionsIdFromProfile.includes(el.value));
    const serviceOptions = rawOptions.filter(el => !el.value);

    let options;
    if (fromProfile.length) {
        options = [
            ...serviceOptions,
            {
                label: l('INSTITUTION_PICKER.FROM_PROFILE_TITLE'),
                options: fromProfile,
            },
            {
                label: l('INSTITUTION_PICKER.OTHER_INSTITUTIONS_TITLE'),
                options: notFromProfile,
            },
        ];
    } else {
        options = rawOptions;
    }
    return options;
}

function CustomOption(props) {
    const { selectProps: sp } = props;
    const value = customOptionData(sp.searchPhrase).label;

    return sp.isValidNewOptionValue ? (
        <components.Option
            {...props}
            innerProps={{
                ...props.innerProps,
                'data-seleniumid': `${sp.seleniumid}__custom-option`,
            }}
        >
            <div className="custom-option-row">
                <SvgIcon.add height="16" iconTitle="" width="16" />
                <span dangerouslySetInnerHTML={{ __html: l('INSTITUTION_PICKER.USE_CUSTOM_FEATURE', { value }) }} />
            </div>
        </components.Option>
    ) : (
        <div />
    );
}

function NoneOption({ data: item, selectProps: sp }) {
    return <div data-seleniumid={`${sp.seleniumid}__none-option`}>{item.label}</div>;
}

function RecognizedOption({ data: item, selectProps: sp }) {
    if (item.country && item.country.includes('(THE)')) {
        // eslint-disable-next-line no-param-reassign
        item.country = item.country.replace('(THE)', '');
    }

    let primaryMatch = false;
    let altNameMatch = false;
    let abbrMatch = false;

    const query = sp.searchPhrase.toLowerCase();
    if (query) {
        primaryMatch = includesEachWord(item.label, query);

        if (item.alternativeNames && item.alternativeNames.length) {
            altNameMatch = item.alternativeNames.find(element => includesEachWord(element, query));
        }

        if (item.abbreviations && item.abbreviations.length) {
            abbrMatch = item.abbreviations.find(element => includesEachWord(element, query));
        }
    }

    return (
        <div data-option-type="recognized">
            <div>
                <strong>{item.label}</strong>
            </div>

            {!primaryMatch && altNameMatch && <section>{altNameMatch}</section>}
            {!primaryMatch && !altNameMatch && abbrMatch && <section>{abbrMatch}</section>}
            <section>
                <Address data={item} seleniumId={`${sp.seleniumid}__address`} />
            </section>
        </div>
    );
}

function ErrorMessage() {
    return <ErrorLabel text={l('INSTITUTION_PICKER.ERROR_LOADING_DATA')} />;
}

function Menu(props) {
    const { selectOption, selectProps: sp } = props;
    const custom = props.options.filter(o => o.custom)[0];

    function emulateOptionClick() {
        if (custom) {
            selectOption(custom);
        }
    }

    function handleEndlessScroll({ target }) {
        if (!sp.isLoading && sp.hasMore) {
            const allOptions = target.querySelectorAll('[data-option-type="recognized"]');
            const lastChild = allOptions && allOptions.length ? allOptions[allOptions.length - 1] : null;
            if (!lastChild) {
                return;
            }

            const lastHeight = Math.max(lastChild.offsetHeight, ENDLESS_SCROLL_MIN_TRESHOLD);

            if (target.scrollTop > target.scrollHeight - target.offsetHeight - lastHeight) {
                sp.loadNextPage();
            }
        }
    }

    return (
        <div>
            <components.Menu
                {...props}
                innerProps={{
                    ...props.innerProps,
                    onScroll: handleEndlessScroll,
                }}
            >
                {props.children}
                <CustomOption
                    {...props}
                    innerProps={{
                        ...props.innerProps,
                        className: 'custom-option',
                        onClick: emulateOptionClick,
                        onTouchStart: emulateOptionClick,
                    }}
                />
            </components.Menu>
        </div>
    );
}

function MenuList(props) {
    const { selectProps: sp } = props;

    return (
        <div data-seleniumid={`${sp.seleniumid}__list`}>
            <components.MenuList {...props}>
                {props.children}
                {!!(sp.isLoading && sp.options.length) && <div className="loading">Loading...</div>}
            </components.MenuList>
        </div>
    );
}

function Option(props) {
    const { custom, value } = props.data;

    return !custom ? (
        <components.Option {...props}>
            {value === NONE_VALUE ? <NoneOption {...props} /> : <RecognizedOption {...props} />}
        </components.Option>
    ) : null;
}

function Input(props) {
    const { selectProps: sp } = props;
    return <components.Input {...props} data-seleniumid={`${sp.seleniumid}__input`} maxLength="250" />;
}

function IndicatorsContainer(props) {
    return (
        <div style={{ margin: '5px 10px' }}>
            <components.IndicatorsContainer {...props}>
                <SvgIcon.search height="16" iconTitle="" width="16" />
            </components.IndicatorsContainer>
        </div>
    );
}

type Props = {
    displayCustom?: boolean;
    hasMore?: boolean;
    inputValue?: string;
    isError?: boolean;
    isLoading?: boolean;
    isLoadingError?: boolean;
    isLongList?: boolean;
    isValidToSearch: (search: string) => boolean;
    loadNextPage;
    onInputChange;
    onFocus?: () => void;
    onSelectChange;
    options;
    placeholder?: string;
    searchPhrase?: string;
    selectedItem;
    seleniumid?: string;
};

export { NONE_VALUE, transformOptions };
export default function InstitutionPickerView({
    displayCustom,
    hasMore,
    inputValue,
    isError,
    isLoading,
    isLoadingError,
    isValidToSearch,
    loadNextPage,
    onFocus,
    onInputChange,
    onSelectChange,
    options,
    placeholder,
    searchPhrase,
    selectedItem,
    seleniumid,

    ...controlProps
}: Props) {
    const onFocusHandler = () => {
        if (
            !inputValue &&
            selectedItem &&
            ((selectedItem.value && selectedItem.value !== NONE_VALUE) || selectedItem.custom)
        ) {
            onInputChange(selectedItem.label, { action: 'input-change' });
        } else {
            onInputChange(inputValue, { action: 'input-change' });
        }

        if (onFocus) {
            onFocus();
        }
    };

    return (
        <div className="institution-picker-view" data-seleniumid={seleniumid}>
            <Select
                {...controlProps}
                backspaceRemovesValue={false}
                blurInputOnSelect
                className={cn('institution_picker__container', {
                    'institution_picker__container--error': isError,
                })}
                classNamePrefix="institution_picker"
                components={{ IndicatorsContainer, Input, Menu, MenuList, Option }}
                controlShouldRenderValue
                filterOption={() => true}
                getNewOptionData={customOptionData}
                // @ts-ignore
                hasMore={hasMore}
                inputValue={inputValue}
                isLoading={isLoading}
                isValidNewOption={() => searchPhrase && !isLoading && displayCustom && isValidToSearch(searchPhrase)}
                isValidNewOptionValue={searchPhrase && !isLoading && displayCustom && isValidToSearch(searchPhrase)}
                loadNextPage={loadNextPage}
                menuShouldScrollIntoView
                noOptionsMessage={
                    () =>
                        searchPhrase && !displayCustom && isValidToSearch(searchPhrase)
                            ? l('INSTITUTION_PICKER.NOT_FOUND')
                            : null // = no message (will looks like 'closed' if no options, no loader and no custom)
                }
                onChange={onSelectChange}
                onFocus={onFocusHandler}
                onInputChange={onInputChange}
                onKeyDown={e => {
                    if (isLoading && (eventContainsKey(e, KeyCode.DOWN) || eventContainsKey(e, KeyCode.UP))) {
                        e.stopPropagation();
                        e.preventDefault();
                        return false;
                    }

                    return undefined;
                }}
                options={options}
                placeholder={placeholder}
                searchPhrase={searchPhrase}
                seleniumid={seleniumid}
                styles={selectStyles}
                tabSelectsValue={false}
                value={selectedItem || ''}
            />
            {isLoadingError && <ErrorMessage />}
        </div>
    );
}
