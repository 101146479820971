import React from 'react';
import _ from 'underscore';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { isArticlePublished } from 'app/blocks/common/products';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import Image from 'app/blocks/image/image';
import './article.scss';

type Props = {
    article?: Product.Article;
    journal: Product.Journal;
    l: l;
    onlineOpen?: boolean;
    openAccess?: boolean;
    seleniumid?: string;
    shareUrl?: string;
    showISSN?: boolean;
};

function Article({ article, journal, l, onlineOpen, openAccess, seleniumid, shareUrl, showISSN }: Props) {
    if (!journal) {
        return null;
    }

    const displayLinkToArticle = isArticlePublished(article);

    return (
        <div className="article__row" data-seleniumid={`${seleniumid}article-info`}>
            <div>
                <Image className="full-width article__img" src={journal.coverImageUrl} type={Image.TYPES.JOURNAL} />
            </div>
            <div>
                <div>
                    <a
                        className="article__row-JournalName"
                        data-seleniumid={`${seleniumid}journal-title`}
                        href={journal.wolUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {journal.name}
                    </a>
                    {openAccess && (
                        <SvgIcon.openAccess
                            className="open-access-icon"
                            iconDescr="A symbol of a lock"
                            iconTitle="Open Access journal"
                        />
                    )}
                </div>

                {!_.isEmpty(article) && (
                    <>
                        <div className="mt-two-thirds">
                            {displayLinkToArticle ? (
                                <a
                                    className="article__row-ArticleName"
                                    data-seleniumid={`${seleniumid}article-title`}
                                    href={shareUrl || `http://onlinelibrary.wiley.com/doi/${article.doi}/abstract`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    {article.name}
                                </a>
                            ) : (
                                <span
                                    className="article__row-ArticleName"
                                    data-seleniumid={`${seleniumid}article-title`}
                                >
                                    {article.name}
                                </span>
                            )}
                            {onlineOpen && (
                                <SvgIcon.openAccess
                                    className="open-access-icon"
                                    fill="#A059B0"
                                    iconDescr="A symbol of a lock"
                                    iconTitle="Online Open article"
                                />
                            )}
                        </div>
                        <div className="doi mt-two-thirds">
                            <span className="order-article">{l('ARTICLE.DOI')}</span>
                            <span data-seleniumid={`${seleniumid}article-doi`}>{article.doi}</span>
                        </div>
                    </>
                )}

                {showISSN && journal.eissn && (
                    <div className="mt-two-thirds">
                        <span className="order-article">{l('ARTICLE.ONLINE_ISSN')}</span>
                        <span>{journal.eissn}</span>
                    </div>
                )}

                {showISSN && journal.pissn && (
                    <div className="mt-two-thirds">
                        <span className="order-article">{l('ARTICLE.PRINT_ISSN')}</span>
                        <span>{journal.pissn}</span>
                    </div>
                )}
            </div>
        </div>
    );
}

export default withCodes2(ID.ARTICLE)(Article);
