import PropTypes from 'prop-types';
import React from 'react';

function PaymentAmountInfo(props) {
    return (
        <div className="amount-row" style={{ backgroundColor: props.bgColor }}>
            <div className="ar-wrapper">
                <span className="ar-title" style={{ fontWeight: props.isTitleBold ? 600 : 400 }}>
                    {props.title}
                </span>
                <span className="ar-header">{props.subHeader}</span>
            </div>
            <span
                className="ar-amount"
                data-seleniumid={props.seleniumId}
                style={{ fontWeight: props.isAmountBold ? 600 : 400 }}
            >
                {props.amount}
            </span>
        </div>
    );
}

PaymentAmountInfo.propTypes = {
    title: PropTypes.string.isRequired,
    subHeader: PropTypes.string,
    amount: PropTypes.string.isRequired,
    bgColor: PropTypes.string,
    isTitleBold: PropTypes.bool,
    isAmountBold: PropTypes.bool,
};

PaymentAmountInfo.defaultProps = {
    bgColor: '#FFFFFF',
    isTitleBold: false,
    isAmountBold: false,
    subHeader: '',
};

export default PaymentAmountInfo;
