import cn from 'classnames';
import React from 'react';
import './NewLayout.scss';

export default function NewLayout({ className, label, isDisabled, isRequired, isOptional, components }) {
    return (
        <div className={cn('NewLayout-Row', className, { disabledBlock: isDisabled })}>
            <label className={`NewLayout-Row-Label ${className}-Label`}>
                {label}
                {isRequired && <span className="NewLayout-Row-Required Required">*</span>}
                {isOptional && <span className={`NewLayout-Row-Optional ${className}-Optional`}>&nbsp;(optional)</span>}
            </label>
            {components.prompt}
            <div className={`NewLayout-Row-Input ${className}-Input`}>{components.input}</div>
            {components.validation}
            {components.helpText}
        </div>
    );
}
