import cn from 'classnames';
import React from 'react';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { MainTitle, SubTitle } from 'app/blocks/titles/titles';

import './WidgetView.scss';

function HTMLContent({ children, seleniumid }: { children: React.ReactNode; seleniumid: string }): React.ReactElement {
    if (React.Children.count(children) === 1 && typeof children === 'string') {
        return (
            <div
                className="WidgetContent"
                dangerouslySetInnerHTML={{ __html: children }}
                data-seleniumid={`widget-${seleniumid}-content`}
            />
        );
    }
    return (
        <div className="WidgetContent" data-seleniumid={`widget-${seleniumid}-content`}>
            {children}
        </div>
    );
}

type Props = {
    actions: React.ReactNode;
    additionalActions?: React.ReactNode;
    children: React.ReactNode;
    className?: string;
    fill?: boolean;
    icon: keyof typeof SvgIcon;
    required?: boolean;
    seleniumid: string;
    title: string;
};

function BasicView({
    actions,
    additionalActions,
    children,
    className,
    fill = true,
    icon,
    required,
    seleniumid,
    title,
}: Props): React.ReactElement {
    const Icon = SvgIcon[icon];
    // @ts-ignore
    const multiButtons = actions && actions.props.buttons && actions.props.buttons.length;

    return (
        <div
            className={cn('Widget DefaultWidget Block', className, {
                Required: required,
            })}
            data-seleniumid={`widget-${seleniumid}`}
        >
            <div className="LeftBlock" data-seleniumid={`widget-${seleniumid}-left`}>
                <Icon className={cn('Icon', { fill, stroke: !fill })} seleniumid={`widget-${seleniumid}-icon`} />
            </div>
            <div className="RightBlock" data-seleniumid={`widget-${seleniumid}-right`}>
                <MainTitle className="Title" data-seleniumid={`widget-${seleniumid}-text`}>
                    {title}
                </MainTitle>
                <HTMLContent seleniumid={seleniumid}>{children}</HTMLContent>
                <div
                    className={cn({
                        'Actions Full': !required,
                        Multi: multiButtons,
                        RequiredActions: required,
                    })}
                    data-seleniumid={`widget-${seleniumid}-actions`}
                >
                    {actions}
                </div>
                {additionalActions && (
                    <div className={cn({ Additional: !required, AdditionalRequired: required })}>
                        {additionalActions}
                    </div>
                )}
            </div>
        </div>
    );
}

function IconView({
    actions,
    additionalActions,
    children,
    className,
    fill = true,
    icon,
    required,
    seleniumid,
    title,
}: Props): React.ReactElement {
    const Icon = SvgIcon[icon];
    // @ts-ignore
    const multiButtons = actions && actions.props.buttons && actions.props.buttons.length;

    return (
        <div
            className={cn('Widget IconWidget Block', className, {
                Required: required,
            })}
            data-seleniumid={`widget-${seleniumid}`}
        >
            <div className="Title" data-seleniumid={`widget-${seleniumid}-title`}>
                <Icon className={cn('Icon', { fill, stroke: !fill })} seleniumid={`widget-${seleniumid}-icon`} />
                <MainTitle data-seleniumid={`widget-${seleniumid}-text`}>{title}</MainTitle>
            </div>
            <HTMLContent seleniumid={seleniumid}>{children}</HTMLContent>
            <div
                className={cn({
                    'Actions Full': !required,
                    Multi: multiButtons,
                    RequiredActions: required,
                })}
                data-seleniumid={`widget-${seleniumid}-actions`}
            >
                {actions}
            </div>
            {additionalActions && (
                <div className={cn({ Additional: !required, AdditionalRequired: required })}>{additionalActions}</div>
            )}
        </div>
    );
}

function GrayView({
    actions,
    additionalActions,
    children,
    className,
    fill = true,
    icon,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    required,
    seleniumid,
    title,
}: Props): React.ReactElement {
    const Icon = SvgIcon[icon];
    // @ts-ignore
    const multiButtons = actions && actions.props.buttons && actions.props.buttons.length;

    return (
        <div className={cn('Widget GrayWidget Block', className)} data-seleniumid={`widget-${seleniumid}`}>
            <div className="Title" data-seleniumid={`widget-${seleniumid}-title`}>
                <Icon className={cn('Icon', { fill, stroke: !fill })} seleniumid={`widget-${seleniumid}-icon`} />
                <SubTitle data-seleniumid={`widget-${seleniumid}-text`}>{title}</SubTitle>
            </div>
            <HTMLContent seleniumid={seleniumid}>{children}</HTMLContent>
            <div className={cn('Actions', { Multi: multiButtons })} data-seleniumid={`widget-${seleniumid}-actions`}>
                {actions}
            </div>
            {additionalActions && <div className="Additional">{additionalActions}</div>}
        </div>
    );
}

function PurpleView({
    actions,
    additionalActions,
    children,
    className,
    fill = true,
    icon,
    seleniumid,
    title,
}: Omit<Props, 'required'>): React.ReactElement {
    const Icon = SvgIcon[icon];

    return (
        <div className={cn('Widget PurpleWidget Block', className)} data-seleniumid={`widget-${seleniumid}`}>
            <div className="LeftBlock" data-seleniumid={`widget-${seleniumid}-left`}>
                <Icon className={cn('Icon', { fill, stroke: !fill })} seleniumid={`widget-${seleniumid}-icon`} />
            </div>
            <div className="RightBlock" data-seleniumid={`widget-${seleniumid}-right`}>
                <MainTitle className="Title" data-seleniumid={`widget-${seleniumid}-text`}>
                    {title}
                </MainTitle>
                <HTMLContent seleniumid={seleniumid}>{children}</HTMLContent>
                <div className="Actions Full" data-seleniumid={`widget-${seleniumid}-actions`}>
                    {actions}
                </div>
                {additionalActions && <div className="Additional">{additionalActions}</div>}
            </div>
        </div>
    );
}

type PublicationHistoryViewProps = {
    title: string;
    className?: string;
    children: React.ReactNode;
    seleniumid: string;
};

function PublicationHistoryView({
    children,
    className,
    seleniumid,
    title,
}: PublicationHistoryViewProps): React.ReactElement {
    return (
        <div className={cn('Widget PublicationHistory Block', className)} data-seleniumid={`widget-${seleniumid}`}>
            <div className="Title" data-seleniumid={`widget-${seleniumid}-title`}>
                <SubTitle data-seleniumid={`widget-${seleniumid}-text`}>{title}</SubTitle>
            </div>
            <HTMLContent seleniumid={seleniumid}>{children}</HTMLContent>
        </div>
    );
}

type HelpViewProps = {
    title: string;
    className?: string;
    children: React.ReactNode;
    seleniumid: string;
};

function HelpView({ children, className, seleniumid, title }: HelpViewProps): React.ReactElement {
    return (
        <div className={cn('Widget Help Block', className)} data-seleniumid={`widget-${seleniumid}`}>
            <div className="Title" data-seleniumid={`widget-${seleniumid}-title`}>
                <SubTitle data-seleniumid={`widget-${seleniumid}-text`}>{title}</SubTitle>
            </div>
            <HTMLContent seleniumid={seleniumid}>{children}</HTMLContent>
        </div>
    );
}

type ErrorViewProps = {
    className?: string;
    icon: keyof typeof SvgIcon;
    fill?: boolean;
    children: React.ReactNode;
    seleniumid: string;
};

function ErrorView({ children, className, fill = true, icon, seleniumid }: ErrorViewProps): React.ReactElement {
    const Icon = SvgIcon[icon];
    return (
        <div className={cn('Widget Error Block', className)} data-seleniumid={`widget-${seleniumid}`}>
            <div className="LeftBlock" data-seleniumid={`widget-${seleniumid}-left`}>
                <Icon className={cn('Icon', { fill, stroke: !fill })} seleniumid={`widget-${seleniumid}-icon`} />
            </div>
            <div className="RightBlock" data-seleniumid={`widget-${seleniumid}-right`}>
                <HTMLContent seleniumid={seleniumid}>{children}</HTMLContent>
            </div>
        </div>
    );
}

type WarningViewProps = {
    className?: string;
    seleniumid: string;
    title: string;
    children: React.ReactNode;
};

function WarningView({ children, className, seleniumid, title }: WarningViewProps): React.ReactElement {
    return (
        <div className={cn('Widget Warning Block', className)} data-seleniumid={`widget-${seleniumid}`}>
            <div className="RightBlock" data-seleniumid={`widget-${seleniumid}-right`}>
                <MainTitle className="Title" data-seleniumid={`widget-${seleniumid}-text`}>
                    {title}
                </MainTitle>
                <HTMLContent seleniumid={seleniumid}>{children}</HTMLContent>
            </div>
        </div>
    );
}

type BannerViewProps = {
    className?: string;
    icon: keyof typeof SvgIcon;
    fill?: boolean;
    children: React.ReactNode;
    seleniumid: string;
    title: string;
};

function BannerView({
    children,
    className,
    fill = true,
    icon,
    seleniumid,
    title,
}: BannerViewProps): React.ReactElement {
    const Icon = SvgIcon[icon];
    return (
        <div className={cn('Widget BannerWidget Block', className)} data-seleniumid={`widget-${seleniumid}`}>
            <div className="LeftBlock" data-seleniumid={`widget-${seleniumid}-left`}>
                <Icon className={cn('Icon', { fill, stroke: !fill })} seleniumid={`widget-${seleniumid}-icon`} />
            </div>
            <div className="RightBlock" data-seleniumid={`widget-${seleniumid}-right`}>
                <MainTitle className="Title" data-seleniumid={`widget-${seleniumid}-text`}>
                    {title}
                </MainTitle>
                <HTMLContent seleniumid={seleniumid}>{children}</HTMLContent>
            </div>
        </div>
    );
}

export {
    IconView,
    BasicView,
    GrayView,
    PurpleView,
    PublicationHistoryView,
    HelpView,
    ErrorView,
    WarningView,
    BannerView,
};

export default BasicView;
