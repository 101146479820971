import React from 'react';
import _ from 'underscore';
import './funder-picker__list-item.scss';

export default function FunderPickerListItem({ item }: { item: Funder }): React.ReactElement {
    return (
        <div>
            {item.alternateNames ? (
                <div>
                    <div className="FunderPicker__ListItem_FunderName">{item.name}</div>
                    <div className="FunderPicker__ListItem_AlternativeNames">
                        {_.isEmpty(item.alternateNames) ? '' : item.alternateNames.join(' | ')}
                    </div>
                </div>
            ) : (
                item.name
            )}

            {item.parents && (
                <div className="row" style={{ width: '100%' }}>
                    <div className="col-11 FunderPicker__ListItem_Parents">
                        {_.isEmpty(item.parents) ? '_' : item.parents.map(parent => parent.name).join(' / ')}
                    </div>
                    <div className="col-1 FunderPicker__ListItem_Country" />
                </div>
            )}
        </div>
    );
}
