import React from 'react';
import { LinkButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { EditorAffiliation } from '../../../utils';

type Props = {
    affiliation: EditorAffiliation;
    l: l;
    onDelete: (id: string) => void;
    selectorTitlePath: string;
    seleniumid: string;
};

function Header({ affiliation, l, onDelete, selectorTitlePath, seleniumid }: Props) {
    return (
        <>
            <div className="NotConfirmedInstitution-Header">
                <h3 className="NotConfirmedInstitution-Title" data-seleniumid={`${seleniumid}-institution-name`}>
                    {affiliation.institutionName}
                </h3>
                <LinkButton
                    className="NotConfirmedInstitution-DeleteBtn article_affiliation__delete"
                    data-seleniumid={`${seleniumid}-institution-delete`}
                    isBlack
                    onClick={() => onDelete(affiliation.id)}
                >
                    <SvgIcon.delete iconTitle="Delete Icon" />
                    Delete
                </LinkButton>
            </div>
            <div className="NotConfirmedInstitution-SubTitle">
                <p data-seleniumid={`${seleniumid}-suggestions-title`}>
                    <SvgIcon.error
                        className="NotConfirmedInstitution-InfoIcon"
                        iconDescr="Info mark symbol"
                        iconTitle="Info icon"
                    />
                    {l(selectorTitlePath)}
                </p>
            </div>
        </>
    );
}

export default withCodes(Header, ID.ARTICLE_AFFILIATION_EDITOR);
