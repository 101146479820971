import { REST } from './utils';

const { GET, POST } = REST('/alerts');

export interface AlertsPayload {
    alerts: Alert[];
    emails: string[];
    notificationEmail: string;
}

export const getAlerts = (alertId: string): Promise<AlertsPayload> => GET(`/email/${alertId}`);

export const saveAlerts = (alertId: string, data: { alerts: Alert[]; notificationEmail: string }): Promise<boolean> =>
    POST(`/email/${alertId}`, data);
