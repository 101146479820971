import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import './NavigationWidget.scss';

type NavigationWidgetItem = {
    disabled?: boolean;
    id: string;
    seleniumid: string;
    url?: string;
    title: string;
};

type Props = {
    active?: string;
    className?: string;
    items: NavigationWidgetItem[];
    renderItemChildren: (item: NavigationWidgetItem) => React.ReactNode;
    seleniumid: string;
    title: string;
};

export default function NavigationWidget(props: Props) {
    function renderItem(item: NavigationWidgetItem) {
        const itemProps = {
            children: props.renderItemChildren(item),
            className: cn('NavigationWidget-Item', {
                'NavigationWidget-Item--active': item.id === props.active,
                'NavigationWidget-Item--disabled': item.disabled,
            }),
            'data-seleniumid': item.seleniumid,
        };

        return (
            <li key={item.id}>
                {item.disabled || item.id === props.active ? (
                    <span {...itemProps} />
                ) : (
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <Link {...itemProps} to={item.url} />
                )}
            </li>
        );
    }

    return (
        <div>
            <div className="NavigationWidget-Header" data-seleniumid="navigation-header">
                {props.title}
            </div>
            <ul className={cn('NavigationWidget', props.className)} data-seleniumid={props.seleniumid}>
                {props.items.map(renderItem)}
            </ul>
        </div>
    );
}
