import _ from 'underscore';
import AddressDoctor from 'app/blocks/address-doctor/address-doctor';
import AddressDoctorNoSuggestions from 'app/blocks/address-doctor/address-doctor_no-suggestions';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import Alerts from 'app/blocks/common/spinner';
import * as middleware from 'app/blocks/middleware/middleware';
import middlewareStatic from 'app/blocks/middleware/static';

function convertFullAddressToLocation(obj: Address): AddressLocation {
    if (obj) {
        return {
            addressLine1: obj.addressLine1,
            addressLine2: obj.addressLine2,
            city: obj.city,
            countryCode: obj.countryCode,
            countryName: obj.countryName,
            stateCode: obj.stateCode,
            stateName: obj.stateName,
            zipCode: obj.zipCode,
        };
    }

    return null;
}

// eslint-disable-next-line import/prefer-default-export
export const AddressDoctorDialog = (previousAddress: Address, address: Address): Promise<AddressLocation> => {
    const previousLocation = convertFullAddressToLocation(previousAddress);
    const location = convertFullAddressToLocation(address);

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
        if (_.isEqual(previousLocation, location)) {
            resolve(location);
            return;
        }

        try {
            const [payload, errorCodes] = await Promise.all([
                middleware.address.validate(location),
                middlewareStatic.getErrorCodes(),
            ]);

            if (payload.error) {
                showDialog.modal(closeAnd => ({
                    type: AddressDoctorNoSuggestions,
                    userEntered: location,
                    onContinue: closeAnd(() => resolve(location)),
                    onCancel: closeAnd(() => reject(new Error(errorCodes.ADDRESS_VALIDATION_CANCELED))),
                }));
            } else if (_.isEmpty(payload.addresses)) {
                resolve(location);
            } else {
                showDialog.modal(closeAnd => ({
                    type: AddressDoctor,
                    suggestions: payload.addresses,
                    userEntered: location,
                    onContinue: data => closeAnd(() => resolve(data))(),
                    onCancel: closeAnd(() => reject(new Error(errorCodes.ADDRESS_VALIDATION_CANCELED))),
                }));
            }
        } catch (error) {
            showDialog.error(error, { onClose: reject });
        } finally {
            Alerts.removeSpinner();
        }
    });
};
