import cn from 'classnames';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { compose } from 'app/blocks/common/utils';
import validation from 'app/blocks/common/validation';
import ErrorLabel from 'app/blocks/ErrorLabel/ErrorLabel';
import PromptAction from 'app/blocks/Prompt/_action/prompt_action';
import NewLayout from 'app/blocks/Row/NewLayout';
import StaticContent from 'app/blocks/static-content/static-content';
import { SubTitle } from 'app/blocks/titles/titles';
import WarningLabel from 'app/blocks/WarningLabel/WarningLabel';
import { paymentInputMaxLength } from 'app/pages/orders/orders.constants';
import payment from 'app/pages/orders/orders.payment';
import { Input2 } from 'app/ui/form/inputs';
import RadioButton from 'app/ui/form/RadioButton';
import PaymentBreakdown from './PaymentBreakdown';
import withPaymentPanelContainer from './withPaymentPanelContainer';

function PaymentPanel(props) {
    const {
        isPONumberVisible,
        isPaymentMethodEditable,
        isPaymentMethodSupportedByBrowser,
        l,
        onPaymentMethodChange,
        onPaymentMethodFocus,
        onPurchaseNumberChange,
        order,
        paymentMethod,
        paymentMethodsAvailable,
        poNumber,
        validationResults,
    } = props;

    return (
        <section
            className={cn('payment-panel PaymentPanel', {
                'payment-panel--error': !validation.isAllKeysFalseOrEmpty(validationResults),
            })}
            data-seleniumid="PaymentPanel"
            style={{ marginTop: 0 }}
        >
            <SubTitle className="PaymentPanelTitle">{l('LICENSE_SIGNING.PAYMENT.TITLE.PAYMENT')}</SubTitle>

            {get(validationResults, 'paymentMethod') && <ErrorLabel text={validationResults.paymentMethod} />}

            {!isPaymentMethodSupportedByBrowser && (
                <WarningLabel className="my-base" seleniumId="browser-does-not-support-credit-card-payment-warning">
                    {l('ORDER_PANELS.PANEL_PAYMENT.CREDIT_CARD_PAYMENT_IS_NOT_SUPPORTED_BY_BROWSER')}
                </WarningLabel>
            )}
            <div className="PaymentPanelText">{l('ORDER_PANELS.PANEL_PAYMENT.PAY_BY')}</div>

            <div className="PaymentPanel-ActionRow">
                {paymentMethodsAvailable.map(item => (
                    <RadioButton
                        key={item.key}
                        checked={paymentMethod === item.key || paymentMethodsAvailable.length === 1}
                        className="PaymentMethodOption"
                        data-seleniumid={`order-payment-${item.key}-radio-input`}
                        disabled={!isPaymentMethodEditable}
                        id={`order-payment-${item.key}-radio`}
                        label={item.text}
                        labelStyle={{ flex: 'none' }}
                        logo={item.logo ? 'ENABLE' : 'DISABLE'}
                        onChange={e => onPaymentMethodChange(e.target.value)}
                        onFocus={onPaymentMethodFocus}
                        value={item.key}
                    />
                ))}
            </div>

            {paymentMethod === payment.method.SOCOPAY.key && (
                <StaticContent className="AliPayInfoText" seleniumid="alipay-info" src="alipay-info-text.html" />
            )}

            {isPONumberVisible && (
                <NewLayout
                    components={{
                        input: (
                            <Input2
                                className="poNumber-input"
                                data-seleniumid="order-payment-poNumber-input"
                                maxLength={paymentInputMaxLength.PO_NUMBER}
                                onChange={onPurchaseNumberChange}
                                placeholder="Optional"
                                type="text"
                                value={poNumber}
                            />
                        ),
                        prompt: (
                            <PromptAction
                                html={l('TOOLTIP.orderNumber')}
                                seleniumid="order-payment-poNumber__prompt-icon"
                            />
                        ),
                    }}
                    isOptional
                    label={l('ORDER_PANELS.PANEL_PAYMENT.PURCHASE_ORDER_NUMBER')}
                />
            )}

            <div className="PaymentPanelSnapPayText" data-seleniumid="payment-panel-snappay-text">
                *{l('ORDER_PANELS.PANEL_PAYMENT.SNAP_PAY')}
            </div>
            <PaymentBreakdown l={l} order={order} panelName="PaymentBreakdown" paymentMethod={paymentMethod} />
        </section>
    );
}

PaymentPanel.propTypes = {
    isPONumberVisible: PropTypes.bool.isRequired,
    isPaymentMethodEditable: PropTypes.bool.isRequired,
    isPaymentMethodSupportedByBrowser: PropTypes.bool.isRequired,
    l: PropTypes.func.isRequired,
    onPaymentMethodChange: PropTypes.func.isRequired,
    onPaymentMethodFocus: PropTypes.func.isRequired,
    onPurchaseNumberChange: PropTypes.func.isRequired,
    paymentMethod: PropTypes.oneOf([
        payment.method.CREDIT_CARD.key,
        payment.method.INVOICE.key,
        payment.method.PROFORMA.key,
        payment.method.SOCOPAY.key,
    ]),
    paymentMethodsAvailable: PropTypes.arrayOf(
        PropTypes.oneOf([
            payment.method.CREDIT_CARD,
            payment.method.INVOICE,
            payment.method.PROFORMA,
            payment.method.SOCOPAY,
        ]),
    ).isRequired,
    poNumber: PropTypes.string,
    validationResults: PropTypes.shape({}),
};

PaymentPanel.defaultProps = {
    paymentMethod: undefined,
    poNumber: undefined,
    validationResults: {},
};

export { PaymentPanel };
export default compose(
    withPaymentPanelContainer,
    withCodes2(ID.LICENSE_SIGNING, ID.ORDER_PANELS, ID.TOOLTIP),
)(PaymentPanel);
