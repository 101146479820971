import React from 'react';
import { LinkButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import InstitutionPicker from 'app/blocks/institution-picker/institution-picker';
import './newInstitution.scss';

type Props = {
    blacklist: string[];
    institutionsIdFromProfile: string[];
    l: l;
    onCancel: () => void;
    onChange: (id: string, item: any) => void;
};

function NewItem({ blacklist, institutionsIdFromProfile, l, onCancel, onChange }: Props) {
    return (
        <div className="NewInstitution">
            <div className="NewInstitution-InstitutionPicker">
                <InstitutionPicker
                    blacklist={blacklist}
                    changeHandler={institution => onChange(null, institution)}
                    institutionsIdFromProfile={institutionsIdFromProfile}
                    placeholder={l('ARTICLE_AFFILIATION_EDITOR.PICKER_PLACEHOLDERS.ADD')}
                    selectedItem={{
                        id: undefined,
                        name: undefined,
                    }}
                    seleniumid="new-institution"
                />
            </div>
            <div className="NewInstitution-Actions">
                <LinkButton isBlack onClick={onCancel}>
                    <SvgIcon.cancel iconTitle="Cancel Icon" />
                    Cancel
                </LinkButton>
            </div>
        </div>
    );
}

export default withCodes(NewItem, ID.ARTICLE_AFFILIATION_EDITOR);
