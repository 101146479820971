import cn from 'classnames';
import filesize from 'filesize';
import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import ErrorLabel from 'app/blocks/ErrorLabel/ErrorLabel';
import FileItem from './FileItem';
import './FileList.scss';

function FileList({ actions, className, config, fileItems, isTotalSizeCorrect, l }) {
    return (
        <div className={cn('uploadFileList', className)}>
            <div className="uploadFileList-actionLabels">
                <span>{l('LICENSE_SIGNING.LICENSE_SUBMISSION.LABEL.CLEAR_ACTION')}</span>
                <span>{l('LICENSE_SIGNING.LICENSE_SUBMISSION.LABEL.ORDER_ACTION')}</span>
            </div>

            <div>
                {fileItems.map((item, index) => (
                    <FileItem
                        key={item._id}
                        first={index === 0}
                        index={index}
                        item={item}
                        last={index === fileItems.length - 1}
                        onDelete={actions.onDeleteItem}
                        onMoveDown={actions.onMoveItemDown}
                        onMoveUp={actions.onMoveItemUp}
                    />
                ))}
            </div>
            <div className="mt-one-thirds" data-seleniumid="license-upload-error-container">
                {!isTotalSizeCorrect && (
                    <ErrorLabel
                        data-seleniumid="license-upload-file-size-exceeded"
                        text={l('ERROR.UPLOAD_LICENSE_DROP_ERRORS_FILES_SIZE_EXCEEDED_TPL', {
                            maxFileSize: filesize(config.maxTotalFilesSize, { base: 2 }),
                        })}
                    />
                )}
            </div>
        </div>
    );
}

export default withCodes2(ID.ERROR, ID.LICENSE_SIGNING)(FileList);
