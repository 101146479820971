import React from 'react';
import ComboboxWithChevron, { Props as ComboboxProps } from 'app/blocks/ComboboxWithChevron/ComboboxWithChevron';
import withResponsive from 'app/blocks/common/responsive-decorator';
import MobileCountryPicker from 'app/blocks/country-picker/MobileCountryPicker';
import * as middleware from 'app/blocks/middleware/middleware';

const COUNTRY_LIST_SIZE = 300;

type Props = { isFunderCountries?: boolean; isMobile: boolean; name?: string } & Pick<
    ComboboxProps<Country>,
    | 'changeHandler'
    | 'className'
    | 'disabled'
    | 'hideNoneValue'
    | 'isError'
    | 'onFocus'
    | 'placeholder'
    | 'selectedItem'
    | 'seleniumid'
>;

function CountryPicker({ isFunderCountries, isMobile, name, ...props }: Props): React.ReactElement {
    const getCountries = React.useCallback(
        search => middleware.address.getCountriesAll(search, isFunderCountries),
        [isFunderCountries],
    );

    if (isMobile) {
        return <MobileCountryPicker {...props} getListDataPromise={getCountries} name={name} />;
    }

    return <ComboboxWithChevron {...props} getListDataPromise={getCountries} pageSize={COUNTRY_LIST_SIZE} />;
}

export default withResponsive(CountryPicker);
