import React from 'react';
import { Button } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import SuccessMessage from 'app/blocks/confirmation/success-message';
import loginUtils from '../login/utils/login-utils';

type Props = {
    email: string;
    l: l;
};

function SuccessfulMessage({ email, l }: Props): React.ReactElement {
    return (
        <SuccessMessage
            className="m-btm_md"
            title={l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_THANK_YOU_LABEL`)}
        >
            <p
                dangerouslySetInnerHTML={{
                    __html: l(`${ID.REGISTRATION}.confirmationMessages.REGISTRATION_CONF_TEXT`, { email }),
                }}
            />

            <div className="m-btm_md">
                <span
                    className="m-r_mini"
                    dangerouslySetInnerHTML={{
                        __html: l(`${ID.REGISTRATION}.inlineHelp.REGISTRATION_NOT_RCVD_EMAIL_INLINE_HELP`),
                    }}
                />
                <Button
                    className="thankYou-resendButton"
                    name="button"
                    onClick={() => {
                        loginUtils.resendEmailRequest(email);
                    }}
                >
                    {l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_RESEND_BUTTON_LABEL`)}
                </Button>
            </div>
        </SuccessMessage>
    );
}

export default withCodes(SuccessfulMessage, ID.REGISTRATION);
