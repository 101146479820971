/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import FunderPanelContext2 from 'app/blocks/Panel/funder/FunderPanelContext2';
import ConfirmedInstitution from './ConfirmedInstitution/ConfirmedInstitution';
import NewInstitution from './NewIntitution/NewInstitution';

function Institutions({ data, onLoadSubstep, page, substep }) {
    useEffect(() => {
        if (onLoadSubstep && typeof onLoadSubstep === 'function') {
            onLoadSubstep(substep, page);
        }
    }, [onLoadSubstep, page, substep]);

    return (
        <ul className="Institutions">
            {Object.values(data).map(({ isEditing, program, validation }, index) => {
                let Item;

                if (!program.researchFunder) {
                    Item = NewInstitution;
                } else if (program.researchFunder.custom) {
                    Item = ConfirmedInstitution;
                } else {
                    Item = ConfirmedInstitution;
                }

                return (
                    <Item key={index} index={index} isEditing={isEditing} program={program} validation={validation} />
                );
            })}
        </ul>
    );
}

export default FunderPanelContext2.withContext(['data'])(Institutions);
