import cn from 'classnames';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { getSortArrFromObject } from 'app/blocks/common/utils';
import { isHere } from 'app/route/history';

function renderTopMenuItem(item) {
    return (
        <Link
            key={item.id}
            className={cn('Item btn btn-link', {
                Active: item.active,
            })}
            data-seleniumid={item.seleniumid}
            to={item.url}
        >
            {item.title}
        </Link>
    );
}

function mapItem(item) {
    return {
        disabled: false,
        id: item._id,
        seleniumid: `pageNavigation--${item._id}`,
        title: item.title,
        url: item.href,
    };
}

type Props = {
    codes: Record<string, any>;
    children: React.ReactNode;
};

function TopMenu({ children, codes }: Props) {
    const items = useMemo(
        () =>
            getSortArrFromObject(codes[ID.DASHBOARD_LEFT_SIDE])
                .filter(item => !item.hide)
                .map(mapItem),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [codes[ID.DASHBOARD_LEFT_SIDE]],
    );

    return (
        <>
            <div className="TopMenu" data-seleniumid="top-menu">
                {items.length && (
                    <div className="TopMenu-Container" data-seleniumid="top-menu-container">
                        {items.map(item => renderTopMenuItem({ ...item, active: isHere(item.url) }))}
                    </div>
                )}
            </div>
            {children}
        </>
    );
}

export default withCodes2(ID.DASHBOARD_LEFT_SIDE)(TopMenu);
