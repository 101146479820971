import React from 'react';
import { Title, WhiteBlock } from 'app/blocks/blocks';
import { ID, withCodes } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';

function MissingArticleSubmitSuccess(props) {
    const { codes } = props;
    const search = codes.SEARCH_CODES.SEARCH.MISSING_ARTICLE_SUBMIT;

    return (
        <WhiteBlock
            className="page p-2x MissingArticleDetailsSuccessWhiteBlock"
            data-seleniumid="missing-article-details-success"
        >
            <Title>
                <SvgIcon.schedule className="MissingArticleDetailsSuccessIcon" />

                {search.title}
            </Title>
            <div
                className="MissingArticleDetailsSuccessWrapper"
                dangerouslySetInnerHTML={{ __html: search.description }}
            />
        </WhiteBlock>
    );
}

export default withCodes(MissingArticleSubmitSuccess, ID.SEARCH_CODES);
