import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { PrimaryButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import ContentUtils from 'app/blocks/common/content-utils';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import Alerts from 'app/blocks/common/spinner';
import * as Utils from 'app/blocks/common/utils';
import validation from 'app/blocks/common/validation';
import ErrorLabel from 'app/blocks/ErrorLabel/ErrorLabel';
import { getSecurityQuestions, validateSecurityQuestions } from 'app/blocks/middleware/password';
import Password from 'app/blocks/Password/Password';
import { SubTitle } from 'app/blocks/titles/titles';
import EmailPanel from 'app/pages/login/jsx/email-panel';

type Props = {
    l: l;
    onAfterVerification: (
        email: string,
        questions: Array<{ securityAnswer: string; securityQuestionId: string }>,
    ) => void;
};

type State = {
    answersEmail: string;
    questions: Array<{ securityAnswer: string; securityQuestion: string; securityQuestionId: string }>;
};

class SecurityAnswers extends Component<Props, State> {
    static propTypes = {
        l: PropTypes.func.isRequired,
        onAfterVerification: PropTypes.func.isRequired,
    };

    state: State = {
        answersEmail: '',
        questions: null,
    };

    showQuestions = async answersEmail => {
        this.setState({
            answersEmail,
        });

        try {
            Alerts.renderSpinner();

            const payload = await getSecurityQuestions(answersEmail);
            // @ts-ignore
            this.setState({ questions: payload.securityDetails });
        } catch (error) {
            showDialog.error(error, {
                message: await ContentUtils.getServerErrorMessage(ID.RECOVER_PASSWORD, error),
            });
        } finally {
            Alerts.removeSpinner();
        }
    };

    updateAnswer(index, props) {
        const { questions } = this.state;

        questions[index] = {
            ...questions[index],
            ...props,
        };

        this.setState({ questions });
    }

    onChangeAnswer = (index, event) => {
        this.updateAnswer(index, {
            error: undefined,
            securityAnswer: event.target.value,
        });
    };

    validateAnswers() {
        let noErrors = true;

        const { questions } = this.state;

        questions.forEach((question, index) => {
            // eslint-disable-next-line no-param-reassign
            question.securityAnswer = (question.securityAnswer || '').trim();

            const error =
                validation.validate(question.securityAnswer, [
                    [
                        validation.notEmpty,
                        this.props.l('RECOVER_PASSWORD.errorMessages.PWD_RECOVERY_SEC_ANS_MISSING_ERR_TEXT'),
                    ],
                ]) || undefined;

            this.updateAnswer(index, {
                error,
            });

            if (error) {
                noErrors = false;
            }
        });

        this.setState({ questions });

        return noErrors;
    }

    submitAnswers = async () => {
        if (!this.validateAnswers()) {
            return;
        }

        const { answersEmail, questions } = this.state;

        try {
            Alerts.renderSpinner();
            await validateSecurityQuestions(answersEmail, questions);

            this.props.onAfterVerification(answersEmail, questions);
        } catch (error) {
            let message = await ContentUtils.getServerErrorMessage(ID.RECOVER_PASSWORD, error);

            if (error.code === 'REGISTRATION_SQ_ANSWER_LIMIT_EXCEEDED') {
                message = this.props.l(
                    'RECOVER_PASSWORD.errorMessages.PWD_RECOVERY_SEC_ANS_INCORRECT_3_TIMES_ERR_TEXT',
                );

                const timeLeft =
                    (error.payload.timeLeft && moment.duration(error.payload.timeLeft).humanize()) || 'few minutes';
                message = Utils.template(message, { timeLeft });
            }

            showDialog.error(error, { message });
        } finally {
            Alerts.removeSpinner();
        }
    };

    renderQuestion = (question, index) => {
        const id = `recover-security-questions-q-${index + 1}`;

        return (
            <div key={id} className="RecoveryRow">
                <label className="RecoveryRow-Label" htmlFor={id}>
                    {question.securityQuestion}
                </label>
                <div className="RecoveryRow-Input">
                    <Password
                        id={id}
                        isError={question.error}
                        onChange={event => this.onChangeAnswer(index, event)}
                        seleniumid={id}
                        value={question.securityAnswer}
                    />
                    {question.error && <ErrorLabel data-seleniumid={`questions-error-${id}`} text={question.error} />}
                </div>
            </div>
        );
    };

    render() {
        const { l } = this.props;
        const { questions } = this.state;

        if (!questions) {
            return (
                <EmailPanel
                    buttonCaption={l('RECOVER_PASSWORD.uiLabelMessages.PWD_RECOVERY_NEXT_BUTTON_LABEL')}
                    header={l('RECOVER_PASSWORD.uiLabelMessages.PWD_RECOVERY_SEC_QUE_LABEL')}
                    inputCaption={l('RECOVER_PASSWORD.uiLabelMessages.PWD_RECOVERY_PRIMARY_EMAIL_ADDR_LABEL')}
                    inputPlaceholder={l('RECOVER_PASSWORD.uiLabelMessages.PWD_RECOVERY_PRIMARY_EMAIL_PLACEHOLDER')}
                    onSubmit={this.showQuestions}
                    seleniumid="recover-security-questions"
                />
            );
        }

        if (!questions.length) {
            return (
                <div className="RecoveryBlock" data-seleniumid="recover-security-questions-panel">
                    <SubTitle className="RecoveryRow-Title">
                        {l('RECOVER_PASSWORD.uiLabelMessages.PWD_RECOVERY_SEC_QUE_LABEL')}
                    </SubTitle>

                    <div className="RecoveryRow-Form">
                        <p
                            dangerouslySetInnerHTML={{
                                __html: l('RECOVER_PASSWORD.errorMessages.PWD_RECOVERY_NO_SECURITY_ERR_TEXT'),
                            }}
                        />
                    </div>
                </div>
            );
        }

        return (
            <div className="RecoveryBlock" data-seleniumid="recover-security-questions-panel">
                <SubTitle className="RecoveryRow-Title">
                    {l('RECOVER_PASSWORD.uiLabelMessages.PWD_RECOVERY_SEC_QUE_LABEL')}
                </SubTitle>
                <form className="RecoveryRow-Form">
                    {questions.map(this.renderQuestion)}

                    <div className="RecoveryRow RecoveryRow-Actions">
                        <PrimaryButton
                            className="bt"
                            data-seleniumid="recover-security-questions-submit-button"
                            onClick={this.submitAnswers}
                        >
                            {l('RECOVER_PASSWORD.uiLabelMessages.PWD_RECOVERY_SUBMIT_BUTTON_LABEL')}
                        </PrimaryButton>
                    </div>
                </form>
            </div>
        );
    }
}

export default withCodes(SecurityAnswers, ID.RECOVER_PASSWORD);
