import cn from 'classnames';
import React from 'react';
import ReactSelect from 'react-select';
import { LinkButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { compose } from 'app/blocks/common/utils';
import FunderPanelContext2 from 'app/blocks/Panel/funder/FunderPanelContext2';
import Context from 'app/pages/license-signing-process/context/Context';
import { funderInputMaxLength } from 'app/pages/orders/orders.constants';
import { Input } from 'app/ui/form';
import CheckboxButton from 'app/ui/form/CheckboxButton';
import './GrantDetails.scss';

class GrantDetails extends React.PureComponent {
    componentDidMount() {
        const { onLoadSubstep, page, substep } = this.props;

        if (onLoadSubstep && typeof onLoadSubstep === 'function') {
            onLoadSubstep(substep, page);
        }
    }

    renderGrantNumber(total, article, programIndex, grantIndex, program, grantNumber, authors, noGrants) {
        const { l } = this.props;

        // fixme - AS-22227 - .authors from getArticleAndJournal
        const options = article.authors.map(x => ({ value: x.participantId, label: x.name }));

        const onGrantNumberChange = async event => {
            const value = event.target.value.trim();

            if (total === 0) {
                await this.props.addGrant(program._id);
            }

            this.props.updateGrantNumber(program._id, grantIndex, value);
        };

        const onRecipientsChange = async values => {
            if (total === 0) {
                await this.props.addGrant(program._id);
            }
            this.props.updateRecipients(
                program._id,
                grantIndex,
                values.map(option => option.value),
            );
        };

        const onAddGrantClick = () => {
            this.props.addGrant(program._id);
        };

        const onRemoveClick = () => {
            this.props.removeGrant(program._id, grantIndex);
        };

        return (
            <div key={grantIndex} className={cn('grantDetails-grantNumber', { disabledBlock: noGrants })}>
                <div className="grantDetails-number">
                    <div>
                        <label htmlFor={`grantDetails-number-${programIndex}-${grantIndex}`}>
                            {`${l('LICENSE_SIGNING.FUNDERS.LABEL.GRANT_NUMBER')} `}
                            <span className="text_red">{l('LICENSE_SIGNING.FUNDERS.LABEL.POSTFIX_REQUIRED')}</span>
                        </label>
                    </div>
                    <div>
                        <Input
                            data-seleniumid={`grantNumberInput-${programIndex}-${grantIndex}`}
                            disabled={noGrants}
                            id={`grantDetails-number-${programIndex}-${grantIndex}`}
                            maxLength={funderInputMaxLength.GRANT_NUMBER}
                            onChange={onGrantNumberChange}
                            placeholder="Enter grant number"
                            value={grantNumber}
                        />
                    </div>
                </div>
                <div className={cn('grantDetails-recipient', { disabledBlock: noGrants || !grantNumber })}>
                    <div>
                        <label>
                            {`${l('LICENSE_SIGNING.FUNDERS.LABEL.RECIPIENTS')} `}
                            <span className="text_italic">{l('LICENSE_SIGNING.FUNDERS.LABEL.POSTFIX_OPTIONAL')}</span>
                        </label>
                    </div>
                    <div style={{ cursor: noGrants || !grantNumber ? 'not-allowed' : 'default' }}>
                        <ReactSelect
                            className={`recipients recipinets-${programIndex}-${grantIndex}`}
                            classNamePrefix="recipients"
                            // components={{
                            //     Option: ({ children, className, ...rest }) => (
                            //         <components.Option {...rest} className={cn(className, { 'text-primary': rest.isSelected })}>
                            //             <Elements.Checkbox checked={rest.isSelected} onChange={() => {}}>{children}</Elements.Checkbox>
                            //         </components.Option>
                            //     ),
                            // }}
                            hideSelectedOptions={false}
                            isClearable={false}
                            isDisabled={noGrants || !grantNumber}
                            isMulti
                            isSearchable={false}
                            onChange={onRecipientsChange}
                            options={options}
                            placeholder="Add one or more recipients"
                            value={options.filter(option => authors.some(x => x.participantId === option.value))}
                        />
                    </div>
                </div>
                <div className="grantDetails-actions">
                    {total > 1 && (
                        <LinkButton
                            data-seleniumid={`removeGrantButton-${programIndex}-${grantIndex}`}
                            disabled={noGrants}
                            isBlack
                            isDanger
                            onClick={onRemoveClick}
                        >
                            <SvgIcon.cancel iconTitle="Clear Icon" />
                            {l('LICENSE_SIGNING.BUTTON.REMOVE')}
                        </LinkButton>
                    )}
                    {grantIndex === total - 1 && (
                        <LinkButton
                            data-seleniumid={`addGrantButton-${programIndex}`}
                            disabled={noGrants || !grantNumber}
                            isBlack
                            onClick={onAddGrantClick}
                        >
                            <SvgIcon.add iconTitle="Add Grant Icon" />
                            {l('LICENSE_SIGNING.BUTTON.ADD_GRANT')}
                        </LinkButton>
                    )}
                </div>
            </div>
        );
    }

    render() {
        const { l } = this.props;

        return (
            <div className="grantDetails">
                {Object.values(this.props.data).map(({ noGrants, program }, i) => (
                    <div
                        key={program._id}
                        className={cn('grantDetails-researchProgram', {
                            'grantDetails-researchProgram--error':
                                !noGrants && program.grants.every(x => !x.grantNumber),
                        })}
                    >
                        <div className="grantDetails-researchFunder">{program.researchFunder.name}</div>
                        <div className="grantDetails-grantNumbers">
                            {(program.grants.length > 0 ? program.grants : [{ authors: [], grantNumber: '' }]).map(
                                ({ authors, grantNumber }, j) =>
                                    this.renderGrantNumber(
                                        program.grants.length,
                                        this.props.article,
                                        i,
                                        j,
                                        program,
                                        grantNumber,
                                        authors,
                                        noGrants,
                                    ),
                            )}
                        </div>

                        <CheckboxButton
                            checked={noGrants}
                            className="grantDetails-noGrants"
                            data-seleniumid={`noGrantsCheckbox-${i}`}
                            id={`noGrants-${i}`}
                            label={l('LICENSE_SIGNING.FUNDERS.LABEL.NO_GRANTS')}
                            onChange={() => this.props.toggleNoGrants(program._id)}
                        />
                    </div>
                ))}
            </div>
        );
    }
}

export default compose(
    Context.withContext(['article']),
    FunderPanelContext2.withContext([
        'addGrant',
        'data',
        'removeGrant',
        'toggleNoGrants',
        'updateGrantNumber',
        'updateRecipients',
    ]),
    withCodes2(ID.FUNDER, ID.LICENSE_SIGNING),
)(GrantDetails);
