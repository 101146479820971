function isArticlePublished(data) {
    const inSearchResp = data?.publishedAsEarlyViewDate || data?.publishedInIssueDate;
    const inArticleAndJournalResp = data?.pubStatusDates?.EARLY_VIEW || data?.pubStatusDates?.ISSUE_PUB_ONLINE;
    return !!(inSearchResp || inArticleAndJournalResp || data?.published); // todo: check if 'data.published' is legacy
}

function isOnlineOpenJournal(journal) {
    return journal.revenueModel === 'OO';
}

export { isArticlePublished, isOnlineOpenJournal };
