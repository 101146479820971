import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';

type Props<T = any> = {
    articlesTxt: string;
    clearSearch: () => void;
    filtered?: T[];
    items: T[];
    l: l;
    searchValue?: string;
    startSearch: (value: string) => void;
};

function DashboardFilter({
    articlesTxt,
    clearSearch,
    filtered,
    items,
    l,
    searchValue,
    startSearch,
}: Props): React.ReactElement {
    const inputRef = React.useRef<HTMLInputElement>();

    const onClearSearch = () => {
        clearSearch();

        inputRef.current.focus();
    };

    return (
        <div className="DashboardFilter" data-seleniumid="dashboard-filter">
            <div className="DashboardFilter-Header" data-seleniumid="filter-header">
                <span className="DashboardFilter-Header-Text" data-seleniumid="filter-header-text">
                    {l('DASHBOARD.DASHBOARD_FILTER_TEXT')}
                </span>
                {filtered ? (
                    <span data-seleniumid="filter-header-article-count-filtered">
                        {`${filtered.length} of ${items.length} ${articlesTxt}`}
                    </span>
                ) : (
                    <span data-seleniumid="filter-header-article-count">{`${items.length} ${articlesTxt}`}</span>
                )}
            </div>
            <div className="DashboardFilter-Content" data-seleniumid="filter-content">
                <SvgIcon.search seleniumid="filter-icon-search" />
                <input
                    ref={inputRef}
                    className="DashboardFilter-Input"
                    data-seleniumid="filter-field"
                    onChange={e => startSearch(e.target.value)}
                    onFocus={() => inputRef.current.select()}
                    placeholder={l('DASHBOARD.DASHBOARD_FILTER_PLACEHOLDER')}
                    type="text"
                    value={searchValue}
                />
                {searchValue && <SvgIcon.clear onClick={onClearSearch} seleniumid="filter-icon-clear" />}
            </div>
        </div>
    );
}

export default withCodes2(ID.DASHBOARD)(DashboardFilter);
