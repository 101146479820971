import React from 'react';
import { Layout } from 'app/blocks/blocks';
import { ID, withCodes } from 'app/blocks/common/codes';
import validation from 'app/blocks/common/validation';
import * as middleware from 'app/blocks/middleware/middleware';
import OrdersRow from 'app/blocks/Row/Row';
import { SubTitle } from 'app/blocks/titles/titles';
import { vatTaxInputMaxLength } from 'app/pages/orders/orders.constants';
import CheckboxButton from 'app/ui/form/CheckboxButton';

export function displayVAT(countryCode) {
    return !!countryCode && countryCode !== 'US';
}

const CountryVATFormatHint = ({ ignoreRevenueModel, l, revenueModel }) =>
    ignoreRevenueModel || revenueModel === 'OO' ? (
        <>
            <br />
            {l('ORDER_PANELS.PANEL_VAT_TAX.VAT_NUMBER_INFO')}
        </>
    ) : null;

const CountryVATNumberInfo = ({ className, countryVATFormat, ignoreRevenueModel, l, revenueModel }) =>
    countryVATFormat ? (
        <div className={`${className}-Row`} data-seleniumid="VAT-number">
            {l('ORDER_PANELS.PANEL_VAT_TAX.VAT_COUNTRY_TEXT', { countryVATFormat })}
            <CountryVATFormatHint ignoreRevenueModel={ignoreRevenueModel} l={l} revenueModel={revenueModel} />
        </div>
    ) : null;

function NotUS({ className, countryCode, ignoreRevenueModel, l, revenueModel, updateVatIdNumber, vatIdNumber }) {
    const [countryVATFormat, setCountryVATFormat] = React.useState('');
    const [vatTaxRelevanceDetails, setVatTaxRelevanceDetails] = React.useState({});
    React.useEffect(() => {
        (async function () {
            setCountryVATFormat(await middleware.product.getCountryVATFormat(countryCode));
            setVatTaxRelevanceDetails(await middleware.orders.getVatTaxRelevanceDetails(countryCode));
        })();
    }, [countryCode]);

    const error =
        vatIdNumber && !validation.validateVatIdNumber(vatIdNumber, vatTaxRelevanceDetails.regex)
            ? { message: l('ERROR.VAT_TAX_VAT_ID_NOT_VALID') }
            : null;

    return (
        <div className={`${className}-Form`}>
            <OrdersRow
                className={`${className}-Row`}
                error={error}
                formSeleniumid="order-vat"
                isVertical
                label={l('ORDER_PANELS.PANEL_VAT_TAX.VAT_TAX_NUMBER')}
                maxLength={vatTaxInputMaxLength.VAT_ID}
                name="idNumber"
                onChange={updateVatIdNumber}
                placeholder="Optional"
                value={vatIdNumber}
            />

            <CountryVATNumberInfo
                className={className}
                countryVATFormat={countryVATFormat}
                ignoreRevenueModel={ignoreRevenueModel}
                l={l}
                revenueModel={revenueModel}
            />
        </div>
    );
}

const VatOrTaxForm = props => {
    const {
        className,
        countryCode,
        ignoreRevenueModel,
        isValidating,
        l,
        revenueModel,
        updateVatIdNumber,
        vatIdNumber,
    } = props;

    const [isTaxChecked, setIsTaxChecked] = React.useState(false);

    if (isValidating) {
        return <Layout isLoading />;
    }

    const checkbox = (
        <div className="ExemptionStatus" style={{ marginTop: 30 }}>
            <div className="ExemptionStatus-Title">{l('LICENSE_SIGNING.PAYMENT.TAX_CHECKBOX.TITLE')}</div>
            <CheckboxButton
                checked={isTaxChecked}
                className="ExemptionStatus-Checkbox"
                data-seleniumid="tax-exemption-status-checkbox"
                label={l('LICENSE_SIGNING.PAYMENT.TAX_CHECKBOX.LABEL')}
                onChange={() => setIsTaxChecked(!isTaxChecked)}
            />
            {isTaxChecked && (
                <div
                    className="ExemptionStatus-Text"
                    dangerouslySetInnerHTML={{ __html: l('LICENSE_SIGNING.PAYMENT.TAX_CHECKBOX.TEXT') }}
                />
            )}
        </div>
    );

    let field = null;

    if (displayVAT(countryCode, revenueModel)) {
        field = (
            <NotUS
                className={className}
                countryCode={countryCode}
                ignoreRevenueModel={ignoreRevenueModel}
                l={l}
                revenueModel={revenueModel}
                updateVatIdNumber={updateVatIdNumber}
                vatIdNumber={vatIdNumber}
            />
        );
    }

    return (
        <>
            {countryCode && (
                <SubTitle className="VatTaxPanel-Title">{l('LICENSE_SIGNING.PAYMENT.TITLE.VAT_TAX')}</SubTitle>
            )}
            {field}
            {countryCode && checkbox}
        </>
    );
};

export default withCodes(VatOrTaxForm, ID.ORDER_PANELS, ID.TOOLTIP, ID.LICENSE_SIGNING);
