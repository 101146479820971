import React from 'react';

function DiscountsTypeContent({ children }: { children: string[] }) {
    return (
        <ul className="orderDiscounts-texts">
            {children.map(el => (
                <li dangerouslySetInnerHTML={{ __html: el }} key={el} className="orderDiscounts-text" />
            ))}
        </ul>
    );
}

export default DiscountsTypeContent;
