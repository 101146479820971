import React from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import StaticContent from 'app/blocks/static-content/static-content';
import { HelpView } from 'app/blocks/views/WidgetView';
import { useArticlePage } from 'app/pages/article/ArticlePage.context';
import { canDoRequiredActions } from 'app/pages/dashboard/utils';
import { linkActions } from '../actions';

const panel = 'funding';

function HelpWidget({ l }: { l: l }): React.ReactElement {
    const item = useArticlePage();
    const { getAllActions, hasPanel, hasRequired } = item.helpers;
    const showFunders = hasPanel(panel) && !hasRequired(panel) && !item.journal.openAccess;

    const buttons = [];
    linkActions(getAllActions(panel)).forEach(a => buttons.push(', ', a));
    const [, links] = buttons;

    const src = `help-widget-${canDoRequiredActions(item) ? 'RESPONSIBLE_CORRESPONDING_AUTHOR' : 'AUTHOR'}.html`;

    return (
        <HelpView seleniumid="help" title={l('WIDGETS.HELP.TITLE')}>
            <>
                {showFunders && (
                    <>
                        <StaticContent seleniumid="help-affiliations" src="help-widget-affiliations.html" />
                        {links}
                    </>
                )}
                <StaticContent seleniumid="help-author" src={src} />
            </>
        </HelpView>
    );
}

export default withCodes(HelpWidget, ID.WIDGETS);
