/* eslint-disable react/no-unused-state */
import { autobind } from 'core-decorators';
import update from 'immutability-helper';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import { processing } from 'app/blocks/common/decorators';
import { compose } from 'app/blocks/common/utils';
import withLightPanel from 'app/blocks/Panel/advanced/withLightPanel';
import OnlineOpenPaymentContext from 'app/pages/license-signing-process/Payment/OnlineOpenPaymentContext';
import payment from 'app/pages/orders/orders.payment';
import validation from 'app/pages/orders/panel/payment/validation';

const PaymentMethod = payment.method;

const defaultState = {
    paymentDetails: {},
};

function withPaymentPanelContainer(Component) {
    @autobind
    class PaymentPanelContainer extends React.PureComponent {
        static propTypes = {
            canChangePaymentMethod: PropTypes.bool,
            isOrderExisting: PropTypes.bool,
            order: PropTypes.shape({ paymentDetails: PropTypes.shape({}) }).isRequired,
            paymentDetails: PropTypes.shape({}),
            paymentMethods: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
            setState: PropTypes.func.isRequired,
            setValidationHandler: PropTypes.func.isRequired,
        };

        static defaultProps = {
            ...defaultState,
            canChangePaymentMethod: true,
            isOrderExisting: false,
        };

        async componentDidMount() {
            this.props.setValidationHandler(this.validate);
            this.updatePayment(this.props.order.paymentDetails);
        }

        setState(patch) {
            this.props.setState(patch);
        }

        @processing('isValidating')
        async validate() {
            try {
                await validation(this.props.paymentDetails);
                this.props.setState({ validationResults: undefined });
            } catch (error) {
                if (error.name === 'ValidationError') {
                    this.props.setState({ validationResults: error.errors });
                }

                throw error;
            }
        }

        // eslint-disable-next-line react/no-unused-class-component-methods
        isChanged() {
            return Object.keys(defaultState).some(
                key => this.props[key] && !_.isEqual(defaultState[key], this.props[key]),
            );
        }

        updatePayment(paymentDetails) {
            this.setState({ paymentDetails: { ...this.props.paymentDetails, ...paymentDetails } });
        }

        onPaymentMethodChange(paymentMethod) {
            if (paymentMethod === PaymentMethod.CREDIT_CARD.key) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { poNumber, ...otherPaymentDetails } = this.props.paymentDetails;

                this.setState({ paymentDetails: { ...otherPaymentDetails, paymentMethod } });
            } else {
                this.updatePayment({ paymentMethod });
            }
            this.props.order.paymentDetails.paymentMethod = paymentMethod;
        }

        onPurchaseNumberChange(poNumber) {
            this.updatePayment({ poNumber });
        }

        onPaymentMethodFocus() {
            this.setState({
                validationResults: update(this.props.validationResults || {}, { paymentMethod: { $set: undefined } }),
            });
        }

        isPoNumberShouldBeDisabled() {
            const method = this.props.paymentDetails.paymentMethod;
            return method !== PaymentMethod.PROFORMA.key && method !== PaymentMethod.INVOICE.key;
        }

        render() {
            return (
                <Component
                    {...this.props}
                    isPaymentMethodEditable={this.props.canChangePaymentMethod}
                    isPoNumberShouldBeDisabled={this.isPoNumberShouldBeDisabled()}
                    onPaymentMethodChange={this.onPaymentMethodChange}
                    onPaymentMethodFocus={this.onPaymentMethodFocus}
                    onPurchaseNumberChange={this.onPurchaseNumberChange}
                    paymentMethod={get(this.props, 'paymentDetails.paymentMethod')}
                    paymentMethodsAvailable={this.props.paymentMethods}
                    poNumber={get(this.props, 'paymentDetails.poNumber')}
                />
            );
        }
    }

    return PaymentPanelContainer;
}

export { withPaymentPanelContainer };
export default Component =>
    compose(
        OnlineOpenPaymentContext.withContext(state => ({
            ...state.initiate.payment,
            canChangePaymentMethod: state.canChangePaymentMethod,
            isOrderExisting: state.isOrderExisting,
            isPaymentMethodSupportedByBrowser: state.isPaymentMethodSupportedByBrowser,
            paymentMethods:
                state.order.billingAddress.countryCode === 'CN' && state.socopayEnabled
                    ? payment.ooPaymentMethodsWithAlipay
                    : payment.ooPaymentMethods,
            register: state.registerPanel,
            setState: state.setPaymentState,
            unregister: state.unregisterPanel,
            order: state.order,
        })),
        withLightPanel('payment'),
        withPaymentPanelContainer,
    )(Component);
