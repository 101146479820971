import update from 'immutability-helper';
import _ from 'underscore';

export function validateTotalSize(fileItems, config) {
    const totalFilesSize = fileItems.map(i => i.file.size).reduce((s, v) => s + v, 0);
    return totalFilesSize <= config.maxTotalFilesSize;
}

export function validateFilesCount(itemsCount, fileItems, config) {
    return itemsCount + fileItems.length <= config.maxFilesNumber;
}

export function validateFileTypes(types, config) {
    return types.every(type => config.supportedMimeTypes.indexOf(type) > -1);
}

export function validateFileType(item, config) {
    const fileExtension = item.file.name.split('.').pop().toLowerCase();

    return {
        ...item,
        hasCorrectFileType:
            config.supportedMimeTypes.indexOf(item.file.type) > -1 ||
            ['pdf', 'png', 'jpg', 'jpeg'].includes(fileExtension),
    };
}

export function validateFileSizeExceeded(item, config) {
    return {
        ...item,
        hasCorrectFileSize: config.maxTotalFilesSize > item.file.size,
    };
}

export function validateFileName(item, fileItems) {
    return {
        ...item,
        hasCorrectFileName: fileItems.map(x => x.file.name).indexOf(item.file.name) === -1,
    };
}

export function validate(item) {
    return _.every([_.isEmpty(item.error), item.hasCorrectFileType, item.hasCorrectFileSize, item.hasCorrectFileName]);
}

export function validateFileItem(item) {
    return update(item, { isAborted: { $set: !validate(item) } });
}

export function filterAllDuplicatesOnDelete(deletedItem, fileItems) {
    const nameCount = fileItems.reduce((n, value) => n + (value.file.name === deletedItem.file.name), 0);

    return fileItems.map(item => {
        if (item.file.name === deletedItem.file.name && nameCount === 1) {
            return update(validateFileItem(item), { hasCorrectFileName: { $set: true } });
        }

        return item;
    });
}
