import React from 'react';
import { Layout } from 'app/blocks/blocks';
import { compose } from 'app/blocks/common/utils';
import DiscountAndBillingContext from './DiscountAndBillingContext';
import InitiateStep from './InitiateStep';

function DiscountAndBillingDetails({ isProcessing }) {
    if (isProcessing) {
        return <Layout isLoading />;
    }
    return <InitiateStep />;
}

export default compose(
    DiscountAndBillingContext.withProvider,
    DiscountAndBillingContext.withContext(state => ({
        isProcessing: state.isProcessing,
    })),
)(DiscountAndBillingDetails);
