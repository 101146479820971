import React from 'react';
import { l } from 'app/blocks/common/codes';
import Modal from 'app/blocks/Modal/Modal';
import './NotifyAffiliationsPopup.scss';
import StatusPopup, { StatusPopupTypeEnum } from 'app/blocks/StatusPopup/StatusPopup';
import { ForwardButtonWithOkIcon } from 'app/pages/license-signing-process/buttons';

export default function NotifyAffiliationsSuccessPopup(props) {
    return (
        <Modal
            className="Dialog"
            ignoreBackdropClicks
            modalHeading={props?.modalHeading}
            onClose={props?.onClose}
            type="custom"
        >
            <form>
                <div
                    className="modal-body Dialog-Body"
                    data-seleniumid="modal-body"
                    style={{ paddingBottom: '30px', textAlign: 'left' }}
                >
                    <div>
                        <StatusPopup
                            isCloseButton={false}
                            isOkayIcon
                            seleniumid="article-affiliations-status-popup"
                            type={StatusPopupTypeEnum.INFO}
                        >
                            {l('LICENSE_SIGNING.NOTIFYAFFILIATION.CONTENT.SUCCESS')}
                        </StatusPopup>
                        <div className="notify-success-msg-main">
                            <p className="notify-msg" data-seleniumid="affiliation-add-note">
                                {l('LICENSE_SIGNING.NOTIFYAFFILIATION.CONTENT.MESSAGE')}
                            </p>
                            <p className="notify-msg" data-seleniumid="affiliation-help-note">
                                {l('LICENSE_SIGNING.NOTIFYAFFILIATION.CONTENT.MESSAGE1')}
                            </p>
                        </div>
                    </div>
                    <div className="Dialog-BodyActions Dialog-BodyActions-Row" style={{ with: '100%' }}>
                        <ForwardButtonWithOkIcon
                            className="notify-close-btn"
                            data-seleniumid="close-button"
                            onClick={() => props?.onClose()}
                        >
                            {l('LICENSE_SIGNING.NOTIFYAFFILIATION.BUTTON.CLOSE')}
                        </ForwardButtonWithOkIcon>
                    </div>
                </div>
            </form>
        </Modal>
    );
}
