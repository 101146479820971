import React, { Component } from 'react';
import { PrimaryButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import validation from 'app/blocks/common/validation';
import ErrorLabel from 'app/blocks/ErrorLabel/ErrorLabel';
import { SubTitle } from 'app/blocks/titles/titles';
import { Input } from 'app/ui/form';

type Props = {
    autofocus?: boolean;
    buttonCaption: string;
    header: string;
    inputCaption: string;
    inputPlaceholder: string;
    l: l;
    onSubmit: (email: string) => void;
    seleniumid: string;
};

type State = {
    email?: string;
    error?: string;
};

class EmailPanel extends Component<Props, State> {
    static defaultProps = {
        autofocus: false,
    };

    state: State = {
        email: '',
        error: '',
    };

    emailInput = React.createRef<HTMLInputElement>();

    componentDidMount() {
        if (this.props.autofocus) {
            this.emailInput.current.focus();
        }
    }

    onChange = event => {
        this.setState({
            email: event.target.value.trim(),
            error: '',
        });
    };

    validate() {
        const { l } = this.props;

        // eslint-disable-next-line react/no-access-state-in-setstate
        const error = validation.validate(this.state.email, [
            [validation.notEmpty, l('RECOVER_PASSWORD.errorMessages.PWD_RECOVERY_EMAIL_ADDR_MISSING_ERR_TEXT')],
            [validation.isEmailValid, l('RECOVER_PASSWORD.errorMessages.PWD_RECOVERY_EMAIL_ADDR_INVALID_ERR_TEXT')],
        ]);

        this.setState({
            error,
        });

        return !error;
    }

    onSubmit = async () => {
        if (this.validate()) {
            this.props.onSubmit(this.state.email);
        }
    };

    render() {
        const { buttonCaption, header, inputCaption, inputPlaceholder, seleniumid } = this.props;

        const { email, error } = this.state;

        const id = `${seleniumid}-email-input`;

        return (
            <div className="RecoveryBlock" data-seleniumid={`${seleniumid}-panel`}>
                <SubTitle className="RecoveryRow-Title">{header}</SubTitle>
                <form className="RecoveryRow-Form">
                    <div className="RecoveryRow">
                        <label className="RecoveryRow-Label" htmlFor={id}>
                            {inputCaption}
                        </label>
                        <div className="RecoveryRow-Input">
                            <Input
                                ref={this.emailInput}
                                data-seleniumid={`${seleniumid}-email`}
                                id={id}
                                isError={!!error}
                                onChange={this.onChange}
                                placeholder={inputPlaceholder}
                                type="email"
                                value={email}
                            />
                            {error && <ErrorLabel text={error} />}
                        </div>
                    </div>

                    <div className="RecoveryRow RecoveryRow-Actions">
                        <PrimaryButton data-seleniumid={`${seleniumid}-button`} onClick={this.onSubmit}>
                            {buttonCaption}
                        </PrimaryButton>
                    </div>
                </form>
            </div>
        );
    }
}

export { EmailPanel };
export default withCodes(EmailPanel, ID.RECOVER_PASSWORD);
