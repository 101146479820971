/* eslint-disable react/no-string-refs, no-nested-ternary */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import { tryCatch } from 'app/blocks/common/decorators';
import StaticContent from 'app/blocks/static-content/static-content';
import SearchAdvancedResult from 'app/pages/search/_advanced/_result/search_advanced_result';
import ContentSearchForm from 'app/pages/search/_advanced/content_search_form';
import Form from 'app/pages/search/_advanced/search_advanced.form';
import './advance_content_search.scss';
import NoArticleResults from 'app/pages/search/no-article-results/NoArticleResults';

export class AdvanceContentSearch extends Component {
    static propTypes = {
        jid: PropTypes.string,
        context: PropTypes.shape({}),
    };

    static defaultProps = {
        jid: undefined,
        context: undefined,
    };

    state = {
        action: 'provide',
        type: this.props.type,
        journalForm: Form.create(),
        articleForm: Form.create(),
        jid: this.props.jid,
    };

    @tryCatch
    async componentDidMount() {
        if (this.props.context) {
            const { context } = this.props;
            this.setState(
                {
                    type: this.props.type,
                    action: 'search',
                    context: this.props.context,
                    journalForm: context.journalForm,
                    articleForm: context.articleForm,
                },
                () => context.action === 'provide' && this.displayAttributes(),
            );
        }
    }

    displayAttributes = () => this.setState({ action: 'provide', context: null });

    search = (form, type) => this.setState({ type, action: 'search', form });

    clear = () => {
        const form = Form.create();
        if (this.state.type === 'journal') {
            this.setState({ journalForm: form });
        } else {
            this.setState({ articleForm: form, jid: null });
        }
    };

    render() {
        const { journalForm, articleForm, jid, form, type, action } = this.state;

        return (
            <div>
                {action === 'provide' ? (
                    <ContentSearchForm
                        ref="form"
                        articleForm={articleForm}
                        clear={this.clear}
                        jid={jid}
                        journalForm={journalForm}
                        onSearch={this.search}
                        type={type}
                    />
                ) : type === 'article' ? (
                    <SearchAdvancedResult
                        ref={r => {
                            // eslint-disable-next-line react/no-unused-class-component-methods
                            this.innerResults = r;
                        }}
                        articleForm={articleForm}
                        context={this.state.context}
                        form={form}
                        goBack={this.displayAttributes}
                        jid={jid}
                        journalForm={journalForm}
                        notfoundRender={() => <NoArticleResults />}
                        title="Article Search Results"
                        type="article"
                    />
                ) : (
                    <SearchAdvancedResult
                        ref={r => {
                            // eslint-disable-next-line react/no-unused-class-component-methods
                            this.innerResults = r;
                        }}
                        articleForm={articleForm}
                        form={form}
                        goBack={this.displayAttributes}
                        journalForm={journalForm}
                        notfoundRender={() => (
                            <div className="page p-top_big p-btm_big">
                                <StaticContent
                                    key="advanced-search__no-results"
                                    src="advanced-search__no-results.html"
                                />
                            </div>
                        )}
                        title="Journal Search Results"
                        type="journal"
                    />
                )}
            </div>
        );
    }
}

export default withCodes(AdvanceContentSearch, ID.SEARCH_CODES);
