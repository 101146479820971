import React, { Component } from 'react';
import { scrollIntoDomElement } from 'app/blocks/common/scroll';

export default function withScrollTo(selector: string, scrollIntoViewArg?: any) {
    return function <P>(WrappedComponent: React.ComponentType<P>): React.ComponentType<Omit<P, 'scrollTo'>> {
        return class extends Component<P> {
            componentDidMount() {
                this.scrollTo(scrollIntoViewArg);
            }

            scrollTo = (param, delay = 0) => scrollIntoDomElement(selector, param, delay);

            render() {
                return <WrappedComponent scrollTo={this.scrollTo} {...this.props} />;
            }
        };
    };
}
