import React, { useCallback, useEffect, useState } from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { compose } from 'app/blocks/common/utils';
import withConfirmLeaving from 'app/blocks/common/withConfirmLeaving';
import withScrollToTop from 'app/blocks/common/withScrollToTop';
import FileUploader from 'app/blocks/file-uploader/FileUploader';
import { useLicenseFlow } from 'app/pages/license-signing-process/context/Context';
import SingleAuthorView from './SingleAuthorView';
import { getLastLicenseConfirmedStep } from '../../context/utils';
import prepareLabels from '../labels';

function SingleAuthor({ l, setChangesChecker, unsetChangesChecker }) {
    const {
        all,
        isLicenseSubmissionConfirming,
        confirmPrintLicenseSubmission,
        uploadsConfig,
        getPrevStep,
        goBack,
        article,
        journal,
        listCopyrightOwnerships,
        confirmStepTracker,
    } = useLicenseFlow();

    const stepTracker: Record<string, any> = all?.licenseSubmission?.stepTracker || {};

    const [currentStep, setCurrentStep] = useState(getLastLicenseConfirmedStep(stepTracker));
    const [isObtained, setIsObtained] = useState(!!stepTracker?.obtainOrDownloadLicenseStep?.confirmed);

    const confirmStep = useCallback(async () => {
        await confirmStepTracker(currentStep);
        setCurrentStep(currentStep + 1);
    }, [confirmStepTracker, currentStep]);

    const [fileItemsUploader, setFileItemsUploader] = useState([]);
    const [canConfirmUploader, setCanConfirmUploader] = useState(false);
    const refUploader = React.useRef(null);

    const onFileItemsChanged = useCallback((canConfirm, fileItems) => {
        setCanConfirmUploader(canConfirm);
        setFileItemsUploader(fileItems);
    }, []);

    useEffect(() => {
        setChangesChecker(() => !!fileItemsUploader?.length);
    }, [fileItemsUploader, setChangesChecker]);

    const canConfirm = canConfirmUploader && !isLicenseSubmissionConfirming;

    const firstOwnership = all?.copyrightOwnership?.copyrightOwnerships?.[0];
    const textParams = prepareLabels(
        l,
        firstOwnership,
        {
            article,
            isOA: journal?.revenueModel === 'OA' || !!all?.openAccessOption?.authorSelectedOnlineOpen,
            isMultiAuthors: !all?.copyrightOwnership?.singleAuthor,
            listCopyrightOwnerships,
            uploadsConfig,
        },
        currentStep,
    );

    const submitLicense = useCallback(() => {
        const files = [
            {
                ...firstOwnership,
                files: fileItemsUploader.map(x => x.id),
            },
        ];

        const fileNames = {};
        fileItemsUploader.forEach(fi => {
            fileNames[fi.id] = fi.file?.name;
        });

        confirmPrintLicenseSubmission(files, fileNames, corruptedIds =>
            refUploader.current.markFilesAsCorruptedByNames(corruptedIds),
        );
        unsetChangesChecker();
    }, [confirmPrintLicenseSubmission, fileItemsUploader, firstOwnership, unsetChangesChecker]);

    return (
        <SingleAuthorView
            canConfirm={canConfirm}
            confirmStep={confirmStep}
            currentStep={currentStep}
            getPrevStep={getPrevStep}
            goBack={goBack}
            isFlowStarted={stepTracker?.signingLicenseStep?.confirmed}
            isObtained={isObtained}
            setCurrentStep={setCurrentStep}
            setIsObtained={setIsObtained}
            stepTracker={stepTracker}
            submitLicense={submitLicense}
            textParams={textParams}
            uploader={
                <FileUploader
                    // @ts-ignore
                    ref={refUploader}
                    config={uploadsConfig}
                    initialFileItems={fileItemsUploader}
                    onFileItemsChanged={onFileItemsChanged}
                />
            }
        />
    );
}

export default compose(withConfirmLeaving, withScrollToTop, withCodes2(ID.LICENSE_SUBMISSION))(SingleAuthor);
