import React from 'react';
import { l } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';

const JournalDiscountsError: React.FC = () => {
    return (
        <div className="journalDiscountsError">
            <SvgIcon.error />
            <div className="journalDiscountsError-container">
                <span className="journalDiscountsError-messageText">
                    {l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.JOURNAL_DISCOUNTS_ERROR_TITLE')}
                </span>
                <span className="journalDiscountsError-messageText">
                    {l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.JOURNAL_DISCOUNTS_ERROR_SUBTITLE')}
                </span>
                <ul
                    className="journalDiscountsError-messageList"
                    dangerouslySetInnerHTML={{
                        __html: l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.JOURNAL_DISCOUNTS_ERROR_MESSAGE'),
                    }}
                />
            </div>
        </div>
    );
};

export default JournalDiscountsError;
