import filesize from 'filesize';
import { getPrintLicensePdfUrl } from 'app/blocks/middleware/license';
import { LICENSE_STEPS } from '../context/utils';

export default function (
    l,
    firstOwnership,
    {
        article,
        isOA,
        isMultiAuthors,
        listCopyrightOwnerships = [],
        uploadsConfig: { maxFilesNumber, maxTotalFilesSize },
    },
    currentStep = undefined,
) {
    const categorySelector = firstOwnership?.copyrightOwnershipSubtype
        ? `${firstOwnership?.copyrightOwnership}.ITEMS.${firstOwnership?.copyrightOwnershipSubtype}`
        : firstOwnership?.copyrightOwnership;

    const isPdf = !(
        l(`LICENSE_SUBMISSION.${categorySelector}.OBTAIN_FLOW${isOA ? '_OA' : '_SUBS'}`, {}, '') ||
        l(`LICENSE_SUBMISSION.${categorySelector}.OBTAIN_FLOW`, {}, '')
    );

    const longOwner = l(`LICENSE_SUBMISSION.${categorySelector}.TITLE`);
    const shortOwner = l(`LICENSE_SUBMISSION.${categorySelector}.SHORT_TITLE`, {}, longOwner);

    const standardLink = getPrintLicensePdfUrl(
        article?.id,
        firstOwnership?.copyrightOwnership,
        firstOwnership?.copyrightOwnershipSubtype,
    );

    const listCategory = listCopyrightOwnerships.find(c => c?.id === firstOwnership?.copyrightOwnership);
    const typeCategory = listCategory?.subCategoryTypes?.find(t => !!t?.isSubscription !== isOA);

    const listSubCategory = listCategory?.subItems?.find(c => c?.id === firstOwnership?.copyrightOwnershipSubtype);
    const typeSubCategory = listSubCategory?.subCategoryTypes?.find(t => !!t.isSubscription !== isOA);

    const longName = l(
        `LICENSE_SUBMISSION.${categorySelector}.LICENSE_LONG.${isOA ? 'OA' : 'SUBS'}`, // the secret feature - configuration through UI
        {},
        typeSubCategory?.longName || typeCategory?.longName || l('LICENSE_SUBMISSION.LABELS.LICENSE.LONG'),
    );
    const shortName = l(
        `LICENSE_SUBMISSION.${categorySelector}.LICENSE_SHORT.${isOA ? 'OA' : 'SUBS'}`, // the secret feature - configuration through UI
        {},
        typeSubCategory?.shortName || typeCategory?.shortName || l('LICENSE_SUBMISSION.LABELS.LICENSE.SHORT'),
    );

    const texts = {
        categorySelector,
        isPdf,
        isOA,

        longName,
        shortName,
        standardLink,
        shortOwner,
        longOwner,
        maxFilesNumber,
        maxTotalFilesSize: filesize(maxTotalFilesSize, { base: 2 }),

        EXT_TEXT: '',
        PREFIX_TEXT: '',
    };

    const commonKey = `${isPdf ? 'DOWNLOAD' : 'OBTAIN'}${isOA ? '_OA' : ''}`;

    if (currentStep !== undefined) {
        // Single author flow
        const { folder } = LICENSE_STEPS[currentStep];
        texts.currentSubfolder = folder;

        texts.EXT_TEXT =
            l(`LICENSE_SUBMISSION.${categorySelector}.EXT_TEXT`, texts, '') ||
            l(`LICENSE_SUBMISSION.LABELS.${folder}.EXT_TEXT`, texts, '');

        const multiPostfix = isMultiAuthors ? '__MULTI_AUTHOR' : '';
        texts.currentText =
            l(`LICENSE_SUBMISSION.${categorySelector}.${folder}_${isOA ? 'OA' : 'SUBS'}${multiPostfix}`, texts, '') ||
            l(`LICENSE_SUBMISSION.${categorySelector}.${folder}${multiPostfix}`, texts, '') ||
            l(`LICENSE_SUBMISSION.${categorySelector}.${folder}_${isOA ? 'OA' : 'SUBS'}`, texts, '') ||
            l(`LICENSE_SUBMISSION.${categorySelector}.${folder}`, texts, '') ||
            l(`LICENSE_SUBMISSION.LABELS.${folder}.${commonKey}`, texts);
    } else {
        // Multi author flow
        texts.PREFIX_TEXT =
            l(`LICENSE_SUBMISSION.${categorySelector}.PREFIX_TEXT`, texts, '') ||
            l(`LICENSE_SUBMISSION.LABELS.INSTRUCTIONS.${isPdf ? 'DOWNLOAD_PREFIX' : 'OBTAIN_PREFIX'}`, texts, '');

        texts.hintText =
            l(`LICENSE_SUBMISSION.${categorySelector}.UPLOAD_HINT`, texts, '') ||
            l(`LICENSE_SUBMISSION.LABELS.UPLOAD.${isPdf ? 'DOWNLOAD_HINT' : 'OBTAIN_HINT'}`, texts, '');

        texts.instructionsText =
            l(`LICENSE_SUBMISSION.${categorySelector}.INSTRUCTIONS_${isOA ? 'OA' : 'SUBS'}__MULTI_AUTHOR`, texts, '') ||
            l(`LICENSE_SUBMISSION.${categorySelector}.INSTRUCTIONS__MULTI_AUTHOR`, texts, '') ||
            l(`LICENSE_SUBMISSION.${categorySelector}.INSTRUCTIONS_${isOA ? 'OA' : 'SUBS'}`, texts, '') ||
            l(`LICENSE_SUBMISSION.${categorySelector}.INSTRUCTIONS`, texts, '') ||
            l(`LICENSE_SUBMISSION.LABELS.INSTRUCTIONS.${commonKey}`, texts);
    }

    return texts;
}
