import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { compose } from 'app/blocks/common/utils';
import withConfirmLeaving, { onLeavePage } from 'app/blocks/common/withConfirmLeaving';
import withScrollToTop from 'app/blocks/common/withScrollToTop';
import { BackAndForthButtons } from 'app/pages/license-signing-process/buttons';
import { useLicenseFlow } from 'app/pages/license-signing-process/context/Context';
import { BackAndForthContainer, SectionPrompt, SectionTitle } from 'app/pages/license-signing-process/styles';
import { navigate } from 'app/route/history';

function ConfirmRCAChange({
    confirmRCAChange,
    correspondingAuthors,
    isRCAChangeConfirming,
    l,
    rca,
    setChangesChecker,
    unsetChangesChecker,
}) {
    React.useEffect(() => {
        // no not trigger modal in case of fixing browser address bar in Context
        const timeout = setTimeout(() => setChangesChecker(() => true, onLeavePage), 1000);

        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { article } = useLicenseFlow();

    const name = `${rca.firstName} ${rca.lastName}`;

    const buttonProps =
        correspondingAuthors.length === 1
            ? {
                  buttonCancelLabel: l('LICENSE_SIGNING.BUTTON.CORRESPONDING_AUTHORS'),
                  onCancel() {
                      unsetChangesChecker();
                      navigate(`/license-signing/${article.id}/confirmAuthors`);
                  },
              }
            : {
                  buttonCancelLabel: l('LICENSE_SIGNING.BUTTON.RESPONSIBLE_AUTHOR'),
                  onCancel() {
                      unsetChangesChecker();
                      navigate(`/license-signing/${article.id}/confirmAuthors/confirmRCA`);
                  },
              };

    return (
        <>
            <section className="ConfirmRCA">
                <SectionTitle>{l('LICENSE_SIGNING.CONFIRM_AUTHORS.CONFIRM_RCA.CONFIRM_RCA_CHANGE_TITLE')}</SectionTitle>

                <SectionPrompt>
                    {l('LICENSE_SIGNING.CONFIRM_AUTHORS.CONFIRM_RCA.CONFIRM_RCA_CHANGE_PROMPT', {
                        name,
                        email: rca.email,
                    })}
                </SectionPrompt>

                <SectionPrompt>
                    {l('LICENSE_SIGNING.CONFIRM_AUTHORS.CONFIRM_RCA.CONFIRM_RCA_CHANGE_PROMPT_RESPONSIBILITY', {
                        name,
                    })}
                </SectionPrompt>
            </section>

            <BackAndForthContainer>
                <BackAndForthButtons
                    {...buttonProps}
                    buttonConfirmingLabel={l('BUTTONS.CONFIRMING')}
                    buttonConfirmLabel={l('LICENSE_SIGNING.BUTTON.CONFIRM_CHANGE')}
                    isConfirming={isRCAChangeConfirming}
                    onConfirm={async () => {
                        await confirmRCAChange();
                        unsetChangesChecker();
                    }}
                />
            </BackAndForthContainer>
        </>
    );
}

export default compose(
    withConfirmLeaving,
    withScrollToTop,
    withCodes2(ID.BUTTONS, ID.LICENSE_SIGNING, ID.LOGIN),
)(ConfirmRCAChange);
