import cn from 'classnames';
import React from 'react';
import OrdersDataItem from 'app/pages/orders/data-item';
import ReviewPanelHeader from './ReviewPanelHeader';

function ContactReviewPanel(props) {
    const DataItemAndAuxInfo = React.useMemo(
        () => OrdersDataItem.create(props.order, props.initOrder),
        [props.order, props.initOrder],
    );

    return (
        <section
            className={cn('payment-panel', props.panelName)}
            data-seleniumid={`order-${props.adderessType}-address-panel-review`}
        >
            <ReviewPanelHeader onEdit={props.onEdit} panelName={props.panelName}>
                {props.title}
            </ReviewPanelHeader>

            <DataItemAndAuxInfo.OrdersDataItem
                className={`${props.panelName}-ContentRow`}
                innerInfo={[[`${props.adderessType}.fullName`]]}
                seleniumid={`order-review-${props.adderessType}-fullName`}
            />

            <DataItemAndAuxInfo.OrdersDataItem
                className={`${props.panelName}-ContentRow`}
                innerInfo={[[`${props.adderessType}.institutionName`]]}
                seleniumid={`order-review-${props.adderessType}-institutionName`}
            />

            <DataItemAndAuxInfo.OrdersDataItem
                className={`${props.panelName}-ContentRow`}
                innerInfo={[[`${props.adderessType}.departmentName`]]}
                seleniumid={`order-review-${props.adderessType}-departmentName`}
            />

            <DataItemAndAuxInfo.OrdersDataItem
                className={`${props.panelName}-ContentRow`}
                innerInfo={[[`${props.adderessType}.addressLine1`], [`${props.adderessType}.addressLine2`, ', ']]}
                seleniumid={`order-review-${props.adderessType}-addressLine`}
            />
            <DataItemAndAuxInfo.OrdersDataItem
                className={`${props.panelName}-ContentRow`}
                innerInfo={[
                    [`${props.adderessType}.city`],
                    [`${props.adderessType}.stateName`, ', '],
                    [`${props.adderessType}.zipCode`, ' '],
                ]}
                seleniumid={`order-review-${props.adderessType}-cityStateZipCode`}
            />

            <DataItemAndAuxInfo.OrdersDataItem
                className={`${props.panelName}-ContentRow`}
                innerInfo={[[`${props.adderessType}.countryName`]]}
                seleniumid={`order-review-${props.adderessType}-countryName`}
            />
            <DataItemAndAuxInfo.OrdersDataItem
                className={`${props.panelName}-ContentRow`}
                innerInfo={[[`${props.adderessType}.phoneNumber`]]}
                seleniumid={`order-review-${props.adderessType}-phoneNumber`}
            />
            <DataItemAndAuxInfo.OrdersDataItem
                className={`${props.panelName}-ContentRow`}
                innerInfo={[[`${props.adderessType}.primaryEmail`]]}
                seleniumid={`order-review-${props.adderessType}-primaryEmail`}
            />
        </section>
    );
}

function CorrespondenceAddressReviewPanel(props) {
    return <ContactReviewPanel {...props} adderessType="correspondenceAddress" title="Contact Information" />;
}

function BillingAddressReviewPanel(props) {
    return <ContactReviewPanel {...props} adderessType="billingAddress" title="Billing Information: " />;
}

export { BillingAddressReviewPanel, CorrespondenceAddressReviewPanel };
