import cn from 'classnames';
import { autobind } from 'core-decorators';
import React from 'react';
import _ from 'underscore';
import Article from 'app/blocks/article/article';
import { Layout, Title, WhiteBlock } from 'app/blocks/blocks';
import { Button, PrimaryButton } from 'app/blocks/buttons/buttons';
import processing from 'app/blocks/common/decorators/processing';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import * as middleware from 'app/blocks/middleware/article-assignment';
import * as middlewareCommon from 'app/blocks/middleware/middleware';
import { SubTitle } from 'app/blocks/titles/titles';
import ArticleAssignmentResult from 'app/pages/assign-article/_result/assign-article_result';
import { Input2 } from 'app/ui/form/inputs';
import RadioButton from 'app/ui/form/RadioButton';

const CHECK_ARTICLE_TITLE = /[a-z]|[0-9]/i;

@autobind
class ArticleAssignment extends React.Component {
    state = {
        form: {},
        isLoading: true,
        isNotifying: false,
        fields: [
            {
                name: 'DOI',
                placeholder: 'Enter article DOI',
                isSmall: true,
                queryParam: 'articleDoi',
            },
            {
                name: 'Editorial Ref Code',
                placeholder: 'Enter article ID from editorials',
                isSmall: true,
                queryParam: 'articleManuscriptId',
            },
            {
                name: 'Article Title',
                placeholder: 'Article title contains',
                queryParam: 'articleTitle',
                isRequired: true,
                validation: title => !_.isEmpty(title) && CHECK_ARTICLE_TITLE.test(title),
            },
            {
                name: 'Email used for Submission',
                placeholder: 'Enter email address used to submit this article',
                queryParam: 'submissionEmailAddress',
            },
        ],
    };

    @processing('isLoading')
    async componentDidMount() {
        try {
            const journal = await middlewareCommon.product.getJournalById(this.props.journalId);
            this.setState({ journal });
        } catch (error) {
            showDialog.addErrorMessageDialog(error, this.cancel);
        }
    }

    @processing('isNotifying')
    async notifyAdmin() {
        try {
            const title = this.state.form.articleTitle;
            const result = await middleware.notifyAdmin(
                this.props.journalId,
                title,
                // eslint-disable-next-line react/no-access-state-in-setstate
                this.state.form,
                // eslint-disable-next-line react/no-access-state-in-setstate
                this.state.correspondingAuthor,
            );
            this.setState({ result });
        } catch (error) {
            showDialog.addErrorMessageDialog(error);
        }
    }

    onValueChange(field, value) {
        const { form } = this.state;
        form[field] = value;
        this.setState({ form });
    }

    cancel() {
        this.props.cancel();
    }

    isReadyToSubmit() {
        const { fields, form } = this.state;
        const isValid = fields.every(field => !field.validation || field.validation(form[field.queryParam]));

        return (
            !this.state.isNotifying && !this.state.isLoading && isValid && _.isBoolean(this.state.correspondingAuthor)
        );
    }

    // todo: <Article /> should be like <Journal /> to separate different views

    render() {
        const s_ = this.state;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <Title seleniumid="advanced-search-page">Article Assignment</Title>
                        {s_.result ? (
                            <ArticleAssignmentResult gotoDashboard={this.props.gotoDashboard} result={s_.result} />
                        ) : (
                            <div>
                                <Layout isLoading={this.state.isLoading}>
                                    <WhiteBlock className="page p-2x">
                                        <SubTitle className="m-btm_normal">Enter article details</SubTitle>

                                        <Article journal={s_.journal} showISSN />

                                        <div className="text_bold m-btm_md">
                                            In order to assign correct article to you: please enter all available
                                            article details.
                                        </div>

                                        {s_.fields.map((field, index) => (
                                            <div
                                                key={field.name}
                                                className={cn('row row_mini', { 'm-top_mini': index > 0 })}
                                            >
                                                <div className="col-md-3 p-l_mini">
                                                    {field.name}
                                                    {field.isRequired && '*'}
                                                </div>
                                                <div
                                                    className={cn({
                                                        'col-md-6': field.isSmall,
                                                        'col-md-9': !field.isSmall,
                                                    })}
                                                >
                                                    <Input2
                                                        data-seleniumid={`${field.queryParam}-input`}
                                                        onChange={value => this.onValueChange(field.queryParam, value)}
                                                        placeholder={field.placeholder}
                                                    />
                                                </div>
                                            </div>
                                        ))}

                                        <div className="text_bold m-top_md m-btm_mini">
                                            Are you corresponding author or co-author of this article? You have to
                                            select one option to proceed further.
                                        </div>

                                        <RadioButton
                                            checked={this.state.correspondingAuthor === true}
                                            id="assign-article_by-journal-radio-author"
                                            label="I am a corresponding author"
                                            name="assign-article_by-journal-radio"
                                            onChange={() => this.setState({ correspondingAuthor: true })}
                                            style={{ display: 'inline-flex', marginRight: '15px' }}
                                            value
                                        />

                                        <RadioButton
                                            checked={this.state.correspondingAuthor === false}
                                            id="assign-article_by-journal-radio-co-author"
                                            label="I am a co-author"
                                            name="assign-article_by-journal-radio"
                                            onChange={() => this.setState({ correspondingAuthor: false })}
                                            style={{ display: 'inline-flex' }}
                                            value={false}
                                        />
                                    </WhiteBlock>
                                </Layout>

                                <div className="m-top_md">
                                    <div className="pull-left">
                                        <Button onClick={this.cancel}>Cancel</Button>
                                    </div>

                                    <div className="pull-right">
                                        <PrimaryButton disabled={!this.isReadyToSubmit()} onClick={this.notifyAdmin}>
                                            {this.state.isNotifying ? 'Notifying Admin...' : 'Notify Admin'}
                                        </PrimaryButton>
                                    </div>

                                    <span className="clearfix" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default ArticleAssignment;
