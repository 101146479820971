import React from 'react';
import './NoArticleResults.scss';
import { Link, useLocation } from 'react-router-dom';
import { Title } from 'app/blocks/blocks';
import { ID, withCodes } from 'app/blocks/common/codes';
import AdvanceSearchButtons from 'app/pages/search/advance-search-buttons/AdvanceSearchButtons';
import { reload, navigate } from 'app/route/history';

function NoArticleResults(props) {
    const { codes } = props;
    const search = codes.SEARCH_CODES.SEARCH.NO_RESULTS;

    const location = useLocation();

    const onArticleSearch = () => reload();
    const onBackToArticleSearch = () => {
        navigate('/advancedsearch');
    };

    return (
        <div className="col-12">
            <Title seleniumid="no-find">{search.title}</Title>
            <div
                className="NoArticleResultsDescription"
                dangerouslySetInnerHTML={{
                    __html: search.description,
                }}
            />
            <div className="NoArticleResultsResultsCount NoArticleResultsBorder" data-seleniumid="search-resutls">
                {search.about_total}
            </div>
            <div className="NoArticleResultsSearchSeparate NoArticleResultsBorder">
                <Title seleniumid="no-results">{search.not_found}</Title>
                <p data-seleniumid="change-search">
                    <Link
                        data-seleniumid="change-search-link"
                        onClick={location.pathname === '/advancedsearch' ? onArticleSearch : null}
                        to="/advancedsearch"
                    >
                        <strong>Refine/change search</strong>
                    </Link>{' '}
                    <br /> Double-check your details and try your search again{' '}
                </p>{' '}
                <p data-seleniumid="get-help">
                    <Link data-seleniumid="get-help-link" to="/get-help-on-article">
                        <strong>Get help adding your article</strong>
                    </Link>{' '}
                    <br /> If your article has not yet been published, it may not be shown in search results. Create a
                    request and we&#39;ll attempt to locate and add it to your account.{' '}
                </p>
            </div>
            <AdvanceSearchButtons
                hideNext
                onPrevious={location.pathname === '/advancedsearch' ? onArticleSearch : onBackToArticleSearch}
                previousBtnText={search.btn_txt}
            />
        </div>
    );
}

export default withCodes(NoArticleResults, ID.SEARCH_CODES);
