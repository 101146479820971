import React from 'react';
import addBannerMessages from 'app/blocks/banner-messages/banner-messages';

export default function withBanner<P>(Component: React.ComponentType<P>): React.ComponentType<P> {
    return function WithBanner(props) {
        React.useEffect(() => {
            addBannerMessages({
                appendTo: 'banner-messages-container',
                bannerMessageAllSpaElemId: 'AS__info-message-all-spa',
                bannerMessageElemId: 'AS__info-message',
                hash: window.location.hash.replace(/^#\/?/, ''),
                hashPartsWithMessages: [
                    { 'type=online-open': 'banner-message-online-open' },
                    { 'type=openaccess': 'banner-message-openaccess' },
                ],
                hashesWithMessages: ['login', 'register', 'profile', 'dashboard', 'orders'],
            });
        }, []);

        return <Component {...props} />;
    };
}
