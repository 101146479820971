import React from 'react';
import { LinkButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { useAuth } from 'app/blocks/common/providers/AuthProvider';
import { compose } from 'app/blocks/common/utils';
import withConfirmLeaving, { onLeavePage } from 'app/blocks/common/withConfirmLeaving';
import withScrollToTop from 'app/blocks/common/withScrollToTop';
import { ForwardButton } from 'app/pages/license-signing-process/buttons';
import { useLicenseFlow } from 'app/pages/license-signing-process/context/Context';
import { BackAndForthContainer, SectionPrompt, SectionTitle } from 'app/pages/license-signing-process/styles';
import { navigate } from 'app/route/history';
import AuthorRolesWidget from './AuthorRolesWidget';
import ConfirmAuthorRolesCheckboxList from './ConfirmAuthorRolesCheckboxList';
import './ConfirmCorrAuthors.scss';

function getOptions(authors, corrAuthorIds, l) {
    return authors.map(({ participantId, firstName, lastName }) => {
        const checked = corrAuthorIds.includes(participantId);
        const label = checked ? (
            <>
                {`${firstName} ${lastName}`}
                <span style={{ marginLeft: 15, fontWeight: 'normal', fontSize: 12 }}>
                    {l('LICENSE_SIGNING.CONFIRM_AUTHORS.CORR_AUTHOR')}
                </span>
            </>
        ) : (
            `${firstName} ${lastName}`
        );

        return { value: participantId, label };
    });
}

function ConfirmCorrAuthors({
    confirmCorrespondingAuthors,
    correspondingAuthors,
    incorrectListReported,
    isCorrespondingAuthorsConfirming,
    l,
    onConfirmSubstep,
    setChangesChecker,
    unsetChangesChecker,
}) {
    React.useEffect(() => {
        // no not trigger modal in case of fixing browser address bar in Context
        const timeout = setTimeout(() => setChangesChecker(() => true, onLeavePage), 1000);

        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { user } = useAuth();
    const { all, article } = useLicenseFlow();

    const authors = React.useMemo(() => all.confirmAuthors?.rcaConfirmationData ?? [], [all]);

    const [ids, setIds] = React.useState(correspondingAuthors.map(x => x.participantId));

    const options = React.useMemo(() => getOptions(authors, ids, l), [authors, ids, l]);

    return (
        <>
            <section className="AuthorRoles">
                <SectionTitle>{l('LICENSE_SIGNING.CONFIRM_AUTHORS.CONFIRM_CORR_AUTHOR.TITLE')}</SectionTitle>

                <SectionPrompt>{l('LICENSE_SIGNING.CONFIRM_AUTHORS.CONFIRM_CORR_AUTHOR.PROMPT')}</SectionPrompt>

                <div className="AuthorsContainer mt-2x">
                    <ConfirmAuthorRolesCheckboxList onChange={setIds} options={options} values={ids} />

                    <AuthorRolesWidget />
                </div>
            </section>

            <BackAndForthContainer className="licenseSigningProcessPage-backAndForth--AuthorRoles">
                <div className="page-controls">
                    {!user.disableIncorrectList && !incorrectListReported && (
                        <LinkButton
                            data-seleniumid="goToIncorrectList"
                            isUnderline
                            onClick={() => {
                                onConfirmSubstep();
                                unsetChangesChecker();
                                navigate(`/license-signing/${article.id}/confirmAuthors/wrong-list`);
                            }}
                        >
                            {l('LICENSE_SIGNING.BUTTON.AUTHOR_LIST_INCORRECT')}
                        </LinkButton>
                    )}

                    <ForwardButton
                        disabled={ids.length === 0 || isCorrespondingAuthorsConfirming}
                        onClick={async () => {
                            await confirmCorrespondingAuthors(ids);
                            unsetChangesChecker();
                        }}
                        processingLabel={l('BUTTONS.CONFIRMING')}
                    >
                        {l('LICENSE_SIGNING.BUTTON.CONFIRM_CORRESPONDING_AUTHORS', { number: ids.length })}
                    </ForwardButton>
                </div>
            </BackAndForthContainer>
        </>
    );
}

export default compose(
    withConfirmLeaving,
    withScrollToTop,
    withCodes2(ID.BUTTONS, ID.LICENSE_SIGNING),
)(ConfirmCorrAuthors);
