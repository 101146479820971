import React from 'react';
import _ from 'underscore';
import { ID, withCodes } from 'app/blocks/common/codes';
import * as Utils from 'app/blocks/common/utils';
import InstitutionPicker from 'app/blocks/institution-picker/institution-picker';
import { NONE_VALUE } from 'app/blocks/institution-picker/institution-picker-view';
import RowDropdown from 'app/blocks/Row/_Dropdown/Row_Dropdown';
import CountryPickerWithTooltip from 'app/blocks/Row/_DropdownWithTooltip/Row__Dropdown_WithTooltip';
import OrdersRow from 'app/blocks/Row/Row';
import { addressInputMaxLength } from 'app/pages/orders/orders.constants';
import { isStateRequired, isPostalCodeHelpTextRequired } from 'app/pages/orders/panel/contact/validation';

type LFunction = (code: string) => string;
type HandleAddressFunction = (address: string) => void;
type FieldFocusFunction = (field: string) => void;
type EditableAddress = Address & {
    billToType: string;
    primaryEmail: string;
};

type EditAddressProps = {
    address: EditableAddress;
    codes: {};
    handleAddressChange: HandleAddressFunction;
    l: LFunction;
    onFieldFocus: FieldFocusFunction;
    panelName: string;
    seleniumid: string;
    supportDataProvider: any;
    validationResults: any;
};
const EditAddress: React.FC<EditAddressProps> = ({
    address,
    codes,
    handleAddressChange,
    l,
    onFieldFocus,
    panelName,
    seleniumid,
    supportDataProvider,
    validationResults = {},
}) => {
    const updateAddress = newAddress => {
        handleAddressChange(newAddress);
    };

    const updateAddressField = (property, value) => {
        const newAddress = Utils.updateProperty(address, property, value);
        updateAddress(newAddress);
    };

    const updateAddressFields = properties => {
        const newAddress = Utils.updateProperties(address, properties);
        updateAddress(newAddress);
    };

    const handleFirstNameChange = firstName => {
        updateAddressField('firstName', firstName);
    };

    const handleLastNameChange = lastName => {
        updateAddressField('lastName', lastName);
    };

    const handleInstitutionChange = institution => {
        const fields = _.extend(Utils.readPickField(institution, 'institution', 'Code'), { departmentName: undefined });
        updateAddressFields(fields);
    };

    const handleDepartmentChange = departmentName => {
        updateAddressField('departmentName', departmentName);
    };

    const handleAddressLine1Change = addressLine1 => {
        updateAddressField('addressLine1', addressLine1);
    };

    const handleAddressLine2Change = addressLine2 => {
        updateAddressField('addressLine2', addressLine2);
    };

    const handleCityChange = city => {
        updateAddressField('city', city);
    };

    const handleCountryChange = async country => {
        const fields = address?.countryCode
            ? _.extend(
                  Utils.readPickField(country, 'country', 'Code'),
                  Utils.readPickField(undefined, 'state', 'Code'),
                  Utils.readPickField(undefined, 'zip', 'Code'),
                  { addressLine1: undefined },
                  { addressLine2: undefined },
                  { city: undefined },
              )
            : _.extend(
                  Utils.readPickField(country, 'country', 'Code'),
                  Utils.readPickField(undefined, 'state', 'Code'),
              );
        updateAddressFields(fields);
    };

    const handleStateChange = state => {
        const fields = Utils.readPickField(state, 'state', 'Code');
        updateAddressFields(fields);
    };

    const handleZipCodeChange = zipCode => {
        updateAddressField('zipCode', zipCode);
    };

    const handlePhoneNumberChange = phoneNumber => {
        updateAddressField('phoneNumber', phoneNumber.trim());
    };

    const handleMailChange = email => {
        updateAddressField('primaryEmail', email);
    };

    const onFocus = e => {
        onFieldFocus(e.target.name);
    };

    const institutionPickerRenderer = props => (
        <InstitutionPicker
            changeHandler={props.changeHandler}
            className="lName"
            isClearable
            isError={!!props.error}
            minLengthToSearch={0}
            noneLabel={props.noneLabel}
            onFocus={props.onFocus}
            placeholder={props.placeholder}
            selectedItem={props.selectedItem}
            seleniumid={`${props.formSeleniumid}-${props.name}`}
            showNone
        />
    );

    const profileErrorCodes = codes[ID.PROFILE_ACCOUNT].errorMessages;

    const itemNullName = l('ORDER_PANELS.PANEL_CONTACT.NO_AFFILIATIONS');
    const institutionName = Utils.createPickField(address, 'institution', 'Name').name;
    const countryCode = Utils.createPickField(address, 'country', 'Code').id;
    const shouldSetState = countryCode && isStateRequired(countryCode, false);
    const shouldShowPostalCodeHelpText = countryCode && isPostalCodeHelpTextRequired(countryCode);

    const isIndividual = address.billToType !== 'ORGANIZATION';

    const departmentDisable =
        institutionName === undefined ||
        institutionName === null ||
        institutionName === '' ||
        institutionName === NONE_VALUE ||
        institutionName === itemNullName;

    // TODO talk with qa, replace formSeleniumId with seleniumId
    return (
        <div className={`${panelName}-Form`} data-seleniumid={seleniumid}>
            <div className={`${panelName}-inlineInputs`}>
                <OrdersRow
                    className={`${panelName}-Row`}
                    error={validationResults.firstName}
                    errorMessages={profileErrorCodes}
                    formSeleniumid="address"
                    isRequired
                    label={
                        isIndividual
                            ? l('ORDER_PANELS.PANEL_CONTACT.FIRST_NAME_LABEL')
                            : l('ORDER_PANELS.PANEL_CONTACT.RECIPIENT_FIRST_NAME_LABEL')
                    }
                    maxLength={addressInputMaxLength.FIRST_NAME}
                    name="firstName"
                    onChange={handleFirstNameChange}
                    onFocus={onFocus}
                    placeholder={
                        isIndividual
                            ? l('ORDER_PANELS.PANEL_CONTACT.FIRST_NAME_PLACEHOLDER')
                            : l('ORDER_PANELS.PANEL_CONTACT.RECIPIENT_FIRST_NAME_PLACEHOLDER')
                    }
                    value={address.firstName}
                />
                <OrdersRow
                    className={`${panelName}-Row`}
                    error={validationResults.lastName}
                    errorMessages={profileErrorCodes}
                    formSeleniumid="address"
                    isRequired
                    label={
                        isIndividual
                            ? l('ORDER_PANELS.PANEL_CONTACT.LAST_NAME_LABEL')
                            : l('ORDER_PANELS.PANEL_CONTACT.RECIPIENT_LAST_NAME_LABEL')
                    }
                    maxLength={addressInputMaxLength.LAST_NAME}
                    name="lastName"
                    onChange={handleLastNameChange}
                    onFocus={onFocus}
                    placeholder={
                        isIndividual
                            ? l('ORDER_PANELS.PANEL_CONTACT.LAST_NAME_PLACEHOLDER')
                            : l('ORDER_PANELS.PANEL_CONTACT.RECIPIENT_LAST_NAME_PLACEHOLDER')
                    }
                    value={address.lastName}
                />
            </div>

            {!isIndividual && (
                <div className={`${panelName}-inlineInputs`}>
                    <OrdersRow
                        className={`${panelName}-Row`}
                        error={validationResults.primaryEmail}
                        formSeleniumid="address"
                        isRequired
                        label={l('ORDER_PANELS.PANEL_CONTACT.RECIPIENT_EMAIL_LABEL')}
                        maxLength={addressInputMaxLength.EMAIL}
                        name="email"
                        onChange={handleMailChange}
                        onFocus={() => onFieldFocus('primaryEmail')}
                        placeholder={l('ORDER_PANELS.PANEL_CONTACT.RECIPIENT_EMAIL_PLACEHOLDER')}
                        type="email"
                        value={address.primaryEmail}
                    />
                    <div>
                        <OrdersRow
                            className={`${panelName}-Row`}
                            error={validationResults.phoneNumber}
                            formSeleniumid="address"
                            isRequired
                            label={l('ORDER_PANELS.PANEL_CONTACT.RECIPIENT_PHONE_LABEL')}
                            maxLength={addressInputMaxLength.PHONE}
                            name="phoneNumber"
                            onChange={handlePhoneNumberChange}
                            onFocus={onFocus}
                            placeholder={l('ORDER_PANELS.PANEL_CONTACT.RECIPIENT_PHONE_PLACEHOLDER')}
                            type="tel"
                            value={address.phoneNumber}
                        />
                        <strong className={`${panelName}-Static`}>
                            {l('ORDER_PANELS.PANEL_CONTACT.PHONE_STATIC_TEXT')}
                        </strong>
                    </div>
                </div>
            )}
            <div className={`${panelName}-inlineInputs`}>
                <RowDropdown
                    changeHandler={handleInstitutionChange}
                    className={`${panelName}-Row`}
                    comboboxRenderer={institutionPickerRenderer}
                    error={validationResults.institution}
                    formSeleniumid="address"
                    isRequired
                    label={l('ORDER_PANELS.PANEL_CONTACT.AFFILIATION_LABEL')}
                    name="institution"
                    noneLabel={itemNullName}
                    onFocus={() => onFieldFocus('institution')}
                    placeholder={l('ORDER_PANELS.PANEL_CONTACT.AFFILIATION_HOA_PLACEHOLDER')}
                    selectedItem={Utils.createPickField(address, 'institution', 'Code')}
                />
                <OrdersRow
                    className={`${panelName}-Row`}
                    disabled={departmentDisable}
                    error={validationResults.departmentName}
                    errorMessages={profileErrorCodes}
                    formSeleniumid="address"
                    isOptional
                    label={l('ORDER_PANELS.PANEL_CONTACT.DEPARTMENT_LABEL')}
                    maxLength={addressInputMaxLength.DEPARTMENT}
                    name="department"
                    onChange={handleDepartmentChange}
                    onFocus={() => onFieldFocus('departmentName')}
                    placeholder={l('ORDER_PANELS.PANEL_CONTACT.DEPARTMENT_PLACEHOLDER')}
                    value={address.departmentName}
                />
            </div>
            <div className={`${panelName}-inlineInputs`}>
                <OrdersRow
                    className={`${panelName}-Row`}
                    error={validationResults.addressLine1}
                    errorMessages={profileErrorCodes}
                    formSeleniumid="address"
                    isRequired
                    label={l('ORDER_PANELS.PANEL_CONTACT.STREET_ADDRESS_1_LABEL')}
                    maxLength={addressInputMaxLength.STREET_ADDRESS}
                    name="addressLine1"
                    onChange={handleAddressLine1Change}
                    onFocus={onFocus}
                    placeholder={l('ORDER_PANELS.PANEL_CONTACT.STREET_ADDRESS_1_PLACEHOLDER')}
                    value={address.addressLine1}
                />
                <OrdersRow
                    className={`${panelName}-Row`}
                    error={validationResults.addressLine2}
                    errorMessages={profileErrorCodes}
                    formSeleniumid="address"
                    isOptional
                    label={l('ORDER_PANELS.PANEL_CONTACT.STREET_ADDRESS_2_LABEL')}
                    maxLength={addressInputMaxLength.STREET_ADDRESS}
                    name="addressLine2"
                    onChange={handleAddressLine2Change}
                    onFocus={onFocus}
                    placeholder={l('ORDER_PANELS.PANEL_CONTACT.STREET_ADDRESS_2_PLACEHOLDER')}
                    value={address.addressLine2}
                />
            </div>
            <div className={`${panelName}-inlineInputs`}>
                <OrdersRow
                    className={`${panelName}-Row`}
                    error={validationResults.city}
                    errorMessages={profileErrorCodes}
                    formSeleniumid="address"
                    isRequired
                    label={l('ORDER_PANELS.PANEL_CONTACT.CITY_LABEL')}
                    maxLength={addressInputMaxLength.CITY}
                    name="city"
                    onChange={handleCityChange}
                    onFocus={onFocus}
                    placeholder={l('ORDER_PANELS.PANEL_CONTACT.CITY_PLACEHOLDER')}
                    value={address.city}
                />
                <div />
            </div>
            <div className={`${panelName}-inlineInputs`}>
                <CountryPickerWithTooltip
                    changeHandler={handleCountryChange}
                    className={`${panelName}-Row`}
                    disabled={false}
                    error={validationResults.country}
                    formSeleniumid="address"
                    isRequired
                    isShowTooltip={false}
                    label={l('ORDER_PANELS.PANEL_CONTACT.COUNTRY_LABEL')}
                    name="country"
                    onFocus={() => onFieldFocus('country')}
                    placeholder={l('ORDER_PANELS.PANEL_CONTACT.COUNTRY_PLACEHOLDER')}
                    selectedItem={Utils.createPickField(address, 'country', 'Code')}
                    tooltipNodeId="country"
                />
                <RowDropdown
                    changeHandler={handleStateChange}
                    className={`${panelName}-Row`}
                    disabled={!countryCode}
                    dontLoadOnMount
                    error={validationResults.state}
                    formSeleniumid="address"
                    getListDataPromise={supportDataProvider.getStatesPromise(
                        Utils.createPickField(address, 'country', 'Code'),
                    )}
                    isOptional={!shouldSetState}
                    isRequired={shouldSetState}
                    label={l('ORDER_PANELS.PANEL_CONTACT.STATE_LABEL')}
                    name="state"
                    onFocus={() => onFieldFocus('state')}
                    placeholder={
                        shouldSetState
                            ? l('ORDER_PANELS.PANEL_CONTACT.SELECT_STATE_PLACEHOLDER')
                            : l('ORDER_PANELS.PANEL_CONTACT.SELECT_STATE_OPTIONAL_PLACEHOLDER')
                    }
                    selectedItem={Utils.createPickField(address, 'state', 'Code')}
                />
            </div>
            <div className={`${panelName}-inlineInputs`}>
                <OrdersRow
                    className={`${panelName}-Row`}
                    error={validationResults.zipCode}
                    errorMessages={profileErrorCodes}
                    formSeleniumid="address"
                    helpText={
                        shouldShowPostalCodeHelpText ? l('ORDER_PANELS.PANEL_CONTACT.ZIP_CODE_HELP_TEXT') : undefined
                    }
                    isRequired
                    label={l('ORDER_PANELS.PANEL_CONTACT.ZIP_CODE_LABEL')}
                    maxLength={addressInputMaxLength.ZIP_CODE}
                    name="zipCode"
                    onChange={handleZipCodeChange}
                    onFocus={onFocus}
                    placeholder={l('ORDER_PANELS.PANEL_CONTACT.ZIP_CODE_PLACEHOLDER')}
                    value={address.zipCode}
                />
                <div />
            </div>
            <div className={`${panelName}-inlineInputs`}>
                {isIndividual && (
                    <OrdersRow
                        className={`${panelName}-Row`}
                        error={validationResults.primaryEmail}
                        formSeleniumid="address"
                        isRequired
                        label={l('ORDER_PANELS.PANEL_CONTACT.EMAIL_LABEL')}
                        maxLength={addressInputMaxLength.EMAIL}
                        name="email"
                        onChange={handleMailChange}
                        onFocus={() => onFieldFocus('primaryEmail')}
                        placeholder={l('ORDER_PANELS.PANEL_CONTACT.EMAIL_PLACEHOLDER')}
                        type="email"
                        value={address.primaryEmail}
                    />
                )}
                {isIndividual && (
                    <div>
                        <OrdersRow
                            className={`${panelName}-Row`}
                            error={validationResults.phoneNumber}
                            formSeleniumid="address"
                            isRequired
                            label={l('ORDER_PANELS.PANEL_CONTACT.PHONE_LABEL')}
                            maxLength={addressInputMaxLength.PHONE}
                            name="phoneNumber"
                            onChange={handlePhoneNumberChange}
                            onFocus={onFocus}
                            placeholder={l('ORDER_PANELS.PANEL_CONTACT.PHONE_PLACEHOLDER')}
                            type="tel"
                            value={address.phoneNumber}
                        />
                        <strong className={`${panelName}-Static`}>
                            {l('ORDER_PANELS.PANEL_CONTACT.PHONE_STATIC_TEXT')}
                        </strong>
                    </div>
                )}
            </div>
        </div>
    );
};

export default withCodes(EditAddress, ID.ORDER_PANELS, ID.PROFILE_ACCOUNT, ID.STATE_MANDATORY_COUNTRIES);
