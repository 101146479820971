import cn from 'classnames';
import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { compose } from 'app/blocks/common/utils';
import validation from 'app/blocks/common/validation';
import ErrorLabel from 'app/blocks/ErrorLabel/ErrorLabel';
import StaticContent from 'app/blocks/static-content/static-content';
import { SubTitle } from 'app/blocks/titles/titles';
import { Discount } from 'app/pages/orders/panel/discount';
import withDiscountPanelContainer from './withDiscountPanelContainer';

function DiscountPanel(props) {
    const { discountsError, hasAdditionalDiscounts, hasSocietyDiscounts } = props;

    if (!discountsError && !hasSocietyDiscounts && !hasAdditionalDiscounts) {
        return null;
    }

    return (
        <section
            className={cn('payment-panel DiscountPanel', {
                'payment-panel--error': !validation.isAllKeysFalseOrEmpty(props.validationResults),
            })}
            data-seleniumid="DiscountPanel"
        >
            <SubTitle className="DiscountPanel-Title">
                {props.l('LICENSE_SIGNING.PAYMENT.TITLE.DISCOUNT')}
                <span>(optional)</span>
            </SubTitle>

            {(!!discountsError && (
                <StaticContent
                    key="discount_service_unavailability_error"
                    seleniumid="discount-service-unavailable"
                    src="discount-service-unavailable-error.html"
                />
            )) || <Discount {...props} panelName="DiscountPanel" />}

            {!validation.isAllKeysFalseOrEmpty(props.validationResults) && (
                <ErrorLabel text="Discount validation error" />
            )}
        </section>
    );
}

export default compose(withDiscountPanelContainer, withCodes2(ID.LICENSE_SIGNING))(DiscountPanel);
