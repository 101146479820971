import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

export default function withLightPanel(panelName) {
    return function (Component) {
        @autobind
        class LightPanel extends React.PureComponent {
            static propTypes = {
                register: PropTypes.func.isRequired,
                unregister: PropTypes.func.isRequired,
            };

            // eslint-disable-next-line react/no-unused-class-component-methods
            panelName = panelName;

            topPosition = 0;

            doScrollToElement = false;

            componentDidMount() {
                this.props.register(this);
            }

            componentWillUnmount() {
                this.props.unregister(this);
            }

            // eslint-disable-next-line react/no-unused-class-component-methods
            async validate() {
                await this.validationHandler();
            }

            setPanelPosition(topPosition) {
                this.topPosition = topPosition;
                if (this.doScrollToElement) {
                    this.doScrollToElement = false;
                    window.scrollTo(0, this.topPosition);
                }
            }

            setValidationHandler(fn) {
                this.validationHandler = fn;
            }

            updateTopPosition() {
                // eslint-disable-next-line react/no-find-dom-node
                this.setPanelPosition(window.pageYOffset + ReactDOM.findDOMNode(this).getBoundingClientRect().top);
            }

            // eslint-disable-next-line react/no-unused-class-component-methods
            scrollToPanel() {
                setTimeout(() => {
                    this.updateTopPosition();
                    window.scrollTo(0, this.topPosition);
                }, 300);
            }

            render() {
                return <Component {...this.props} setValidationHandler={this.setValidationHandler} />;
            }
        }

        return LightPanel;
    };
}
