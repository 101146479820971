import { autobind } from 'core-decorators';
import update from 'immutability-helper';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import { Layout } from 'app/blocks/blocks';
import { processing, tryCatch } from 'app/blocks/common/decorators';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import { compose, getQueryParamSafe, looseEqual } from 'app/blocks/common/utils';
import { ServiceError } from 'app/blocks/common/utils-errortypes';
import withAuth from 'app/blocks/common/withAuth';
import withConfirmLeaving from 'app/blocks/common/withConfirmLeaving';
import { NONE_VALUE } from 'app/blocks/institution-picker/institution-picker-view';
import * as licenseMiddleware from 'app/blocks/middleware/license';
import * as middleware from 'app/blocks/middleware/middleware';
import LicenseSigningProcessContext from 'app/pages/license-signing-process/context/Context';
import withContext from 'app/pages/license-signing-process/context/withContext';
import { suggestAddresses } from 'app/pages/license-signing-process/Payment/utils';
import payment from 'app/pages/orders/orders.payment';
import { notifyUserThatOrderIsSaved, notifyUserThatVatTaxShouldBeReentered } from 'app/pages/orders/orders.utils';
import { isStateRequired } from 'app/pages/orders/panel/contact/validation';
import routes from 'app/pages/routes';
import { getContactState } from './utils';
import tempStorage from '../../../route/tempStorage';
import PaymentPanelEnum from '../Payment/PaymentPanelEnum';
import PaymentStepEnum from '../Payment/PaymentStepEnum';
import StepEnum from '../StepEnum';

const Context = React.createContext({});

function resetInititateState(initiate, order) {
    return update(initiate, {
        [PaymentPanelEnum.DISCOUNT]: { discountDetails: { $set: { ...order?.discountDetails } } },
        [PaymentPanelEnum.CONTACT]: (function () {
            const addresses = getContactState(order);

            return _.object(
                Object.keys(addresses),
                Object.values(addresses).map(value => ({ $set: value })),
            );
        })(),
        [PaymentPanelEnum.VAT_TAX]: { vatTaxDetails: { $set: { ...order.vatTaxDetails } } },
        statusPopupError: { $set: undefined },
    });
}

function withProvider(WrappedComponent) {
    @autobind
    class DiscountAndBillingContext extends React.PureComponent {
        static propTypes = {
            article: PropTypes.shape({ id: PropTypes.string }).isRequired,
            confirmDiscountAndBilling: PropTypes.func.isRequired,
            confirmStep: PropTypes.func.isRequired,
            isInstitutionalDiscount: PropTypes.bool,
            isOrderExisting: PropTypes.bool.isRequired,
            journal: PropTypes.shape({}).isRequired,
            location: PropTypes.shape({ href: PropTypes.string }),
            order: PropTypes.shape({}).isRequired,
            reload: PropTypes.func.isRequired,
            setChangesChecker: PropTypes.func.isRequired,
            substep: PropTypes.string,
            unsetChangesChecker: PropTypes.func.isRequired,
        };

        static defaultProps = {
            isInstitutionalDiscount: false,
            location: window.location,
            substep: PaymentStepEnum.INITIATE,
        };

        state = {
            initiate: {
                [PaymentPanelEnum.DISCOUNT]: {},
                [PaymentPanelEnum.CONTACT]: {},
                [PaymentPanelEnum.VAT_TAX]: {},
            },
            isOrderFromNonSanctionCountry: true,
            isProcessing: true,
            prices: [],
            review: {},
        };

        @tryCatch.showPopUpAndGoToDashboard
        @processing
        async componentDidMount() {
            const params = getQueryParamSafe(window.location.href);
            const panel = tempStorage.get();

            if (params.code) {
                showDialog.error({
                    code: params.code,
                    message: params.message && decodeURIComponent(params.message.replace(/\+/g, '%20')),
                    refId: params.refId,
                });
            }

            const aid = this.props.article.id;
            const { order } = this.props;
            const [profileAddresses, emailDetails, prices, socopayEnabled] = await Promise.all([
                middleware.profile.getAddresses(),
                middleware.profile.getEmailDetails(),
                licenseMiddleware.getBasePrices(aid),
                middleware.payment.getSocopayEnabled(),
            ]);

            if (!order.pricingDetails.currency) {
                order.pricingDetails.currency = get(prices, '[0].currency');
            }

            this.setState(
                state => ({
                    emailDetails,
                    initOrder: { ...order },
                    initiate: resetInititateState(state.initiate, order),
                    order,
                    prices,
                    profileAddresses,
                    socopayEnabled,
                }),
                () => {
                    this.initialOrder = this.getOrderWithUpdates();
                },
            );

            this.panels = [];

            this.props.setChangesChecker(() => !looseEqual(this.initialOrder, this.getOrderWithUpdates()));

            if (panel) {
                setTimeout(() => this.scrollTo(panel), 300);
            }

            if (params.scrollTo) {
                setTimeout(() => this.scrollTo(params.scrollTo), 300);
            }
        }

        componentDidUpdate(prevProps, prevState) {
            const oldBillingCountry = get(prevState, 'initiate.addresses.mainAddress.countryCode');
            const newBillingCountry = get(this.state, 'initiate.addresses.mainAddress.countryCode');

            this.setState({ previousBillingCountry: oldBillingCountry });

            if (oldBillingCountry !== newBillingCountry) {
                if (
                    oldBillingCountry && // do not trigger on didMount
                    this.isVatTaxNonEmpty()
                ) {
                    if (oldBillingCountry !== undefined && newBillingCountry !== undefined)
                        notifyUserThatVatTaxShouldBeReentered();
                    this.setVatTaxState({ vatTaxDetails: {} });
                }
                this.updateCurrency(newBillingCountry);
            }

            // fixme
            if (this.props.substep !== prevProps.substep) {
                this.props.setChangesChecker(() => !looseEqual(this.initialOrder, this.getOrderWithUpdates()));
            }
        }

        setInitiateState(patch, callback = () => {}) {
            this.setState(state => ({ initiate: { ...state.initiate, ...patch(state.initiate) } }), callback);
        }

        isVatTaxNonEmpty() {
            return Object.values(get(this.state, 'initiate.vatTax.vatTaxDetails', {})).some(x => !!x);
        }

        shouldRemovePaymentMethod(newBillingCountry) {
            return (
                get(this.state, 'order.paymentDetails.paymentMethod') === payment.method.SOCOPAY.key &&
                (!this.state.socopayEnabled || newBillingCountry !== 'CN')
            );
        }

        @tryCatch
        async updateCurrency(countryCode) {
            const currency = await middleware.product.getCountryCurrency(countryCode);
            this.setState(state => ({
                order: update(state.order, { pricingDetails: { currency: { $set: currency } } }),
            }));
        }

        // initiate things
        registerPanel(panel) {
            this.panels.push(panel);
        }

        unregisterPanel(panel) {
            this.panels = this.panels.filter(x => x.panelName === panel.panelName);
        }

        scrollTo(type) {
            this.panels.find(panel => panel.panelName === type).scrollToPanel();
        }

        setDiscountState(patch, callback) {
            this.setInitiateState(state => ({ discount: { ...state.discount, ...patch } }), callback);
        }

        setContactState(patch, callback) {
            this.setInitiateState(state => ({ addresses: { ...state.addresses, ...patch } }), callback);
        }

        setVatTaxState(patch, callback) {
            this.setInitiateState(state => ({ vatTax: { ...state.vatTax, ...patch } }), callback);
        }

        isSaveAndPreviewAllowed() {
            const { journal } = this.props;
            const isOnlineOpen = journal.onlineOpen;
            return [
                isOnlineOpen ? true : get(this.state, 'initiate.addresses.mainAddress.fullName'),
                isOnlineOpen ? get(this.state, 'initiate.addresses.mainAddress.firstName') : true,
                isOnlineOpen ? get(this.state, 'initiate.addresses.mainAddress.lastName') : true,
                isOnlineOpen ? get(this.state, 'initiate.addresses.mainAddress.billToType') : true,
                get(this.state, 'initiate.addresses.mainAddress.institutionName') === NONE_VALUE ||
                    !!get(this.state, 'initiate.addresses.mainAddress.institutionName'),
                get(this.state, 'initiate.addresses.mainAddress.addressLine1'),
                get(this.state, 'initiate.addresses.mainAddress.city'),
                get(this.state, 'initiate.addresses.mainAddress.countryCode'),
                isStateRequired(get(this.state, 'initiate.addresses.mainAddress.countryCode'))
                    ? get(this.state, 'initiate.addresses.mainAddress.stateCode')
                    : true,
                get(this.state, 'initiate.addresses.mainAddress.zipCode'),
                get(this.state, 'initiate.addresses.mainAddress.phoneNumber'),
                get(this.state, 'initiate.addresses.mainAddress.primaryEmail'),
                get(this.state, 'isOrderFromNonSanctionCountry'),
            ].every(x => !!x);
        }

        getOrderWithUpdates() {
            return {
                ...this.state.order,
                billingAddress: get(this.state, 'initiate.addresses.mainAddress'),
                discountDetails: get(this.state, 'initiate.discount.discountDetails'),
                vatTaxDetails: get(this.state, 'initiate.vatTax.vatTaxDetails'),
            };
        }

        createWarningPopupMessage(key, payload = null) {
            const { l } = this.props;
            if (payload) {
                return new ServiceError(l(key, payload), null, null, null);
            }

            return new ServiceError(l(key), null, null, null);
        }

        async showSanctionedBillingAttemptsPopup(remainingSanctionedBillingAttempts) {
            if (parseInt(remainingSanctionedBillingAttempts, 10) > 0) {
                const key = 'ORDER_PANELS.SANCTION_COUNTRY_WARNING.SUBSCRIPTION_TO_OPEN_LIMIT_NOT_EXCEEDED';
                const payload = {
                    remainingAttemptValue: remainingSanctionedBillingAttempts,
                };
                await showDialog.addErrorMessageDialog(this.createWarningPopupMessage(key, payload));
            } else {
                const key = 'ORDER_PANELS.SANCTION_COUNTRY_WARNING.SUBSCRIPTION_TO_OPEN_LIMIT_EXCEEDED';
                await showDialog.addErrorMessageDialog(
                    this.createWarningPopupMessage(key),
                    this.props.confirmDiscountAndBilling,
                );
            }
        }

        async validateAndGetOrderWithUpdates() {
            const discountDetails = get(this.state, 'initiate.discount.discountDetails');
            for (const f of ['societyPromoCode', 'wileyPromoCode']) {
                if (discountDetails[f] && discountDetails[f].trim) {
                    discountDetails[f] = discountDetails[f].trim().replace(/[ ]+/g, ' ');
                }
            }
            await new Promise(resolve => this.setDiscountState(discountDetails, resolve));

            await Promise.all(this.panels.map(it => it.validate()));

            const addresses = await suggestAddresses(
                {
                    mainAddress: this.state.order.billingAddress,
                },
                {
                    mainAddress: this.state.initiate.addresses.mainAddress,
                },
            );

            const newBillingCountry = get(addresses, 'mainAddress.countryCode');

            const { previousBillingCountry } = this.state;

            if (this.shouldRemovePaymentMethod(newBillingCountry)) {
                delete this.state.order.paymentDetails.paymentMethod;
            }

            const isBillingCountryChangedAndVatTaxShouldBeReentered =
                previousBillingCountry !== newBillingCountry && this.isVatTaxNonEmpty();

            this.setContactState(addresses);

            if (isBillingCountryChangedAndVatTaxShouldBeReentered) {
                throw new Error('VatTaxShouldBeReentered');
            }

            return {
                ...this.state.order,
                billingAddress: addresses.mainAddress,
                correspondenceAddress: addresses.mainAddress,
                discountDetails,
                vatTaxDetails: get(this.state, 'initiate.vatTax.vatTaxDetails'),
            };
        }

        closeStatusPopup() {
            this.setInitiateState(() => ({ statusPopupError: undefined }));
        }

        @tryCatch.popUpOnly
        @processing('initiate.isSavingForLater')
        async saveForLater() {
            const order = this.getOrderWithUpdates();
            await middleware.onlineopen.saveForLater(order);
            this.setState({ order });
            await notifyUserThatOrderIsSaved('ONLINE_OPEN', 'initiate');

            this.props.unsetChangesChecker();

            routes.navigateToUrl(`article/${this.state.order.article.id}`);
        }

        @tryCatch.popUpOnly
        @processing('initiate.isConfirming')
        async onInitiateConfirm() {
            try {
                const { isEditOrderFlow, l } = this.props;

                const {
                    remainingSanctionedBillingAttempts,
                    sanctionCountryWithNewOrder,
                    sanctionCountryWithOOOrderEdit,
                    sanctionCountryWithSubscriptionLicense,
                } = await middleware.orders.checkBillingCountryAndOrderStatus(
                    this.state.initiate.addresses.mainAddress.countryCode,
                    isEditOrderFlow,
                    this.state.order.article.id,
                );

                if (sanctionCountryWithOOOrderEdit) {
                    const key = 'ORDER_PANELS.SANCTION_COUNTRY_WARNING.HOA_ORDER_EDIT_MESSAGE';
                    await showDialog.addErrorMessageDialog(this.createWarningPopupMessage(key));
                } else if (sanctionCountryWithSubscriptionLicense) {
                    this.showSanctionedBillingAttemptsPopup(remainingSanctionedBillingAttempts);
                } else if (sanctionCountryWithNewOrder) {
                    await showDialog.confirmation({
                        cancelBtnLabel: l('BUTTONS.CANCEL'),
                        confirmBtnLabel: l('BUTTONS.CONTINUE'),
                        message: l('ORDER_PANELS.SANCTION_COUNTRY_WARNING.MESSAGE'),
                        onApprove: () => {
                            this.setState({ isOrderFromNonSanctionCountry: false });
                            this.props.confirmStep(StepEnum.OPEN_ACCESS_OPTIONS, {
                                authorSelectedOnlineOpen: false,
                            });
                            middleware.orders.auditSanctionCountryData(
                                this.state.order.article.id,
                                this.state.initiate.addresses.mainAddress.countryCode,
                            );
                        },
                    });
                } else {
                    const order = await this.validateAndGetOrderWithUpdates();
                    const savedOrder = await middleware.onlineopen.saveAndPreview(order);

                    this.setState(
                        state => ({
                            initiate: resetInititateState(state.initiate, savedOrder),
                            order: savedOrder,
                        }),
                        async () => {
                            this.initialOrder = this.getOrderWithUpdates();
                            await this.props.confirmDiscountAndBilling(savedOrder);
                        },
                    );
                }
            } catch (error) {
                // instanceof does not work
                if (error.name === 'ValidationError') {
                    this.setInitiateState(() => ({
                        statusPopupError: new Error(
                            'There was an error with your submission. Please scroll down to review',
                        ),
                    }));

                    // Object.values(PanelEnum).some((x) => {
                    //     if (this.state[x].validationResults) {
                    //         this.scrollTo(x);
                    //         return true;
                    //     }
                    //
                    //     return false;
                    // });
                } else if (error.message === 'VatTaxShouldBeReentered') {
                    this.scrollTo(PaymentPanelEnum.VAT_TAX);
                } else {
                    throw error;
                }
            }
        }

        render() {
            if (this.state.isProcessing) {
                return <Layout isLoading />;
            }

            return (
                <Context.Provider
                    value={{
                        ...this.state,
                        article: this.props.article,
                        closeStatusPopup: this.closeStatusPopup,
                        initiate: {
                            ...this.state.initiate,
                            isSaveAndPreviewAllowed: this.isSaveAndPreviewAllowed(),
                        },
                        isInstitutionalDiscount: this.props.isInstitutionalDiscount,
                        isOrderExisting: this.props.isOrderExisting,
                        journal: this.props.journal,
                        onInitiateConfirm: this.onInitiateConfirm,
                        orderWithUpdates: this.getOrderWithUpdates(),
                        registerPanel: this.registerPanel,
                        saveForLater: this.saveForLater,
                        setContactState: this.setContactState,
                        setDiscountState: this.setDiscountState,
                        setVatTaxState: this.setVatTaxState,
                        unregisterPanel: this.unregisterPanel,
                    }}
                >
                    <WrappedComponent {...this.props} />
                </Context.Provider>
            );
        }
    }

    return DiscountAndBillingContext;
}

export { withProvider };
export default {
    withContext: withContext(Context),
    withProvider: Component =>
        compose(
            LicenseSigningProcessContext.withContext(state => ({
                article: state.article,
                confirmDiscountAndBilling: state.confirmDiscountAndBilling,
                confirmStep: state.confirmStep,
                isEditOrderFlow: get(state, 'all.discountAndBillingDetails.isEditOrderFlow', false),
                isInstitutionalDiscount: state?.all?.onlineOpenPayment?.isInstitutionalDiscount ?? false,
                isOrderExisting: state.all.onlineOpenPayment.isEditOrderFlow,
                journal: state.journal,
                l: state.l,
                onConfirmSubstep: state.onConfirmSubstep,
                order: state.all.onlineOpenPayment.onlineOpenOrder,
                reload: state.reload,
            })),
            withConfirmLeaving,
            withAuth,
            withProvider,
        )(Component),
};
