import React from 'react';
import StaticContent from 'app/blocks/static-content/static-content';

function DiscountedPricesFootnote() {
    return (
        <span className="orderPrices--info">
            <StaticContent src="order_oo_discounted__currency_info_message.html" />
        </span>
    );
}

export default DiscountedPricesFootnote;
