import cn from 'classnames';
import React from 'react';
import './or-delimiter.scss';

export default function ({ className }: { className?: string }) {
    return (
        <div className={cn('OrDelimiter', className)}>
            <div className="OrDelimiter-Line">
                <div className="OrDelimiter-LineCircle" />
            </div>
        </div>
    );
}
