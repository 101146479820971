import cn from 'classnames';
import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import OrdersDataItem from 'app/pages/orders/data-item';
import flow from 'app/pages/orders/orders.flow';
import { displayVAT } from 'app/pages/orders/panel/vat-tax';
import ReviewPanelHeader from './ReviewPanelHeader';

function isEmpty(obj) {
    return obj && Object.values(obj).every(x => !x);
}

function VatTaxReviewPanel({ initOrder = () => {}, l = c => c, onEdit = () => {}, order = {}, panelName = '' }) {
    const DataItemAndAuxInfo = React.useMemo(() => OrdersDataItem.create(order, initOrder), [order, initOrder]);

    const countryCode = flow.getBillingCountryCode(order);

    if (displayVAT(countryCode)) {
        return (
            <section className={cn('payment-panel', panelName)} data-seleniumid="order-vatTax-panel-review">
                <ReviewPanelHeader onEdit={onEdit} panelName={panelName}>
                    {l('ORDER_PANELS.PANEL_VAT_TAX_REVIEW.VAT_DETAILS')}
                </ReviewPanelHeader>

                {(isEmpty(DataItemAndAuxInfo.auxInfo.order.vatTaxDetails) ||
                    !DataItemAndAuxInfo.auxInfo.order.vatTaxDetails.taxExemptionNumber) && (
                    <div className="mt-base" data-seleniumid="order-vatTax-panel-review--empty">
                        {l('ORDER_PANELS.PANEL_VAT_TAX_REVIEW.EMPTY')}
                    </div>
                )}

                {DataItemAndAuxInfo.auxInfo.order.vatTaxDetails.vatIdNumber && (
                    <DataItemAndAuxInfo.OrdersDataItem
                        className={`${panelName}-ContentRow`}
                        innerInfo={[['vatTaxDetails.vatIdNumber']]}
                        seleniumid="order-review-vat-idNumber"
                        titleCls="order-article"
                        titleTxt={l('ORDER_PANELS.PANEL_VAT_TAX.VAT_NUMBER')}
                    />
                )}
            </section>
        );
    }

    return null;
}

export { VatTaxReviewPanel };

export default withCodes2(ID.ORDER_PANELS)(VatTaxReviewPanel);
