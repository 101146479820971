import React from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import StaticContent from 'app/blocks/static-content/static-content';
import { MainTitle } from 'app/blocks/titles/titles';

function GetStarted({ l }: { l: l }) {
    return (
        <div className="GetStarted" data-seleniumid="get-started-block">
            <div>
                <MainTitle data-seleniumid="get-started-title">{l('DASHBOARD.GET_STARTED.TITLE')}</MainTitle>
            </div>
            <StaticContent
                key="get_started"
                className="GetStarted-Static"
                seleniumid="get-started"
                src="get_started.html"
            />
        </div>
    );
}

export default withCodes(GetStarted, ID.DASHBOARD);
