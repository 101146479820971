import cn from 'classnames';
import React from 'react';
import './HelpText.scss';

type Props = Either<{ children: React.ReactNode; text: string }> & {
    className?: string;
    id?: string;
    [props: string]: any;
};

function HelpText({ children, text, className, id, ...props }: Props): React.ReactElement {
    return (
        <div className={cn('HelpText', className)} data-seleniumid="help-text-label" id={id}>
            {text ? (
                <small data-seleniumid="help-text-label-text" {...props} dangerouslySetInnerHTML={{ __html: text }} />
            ) : (
                children
            )}
        </div>
    );
}

export default HelpText;
