import React from 'react';
import { useAuth } from 'app/blocks/common/providers/AuthProvider';
import StaticContent from 'app/blocks/static-content/static-content';
import 'app/blocks/common/css/helpout-alert.scss';

export default function RightMenu() {
    const { user } = useAuth();

    return (
        <div className="col-lg-4">
            {user.primaryEmail && (
                <StaticContent
                    params={{ email: user.primaryEmail }}
                    seleniumid="dashboard-promo"
                    src="dashboard-promo.html"
                />
            )}
        </div>
    );
}
