import cn from 'classnames';
import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import CheckboxButton from 'app/ui/form/CheckboxButton';
import PaymentAmountRow from './PaymentAmountRow';

import './PaymentAmountBreakdown.scss';

function PaymentAmountBreakdown(props) {
    const { chinaPayDetails, isAlipayDataCorrect, isOrderHistory, l, onAlipayAgreeChange, order } = props;
    const AlipaymentAgreement = () => (
        <div
            dangerouslySetInnerHTML={{ __html: l('LICENSE_SIGNING.PAYMENT.LABEL.SOCOPAY_POLICY_LINE') }}
            data-seleniumid="alipayPaymentAgreeCheckbox-label"
        />
    );

    return (
        <section className={cn('payment-panel', props.panelName)} data-seleniumid="order-price-panel-review">
            <div style={{ marginTop: '20px' }} />
            <div className="amount-rows">
                <PaymentAmountRow
                    amount={order.pricingDetails.basePrice}
                    bold
                    grayed={isOrderHistory}
                    seleniumid="base-price"
                    subHeader={`${l(
                        'ORDER_PANELS.PANEL_CHINA_PAY.PAYMENT_BREAKDOWN.ARTICLE_SUBMISSION_CHARGE_SUB_HEADER',
                    )}: ${order.article.type}`}
                    title={l('ORDER_PANELS.PANEL_CHINA_PAY.PAYMENT_BREAKDOWN.ARTICLE_SUBMISSION_CHARGE_HEADER')}
                />
                <PaymentAmountRow
                    amount={order.pricingDetails.discountAmount}
                    grayed={isOrderHistory}
                    seleniumid="discount"
                    title={l('ORDER_PANELS.PANEL_CHINA_PAY.PAYMENT_BREAKDOWN.DISCOUNT_HEADER')}
                />
                <PaymentAmountRow
                    amount={order.pricingDetails.totalAmount}
                    bold
                    grayed
                    seleniumid="sub-total"
                    title={l('ORDER_PANELS.PANEL_CHINA_PAY.PAYMENT_BREAKDOWN.SUBTOTAL_USD_HEADER')}
                />
                <PaymentAmountRow
                    amount={chinaPayDetails.subTotal}
                    currency="RMB"
                    grayed={isOrderHistory}
                    seleniumid="sub-total-rmb"
                    subHeader={`1 USD ≈ ${chinaPayDetails.conversionRate} CNY`}
                    title={l('ORDER_PANELS.PANEL_CHINA_PAY.PAYMENT_BREAKDOWN.SUBTOTAL_CNY_HEADER')}
                />
                <PaymentAmountRow
                    amount={chinaPayDetails.surcharge}
                    currency="RMB"
                    grayed={isOrderHistory}
                    seleniumid="surcharge-rmb"
                    title={l('ORDER_PANELS.PANEL_CHINA_PAY.PAYMENT_BREAKDOWN.SURCHARD_HEADER')}
                />
                <PaymentAmountRow
                    amount={chinaPayDetails.tax}
                    currency="RMB"
                    grayed={isOrderHistory}
                    seleniumid="tax-rmb"
                    title={l('ORDER_PANELS.PANEL_CHINA_PAY.PAYMENT_BREAKDOWN.TAX_HEADER')}
                />
                <PaymentAmountRow
                    amount={chinaPayDetails.finalPrice}
                    blue={!isOrderHistory}
                    bold
                    currency="RMB"
                    grayed={isOrderHistory}
                    seleniumid="final-price-rmb"
                    title={l('ORDER_PANELS.PANEL_CHINA_PAY.PAYMENT_BREAKDOWN.ORDER_TOTAL_HEADER')}
                />
                {!isOrderHistory && (
                    <div>
                        <div className="payment-PayViaAlipay">
                            <span data-seleniumid="payment-pay-via-alipay-label">
                                {l('ORDER_PANELS.PANEL_CHINA_PAY.ALIPAY_PAY_TITLE')}
                            </span>
                            <SvgIcon.alipay2 className="AlipayText-Icon" seleniumid="payment-pay-via-alipay-logo" />
                            <SvgIcon.alipay
                                className="AlipayText-Icon"
                                seleniumid="payment-pay-via-alipay-logo-with-name"
                            />
                        </div>
                        <div>
                            <span
                                className="payment-PayViaAlipay-verifyTxt"
                                data-seleniumid="alipay-payment-socopay-description"
                            >
                                {l('ORDER_PANELS.PANEL_CHINA_PAY.ALIPAY_PAY_SUBTITLE')}
                            </span>
                            <br /> <br />
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: l('ORDER_PANELS.PANEL_CHINA_PAY.ALIPAY_PAY_DESCRIPTION'),
                                }}
                                data-seleniumid="alipay-payment-mobile-description"
                            />
                        </div>
                        <CheckboxButton
                            checked={isAlipayDataCorrect}
                            className="payment-AcceptCheckBox"
                            data-seleniumid="alipayPaymentAgreeCheckbox"
                            id="alipayAgreeCheckbox"
                            label={<AlipaymentAgreement />}
                            onChange={event => onAlipayAgreeChange(event.target.checked)}
                        />
                    </div>
                )}
            </div>
        </section>
    );
}

export default withCodes2(ID.ORDER_PANELS, ID.LICENSE_SIGNING)(PaymentAmountBreakdown);
