import React from 'react';
import { Button } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import { viewFullArticle } from 'app/blocks/Panel/actions/buttons/panel_actions__buttons.utils';
import { BasicView, GrayView } from 'app/blocks/views/WidgetView';
import { useArticlePage } from 'app/pages/article/ArticlePage.context';
import { CustomAction, CustomLink } from '../actions';

function DownloadArticleWidget({ l }: { l: l }): React.ReactElement {
    const item = useArticlePage();
    const { getActions, getStatus, hasCitations } = item.helpers;

    const licenseButton = getActions('license').VIEW_LICENSE;
    const licenseLink = !!licenseButton && (
        <CustomLink button={licenseButton} data-seleniumid="license-agreement">
            {l('WIDGETS.DOWNLOAD_ARTICLE.LICENSE_CAPTION')}
        </CustomLink>
    );

    let licenseText;
    if (!licenseLink) {
        licenseText = <span dangerouslySetInnerHTML={{ __html: l('WIDGETS.DOWNLOAD_ARTICLE.CONTENT_NO_LICENSE') }} />;
    } else {
        const [before, after] = l('WIDGETS.DOWNLOAD_ARTICLE.CONTENT_WITH_LICENSE')
            .split('#LICENSE_LINK#')
            .map(text => <span dangerouslySetInnerHTML={{ __html: text }} />);
        licenseText = (
            <>
                {before}
                {licenseLink}
                {after}
            </>
        );
    }

    const Download = item.publication.pdfAvailable ? (
        <CustomAction
            onClick={async e => {
                e.preventDefault();
                await viewFullArticle(item.id, item.journal);
            }}
            seleniumid="ARTICLE_PDF_URL"
        >
            {l('WIDGETS.DOWNLOAD_ARTICLE.DOWNLOAD_BUTTON_CAPTION')}
        </CustomAction>
    ) : (
        <Button
            data-seleniumid="ARTICLE_PDF_URL"
            href={`https://doi.org/${item.article.doi}`}
            isLinkTag
            target="_blank"
        >
            {l('WIDGETS.DOWNLOAD_ARTICLE.DOWNLOAD_LINK_CAPTION')}
        </Button>
    );

    const View = item.status.published && hasCitations() ? GrayView : BasicView;
    return (
        <View
            actions={Download}
            additionalActions={
                // eslint-disable-next-line react/jsx-no-target-blank
                <a
                    className="ActionLink"
                    data-seleniumid="ORDER_REPRINT_URL"
                    href={l('WIDGETS.DOWNLOAD_ARTICLE.ORDER_REPRINT_URL')}
                    target="_blank"
                >
                    {l('WIDGETS.DOWNLOAD_ARTICLE.ORDER_REPRINT_TEXT')}
                </a>
            }
            icon="download"
            seleniumid="downloadArticle"
            title={l('WIDGETS.DOWNLOAD_ARTICLE.TITLE')}
        >
            <div>
                <div data-seleniumid="publications-status-text">{getStatus('publication')}</div>
                <div className="inline-children" data-seleniumid="license-text">
                    {licenseText}
                </div>
            </div>
        </View>
    );
}

export default withCodes(DownloadArticleWidget, ID.WIDGETS);
