import V, { isEmailValid } from 'app/blocks/common/validation';

const ERROR_PREFIX = 'REGISTRATION.errorMessages';

export function validateEmail(email: string): string {
    return V.validate(email.trim(), [
        [V.notEmpty, `${ERROR_PREFIX}.REGISTRATION_PRIMARY_EMAIL_ADDR_MISSING_ERR_TEXT`],
        [isEmailValid, `${ERROR_PREFIX}.REGISTRATION_PRIMARY_EMAIL_ADDR_INVALID_ERR_TEXT`],
        [v => !(v || '').includes('*'), `${ERROR_PREFIX}.REGISTRATION_PRIMARY_EMAIL_ADDR_MISSING_ERR_TEXT`],
    ]);
}

export function validate(
    firstName: string,
    lastName: string,
    countryCode: string,
    email: string,
    repeatEmail: string,
    password: string,
    repeatPassword: string,
    areaOfInterest: string,
    wileyTermsOfUseConsent: boolean,
    chinaPIPLConsent: boolean,
): {
    firstName: string;
    lastName: string;
    country: string;
    email: string;
    repeatEmail: string;
    password: string;
    repeatPassword: string;
    areaOfInterest: string;
    wileyTermsOfUseConsent: string;
    chinaPIPLConsent: string;
} {
    const similarPasswords = () => password === repeatPassword;
    const similarEmails = () => email === repeatEmail;

    return {
        areaOfInterest: V.validate(areaOfInterest, [
            [v => v === null || !!v, `${ERROR_PREFIX}.REGISTRATION_AREAS_MISSING_ERR_TEXT`],
        ]),
        chinaPIPLConsent: V.validate(chinaPIPLConsent, [
            [v => countryCode !== 'CN' || !!v, `${ERROR_PREFIX}.PIPL_NOT_CONFIRMED`],
        ]),
        country: V.validate(countryCode.trim(), [
            [V.notEmpty, `${ERROR_PREFIX}.REGISTRATION_COUNTRY_MISSING_ERR_TEXT`],
        ]),
        email: validateEmail(email),
        firstName: V.validate(firstName.trim(), [
            [V.notEmpty, `${ERROR_PREFIX}.REGISTRATION_FIRST_NAME_MISSING_ERR_TEXT`],
            [V.validateInputConstrainedLatinExtendedChars, `${ERROR_PREFIX}.REGISTRATION_FIRST_NAME_INVALID_ERR_TEXT`],
            [V.validateInputFullLatinExtendedChars, `${ERROR_PREFIX}.REGISTRATION_FIRST_NAME_INVALID_ERR_TEXT`],
        ]),
        lastName: V.validate(lastName.trim(), [
            [V.notEmpty, `${ERROR_PREFIX}.REGISTRATION_LAST_NAME_MISSING_ERR_TEXT`],
            [V.validateInputConstrainedLatinExtendedChars, `${ERROR_PREFIX}.REGISTRATION_LAST_NAME_INVALID_ERR_TEXT`],
            [V.validateInputFullLatinExtendedChars, `${ERROR_PREFIX}.REGISTRATION_LAST_NAME_INVALID_ERR_TEXT`],
        ]),
        password: V.validate(password, [
            [V.notEmpty, `${ERROR_PREFIX}.REGISTRATION_PASSWORD_MISSING_ERR_TEXT`],
            [V.isPasswordValid, `${ERROR_PREFIX}.REGISTRATION_STRICT_PASSWORD_INVALID_ERR_TEXT`],
        ]),
        repeatEmail: V.validate(repeatEmail.trim(), [
            [V.notEmpty, `${ERROR_PREFIX}.REGISTRATION_RETYPE_PRIMARY_EMAIL_ADDR_MISSING_ERR_TEXT`],
            [similarEmails, `${ERROR_PREFIX}.REGISTRATION_RETYPE_PRIMARY_EMAIL_ADDR_INVALID_ERR_TEXT`],
        ]),
        repeatPassword: V.validate(repeatPassword, [
            [V.notEmpty, `${ERROR_PREFIX}.REGISTRATION_CONFIRM_PASSWORD_MISSING_ERR_TEXT`],
            [similarPasswords, `${ERROR_PREFIX}.REGISTRATION_CONFIRM_PASSWORD_INVALID_ERR_TEXT`],
        ]),
        wileyTermsOfUseConsent: V.validate(wileyTermsOfUseConsent, [[v => !!v, `${ERROR_PREFIX}.TERMS_NOT_CONFIRMED`]]),
    };
}

export default null;
