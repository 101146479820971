import { getUrlParam } from 'app/blocks/middleware/url-params';
import { REST } from './utils';

const { GET, POST } = REST('/user');

export const getSecurityQuestions = (
    email: string,
): Promise<{
    securityDetails: Array<{ securityQuestion: string; securityQuestionId: string }>;
}> => GET(`/securityQuestions/${encodeURIComponent(email)}/`);

export const validateSecurityQuestions = (
    email: string,
    answers: Array<{ securityAnswer: string; securityQuestionId: string }>,
): Promise<void> => POST('/securityQuestions/validate/', { emailId: email, securityDetails: answers });

export const sendResetCode = (email: string): Promise<void> =>
    POST('/password/sendResetCode/', { primaryEmailAddr: email, returnUrl: getUrlParam('returnUrl') });

export const verifyResetLink = (uuid: string): Promise<{ emailId: string }> =>
    GET(`/password/verifyResetCode/${uuid}/`);

export const resetUsingCode = (email: string, password: string, uuid: string): Promise<{ emailId: string }> =>
    POST(`/password/resetUsingCode/${uuid}/`, {
        emailId: email,
        password,
    });

export const resetUsingSecurityQuestions = (
    email: string,
    password: string,
    answers: Array<{ securityAnswer: string; securityQuestionId: string }>,
): Promise<void> =>
    POST('/password/resetUsingSecurityQuestions/', {
        emailId: email,
        securityDetails: answers,
        password,
    });
