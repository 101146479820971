const LicenseTypeEnum = {
    CC_BY: 'CC-BY',
    CC_BY_NC: 'CC-BY-NC',
    CC_BY_NC_ND: 'CC-BY-NC-ND',
    CC_BY_NC_SA: 'CC-BY-NC-SA',
    CC_BY_ND: 'CC-BY-ND',
    CC_BY_SA: 'CC-BY-SA',
    SUBSCRIPTION: 'Subscription',
} as const;

export default LicenseTypeEnum;
