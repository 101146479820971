import cn from 'classnames';
import React from 'react';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import './WarningLabel.scss';

function WarningLabel({ children, className, seleniumId }) {
    let content = children;

    if (typeof children === 'string') {
        content = <div dangerouslySetInnerHTML={{ __html: children }} />;
    }

    return (
        <div className={cn('WarningLabel', className)} data-seleniumid={seleniumId}>
            <SvgIcon.error iconTitle="Warning Icon" />
            {content}
        </div>
    );
}

export default WarningLabel;
