import React from 'react';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';

import './geoblock.scss';

function Geoblock({ geoTargetedMessage }: { geoTargetedMessage: string }) {
    return (
        <div className="Geoblock">
            <SvgIcon.info className="Geoblock-InfoIcon" iconDescr="Info mark symbol" iconTitle="Info icon" />
            <div
                dangerouslySetInnerHTML={{ __html: geoTargetedMessage }}
                data-seleniumid="article-affiliations-geo-targeted-text"
            />
        </div>
    );
}

export default Geoblock;
