import cn from 'classnames';
import React, { Component } from 'react';
import AffiliationsStepEnum from 'app/blocks/article-affiliations-editor/AffiliationsStepEnum';
import NoAffiliations from 'app/blocks/article-affiliations-editor/article-affiliations-panel/NoAffiliations';
import { ID, withCodes } from 'app/blocks/common/codes';
import CountryPicker from 'app/blocks/country-picker/country-picker';
import * as middleware from 'app/blocks/middleware/middleware';
import InstitutionPicker from './InstitutionPicker/institution-picker';
import { InstitutionPickerItem } from './InstitutionPicker/institution-picker.types';
import Header from '../Header/Header';
import './selectWOAInstitution.scss';

type Props = {
    blacklist: string[];
    institutionsIdFromProfile: string[];
    l: l;
    offset?: number;
    onSelectAffiliationByCountry: (value: ArticleAffiliation) => void;
    pageSize?: number;
    searchStr?: string;
    setNotAffiliated: (value: boolean) => void;
    step: Values<typeof AffiliationsStepEnum>;
};

type State = {
    isWoaFunder: boolean;
    noAffiliations: boolean;
    institutionList: InstitutionPickerItem[];
    selectedCountry?: Country;
    selectedItem?: InstitutionPickerItem;
};

class SelectWOAInstitutionPanel extends Component<Props, State> {
    static defaultProps = {
        offset: 0,
        pageSize: 999,
        searchStr: '',
    };

    state: State = {
        institutionList: [],
        isWoaFunder: true,
        noAffiliations: false,
        selectedCountry: undefined,
    };

    onChangeCountry = (country: Country) => {
        this.setState(
            {
                selectedCountry: country,
                selectedItem: null,
            },
            this.getInstitutions,
        );
    };

    onSelectChange = institution => {
        const { onSelectAffiliationByCountry: selectInstitution } = this.props;

        this.setState({ selectedItem: institution }, () => selectInstitution(institution));
    };

    getInstitutions = async () => {
        const { blacklist, institutionsIdFromProfile, offset, pageSize, searchStr } = this.props;
        const { isWoaFunder, selectedCountry } = this.state;

        try {
            const payload = await middleware.institutions.getInstitutionsList(
                searchStr,
                pageSize,
                offset,
                {
                    blacklist,
                    institutionsIdFromProfile,
                },
                selectedCountry?.id || null,
                isWoaFunder,
            );

            this.setState({ institutionList: this.prepareOptions(payload.list) });
        } catch (error) {
            this.setState({ institutionList: [] });
        }
    };

    onNoAffiliationsChange = () => {
        const { noAffiliations } = this.state;
        const { onSelectAffiliationByCountry, setNotAffiliated } = this.props;

        setNotAffiliated(!noAffiliations);
        onSelectAffiliationByCountry(null);

        this.setState({
            noAffiliations: !noAffiliations,
            selectedCountry: undefined,
            selectedItem: null,
        });
    };

    prepareOptions<T extends { id: string; name: string }>(options: T[]) {
        return options.map(x => ({ ...x, label: x.name, value: x.id }));
    }

    render() {
        const { l, step } = this.props;
        const { institutionList, noAffiliations, selectedCountry, selectedItem } = this.state;

        return (
            <div className="SelectWOAInstitution">
                <Header step={step} />
                <div className={cn('WOACountry', { disabledBlock: noAffiliations })}>
                    <div className="WOACountry-Text">
                        {l('ARTICLE_AFFILIATION_EDITOR.SELECT_WOA_INSTITUTION_COUNTRY_TEXT')}
                    </div>
                    <CountryPicker
                        changeHandler={this.onChangeCountry}
                        disabled={noAffiliations}
                        hideNoneValue
                        isFunderCountries
                        placeholder="Select Country"
                        selectedItem={selectedCountry}
                        seleniumid="country"
                    />
                </div>
                <div
                    className={cn('WOAInstitution', {
                        disabledBlock: noAffiliations || !selectedCountry?.id || institutionList.length === 0,
                    })}
                >
                    <div className="WOAInstitution-Text">
                        {l('ARTICLE_AFFILIATION_EDITOR.SELECT_WOA_INSTITUTION_AFFILIATION_TEXT')}
                    </div>
                    <InstitutionPicker
                        isDisabled={noAffiliations || !selectedCountry?.id || institutionList.length === 0}
                        onSelectChange={this.onSelectChange}
                        options={institutionList}
                        selectedItem={selectedItem}
                        seleniumid="institution-picker"
                    />
                </div>
                <NoAffiliations
                    noAffiliations={noAffiliations}
                    onNoAffiliationsChange={this.onNoAffiliationsChange}
                    step={step}
                />
            </div>
        );
    }
}

export default withCodes(SelectWOAInstitutionPanel, ID.ARTICLE_AFFILIATION_EDITOR);
