import React, { Component } from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import ContentUtils from 'app/blocks/common/content-utils';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import Alerts from 'app/blocks/common/spinner';
import Layout from 'app/blocks/Layout/Layout';
import { consumeAnalyticsContext } from './withAnalytics';

export default function withPreloading(WrappedComponent, { dataMapping, loadDataFunction, showSpinner = true }) {
    class WithPreloading extends Component {
        state = {
            data: undefined,
            errorLoading: null,
            isLoading: true,
        };

        componentDidMount() {
            this.fetchData();
        }

        fetchData = async () => {
            if (showSpinner) {
                Alerts.renderSpinner();
            }
            try {
                const data = await loadDataFunction();
                this.setState({
                    data: dataMapping ? dataMapping(data) : { panelData: data },
                    errorLoading: null,
                    isLoading: false,
                });
                this.props.onLoad();
            } catch (error) {
                this.props.onLoad(error);
                const { l } = this.props;
                const errorMsg = await ContentUtils.getServerErrorMessage(ID.PROFILE_ACCOUNT, error, error.payload);

                showDialog.error(error, { message: errorMsg });
                this.setState({
                    errorLoading: {
                        ...error,
                        message: l('PROFILE_ACCOUNT.errorMessages.UNABLE_TO_LOAD_CONTENT'),
                    },
                    isLoading: false,
                });
            } finally {
                if (showSpinner) {
                    Alerts.removeSpinner();
                }
            }
        };

        render() {
            const { data, errorLoading, isLoading } = this.state;

            if (isLoading || errorLoading) {
                return <Layout className="m-top_mini" error={errorLoading} isLoading={isLoading} />;
            }

            return <WrappedComponent {...this.props} {...data} />;
        }
    }

    return consumeAnalyticsContext(withCodes(WithPreloading, ID.PROFILE_ACCOUNT));
}
