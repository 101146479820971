import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { confirmAffiliationEditing, EditorAffiliation } from 'app/blocks/article-affiliations-editor/utils';
import { LinkButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import EligibilityMessage from 'app/blocks/EligibilityMessage/EligibilityMessage';
import InstitutionPicker from 'app/blocks/institution-picker/institution-picker';
import PromptAction from 'app/blocks/Prompt/_action/prompt_action';
import { ItemTitle } from 'app/blocks/titles/titles';
import Address from 'app/ui/as-ui-components/address/Address';

import './confirmedInstitution.scss';

function mapInstToPickerItem(affiliation) {
    return (
        affiliation && {
            id: affiliation.institutionId,
            name: affiliation.institutionName,
            custom: affiliation.custom,
        }
    );
}

type Props = {
    affiliation: EditorAffiliation;
    articleId: string;
    blacklist: string[];
    cancelableAuthorPaidOrderExists: boolean;
    institutionsIdFromProfile: string[];
    onChange: (id: string, item: any) => void;
    onDelete: (id: string) => void;
    l: l;
    seleniumid: string;
    zeroPriceOrderExists?: boolean;
};

class ConfirmedItem extends Component<Props, { editMode: boolean }> {
    state = {
        editMode: false,
    };

    onCancelEditAffiliation = e => {
        e.target.blur();
        this.setState({ editMode: false });
    };

    onEditAffiliation = async e => {
        e.target.blur();

        const { affiliation } = this.props;

        if (await confirmAffiliationEditing(affiliation.institutionName, affiliation.eligibleForOO)) {
            this.setState({ editMode: true });
        }
    };

    eligibilityMessage() {
        const { l, affiliation, articleId, cancelableAuthorPaidOrderExists, zeroPriceOrderExists } = this.props;

        if (affiliation.hideEligibilityMessage) return null;
        if (!(affiliation.eligibleForOO || affiliation.eligibleForInstitutionalDiscount)) return null;

        return (
            <EligibilityMessage
                className="ConfirmedInstitution-EligibleMessage"
                message={
                    <>
                        <div className="ConfirmedInstitution-EligibleMessage-Text">
                            {l(
                                affiliation.eligibleForInstitutionalDiscount
                                    ? 'ARTICLE_AFFILIATION_EDITOR.DISCOUNT_ELIGIBLE_AFFILIATION_ICON_MESSAGE'
                                    : 'ARTICLE_AFFILIATION_EDITOR.ELIGIBLE_AFFILIATION_ICON_MESSAGE',
                            )}
                        </div>
                        {!!(zeroPriceOrderExists || cancelableAuthorPaidOrderExists) && (
                            <div className="ConfirmedInstitution-EligibleMessage-AuthorPaidOrderExistsWarning mt-base">
                                {zeroPriceOrderExists ? (
                                    // eslint-disable-next-line react/jsx-no-target-blank
                                    <a href="https://support.wiley.com/s/" target="_blank">
                                        {l('ARTICLE_AFFILIATION_EDITOR.ZERO_PRICE_ORDER_EXISTS')}
                                    </a>
                                ) : (
                                    <Link to={`/orders/focusOnArticle/${articleId}`}>
                                        {l('ARTICLE_AFFILIATION_EDITOR.CANCELABLE_AUTHOR_PAID_ORDER_EXISTS')}
                                    </Link>
                                )}
                            </div>
                        )}
                    </>
                }
                title={l(
                    affiliation.eligibleForInstitutionalDiscount
                        ? 'ARTICLE_AFFILIATION_EDITOR.DISCOUNT_ELIGIBLE_TITLE'
                        : 'ARTICLE_AFFILIATION_EDITOR.OPEN_ACCESS',
                )}
            />
        );
    }

    render() {
        const {
            l,
            affiliation,
            blacklist,
            seleniumid,
            institutionsIdFromProfile,

            onChange,
            onDelete,
        } = this.props;

        const verifiedClass = `ConfirmedInstitution-Verification--${affiliation.custom ? 'notVerified' : 'verified'}`;
        const verifiedCode = affiliation.custom ? 'NOT_VERIFIED' : 'VERIFIED';

        return (
            <li
                className={`ConfirmedInstitution ${affiliation.custom ? 'ConfirmedInstitution--custom' : ''}`}
                data-seleniumid={`${seleniumid}-confirmed-institution-item`}
            >
                <ItemTitle className={`ConfirmedInstitution-Verification ${verifiedClass}`}>
                    <span data-seleniumid="institution-verification">
                        {l(`ARTICLE_AFFILIATION_EDITOR.AFFILIATION_${verifiedCode}_TEXT`)}
                    </span>
                    {!affiliation.custom && <SvgIcon.ok iconTitle={verifiedCode} style={{ marginRight: '15px' }} />}
                    <PromptAction
                        html={l(`ARTICLE_AFFILIATION_EDITOR.AFFILIATION_${verifiedCode}_PROMPT_TEXT`)}
                        seleniumid={`${seleniumid}-institution-item-verification__prompt_icon`}
                    />
                </ItemTitle>
                <div className="ConfirmedInstitution-Card">
                    {!this.state.editMode ? (
                        <div className="ConfirmedInstitution-Header">
                            <h3
                                className="ConfirmedInstitution-Title"
                                data-seleniumid={`${seleniumid}-institution-name`}
                            >
                                {affiliation.institutionName}
                            </h3>
                            <p className="ConfirmedInstitution-Text">
                                <Address data={affiliation} seleniumId={seleniumid} />
                            </p>
                        </div>
                    ) : (
                        <div className="ConfirmedInstitution-InstitutionPicker">
                            <InstitutionPicker
                                blacklist={blacklist.filter(instId => instId !== affiliation.institutionId)}
                                changeHandler={institution => {
                                    onChange(affiliation.id, institution);
                                    this.setState({ editMode: false });
                                }}
                                institutionsIdFromProfile={institutionsIdFromProfile}
                                placeholder={l('ARTICLE_AFFILIATION_EDITOR.PICKER_PLACEHOLDERS.SEARCH')}
                                selectedItem={mapInstToPickerItem(affiliation)}
                                seleniumid={`${seleniumid}-institution`}
                            />
                        </div>
                    )}
                    <div className="ConfirmedInstitution-Buttons">
                        {this.state.editMode ? (
                            <LinkButton
                                className="article_affiliation__cancel"
                                data-seleniumid={`${seleniumid}-institution-cancel`}
                                isBlack
                                onClick={this.onCancelEditAffiliation}
                            >
                                <SvgIcon.cancel iconTitle="Cancel Icon" />
                                Cancel
                            </LinkButton>
                        ) : (
                            <LinkButton
                                className="article_affiliation__edit"
                                data-seleniumid={`${seleniumid}-institution-edit`}
                                isBlack
                                onClick={this.onEditAffiliation}
                            >
                                <SvgIcon.edit iconTitle="Edit Icon" />
                                Edit
                            </LinkButton>
                        )}
                        <LinkButton
                            className="article_affiliation__delete"
                            data-seleniumid={`${seleniumid}-institution-delete`}
                            isBlack
                            onClick={() => onDelete(affiliation.id)}
                        >
                            <SvgIcon.delete iconTitle="Delete Icon" />
                            Delete
                        </LinkButton>
                    </div>
                </div>

                {this.eligibilityMessage()}
            </li>
        );
    }
}

export default withCodes(ConfirmedItem, ID.ARTICLE_AFFILIATION_EDITOR);
