import { v4 as uuidv4 } from 'uuid';
import { lAsync } from 'app/blocks/common/codes';
import { confirm, confirmType } from 'app/blocks/Modal/utils';
import ConfirmationDialog from './article-affiliations-panel/Institutions/Dialog/ConfirmationDialog';

type EditorAffiliation = ArticleAffiliation & {
    confirmed: boolean;
    emailMatched?: string;
    hideEligibilityMessage?: boolean;
    id: string;
    suggestions?: Institution[];
};

async function confirmAffiliationDeletion(name: string, isEligible: boolean) {
    if (isEligible) {
        return confirmType(ConfirmationDialog, {
            message: await lAsync('ARTICLE_AFFILIATION_EDITOR.DELETE_ELIGIBLE_CONFIRMATION_TEXT', { name }),
            cancelBtnLabel: await lAsync('ARTICLE_AFFILIATION_EDITOR.DELETE_ELIGIBLE_BTN_REJECT'),
            confirmBtnLabel: await lAsync('ARTICLE_AFFILIATION_EDITOR.DELETE_ELIGIBLE_BTN_CONFIRM'),
        });
    }

    return confirm(await lAsync('ARTICLE_AFFILIATION_EDITOR.DELETE_CONFIRMATION_TEXT', { name }));
}

async function confirmAffiliationEditing(name: string, isEligible: boolean) {
    if (isEligible) {
        return confirmType(ConfirmationDialog, {
            message: await lAsync('ARTICLE_AFFILIATION_EDITOR.EDIT_ELIGIBLE_CONFIRMATION_TEXT', { name }),
            cancelBtnLabel: await lAsync('ARTICLE_AFFILIATION_EDITOR.EDIT_ELIGIBLE_BTN_REJECT'),
            confirmBtnLabel: await lAsync('ARTICLE_AFFILIATION_EDITOR.EDIT_ELIGIBLE_BTN_CONFIRM'),
        });
    }

    return true;
}

function prepareAffiliationsForEditor(
    affiliations: ArticleAffiliation[] | EditorAffiliation[],
    suggestions?: {
        matches?: Record<string, Institution[]>;
        emailSuggestions?: Array<Institution & { emailMatched: string }>;
    },
    confirmed: boolean = false,
): EditorAffiliation[] {
    let affList: EditorAffiliation[] =
        affiliations.map(aff => {
            const custom = !aff.institutionId ? true : aff.custom;
            return {
                id: aff.id || uuidv4(),
                ...aff,
                custom,
                // eslint-disable-next-line no-nested-ternary
                confirmed: aff.submissionData ? (custom ? confirmed : true) : true,
            };
        }) || [];

    if (!confirmed && suggestions && suggestions.emailSuggestions) {
        // @ts-ignore
        // FIXME
        affList = affList.concat(suggestions.emailSuggestions);
    }

    if (!confirmed && suggestions && suggestions.matches) {
        const affiliationsMatches = suggestions.matches;
        affList.forEach(aff => {
            // eslint-disable-next-line no-param-reassign
            aff.suggestions = affiliationsMatches[aff.affiliationId];
        });
    }

    return affList;
}

function isEmailSuggestion(
    item: EditorAffiliation | (Institution & { emailMatched: string }),
): item is Institution & { emailMatched: string } {
    return !!item.emailMatched;
}

export {
    EditorAffiliation,
    confirmAffiliationDeletion,
    confirmAffiliationEditing,
    isEmailSuggestion,
    prepareAffiliationsForEditor,
};
