import * as middleware from 'app/blocks/middleware/middleware';

import { REST, GET as DEFAULT_GET } from './utils';

const { GET, POST, PUT } = REST('/userprofile');

export const getAlerts = (): Promise<{ alerts: Alert[]; emails: string[]; notificationEmail: string }> =>
    GET('/alerts/');

export const saveAlerts = (alerts): Promise<boolean> => POST('/alerts/', alerts);

export const securityQuestions = (
    email: string,
): Promise<{
    securityDetails: Array<{ securityQuestion: string; securityQuestionId: string }>;
}> => DEFAULT_GET(`/user/securityQuestions/${encodeURIComponent(email)}/`);

export const getProfileInformation = async (): Promise<Profile> => {
    const response = await GET('/profileInfo/');
    const appinfo = await middleware.appinfo.get();

    response.profileRedirectionURL = appinfo.properties['extsvc.url.profileUrl'];
    return response;
};

export const getAffiliations = (): Promise<Affiliation[]> => GET('/affiliations/');

export const saveAffiliation = (id: string, affiliation): Promise<void> =>
    id ? PUT(`/affiliations/${id}/`, affiliation) : POST('/affiliations/', affiliation);
