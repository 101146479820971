import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { compose } from 'app/blocks/common/utils';
import './ArticleForConfirmationPage.scss';

function ArticleForConfirmationPage({ article, className, l, journal }) {
    return (
        <div className={cn('articleForConfirmationPage', className)}>
            <div className="articleForConfirmationPage-header">
                {l('LICENSE_SIGNING.CONFIRMATION.TITLE.ARTICLE_DETAILS')}
            </div>
            <div className="articleForConfirmationPage-journalName">{journal.name}</div>
            <div className="articleForConfirmationPage-name">{article.name}</div>
            <div className="articleForConfirmationPage-doi">
                DOI:
                {article.doi}
            </div>
        </div>
    );
}

ArticleForConfirmationPage.propTypes = {
    article: PropTypes.shape({
        name: PropTypes.string,
        doi: PropTypes.string,
    }).isRequired,
    className: PropTypes.string,
    l: PropTypes.func.isRequired,
    journal: PropTypes.shape({
        name: PropTypes.string,
    }).isRequired,
};

ArticleForConfirmationPage.defaultProps = {
    className: undefined,
};

export default compose(withCodes2(ID.LICENSE_SIGNING), React.memo)(ArticleForConfirmationPage);
