import React from 'react';

function PasswordStrength({ color, text }: { color: string; text: string }): React.ReactElement {
    return (
        <div className="password-strength small mt-two-thirds">
            <span className="password-strength_label">Password strength: </span>
            <span className="password-strength_value" style={{ color }}>
                <b>{text}</b>
            </span>
        </div>
    );
}

export default PasswordStrength;
