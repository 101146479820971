/* eslint-disable react/forbid-prop-types */

import PropTypes from 'prop-types';
import React from 'react';
import Layout from 'app/blocks/Layout/Layout';
import PageContainer from './PageContainer';

class PageLayout extends React.PureComponent {
    static propTypes = {
        title: PropTypes.string,
        children: PropTypes.node,

        errorMsg: PropTypes.string,
        isLoadingContent: PropTypes.bool,
    };

    static defaultProps = {
        title: null,
        children: null,
        errorMsg: null,
        isLoadingContent: false,
    };

    render() {
        const { children, title, errorMsg, isLoadingContent, seleniumid } = this.props;

        return (
            <PageContainer seleniumid={seleniumid} title={title}>
                <Layout className="lg-2x" errorMsg={errorMsg} isLoading={isLoadingContent}>
                    {children}
                </Layout>
            </PageContainer>
        );
    }
}

export default PageLayout;
