import React from 'react';
import { MainTitle } from 'app/blocks/titles/titles';

type Props = {
    title?: string;
    children: React.ReactNode;
    seleniumid?: string;
};

function PageContainer({ children, title, seleniumid }: Props) {
    return (
        <div className="container">
            <div className="col">
                {title && (
                    <MainTitle className="mb-0" data-seleniumid={seleniumid}>
                        {title}
                    </MainTitle>
                )}
                {children}
            </div>
        </div>
    );
}

export default PageContainer;
