import roles from 'app/blocks/AuthorRoleEnum';

const doesAuthorHaveCorrespondingPrimaryRole = participantRole => {
    return (
        participantRole.primaryRole === roles.RESPONSIBLE_CORRESPONDING_AUTHOR ||
        participantRole.primaryRole === roles.CORRESPONDING_AUTHOR
    );
};

export default doesAuthorHaveCorrespondingPrimaryRole;
