import React, { Component } from 'react';
import { Button } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import DialogMessage from 'app/blocks/dialog/dialog__message';
import UserExistsDialogUser from './user-exists-dialog__user';

type Props = {
    l: l;
    onLogin: () => void;
    onRegisterNewAccount: (user: Registration.ExistedUser) => void;
    users: Registration.ExistedUser[];
};

type State = {
    selectedUser: Registration.ExistedUser;
};

class UserExistsDialog extends Component<Props, State> {
    state: State = {
        selectedUser: this.props.users[0],
    };

    selectUser = user => {
        this.setState({
            selectedUser: user,
        });
    };

    onClickRegisterNew = () => {
        const { onRegisterNewAccount } = this.props;
        const { selectedUser } = this.state;
        onRegisterNewAccount(selectedUser);
    };

    onUserKeyPress = (user, event) => {
        if (event.key === 'Enter') {
            this.selectUser(user);
        }
    };

    renderUser = (user, key) => {
        const { selectedUser } = this.state;

        return (
            <div
                key={key}
                aria-checked={user === selectedUser}
                className="userExists-userItem btm-line"
                onClick={() => this.selectUser(user)}
                onKeyPress={e => this.onUserKeyPress(user, e)}
                role="menuitemradio"
                tabIndex={0}
            >
                <div className="userExists-userIcon">
                    <SvgIcon.person
                        className="register-person-icon register-person-icon--gray"
                        iconDescr="Person Icon"
                        iconTitle="Person Icon"
                    />
                </div>
                <div className="userExists-userData reg_account">
                    <p>
                        {user.title && <span>{user.title} </span>}
                        {user.firstName && <span>{user.firstName} </span>}
                        {user.middleName && <span>{user.middleName} </span>}
                        {user.lastName && <span>{user.lastName} </span>}
                        {user.suffix && <span>{user.suffix}</span>}
                    </p>
                    <p>{user.primaryEmailAddr}</p>
                </div>
            </div>
        );
    };

    render() {
        const { l, users, onLogin } = this.props;
        const { selectedUser } = this.state;

        return (
            <div className="register-alternate-modal">
                <div className="modal-body Dialog-Body" data-seleniumid="modal-body">
                    <DialogMessage
                        innerContent={
                            /* eslint-disable-next-line react/jsx-wrap-multilines */
                            <div>
                                <div data-seleniumid="register-alternate-message-label">
                                    {l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_SELECT_ONE_ACCNT_LABEL`)}
                                </div>
                                <div className="userExists-selection">
                                    <div className="userExists-usersList">
                                        <div className="control_account" role="menu">
                                            {users.map(this.renderUser)}
                                        </div>
                                    </div>
                                    <UserExistsDialogUser user={selectedUser} />
                                </div>
                            </div>
                        }
                        type="register-alternate"
                    />
                    <div className="Dialog-BodyActions Dialog-BodyActions-Row">
                        <Button data-seleniumid="reg-userinfo-reg-btn" onClick={this.onClickRegisterNew}>
                            {l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_REGISTER_NEW_ACCNT_BUTTON_LABEL`)}
                        </Button>
                        <Button data-seleniumid="reg-userinfo-login-btn" onClick={onLogin}>
                            {l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_LOGIN_BUTTON_LABEL`)}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withCodes(UserExistsDialog, ID.REGISTRATION);
