import cn from 'classnames';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { compose } from 'app/blocks/common/utils';
import validation from 'app/blocks/common/validation';
import ErrorLabel from 'app/blocks/ErrorLabel/ErrorLabel';
import { SubTitle } from 'app/blocks/titles/titles';
import OrdersPanelEditAddress from 'app/pages/orders/panel/edit-address';
import './ContactPanel.scss';
import RadioButton from 'app/ui/form/RadioButton';
import withContactPanelContainer from './withContactPanelContainer';

function ContactPanel(props) {
    const {
        addressesProvider,
        l,
        mainAddress,
        onMainAddressFieldFocus,
        onSelectOption,
        updateMainAddress,
        validationResults,
    } = props;

    return (
        <section
            className={cn('payment-panel ContactPanel', {
                'payment-panel--error': !validation.isAllKeysFalseOrEmpty(get(validationResults, 'mainAddress')),
            })}
            data-seleniumid="ContactPanel-billingDetail"
        >
            <SubTitle className="ContactPanel-Title">{l('LICENSE_SIGNING.PAYMENT.TITLE.BILLING_DETAIL')}</SubTitle>

            <p className="ContactPanel-Content" data-seleniumid="ContactPanel-billingContent">
                {l('LICENSE_SIGNING.PAYMENT.CONTENT.BILLING_DTL_CONTNT')}
            </p>

            <div className="ContactPanel-SubTitle">
                <p>
                    <strong data-seleniumid="ContactPanel-WhoPay">{l('LICENSE_SIGNING.PAYMENT.TITLE.WHO_PAY')}</strong>
                    <span className="text_red">{l('LICENSE_SIGNING.FUNDERS.LABEL.POSTFIX_REQUIRED')}</span>
                </p>
            </div>
            <div className="ContactPanel-ButtonSpace">
                <RadioButton
                    checked={mainAddress.billToType === 'INDIVIDUAL'}
                    data-seleniumid="address-individual-radio-input"
                    id="order-individual-payment-radio"
                    label={l('LICENSE_SIGNING.PAYMENT.LABEL.INDIVIDUAL')}
                    labelStyle={{ flex: 'none' }}
                    name="payType"
                    onChange={onSelectOption}
                    style={{ height: '40px' }}
                    value="INDIVIDUAL"
                />
                <RadioButton
                    checked={mainAddress.billToType === 'ORGANIZATION'}
                    data-seleniumid="address-organization-radio-input"
                    id="order-organization-payment-radio"
                    label={l('LICENSE_SIGNING.PAYMENT.LABEL.ORGANIZATION')}
                    labelStyle={{ flex: 'none' }}
                    name="payType"
                    onChange={onSelectOption}
                    style={{ height: '40px' }}
                    value="ORGANIZATION"
                />
            </div>
            <SubTitle className="ContactPanel-Content" data-seleniumid="ContactPanel-Billing">
                {l('LICENSE_SIGNING.PAYMENT.TITLE.BILLING')}
            </SubTitle>

            <div className="ContactPanel-Content">
                <p data-seleniumid={`ContactPanel-Billing-${mainAddress.billToType || 'INDIVIDUAL'}`}>
                    {mainAddress.billToType === 'INDIVIDUAL'
                        ? l('LICENSE_SIGNING.PAYMENT.CONTENT.INDIVIDUAL_PAY')
                        : l('LICENSE_SIGNING.PAYMENT.CONTENT.ORGANIZATION_PAY')}
                </p>
            </div>

            {!validation.isAllKeysFalseOrEmpty(get(validationResults, 'mainAddress')) && (
                <ErrorLabel text="Address validation error" />
            )}

            <OrdersPanelEditAddress
                address={mainAddress}
                handleAddressChange={updateMainAddress}
                isOpenAccessOrder={false}
                onFieldFocus={onMainAddressFieldFocus}
                panelName="ContactPanel"
                seleniumid="main-address"
                supportDataProvider={addressesProvider}
                validationResults={get(validationResults, 'mainAddress')}
            />
        </section>
    );
}

ContactPanel.propTypes = {
    addressesProvider: PropTypes.shape({}).isRequired,
    l: PropTypes.func.isRequired,
    mainAddress: PropTypes.shape({}).isRequired,
    onMainAddressFieldFocus: PropTypes.func.isRequired,
    updateMainAddress: PropTypes.func.isRequired,
    validationResults: PropTypes.shape({
        mainAddress: PropTypes.shape({}),
        message: PropTypes.string,
    }),
};

ContactPanel.defaultProps = {
    validationResults: undefined,
};

export default compose(
    withContactPanelContainer,
    withCodes2(ID.ORDER_PANELS, ID.LICENSE_SIGNING, ID.TOOLTIP),
)(ContactPanel);
