import React from 'react';

const JournalDiscounts: React.FC<{ discounts: string[] }> = ({ discounts }) => {
    return (
        <div className="discountPanel-availableDiscounts" data-seleniumid="discounts-available">
            <ul className="discountPanel-availableDiscountsList">
                {discounts.map(discount => (
                    <li dangerouslySetInnerHTML={{ __html: discount }} key={discount} />
                ))}
            </ul>
        </div>
    );
};

export default JournalDiscounts;
