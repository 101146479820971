import React, { Fragment } from 'react';

type Props = {
    data: Pick<Address, 'city' | 'countryCode' | 'countryName' | 'stateCode' | 'stateName'> & { country?: string };
    seleniumId: string;
};

function Address({ data, seleniumId }: Props) {
    const addressFields = [
        {
            fieldName: 'city',
            fieldValue: data.city,
        },
        {
            fieldName: 'state',
            fieldValue: data.stateName || data.stateCode,
        },
        {
            fieldName: 'country',
            fieldValue: data.countryCode || data.countryName || data.country,
        },
    ];

    return (
        <>
            {addressFields
                .filter(field => field.fieldValue)
                .map((field, index, fields) => {
                    const item = <span data-seleniumid={`${seleniumId}-${field.fieldName}`}>{field.fieldValue}</span>;
                    return index < fields.length - 1 ? (
                        <Fragment key={field.fieldName}>{item}, </Fragment>
                    ) : (
                        <Fragment key={field.fieldName}>{item}</Fragment>
                    );
                })}
        </>
    );
}

export default Address;
