import escape from 'lodash.escape';
import memoize from 'memoize-one';
import { v4 as uuidv4 } from 'uuid';
import AuthorRoleEnum from 'app/blocks/AuthorRoleEnum';
import { RcaInfo } from 'app/blocks/middleware/dashboard';
import RcaStatusEnum from 'app/pages/dashboard/RcaStatusEnum';

const START = uuidv4();
const END = uuidv4();

export function getPublicationStatus({
    published,
    rcaInfo,
    withdrawn,
}: {
    published?: boolean;
    rcaInfo: RcaInfo;
    withdrawn?: boolean;
}) {
    if (rcaInfo.rcaStatus === RcaStatusEnum.AUTHOR_LIST_IS_INCORRECT) {
        return 'AUTHOR_LIST_IS_INCORRECT';
    }
    if (rcaInfo.rcaStatus === RcaStatusEnum.RCA_CONFIRMATION_NEEDED && !rcaInfo.isEeoCaMe) return 'WAITING_CA_ACTION';
    if (withdrawn) return 'WITHDRAWN';
    if (published) return 'PUBLISHED';
    return 'IN_PRODUCTION';
}

// Returned value will be ESCAPED
export const highlight = memoize((str: string = '', words: string[] = []): string => {
    if (!words || !words.length) {
        return escape(str);
    }

    const highlighted = (str || '').replace(
        new RegExp(`(${words.sort((a, b) => b.length - a.length).join('|')})`, 'ig'),
        ($0, $1) => `${START}${$1}${END}`,
    );

    // We should escape after highlight - otherwise we will escape html entities as well
    // But we still can highlight before escape since only letters should be in the words

    return escape(highlighted)
        .replace(new RegExp(START, 'g'), '<span class="highlight">')
        .replace(new RegExp(END, 'g'), '</span>');
});

function inStr(s1: string, s2: string): boolean {
    return (s1 || '').toLowerCase().includes((s2 || '').toLowerCase());
}

export function isMatchedByWords(articleName: string, journalName: string, words: string[]): boolean {
    let found = 0;

    words.forEach(word => {
        if (inStr(articleName, word) || inStr(journalName, word)) {
            found += 1;
        }
    });

    return found === words.length;
}

export function isBlocked({ rcaInfo }: { rcaInfo: RcaInfo }): boolean {
    return (
        rcaInfo.rcaStatus === RcaStatusEnum.AUTHOR_LIST_IS_INCORRECT ||
        (rcaInfo.rcaStatus === RcaStatusEnum.RCA_CONFIRMATION_NEEDED && !rcaInfo.isEeoCaMe)
    );
}

export function canDoRequiredActions({
    rcaInfo,
    participantRole,
}: {
    rcaInfo: RcaInfo;
    participantRole: ParticipantRole;
}): boolean {
    return (
        !isBlocked({ rcaInfo }) &&
        (participantRole.additionalRoles.includes(AuthorRoleEnum.RESPONSIBLE_CORRESPONDING_AUTHOR) ||
            (rcaInfo.rcaStatus === RcaStatusEnum.RCA_CONFIRMATION_NEEDED && rcaInfo.isEeoCaMe))
    );
}

export default { getPublicationStatus, highlight };
