import PropTypes from 'prop-types';
import React from 'react';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import NavigationWidget from 'app/blocks/page/NavigationWidget/NavigationWidget';
import './StepsNavigation.scss';

function StepsNavigation({ activeStep, activeSubStep, steps, title }) {
    const renderItemChildren = item => {
        let name = '';

        if (item.id === activeStep && item.substeps?.[activeSubStep]) {
            name = item.substeps[activeSubStep];
        } else {
            name = item.name;
        }

        return (
            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                {name}
                {item.completed && <SvgIcon.ok iconTitle="Completed Step" />}
            </div>
        );
    };

    return (
        <NavigationWidget
            active={activeStep}
            className="stepsNavigation"
            items={steps}
            renderItemChildren={renderItemChildren}
            seleniumid="steps-navigation"
            title={title}
        />
    );
}

StepsNavigation.propTypes = {
    activeStep: PropTypes.string.isRequired,
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            completed: PropTypes.bool,
            seleniumid: PropTypes.string,
        }),
    ).isRequired,
};

export default StepsNavigation;
