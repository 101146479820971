import cn from 'classnames';
import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import withResponsive from 'app/blocks/common/responsive-decorator';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { compose, strToBool } from 'app/blocks/common/utils';
import StaticContent from 'app/blocks/static-content/static-content';
import { ItemTitle } from 'app/blocks/titles/titles';
import LicenseTypeEnum from 'app/pages/license/LicenseTypeEnum';
import RadioButton from 'app/ui/form/RadioButton';
import { LICENSE_ICONS } from './utils';

import './LicenseSelectionGrid.scss';

function DisallowIcon() {
    return <div className="DisallowIcon" />;
}

type LicenseType = Values<typeof LicenseTypeEnum>;

type Props = {
    className: string;
    config: {
        columnOrder: { [key in LicenseType]: string };
        items: {
            [key: string]: {
                description: string;
                permissions: { [key in LicenseType]: 'true' | 'false' };
            };
        };
    };
    l: l;
    licenseTypes: Array<{ value: LicenseType }>;
    onLicenseTypeChange: (value: LicenseType) => void;
    selectedLicenseType: LicenseType;
    isDesktop: boolean;
    isConflicted: boolean;
};

class LicenseSelectionGrid extends React.PureComponent<Props> {
    renderIconsList(value) {
        const icons = LICENSE_ICONS[value];

        return (
            icons && (
                <div className="mt-base">
                    {icons.map((Icon, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Icon key={index} className={cn({ 'ml-one-thirds': index !== 0 })} />
                    ))}
                </div>
            )
        );
    }

    renderHeaderFooterCell({ value }, licenseType, isHeader) {
        const { l, onLicenseTypeChange } = this.props;
        const shortName = l(`LICENSE.${value}.shortName`);

        const Component = props => (isHeader ? <th {...props} /> : <td {...props} />);

        return (
            <Component key={value} className={cn({ ' LicenseSelectionGrid--selectedCell': value === licenseType })}>
                {isHeader && (
                    <>
                        <a href={l(`LICENSE.${value}.url`)} rel="noopener noreferrer" target="_blank">
                            {shortName}
                        </a>

                        {this.renderIconsList(value)}

                        <div className="mt-base">{l(`LICENSE.${value}.name`)}</div>
                    </>
                )}

                <div
                    style={
                        isHeader
                            ? { position: 'absolute', bottom: 15, left: 15, right: 15, wordBreak: 'break-word' }
                            : undefined
                    }
                >
                    <div>
                        <label
                            data-seleniumid={`licenseType-${value}`}
                            htmlFor={`${value}${isHeader ? 'header' : 'footer'}`}
                            style={{ margin: 0, wordBreak: 'break-all' }}
                        >
                            <RadioButton
                                checked={value === licenseType}
                                data-seleniumid={`radioButton-${value}`}
                                id={`${value}${isHeader ? 'header' : 'footer'}`}
                                label={`Choose ${shortName}`}
                                onChange={() => onLicenseTypeChange(value)}
                                value={value}
                            />
                        </label>
                    </div>
                </div>
            </Component>
        );
    }

    renderBodyRow(item, index, licenseType, licenseTypes) {
        const { l, isConflicted } = this.props;

        return (
            <tr key={item.description}>
                <td
                    dangerouslySetInnerHTML={{
                        __html: l(`LICENSE_SELECTION_GRID.items.${index}.description`, {
                            asterisk: isConflicted ? '*' : '',
                        }),
                    }}
                />
                {licenseTypes.map(({ value }) => {
                    return (
                        <td
                            key={value}
                            className={cn({ ' LicenseSelectionGrid--selectedCell': value === licenseType })}
                        >
                            {strToBool(item.permissions[value]) ? <SvgIcon.ok /> : <DisallowIcon />}
                        </td>
                    );
                })}
            </tr>
        );
    }

    render() {
        const { className, config, licenseTypes, selectedLicenseType, isDesktop } = this.props;
        const sortedLicenseTypes = [...licenseTypes].sort(
            (a, b) =>
                Number.parseInt(config.columnOrder[a.value], 10) - Number.parseInt(config.columnOrder[b.value], 10),
        );

        return isDesktop ? (
            <table className={cn(' LicenseSelectionGrid', className)}>
                <thead>
                    <tr>
                        <th />
                        {sortedLicenseTypes.map(item => this.renderHeaderFooterCell(item, selectedLicenseType, true))}
                    </tr>
                </thead>
                <tbody>
                    {Object.values(config.items).map((item, index) =>
                        this.renderBodyRow(item, index, selectedLicenseType, sortedLicenseTypes),
                    )}
                </tbody>
                <tfoot>
                    <tr>
                        <td />
                        {sortedLicenseTypes.map(item => this.renderHeaderFooterCell(item, selectedLicenseType, false))}
                    </tr>
                </tfoot>
            </table>
        ) : (
            <div className="LicenseType">
                {licenseTypes.map(({ value }) => {
                    const radioLabel = (
                        <ItemTitle
                            className={cn({ text_bold: value === selectedLicenseType })}
                            style={{ display: 'inline-flex', alignItems: 'center' }}
                        >
                            {this.props.l(`LICENSE.${value}.name`)}
                            <img
                                alt={value}
                                src={`/static/media/${value}.png`}
                                style={{ height: '23px', marginLeft: '5px' }}
                            />
                        </ItemTitle>
                    );

                    return (
                        <label
                            key={value}
                            className={cn('LicenseType-Option')}
                            data-seleniumid={`licenseType-${value}`}
                            htmlFor={value}
                        >
                            <RadioButton
                                checked={value === selectedLicenseType}
                                data-seleniumid={`radioButton-${value}`}
                                id={value}
                                label={radioLabel}
                                onChange={() => this.props.onLicenseTypeChange(value)}
                                value={value}
                            />
                            <StaticContent
                                className="LicenseTypes-valuePrompt"
                                seleniumid={`${value}-prompt`}
                                src={`license__${value}.html`}
                            />
                        </label>
                    );
                })}
            </div>
        );
    }
}

export const LicenseSelectionGridWrapped = compose(
    withCodes2(ID.LICENSE, ID.LICENSE_SELECTION_GRID),
    Component => props => <Component {...props} config={props.codes[ID.LICENSE_SELECTION_GRID]} />,
)(LicenseSelectionGrid);

export default compose(withResponsive)(LicenseSelectionGridWrapped);
