import React from 'react';
import { Button, LinkButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import DialogMessage from 'app/blocks/dialog/dialog__message';
import Modal from 'app/blocks/Modal/Modal';

function ConfirmChangeToSubscriptionModal(props) {
    const { l, onReject, onApprove } = props;

    return (
        <Modal
            className="Dialog"
            id="confirmChangeToSubscriptionModal"
            ignoreBackdropClicks
            onClose={onReject}
            type="confirmation"
        >
            <div className="modal-body Dialog-Body" data-seleniumid="modal-body">
                <DialogMessage
                    html={l('LICENSE_SIGNING.OPEN_ACCESS.CHANGE_TO_SUBSCRIPTION_POPUP.TEXT')}
                    type="confirmation"
                />
                <div className="Dialog-BodyActions Dialog-BodyActions-Column">
                    <Button
                        className="noStretch"
                        data-seleniumid="confirmation-dialog-cancel-button"
                        onClick={onReject}
                    >
                        {l('LICENSE_SIGNING.OPEN_ACCESS.CHANGE_TO_SUBSCRIPTION_POPUP.BUTTON_CANCEL')}
                    </Button>
                    <LinkButton data-seleniumid="confirmation-dialog-confirm-button" isUnderline onClick={onApprove}>
                        {l('LICENSE_SIGNING.OPEN_ACCESS.CHANGE_TO_SUBSCRIPTION_POPUP.BUTTON_CONFIRM')}
                    </LinkButton>
                </div>
            </div>
        </Modal>
    );
}
export { ConfirmChangeToSubscriptionModal };
export default withCodes2(ID.LICENSE_SIGNING)(ConfirmChangeToSubscriptionModal);
