import React from 'react';
import { Layout } from 'app/blocks/blocks';
import { processing } from 'app/blocks/common/decorators';

function withDiscountContainer(Component) {
    class DiscountContainer extends React.PureComponent {
        state = { isProcessing: true };

        @processing
        async componentDidMount() {
            const [hasAdditionalDiscounts] = await this.props.controller.hasAdditionalDiscountsPromise();

            this.setState({
                hasAdditionalDiscounts,
            });
        }

        render() {
            const { controller, discountState, ...props } = this.props;
            const { hasAdditionalDiscounts, isProcessing } = this.state;

            if (isProcessing) {
                return <Layout isLoading />;
            }

            return (
                <Component
                    {...props}
                    discountDetails={discountState.discountDetails}
                    getInstitutionsPromise={controller.getInstitutionsPromise}
                    handleInstitutionChange={controller.handleInstitutionChange}
                    handleOtherPromoCodeChange={controller.handleOtherPromoCodeChange}
                    handleSocietyPromoCodeChange={controller.handleSocietyPromoCodeChange}
                    hasAdditionalDiscounts={hasAdditionalDiscounts}
                    isValidating={discountState.isValidating}
                    journalId={discountState.journalId}
                />
            );
        }
    }

    return DiscountContainer;
}

export default withDiscountContainer;
