import cn from 'classnames';
import diff from 'deep-diff/index';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';

function getValue(order, initOrder, keyData, diffKeys) {
    let value = get(order, keyData);
    const removed = !value && diffKeys[keyData];

    if (removed) {
        value = get(initOrder, keyData);
    }

    return value;
}

function hasValue(order, initOrder, keyData, diffKeys) {
    return !_.isEmpty(getValue(order, initOrder, keyData, diffKeys));
}

function isRemoved(order, initOrder, keyData, diffKeys) {
    return !get(order, keyData) && diffKeys[keyData];
}

class OrderItemDescription extends React.Component {
    static propTypes = {
        auxInfo: PropTypes.shape({
            diffKeys: PropTypes.shape({}),
            initOrder: PropTypes.shape({}),
            order: PropTypes.shape({}),
        }).isRequired,
        beforeEdit: PropTypes.func,
        keyData: PropTypes.string.isRequired,
        postfix: PropTypes.string,
        prefix: PropTypes.string,
    };

    static defaultProps = {
        beforeEdit: x => x,
        postfix: '',
        prefix: '',
    };

    customize(value) {
        if (value !== null && value !== undefined && value !== '') {
            return this.props.prefix + this.props.beforeEdit(value) + this.props.postfix;
        }

        return null;
    }

    render() {
        try {
            const { order, initOrder, diffKeys } = this.props.auxInfo;
            const value = getValue(order, initOrder, this.props.keyData, diffKeys);

            return (
                <span
                    className={cn('textWrap', {
                        bluetxt: diffKeys[this.props.keyData] && (initOrder.correlationId || initOrder.ooUniqueId),
                        'removed-property': isRemoved(order, initOrder, this.props.keyData, diffKeys),
                    })}
                    data-seleniumid="order-review-funder-affilationNames"
                >
                    {this.customize(value)}
                </span>
            );
        } catch (error) {
            console.warn(error);

            return null;
        }
    }
}

function OrdersDataItem(props) {
    const titleState =
        props.innerInfo.some(innerItem =>
            hasValue(props.auxInfo.order, props.auxInfo.initOrder, innerItem[0], props.auxInfo.diffKeys),
        ) &&
        props.titleCls &&
        props.titleTxt;

    return (
        <div className={props.className}>
            {titleState && <div className={`${props.className}-Label`}>{props.titleTxt}</div>}
            <div className={titleState ? `${props.className}-Input` : ''} data-seleniumid={props.seleniumid}>
                {props.innerInfo.map(innerItem => (
                    <OrderItemDescription
                        key={innerItem[0]}
                        auxInfo={props.auxInfo}
                        beforeEdit={innerItem[3]}
                        keyData={innerItem[0]}
                        postfix={innerItem[2]}
                        prefix={innerItem[1]}
                    />
                ))}
            </div>
        </div>
    );
}

function getKeysByDiffOrders(order, initOrder) {
    const diffs = diff(initOrder, order) || [];
    // E - edit, N - new => https://www.npmjs.com/package/deep-diff
    const KINDS = 'EN';

    return diffs.reduce((diffKeys, diffItem) => {
        if (KINDS.indexOf(diffItem.kind) >= 0 && diffItem.path) {
            const path = diffItem.path.join('.');
            // eslint-disable-next-line no-param-reassign
            diffKeys[path] = true;
        }
        return diffKeys;
    }, {});
}

function calculateAuxInfo(order, initOrder) {
    let diffKeys = {};
    // check the data changing only for a saved order
    if (initOrder && (initOrder.correlationId || initOrder.existing)) {
        diffKeys = getKeysByDiffOrders(order, initOrder);
    }
    return {
        order,
        initOrder,
        diffKeys,
    };
}

function create(order, initOrder) {
    const auxInfo = calculateAuxInfo(order, initOrder);

    return {
        auxInfo,
        OrdersDataItem: props => <OrdersDataItem auxInfo={auxInfo} {...props} />,
    };
}

export default {
    create,
};
