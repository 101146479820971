import React from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import { eventContainsKey, KeyCode } from 'app/blocks/common/key-utils';
import PromptAction from 'app/blocks/Prompt/_action/prompt_action';
import { ItemTitle } from 'app/blocks/titles/titles';
import Address from 'app/ui/as-ui-components/address/Address';
import { Radio } from 'app/ui/form';
import './selectableAffiliation.scss';

type Props = {
    affiliation: ArticleAffiliation;
    isPrimary?: boolean;
    l: l;
    onSelectAffiliationAsPrimary: (affiliationId: string) => void;
    seleniumid: string;
};

function SelectableAffiliation({ affiliation, isPrimary = false, l, onSelectAffiliationAsPrimary, seleniumid }: Props) {
    return (
        <li
            className={`selectableAffiliation ${affiliation.custom ? 'selectableAffiliation--custom' : ''}`}
            data-seleniumid={`${seleniumid}-selectable-institution-item`}
            onClick={() => onSelectAffiliationAsPrimary(affiliation.affiliationId)}
            onKeyPress={event => {
                if (eventContainsKey(event, KeyCode.ENTER)) {
                    onSelectAffiliationAsPrimary(affiliation.affiliationId);
                }
            }}
            role="presentation"
        >
            <div className="selectableAffiliation-Radio">
                <Radio key={affiliation.affiliationId} checked={isPrimary} onChange={() => {}} />
            </div>
            <div className="selectableAffiliation-Card">
                <ItemTitle className="selectableAffiliation-Verification selectableAffiliation-Verification--verified">
                    <span>{l('ARTICLE_AFFILIATION_EDITOR.AFFILIATION_VERIFIED_TEXT')}</span>
                    <PromptAction
                        html={l('ARTICLE_AFFILIATION_EDITOR.AFFILIATION_VERIFIED_PROMPT_TEXT')}
                        seleniumid={`${seleniumid}-institution-item-verification__prompt_icon`}
                        stopPropogation
                    />
                </ItemTitle>
                <div className="selectableAffiliation-Header">
                    <h3 className="selectableAffiliation-Title" data-seleniumid={`${seleniumid}-institution-name`}>
                        {affiliation.institutionName}
                    </h3>
                    <p className="selectableAffiliation-Text">
                        <Address data={affiliation} seleniumId={seleniumid} />
                    </p>
                </div>
            </div>
        </li>
    );
}

export default withCodes(SelectableAffiliation, ID.ARTICLE_AFFILIATION_EDITOR);
