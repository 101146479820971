import React from 'react';
import { EditorAffiliation } from '../../utils';
import SelectableAffiliation from './SelectableAffiliation/SelectableAffiliation';

import './selectPrimaryAffiliation.scss';

type Props = {
    affiliations: EditorAffiliation[];
    onSelectAffiliationAsPrimary: (affiliationId: string) => void;
    selectedPrimaryAffiliationId?: string;
};

function SelectPrimaryAffiliation({ affiliations, onSelectAffiliationAsPrimary, selectedPrimaryAffiliationId }: Props) {
    let customIndex = 0;

    return (
        <ul className="selectPrimaryAffiliation">
            {affiliations.map(affiliation => {
                let seleniumid;

                if (affiliation.custom) {
                    customIndex += 1;
                    seleniumid = `custom-${customIndex}`;
                } else {
                    seleniumid = affiliation.institutionId;
                }

                const isPrimary = selectedPrimaryAffiliationId === affiliation.affiliationId;

                return (
                    <SelectableAffiliation
                        key={affiliation.id}
                        affiliation={affiliation}
                        isPrimary={isPrimary}
                        onSelectAffiliationAsPrimary={onSelectAffiliationAsPrimary}
                        seleniumid={seleniumid}
                    />
                );
            })}
        </ul>
    );
}

export default SelectPrimaryAffiliation;
