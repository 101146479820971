import React, { useEffect } from 'react';
import { Layout } from 'app/blocks/blocks';
import PageEnum from 'app/blocks/common/PageEnum';
import { compose } from 'app/blocks/common/utils';
import PaymentStepEnum from 'app/pages/license-signing-process/Payment/PaymentStepEnum';
import Context from './Context';
import './Payment.scss';
import ReviewStep from './Review/ReviewStep';
import ReviewFinalChargesStep from './ReviewFinalCharges/ReviewFinalChargesStep';

const pages = {
    [PaymentStepEnum.REVIEW_FINAL_CHARGES]: PageEnum.ONLINE_OPEN_ORDER_REVIEW,
    [PaymentStepEnum.REVIEW]: PageEnum.ONLINE_OPEN_ORDER_REVIEW,
};

function Payment({ isProcessing, onLoadSubstep, substep }) {
    useEffect(() => {
        if (onLoadSubstep && typeof onLoadSubstep === 'function') {
            const page = pages[substep];
            onLoadSubstep(substep, page);
        }
    }, [onLoadSubstep, substep]);

    if (isProcessing) {
        return <Layout isLoading />;
    }

    if (substep === PaymentStepEnum.REVIEW_FINAL_CHARGES) {
        return <ReviewFinalChargesStep />;
    }
    if (substep === PaymentStepEnum.REVIEW) {
        return <ReviewStep />;
    }

    return null;
}

export default compose(
    Context.withProvider,
    Context.withContext(state => ({
        isProcessing: state.isProcessing,
    })),
)(Payment);
