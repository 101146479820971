import _ from 'underscore';
import { cloneDeepSimple } from 'app/blocks/common/utils';

function Form() {
    this.fields = {};
    this.valueSets = {};
}

Form.prototype.value = function (field, value) {
    const valueSet = this.valueSets[field] || new Set();
    this.valueSets[field] = valueSet;

    if (valueSet.has(value)) {
        valueSet.delete(value);
    } else {
        valueSet.add(value);
    }
    return [...valueSet];
};

/**
 * Sets value
 *
 * @param field - can be an Array. Use case: one value should be mapped to several fields
 * @param value - expected to be either String or Number (Objects not supported yet)
 * @param arrayValue - if true - if some value is already written to the {field} (if any) it won't be replaced, but will follow next logic:
 *  - if the same value is already presented in a form for {field}, value will be
 *  - otherwise - will be appended to the list of values for provided {field}.
 *  Use case: checkboxes
 */
Form.prototype.setValue = function (field, value, arrayValue) {
    this.fields[field] = arrayValue ? this.value(field, value) : value;
};

Form.prototype.checkIsEmpty = function () {
    return !Object.values(this.fields).some(v => !_.isEmpty(v));
};

Form.prototype.getValue = function (field) {
    return this.fields[field] || '';
};

Form.prototype.has = function (field, value) {
    const v = this.getValue(field);
    return Array.isArray(v) && v.indexOf(value) !== -1;
};

Form.prototype.buildQuery = function () {
    const { fields } = this;
    return Object.keys(fields)
        .filter(key => !_.isEmpty(fields[key]))
        .reduce((q, key) => {
            let value = fields[key];
            if (typeof value === 'object' && value !== null && 'id' in value) {
                value = value.id;
            }
            return {
                ...q,
                [key]: value,
            };
        }, {});
};

Form.prototype.copy = function () {
    const form = new Form();
    form.fields = cloneDeepSimple(this.fields);
    form.valueSets = cloneDeepSimple(this.valueSets);
    return form;
};

Form.prototype.append = function (key, value) {
    // this doesn't touch the fom itself but instead creates copy
    const form = this.copy();
    form.setValue(key, value);
    return form;
};

export { Form };
export default {
    create: () => new Form(),
};
