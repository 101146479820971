import 'app/styles/styles.scss';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import {
    Route,
    useBlocker,
    useLocation,
    useNavigate,
    createHashRouter,
    createRoutesFromElements,
    RouterProvider,
    Outlet,
} from 'react-router-dom';
import { checkChanges, shouldNavigate } from 'app/app.router.navigation-handling';
import AuthProvider from 'app/blocks/common/providers/AuthProvider';
import ErrorBoundary from 'app/blocks/error-boundary';
import { renderMenu } from 'app/blocks/menu/__renderer/menu__renderer';
import { parseUrlParams } from 'app/blocks/middleware/url-params';
import PageFooter from 'app/blocks/page/__footer/page__footer';
import { setHistory } from 'app/route/history';
import routes from 'app/route/routes';

function App() {
    const history = useNavigate();
    React.useEffect(() => {
        setHistory(history);
    }, [history]);

    parseUrlParams(useLocation());

    const blocker = useBlocker(checkChanges);

    useEffect(() => {
        if (blocker.state === 'blocked') {
            (async function () {
                if (await shouldNavigate()) {
                    blocker.proceed();
                } else {
                    blocker.reset();
                }
            })();
        }
    }, [blocker, blocker.state]);

    return (
        <ErrorBoundary>
            {renderMenu()}
            <Outlet />
        </ErrorBoundary>
    );
}

const router = createHashRouter(createRoutesFromElements(<Route Component={App}>{routes}</Route>));

export default function render() {
    createRoot(document.getElementById('content')).render(
        <AuthProvider>
            <RouterProvider router={router} />
            {ReactDOM.createPortal(<PageFooter />, document.getElementById('AS__footer'))}
            {ReactDOM.createPortal(<template id="as__info" />, document.getElementById('AS__info'))}
        </AuthProvider>,
    );
}
