const numbers = {
    formatNumber(number) {
        if (number === undefined) {
            return number;
        }
        const absNumber = Math.abs(number);
        const integer = parseInt(String(absNumber), 10);
        let fractional: string | number = Math.round((absNumber % 1) * 100);
        if (fractional < 10) {
            fractional = `0${fractional}`;
        }
        return `${(number < 0 ? '-' : '') + integer}.${fractional}`;
    },
    formatPercents(percents) {
        if (Number.isInteger(percents)) {
            return `${String(percents)}%`;
        }

        return `${percents.toFixed(2).replace(/\.00$/, '')}%`;
    },
};

export { numbers };
