import React from 'react';
import { EditorAffiliation, isEmailSuggestion } from '../../utils';
import ConfirmedInstitution from './ConfirmedInstitution/ConfirmedInstitution';
import EmailSuggestion from './EmailSuggestion/EmailSuggestion';
import NotConfirmedInstitution from './NotConfirmedInstitution/NotConfirmedInstitution';

import './institutions.scss';

type Props = {
    affiliations: Array<EditorAffiliation | (Institution & { emailMatched: string })>;
    article: Product.Article;
    blacklist: string[];
    cancelableAuthorPaidOrderExists?: boolean;
    institutionsIdFromProfile: string[];
    onDelete: (id: string) => void;
    onChange: (id: string, item: any) => void;
    zeroPriceOrderExists?: boolean;
};

function Institutions({
    affiliations,
    article,
    blacklist,
    cancelableAuthorPaidOrderExists,
    institutionsIdFromProfile,
    onDelete,
    onChange,
    zeroPriceOrderExists,
}: Props) {
    let customIndex = 0;

    return (
        <ul className="Institutions">
            {affiliations.map(affiliation => {
                let seleniumid;

                if (isEmailSuggestion(affiliation)) {
                    return (
                        <EmailSuggestion
                            affiliation={affiliation}
                            onChange={onChange}
                            onDelete={onDelete}
                            seleniumid={affiliation.id}
                        />
                    );
                }

                if (affiliation.custom) {
                    customIndex += 1;
                    seleniumid = `custom-${customIndex}`;
                } else {
                    seleniumid = affiliation.id || affiliation.institutionId || affiliation.affiliationId;
                }

                if (affiliation.confirmed || !affiliation.submissionData) {
                    return (
                        <ConfirmedInstitution
                            key={seleniumid}
                            affiliation={affiliation}
                            articleId={article.id}
                            blacklist={blacklist}
                            cancelableAuthorPaidOrderExists={cancelableAuthorPaidOrderExists}
                            institutionsIdFromProfile={institutionsIdFromProfile}
                            onChange={onChange}
                            onDelete={onDelete}
                            seleniumid={seleniumid}
                            zeroPriceOrderExists={zeroPriceOrderExists}
                        />
                    );
                }

                return (
                    <NotConfirmedInstitution
                        key={seleniumid}
                        affiliation={affiliation}
                        blacklist={blacklist}
                        institutionsIdFromProfile={institutionsIdFromProfile}
                        onChange={onChange}
                        onDelete={onDelete}
                        seleniumid={seleniumid}
                    />
                );
            })}
        </ul>
    );
}

export default Institutions;
