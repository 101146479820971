import { lAsync } from 'app/blocks/common/codes';
import { ValidationError } from 'app/blocks/common/utils-errortypes';
import * as middleware from 'app/blocks/middleware/middleware';

export default async order => {
    if (order.discountDetails.societyId && !order.discountDetails.societyPromoCode) {
        const error = new ValidationError('Society Promo Code is empty', {
            societyPromoCode: new Error('Society Promo Code is empty'),
        });
        // FIXME remove when oa controller is removed
        error.societyPromoCode = new Error('Society Promo Code is empty');

        throw error;
    }

    try {
        await middleware.onlineopen.validateDiscountData(order);
    } catch (error) {
        if (Array.isArray(error.payload)) {
            const errors = {};

            for (let i = 0; i < error.payload.length; i += 1) {
                const { provider, reason } = error.payload[i];
                // eslint-disable-next-line no-await-in-loop
                errors[`${provider}PromoCode`] = new Error(await lAsync(`ORDER_PANELS.PANEL_DISCOUNT.${reason}`));
            }
            // FIXME remove when oa controller is removed
            throw Object.assign(new ValidationError(error.message, errors), errors);
        }

        throw error;
    }
};
