import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { compose } from 'app/blocks/common/utils';
import validation from 'app/blocks/common/validation';
import withConfirmLeaving, { onLeavePage } from 'app/blocks/common/withConfirmLeaving';
import withScrollToTop from 'app/blocks/common/withScrollToTop';
import OrdersRow from 'app/blocks/Row/Row';
import { ItemTitle } from 'app/blocks/titles/titles';
import { BackAndForthButtons } from 'app/pages/license-signing-process/buttons';
import { useLicenseFlow } from 'app/pages/license-signing-process/context/Context';
import { BackAndForthContainer, SectionPrompt, SectionTitle } from 'app/pages/license-signing-process/styles';
import { navigate } from 'app/route/history';
import './ConfirmAuthorEmails.scss';

function ConfirmAuthorEmails({
    confirmAuthorsEmails,
    correspondingAuthors,
    l,
    setChangesChecker,
    unsetChangesChecker,
}) {
    React.useEffect(() => {
        // no not trigger modal in case of fixing browser address bar in Context
        const timeout = setTimeout(() => setChangesChecker(() => true, onLeavePage), 1000);

        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { article } = useLicenseFlow();

    const [emails, setEmails] = React.useState(() => {
        return correspondingAuthors
            .filter(x => !x.email)
            .map(x => ({
                fullname: `${x.firstName} ${x.lastName}`,
                participantId: x.participantId,
            }));
    });
    const [emailErrors, setEmailErrors] = React.useState({});

    const onConfirm = () => {
        const errors = {};

        emails.forEach(x => {
            const errorMessage = validation.validate(x.email, [
                [validation.notEmpty, l('LOGIN.errorMessages.LOGIN_EMAIL_ADDR_MISSING_ERR_TEXT')],
                [validation.isEmailValid, l('LOGIN.errorMessages.LOGIN_EMAIL_ADDR_INVALID_ERR_TEXT')],
            ]);

            if (errorMessage) {
                errors[x.participantId] = new Error(errorMessage);
            }
        });

        if (Object.keys(errors).length === 0) {
            unsetChangesChecker();
            confirmAuthorsEmails(emails.map(x => ({ email: x.email, participantId: x.participantId })));
        }

        setEmailErrors(errors);
    };

    return (
        <>
            <section className="ConfirmAuthorEmails">
                <SectionTitle>{l('LICENSE_SIGNING.CONFIRM_AUTHORS.CONFIRM_AUTHOR_EMAILS.TITLE')}</SectionTitle>

                <SectionPrompt>{l('LICENSE_SIGNING.CONFIRM_AUTHORS.CONFIRM_AUTHOR_EMAILS.PROMPT')}</SectionPrompt>

                {emails.map(x => (
                    <div key={x.participantId} className="ConfirmAuthorEmails-EmailBlock mt-2x">
                        <ItemTitle>{x.fullname}</ItemTitle>

                        <OrdersRow
                            className="mt-base"
                            error={emailErrors[x.participantId]}
                            formSeleniumid="confirmAuthorEmails"
                            label="Email address"
                            name={x.participantId}
                            onChange={value => {
                                // eslint-disable-next-line no-param-reassign
                                x.email = value;
                                setEmails([...emails]);
                            }}
                            onFocus={() => setEmailErrors({ ...emailErrors, [x.participantId]: undefined })}
                            value={x.email}
                        />
                    </div>
                ))}
            </section>

            <BackAndForthContainer>
                <BackAndForthButtons
                    buttonCancelLabel={l('LICENSE_SIGNING.BUTTON.CORRESPONDING_AUTHORS')}
                    buttonConfirmingLabel={l('BUTTONS.CONFIRMING')}
                    buttonConfirmLabel={l('BUTTONS.CONFIRM')}
                    onCancel={() => {
                        unsetChangesChecker();
                        navigate(`/license-signing/${article.id}/confirmAuthors`, { replace: true });
                    }}
                    onConfirm={onConfirm}
                />
            </BackAndForthContainer>
        </>
    );
}

export default compose(
    withConfirmLeaving,
    withScrollToTop,
    withCodes2(ID.BUTTONS, ID.LOGIN, ID.LICENSE_SIGNING),
)(ConfirmAuthorEmails);
