function scrollToTop() {
    window.scrollTo(0, 0);
}

function scrollToElementBySelector(selector, focus = false) {
    const element = document.querySelector(selector);
    if (element) {
        element.scrollIntoView?.(true);
        window.scrollBy(0, -50);
        if (focus) element.focus();
    }
}

function scrollIntoDomElement(selector = 'body', param = true, delay = 0) {
    return setTimeout(() => {
        try {
            document.querySelector(selector).scrollIntoView(param);
        } catch (error) {
            console.warn(`Exception for '${selector}' selector`);
            console.warn(error);
        }
    }, delay);
}

export { scrollIntoDomElement, scrollToElementBySelector, scrollToTop };
