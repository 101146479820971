import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import PageEnum from 'app/blocks/common/PageEnum';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { compose } from 'app/blocks/common/utils';
import withScrollToTop from 'app/blocks/common/withScrollToTop';
import LicenseSelectionGrid from 'app/blocks/LicenseSelectionGrid/LicenseSelectionGrid';
import { BackAndForthButtons } from 'app/pages/license-signing-process/buttons';
import { useLicenseFlow } from 'app/pages/license-signing-process/context/Context';
import { SectionTitle } from 'app/pages/license-signing-process/styles';
import './LicenseTypes.scss';

function LicenseTypes({ l }: { l: l }): React.ReactElement {
    const { confirmLicenseType, onLoadSubstep, getPrevStep, goBack, isLicenseTypeConfirming, all } = useLicenseFlow();

    const choices = all?.licenseAgreementTypes?.licenseChoices || [];

    // AS-27347 - It's a kind of hack
    const firstMultiple = choices.find(c => c?.licenseChoice?.length > 1);

    const licenseType = firstMultiple?.licenseType?.value;
    const licenseTypes = firstMultiple?.licenseChoice || [];

    const isConflicted = !!choices.find(c => c?.licenseChoice?.length === 1) && !!firstMultiple;

    const [selectedLicenseType, setSelectedLicenseType] = React.useState<string>();

    React.useEffect(() => {
        setSelectedLicenseType(licenseType);
    }, [licenseType]);
    React.useEffect(() => {
        onLoadSubstep(null, PageEnum.LICENSE_AGREEMENT_TYPES);
    }, [onLoadSubstep]);

    const buttonConfirmLabel = selectedLicenseType
        ? l('LICENSE_SIGNING.BUTTON.CONFIRM_LICENSE', {
              license: l(`LICENSE.${selectedLicenseType}.shortName`),
          })
        : l('LICENSE_SIGNING.BUTTON.CONFIRM_AND_PROCEED');

    const getInfoMessagePrompt = () => {
        return (
            <div className="LicenseTypes-InfoBlock" data-seleniumid="license-types-banner">
                <SvgIcon.info className="InfoIcon" />
                <span dangerouslySetInnerHTML={{ __html: l('LICENSE_SIGNING.LICENSE_TYPES.PROMPT') }} />
            </div>
        );
    };

    return (
        <>
            <section className="LicenseTypes">
                <SectionTitle>{l('LICENSE_SIGNING.LICENSE_TYPES.TITLE')}</SectionTitle>

                {getInfoMessagePrompt()}

                <LicenseSelectionGrid
                    isConflicted={isConflicted}
                    licenseTypes={licenseTypes}
                    onLicenseTypeChange={setSelectedLicenseType}
                    selectedLicenseType={selectedLicenseType}
                />
                {isConflicted && (
                    <div className="LicenseTypes-AsteriskBlock" data-seleniumid="license-types-asterisk-block">
                        {l('LICENSE_SIGNING.LICENSE_TYPES.ASTERISK_TEXT')}
                    </div>
                )}
            </section>

            <div className="licenseSigningProcessPage-backAndForth">
                <BackAndForthButtons
                    buttonCancelLabel={getPrevStep().name}
                    buttonConfirmingLabel={l('BUTTONS.CONFIRMING')}
                    buttonConfirmLabel={buttonConfirmLabel}
                    canCancel={!isLicenseTypeConfirming}
                    canConfirm={!!selectedLicenseType && !isLicenseTypeConfirming}
                    isConfirming={isLicenseTypeConfirming}
                    onCancel={() => goBack()}
                    onConfirm={() => confirmLicenseType(selectedLicenseType)}
                />
            </div>
        </>
    );
}

export default compose(withScrollToTop, withCodes2(ID.BUTTONS, ID.LICENSE, ID.LICENSE_SIGNING))(LicenseTypes);
