import React from 'react';
import Article from 'app/blocks/article/article';
import { RowLg7, WhiteBlock } from 'app/blocks/blocks';
import AffiliationsStepEnum from './AffiliationsStepEnum';
import ArticleAffiliationsPanel from './article-affiliations-panel/ArticleAffiliationsPanel';
import Header from './article-affiliations-panel/Header/Header';
import SelectPrimaryAffiliation from './article-affiliations-panel/SelectPrimaryAffiliation/SelectPrimaryAffiliation';
import SelectWOAInstitutionPanel from './article-affiliations-panel/SelectWOAInstitution/SelectWOAInstitutionPanel';
import { EditorAffiliation } from './utils';

import './article-affiliations-editor.scss';

type RenderButtons = (params: {
    onConfirm: () => void;
    onCancel: () => void;
    canConfirm: boolean;
    canCancel: boolean;
    subStep: Values<typeof AffiliationsStepEnum>;
    isSaving: boolean;
}) => React.ReactNode;

type Props = Omit<
    GetProps<typeof ArticleAffiliationsPanel> &
        GetProps<typeof SelectPrimaryAffiliation> &
        GetProps<typeof SelectWOAInstitutionPanel>,
    'affiliations' | 'step'
> & {
    affiliations: EditorAffiliation[];
    eligibleAffiliations: EditorAffiliation[];
    canConfirm?: boolean;
    canCancel?: boolean;
    isSaving: boolean;
    onClickSave: () => void;
    onClickCancel: () => void;
    renderButtons?: RenderButtons;
    subStep: Values<typeof AffiliationsStepEnum>;
    noGeoWaiver: boolean;
    onNoGeoWaiverChange: (value: boolean) => void;
    onPriceProposalCountryChange: (value: boolean) => void;
    onCountryChange: (countryName: string) => void;
};

function ArticleAffiliationsEditorView({
    affiliations,
    article,
    articleAffiliations,
    blacklist = [],
    canCancel,
    canConfirm,
    countryDropdownVisible,
    eligibleAffiliations,
    geoTargetedMessage,
    institutionsIdFromProfile = [],
    isDisabled,
    isEmptyAffiliations,
    isSaving,
    journal,
    newAffiliation,
    noAffiliations,
    noGeoWaiver,
    onAdd,
    onCancel,
    onChange,
    onClickCancel,
    onClickSave,
    onCloseStatusPopup,
    onCountryChange,
    onDelete,
    onNoAffiliationsChange,
    onNoGeoWaiverChange,
    onPriceProposalCountryChange,
    onSelectAffiliationAsPrimary,
    onSelectAffiliationByCountry,
    renderButtons,
    selectedPrimaryAffiliationId,
    setNotAffiliated,
    statusPopup,
    subStep,
    zeroPriceOrderExists,
}: Props) {
    return (
        <>
            <RowLg7 className="m-btm_mini">
                <WhiteBlock className="p_mini">
                    <Article article={article} journal={journal} openAccess={journal && journal.openAccess} />
                </WhiteBlock>
            </RowLg7>

            {subStep === AffiliationsStepEnum.AFFILIATION_LIST && (
                <ArticleAffiliationsPanel
                    affiliations={affiliations}
                    article={article}
                    articleAffiliations={articleAffiliations}
                    blacklist={blacklist}
                    countryDropdownVisible={countryDropdownVisible}
                    geoTargetedMessage={geoTargetedMessage}
                    institutionsIdFromProfile={institutionsIdFromProfile}
                    isDisabled={isDisabled}
                    isEmptyAffiliations={isEmptyAffiliations}
                    journal={journal}
                    newAffiliation={newAffiliation}
                    noAffiliations={noAffiliations}
                    noGeoWaiver={noGeoWaiver}
                    onAdd={onAdd}
                    onCancel={onCancel}
                    onChange={onChange}
                    onCloseStatusPopup={onCloseStatusPopup}
                    onCountryChange={onCountryChange}
                    onDelete={onDelete}
                    onNoAffiliationsChange={onNoAffiliationsChange}
                    onNoGeoWaiverChange={onNoGeoWaiverChange}
                    onPriceProposalCountryChange={onPriceProposalCountryChange}
                    statusPopup={statusPopup}
                    step={subStep}
                    zeroPriceOrderExists={zeroPriceOrderExists}
                />
            )}

            {subStep === AffiliationsStepEnum.SELECT_PRIMARY_AFFILIATION && (
                <>
                    <Header step={subStep} />
                    <SelectPrimaryAffiliation
                        affiliations={eligibleAffiliations}
                        onSelectAffiliationAsPrimary={onSelectAffiliationAsPrimary}
                        selectedPrimaryAffiliationId={selectedPrimaryAffiliationId}
                    />
                </>
            )}

            {subStep === AffiliationsStepEnum.SELECT_AFFILIATION_BY_COUNTRY && (
                <SelectWOAInstitutionPanel
                    blacklist={blacklist}
                    institutionsIdFromProfile={institutionsIdFromProfile}
                    onSelectAffiliationByCountry={onSelectAffiliationByCountry}
                    setNotAffiliated={setNotAffiliated}
                    step={subStep}
                />
            )}

            {renderButtons({
                canCancel,
                canConfirm,
                isSaving,
                onCancel: onClickCancel,
                onConfirm: onClickSave,
                subStep,
            })}
        </>
    );
}

export default ArticleAffiliationsEditorView;
