import { lAsync } from 'app/blocks/common/codes';
import { ValidationError } from 'app/blocks/common/utils-errortypes';
import validation from 'app/blocks/common/validation';
import flow from 'app/pages/orders/orders.flow';

export default async order => {
    const { vatTaxDetails } = order;

    const vatTaxRelevanceDetails = await flow.getVatTaxRelevanceDetails(order);

    if (
        vatTaxDetails.vatIdNumber &&
        !validation.validateVatIdNumber(vatTaxDetails.vatIdNumber, vatTaxRelevanceDetails.regex)
    ) {
        throw new ValidationError(await lAsync('ERROR.VAT_TAX_VAT_ID_IN_VALID'));
    }
};
