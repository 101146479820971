import React, { Component } from 'react';
import { PrimaryButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import RadioButton from 'app/ui/form/RadioButton';

type Props = {
    affiliation: Institution & { emailMatched: string };
    l: l;
    onDelete: (id: string) => void;
    onChange: (id: string, item: any) => void;
    seleniumid: string;
};

class EmailSuggestion extends Component<Props> {
    state = {
        selectedItem: null,
        option: undefined,
    };

    onSelectOption = option => {
        const { affiliation } = this.props;
        this.setState({
            selectedItem: affiliation,
            option,
        });
    };

    onSaveChanges = () => {
        const { selectedItem, option } = this.state;
        const { onChange, onDelete } = this.props;
        if (option) {
            onChange(selectedItem.id, selectedItem);
        } else {
            onDelete(selectedItem.id);
        }
    };

    render() {
        const { seleniumid, l, affiliation } = this.props;
        const { option, selectedItem } = this.state;
        return (
            <li className="NotConfirmedInstitution" data-seleniumid={`${seleniumid}-not-confirmed-institution-item`}>
                <p
                    dangerouslySetInnerHTML={{
                        __html: l('ARTICLE_AFFILIATION_EDITOR.SELECT_EMAIL_SUGGESTION', {
                            email: affiliation.emailMatched,
                            affiliation: affiliation.name,
                        }),
                    }}
                    className="NotConfirmedInstitution-Text"
                    data-seleniumid={`${seleniumid}-suggestions-hint`}
                />
                <ul style={{ padding: 0, marginTop: '15px' }}>
                    <li className="NotConfirmedInstitution-Option">
                        <RadioButton
                            checked={option === true}
                            className="NotConfirmedInstitution-RadioBtn"
                            data-seleniumid={`${seleniumid}-email-suggestion-yes`}
                            id={`${seleniumid}-YES`}
                            label={<span className="Suggestion-Title">YES</span>}
                            labelClassName="NotConfirmedInstitution-Label Suggestion"
                            name={`${seleniumid}-email-suggestion`}
                            onChange={() => this.onSelectOption(true)}
                            value
                        />
                    </li>
                    <li className="NotConfirmedInstitution-Option">
                        <RadioButton
                            checked={option === false}
                            className="NotConfirmedInstitution-RadioBtn"
                            data-seleniumid={`${seleniumid}-email-suggestion-no`}
                            id={`${seleniumid}-NO`}
                            label={<span className="Suggestion-Title">NO</span>}
                            labelClassName="NotConfirmedInstitution-Label Suggestion"
                            name={`${seleniumid}-email-suggestion`}
                            onChange={() => this.onSelectOption(false)}
                            value={false}
                        />
                    </li>
                </ul>
                <PrimaryButton
                    className="NotConfirmedInstitution-SaveBtn"
                    data-seleniumid={`${seleniumid}-institution-save`}
                    disabled={selectedItem === null}
                    onClick={this.onSaveChanges}
                >
                    {l('ARTICLE_AFFILIATION_EDITOR.AFFILIATION_EMAIL_SUGGESTIONS_SAVE_CHANGES_BUTTON')}
                </PrimaryButton>
            </li>
        );
    }
}

export default withCodes(EmailSuggestion, ID.ARTICLE_AFFILIATION_EDITOR);
