import React, { useEffect } from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import PageEnum from 'app/blocks/common/PageEnum';
import { compose } from 'app/blocks/common/utils';
import withScrollToTop from 'app/blocks/common/withScrollToTop';
import OrderDiscounts from 'app/pages/AuthorPaidOrder/ConfirmationPage/OrderDiscounts';
import { BackButton, ForwardButton } from 'app/pages/license-signing-process/buttons';
import { useLicenseFlow } from 'app/pages/license-signing-process/context/Context';
import DiscountAndBillingContext from 'app/pages/license-signing-process/DiscountAndBillingDetails/DiscountAndBillingContext';
import ContactPanel from './ContactPanel';
import DiscountPanel from './DiscountPanel';
import InitiateStepTop from './InitiateStepTop';
import VatTaxPanel from './VatTaxPanel';

function InitiateStep({
    l,
    isInstitutionalDiscount,
    onCloseStatusPopup,
    onInitiateConfirm,
    prices,
    isConfirming,
    isSaveAndPreviewAllowed,
    statusPopupError,
}) {
    const { getPrevStep, goBack, journal, onLoadSubstep } = useLicenseFlow();

    useEffect(() => {
        if (onLoadSubstep && typeof onLoadSubstep === 'function') {
            onLoadSubstep(null, PageEnum.ONLINE_OPEN_ORDER_INITIATE);
        }
    }, [onLoadSubstep]);

    const statusPopupOptions = {
        onCloseStatusPopup,
        statusPopupError,
    };

    return (
        <>
            <section className="payment payment--initiate">
                <div>
                    <InitiateStepTop
                        isInstitutionalDiscount={isInstitutionalDiscount}
                        prices={prices}
                        statusPopupOptions={statusPopupOptions}
                    />
                    <DiscountPanel />
                    <ContactPanel />
                    <VatTaxPanel />
                </div>
                <div>
                    <OrderDiscounts className="orderDiscounts--licenseSigning" journalId={journal.id} />
                </div>
            </section>

            <div className="licenseSigningProcessPage-backAndForth licenseSigningProcessPage-backAndForth--payment">
                <div className="page-controls">
                    <BackButton onClick={goBack}>{getPrevStep().name}</BackButton>

                    <ForwardButton
                        data-seleniumid="saveAndPreviewButton"
                        disabled={!isSaveAndPreviewAllowed}
                        isProcessing={isConfirming}
                        onClick={onInitiateConfirm}
                        processingLabel={l('BUTTONS.CONFIRMING')}
                    >
                        {l('LICENSE_SIGNING.BUTTON.CONFIRM_AND_PROCEED')}
                    </ForwardButton>
                </div>
            </div>
        </>
    );
}

export default compose(
    withScrollToTop,
    DiscountAndBillingContext.withContext(state => ({
        isConfirming: state.initiate.isConfirming,
        isInstitutionalDiscount: state.isInstitutionalDiscount,
        isOrderExisting: state.isOrderExisting,
        isSaveAndPreviewAllowed: state.initiate.isSaveAndPreviewAllowed,
        isSavingForLater: state.initiate.isSavingForLater,
        onCloseStatusPopup: state.closeStatusPopup,
        onInitiateConfirm: state.onInitiateConfirm,
        order: state.order,
        prices: state.prices,
        saveAndPreview: state.saveAndPreview,
        statusPopupError: state.initiate.statusPopupError,
    })),
    withCodes2(ID.BUTTONS, ID.LICENSE_SIGNING),
)(InitiateStep);
