import React from 'react';
import { createRoot } from 'react-dom/client';
import { O } from 'ts-toolbelt';
import StaticContent from 'app/blocks/static-content/static-content';

type BannerConfig = {
    appendTo: string;
    bannerMessageElemId: string;
    bannerMessageAllSpaElemId: string;
    hashesWithMessages: string[];
    hashPartsWithMessages: Array<{ [key: string]: string }>;
    hash: string;
};

const defaultParams: BannerConfig = {
    appendTo: 'body',
    bannerMessageAllSpaElemId: 'banner-message-all-spa',
    bannerMessageElemId: 'banner-message',
    hash: '',
    hashPartsWithMessages: [],
    hashesWithMessages: [],
};

const roots = {};

export function clearBanner(containerId) {
    if (roots[containerId]) {
        roots[containerId].render(null);
    }
}

export const renderBanner = (staticFileName, containerId, appendTo) => {
    if (!document.getElementById(containerId)) {
        document.getElementById(appendTo).appendChild(document.createElement('div')).id = containerId;
    }

    roots[containerId] = roots[containerId] || createRoot(document.getElementById(containerId));
    roots[containerId].render(
        <StaticContent
            key={staticFileName}
            hideLoadingText
            seleniumid={staticFileName}
            src={`${staticFileName}.html`}
        />,
    );
};

export default (params: O.Optional<BannerConfig>) => {
    const {
        appendTo,
        bannerMessageAllSpaElemId,
        bannerMessageElemId,
        hash,
        hashPartsWithMessages,
        hashesWithMessages,
    } = { ...defaultParams, ...params };

    let msg;
    if (hashesWithMessages.includes(hash)) {
        msg = `banner-message-${hash}`;
    }
    hashPartsWithMessages.forEach(hashPart => {
        if (hash.includes(Object.keys(hashPart)[0])) {
            // eslint-disable-next-line prefer-destructuring
            msg = Object.values(hashPart)[0];
        }
    });

    renderBanner('banner-message-all-spa', bannerMessageAllSpaElemId, appendTo);
    if (msg) {
        renderBanner(msg, bannerMessageElemId, appendTo);
    } else {
        clearBanner(bannerMessageElemId);
    }
};
