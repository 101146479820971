import cn from 'classnames';
import get from 'lodash.get';
import React from 'react';
import { ID, l, withCodes2 } from 'app/blocks/common/codes';
import { compose } from 'app/blocks/common/utils';
import validation from 'app/blocks/common/validation';
import ErrorLabel from 'app/blocks/ErrorLabel/ErrorLabel';
import { SubTitle } from 'app/blocks/titles/titles';
import { BillToType } from 'app/pages/license-signing-process/DiscountAndBillingDetails/types';
import RadioButton from 'app/ui/form/RadioButton';
import EditAddress from './EditAddress';
import withPanel from './withPanel';
import './BillingDetails.scss';

type EditableAddress = Address & {
    billToType: string;
    primaryEmail: string;
};

type ValidationResult = {
    mainAddress: Object;
    message: string;
};

type BillingDetailsProps = {
    addressesProvider: Object;
    mainAddress: EditableAddress;
    onMainAddressFieldFocus: () => {};
    onSelectBillToType: (e) => {};
    updateMainAddress: () => {};
    validationResults: ValidationResult[];
    isWaiverApplied: boolean;
};

const BillingDetails: React.FC<BillingDetailsProps> = props => {
    const {
        addressesProvider,
        isWaiverApplied,
        mainAddress,
        onMainAddressFieldFocus,
        onSelectBillToType,
        updateMainAddress,
        validationResults,
    } = props;

    const getPaymentContent = () => {
        if (isWaiverApplied) {
            return '';
        }

        switch (mainAddress.billToType) {
            case BillToType.INDIVIDUAL:
                return l('LICENSE_SIGNING.PAYMENT.CONTENT.INDIVIDUAL_PAY');
            case BillToType.ORGANIZATION:
                return l('LICENSE_SIGNING.PAYMENT.CONTENT.ORGANIZATION_PAY');
            default:
                return '';
        }
    };

    return (
        <section
            className={cn('payment-panel BillingDetails', {
                'payment-panel--error': !validation.isAllKeysFalseOrEmpty(get(validationResults, 'mainAddress')),
            })}
            data-seleniumid="BillingDetails"
        >
            <SubTitle className="BillingDetails-Title" data-seleniumid="billing-details-header">
                {isWaiverApplied
                    ? l('LICENSE_SIGNING.PAYMENT.TITLE.DETAILS')
                    : l('LICENSE_SIGNING.PAYMENT.TITLE.BILLING_DETAIL')}
            </SubTitle>

            {!isWaiverApplied && (
                <p className="BillingDetails-Message" data-seleniumid="BillingDetails-BillToType-Message">
                    {l('LICENSE_SIGNING.PAYMENT.CONTENT.BILLING_DTL_CONTNT')}
                </p>
            )}

            <div className="BillingDetails-BillToTypes">
                {!isWaiverApplied && (
                    <>
                        <div className="BillingDetails-SubTitle">
                            <p>
                                <strong data-seleniumid="BillingDetails-WhoPay">
                                    {l('LICENSE_SIGNING.PAYMENT.TITLE.WHO_PAY')}
                                </strong>
                                <span className="BillingDetails-SubTitle-Decorator">{`${l(
                                    'LICENSE_SIGNING.FUNDERS.LABEL.POSTFIX_REQUIRED',
                                )}`}</span>
                            </p>
                        </div>
                        <RadioButton
                            checked={mainAddress?.billToType === BillToType.INDIVIDUAL}
                            className="BillingDetails-BillToTypeOption"
                            data-seleniumid="BillingDetails-billing-type"
                            label={l('LICENSE_SIGNING.PAYMENT.LABEL.INDIVIDUAL')}
                            name="billing-type"
                            onChange={onSelectBillToType}
                            value={BillToType.INDIVIDUAL}
                        />
                        <RadioButton
                            checked={mainAddress?.billToType === BillToType.ORGANIZATION}
                            className="BillingDetails-BillToTypeOption"
                            data-seleniumid="BillingDetails-billing-type"
                            label={l('LICENSE_SIGNING.PAYMENT.LABEL.ORGANIZATION')}
                            name="billing-type"
                            onChange={onSelectBillToType}
                            value={BillToType.ORGANIZATION}
                        />
                    </>
                )}
            </div>

            {(!!mainAddress?.billToType || isWaiverApplied) && (
                <div data-seleniumid="BillingDetails-Address-Section">
                    <SubTitle
                        className="BillingDetails-BillingAddress-SubTitle"
                        data-seleniumid="BillingDetails-BillingAddress"
                    >
                        {isWaiverApplied
                            ? l('LICENSE_SIGNING.PAYMENT.TITLE.ADDRESS')
                            : l('LICENSE_SIGNING.PAYMENT.TITLE.BILLING')}
                    </SubTitle>

                    {isWaiverApplied && (
                        <p className="BillingDetails-Message" data-seleniumid="BillingDetails-BillToType-Message">
                            {l('LICENSE_SIGNING.PAYMENT.CONTENT.BILLING_DTL_WAIVER_CONTENT')}
                        </p>
                    )}

                    <div className="BillingDetails-Message">
                        <p
                            data-seleniumid={`BillingDetails-Billing-${
                                mainAddress.billToType || BillToType.INDIVIDUAL
                            }-Message`}
                        >
                            {getPaymentContent()}
                        </p>
                    </div>

                    {!validation.isAllKeysFalseOrEmpty(get(validationResults, 'mainAddress')) && (
                        <ErrorLabel text="Address validation error" />
                    )}

                    <EditAddress
                        address={mainAddress}
                        handleAddressChange={updateMainAddress}
                        onFieldFocus={onMainAddressFieldFocus}
                        panelName="BillingDetails"
                        seleniumid="main-address"
                        supportDataProvider={addressesProvider}
                        validationResults={get(validationResults, 'mainAddress')}
                    />
                </div>
            )}
        </section>
    );
};

export default compose(withCodes2(ID.ORDER_PANELS, ID.LICENSE_SIGNING, ID.TOOLTIP), withPanel)(BillingDetails);
