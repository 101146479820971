import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { ItemTitle } from 'app/blocks/titles/titles';
import Item from './Item';
import './ArticleAffiliationsHelpWidget.scss';

function ArticleAffiliationsHelpWidget(props) {
    const items: Omit<GetProps<typeof Item>, 'index'>[] = Object.values(
        props.codes[ID.ARTICLE_AFFILIATION_HELP_WIDGET].items,
    );

    return (
        <section className="ArticleAffiliationsHelpWidget" data-seleniumid="ArticleAffiliationsHelpWidget">
            <ItemTitle className="ArticleAffiliationsHelpWidget-Title mb-2x" component="h3">
                {props.l('ARTICLE_AFFILIATION_HELP_WIDGET.TITLE')}
            </ItemTitle>

            {items.map((item, index) => (
                <Item key={item.title} description={item.description} index={index} title={item.title} />
            ))}
        </section>
    );
}

export default withCodes2(ID.ARTICLE_AFFILIATION_HELP_WIDGET)(ArticleAffiliationsHelpWidget);
