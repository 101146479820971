import { autobind } from 'core-decorators';
import update from 'immutability-helper';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import * as utils from 'app/blocks/common/utils';
import * as middleware from 'app/blocks/middleware/middleware';
import withLightPanel from 'app/blocks/Panel/advanced/withLightPanel';
import validation from 'app/pages/orders/panel/contact/validation';
import DiscountAndBillingContext from './DiscountAndBillingContext';

const addressesProvider = {
    getInstitutionsPromise: middleware.address.getInstitutions,
    getStatesPromise:
        parent =>
        (...args) =>
            middleware.address.getStates(parent, ...args),
};

const defaultState = {
    mainAddress: {},
};

function withContactPanelContainer(Component) {
    @autobind
    class ContactPanelContainer extends React.PureComponent {
        static propTypes = {
            disableDifferentCountrySelection: PropTypes.bool,
            emailDetails: PropTypes.shape({ primaryEmailAddr: PropTypes.string }).isRequired,
            mainAddress: PropTypes.shape({ countryCode: PropTypes.string }),
            profileAddresses: PropTypes.shape({}).isRequired,
            setState: PropTypes.func.isRequired,
            setValidationHandler: PropTypes.func.isRequired,
        };

        state = {
            INDIVIDUAL: { billToType: 'INDIVIDUAL' },
            ORGANIZATION: { billToType: 'ORGANIZATION' },
        };

        onSelectOption = e => {
            this.props.setState({ mainAddress: this.state[e.target.value] });
        };

        static defaultProps = {
            ...defaultState,
            disableDifferentCountrySelection: false,
        };

        async componentDidMount() {
            const { mainAddress } = this.props;
            this.setState({ [mainAddress?.billToType]: mainAddress });
            this.props.setValidationHandler(this.validate);
        }

        async validate() {
            try {
                validation(
                    {
                        mainAddress: utils.trimStringProperties(this.props.mainAddress),
                    },
                    true,
                );

                this.props.setState({ validationResults: undefined });
            } catch (error) {
                if (error.name === 'ValidationError') {
                    this.props.setState({ validationResults: error.errors });
                }

                throw error;
            }
        }

        // eslint-disable-next-line react/no-unused-class-component-methods
        isChanged() {
            return Object.keys(defaultState).some(
                key => this.state[key] && !_.isEqual(defaultState[key], this.props[key]),
            );
        }

        onMainAddressFieldFocus(field) {
            if (get(this.props, 'validationResults.mainAddress')) {
                this.props.setState({
                    validationResults: update(this.props.validationResults, {
                        mainAddress: { [field]: { $set: undefined } },
                    }),
                });
            }
        }

        updateMainAddress(mainAddress) {
            this.setState({ [mainAddress.billToType]: mainAddress });
            this.props.setState({ mainAddress });
        }

        render() {
            return (
                <Component
                    {...this.props}
                    addressesProvider={addressesProvider}
                    onMainAddressFieldFocus={this.onMainAddressFieldFocus}
                    onSelectOption={this.onSelectOption}
                    updateMainAddress={this.updateMainAddress}
                />
            );
        }
    }

    return ContactPanelContainer;
}

export { withContactPanelContainer };
export default Component =>
    utils.compose(
        DiscountAndBillingContext.withContext(state => ({
            ...state.initiate.addresses,
            disableDifferentCountrySelection: false,
            emailDetails: state.emailDetails,
            profileAddresses: state.profileAddresses,
            register: state.registerPanel,
            setState: state.setContactState,
            unregister: state.unregisterPanel,
        })),
        withLightPanel('addresses'),
        withContactPanelContainer,
    )(Component);
