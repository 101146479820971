import React from 'react';
import { Layout, Title } from 'app/blocks/blocks';
import { Button } from 'app/blocks/buttons/buttons';
import { processing, tryCatch } from 'app/blocks/common/decorators';
import * as middleware from 'app/blocks/middleware/middleware';
import { navigate } from 'app/route/history';

type State = {
    isLoading: boolean;
} & Awaited<ReturnType<typeof middleware.profile.confirmPrimaryEmailChange>>;

class ChangePrimaryEmail extends React.PureComponent<{ guid: string }, State> {
    state: State = {
        isLoading: true,
        primaryEmailAddr: undefined,
        firstName: undefined,
        lastName: undefined,
    };

    @tryCatch.showPopUpAndGoToDashboard
    @processing('isLoading')
    async componentDidMount() {
        const { guid } = this.props;

        this.setState(await middleware.profile.confirmPrimaryEmailChange(guid));
    }

    navigateToLogin() {
        navigate('login');
    }

    render() {
        const { primaryEmailAddr, firstName, isLoading, lastName } = this.state;

        return (
            <div className="container">
                <Title>
                    {isLoading ? 'Waiting for primary email change confirmation...' : 'Changed primary email'}
                </Title>
                <Layout isLoading={isLoading}>
                    <div className="row">
                        <div className="col-12">
                            <div>
                                <p>{`${firstName} ${lastName},`}</p>
                                <p>
                                    Your primary email has been successfully changed to{' '}
                                    <strong>{primaryEmailAddr}</strong>. Please click Login.
                                </p>
                                <Button onClick={() => this.navigateToLogin()}>Login</Button>
                            </div>
                        </div>
                    </div>
                </Layout>
            </div>
        );
    }
}

export default ChangePrimaryEmail;
