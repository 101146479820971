import React from 'react';
import Modal from 'app/blocks/Modal/Modal';
import UserExistsDialog from './user-exists-dialog';
import './user-exists-modal.scss';

type Props = { onClose: () => void } & GetProps<typeof UserExistsDialog>;

function UserExistsModal({ onClose, ...props }: Props): React.ReactElement {
    return (
        <Modal id="registration_user_exists_modal" ignoreBackdropClicks onClose={onClose}>
            <UserExistsDialog {...props} />
        </Modal>
    );
}

export default UserExistsModal;
