import React from 'react';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { Input } from 'app/ui/form';
import './SearchInput.scss';

type InputProps = GetProps<typeof Input>;

type Props = {
    onSearchQueryChange: InputProps['onChange'];
    text: InputProps['value'];
} & Omit<InputProps, 'onChange' | 'onFocus' | 'type' | 'value'>;

function SearchInput({ text = '', onSearchQueryChange, ...props }: Props) {
    return (
        <div className="Search-Input">
            <Input onChange={onSearchQueryChange} onFocus={onSearchQueryChange} type="text" value={text} {...props} />
            <SvgIcon.search iconTitle="Search Icon" />
        </div>
    );
}

export default SearchInput;
