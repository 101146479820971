import { RcaInfo } from 'app/blocks/middleware/dashboard';
import RcaStatusEnum from 'app/pages/dashboard/RcaStatusEnum';

function getPublicationStatus({
    published,
    rcaInfo,
    withdrawn,
}: {
    published?: boolean;
    rcaInfo: RcaInfo;
    withdrawn?: boolean;
}) {
    if (rcaInfo.rcaStatus === RcaStatusEnum.AUTHOR_LIST_IS_INCORRECT) {
        return 'AUTHOR_LIST_IS_INCORRECT';
    }
    if (rcaInfo.rcaStatus === RcaStatusEnum.RCA_CONFIRMATION_NEEDED && !rcaInfo.isEeoCaMe) return 'WAITING_CA_ACTION';
    if (withdrawn) return 'WITHDRAWN';
    if (published) return 'PUBLISHED';
    return 'IN_PRODUCTION';
}

export default getPublicationStatus;
