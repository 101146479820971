import PropTypes from 'prop-types';
import React from 'react';
import { enrichPrices, sortAsc, toPriceListFormat } from 'app/blocks/common/getCurrencySymbol';

function DiscountedPriceList({ l, prices, isSecondaryText }) {
    const list = enrichPrices(prices).sort(sortAsc);
    return (
        <>
            {isSecondaryText && (
                <div className="orderPrices--text-secondary">
                    {l('LICENSE_SIGNING.OPEN_ACCESS.LABEL.CURRENCIES_CARD_DISCOUNTED_SECONDARY')}
                </div>
            )}
            <div className="orderPrices--text">{l('LICENSE_SIGNING.OPEN_ACCESS.LABEL.CURRENCIES_CARD_DISCOUNTED')}</div>

            <ul className="ul-drop-list-styles orderPrices-items" data-seleniumid="order__discounted-prices">
                {list.map(price => (
                    <li key={price.name} className="orderPrices-item">
                        <span className="orderPrices-name">{price.name}</span>
                        <span className="orderPrices--base">
                            {price.symbol}
                            <span data-seleniumid={`order__base-price_${price.base}`}>
                                {toPriceListFormat(price.base)}
                            </span>
                        </span>
                        <span className="orderPrices--discounted">
                            {price.symbol}
                            <span data-seleniumid={`order__price_${price.name}`}>
                                {toPriceListFormat(price.amount)}
                            </span>
                        </span>
                    </li>
                ))}
            </ul>
        </>
    );
}

DiscountedPriceList.propTypes = {
    isSecondaryText: PropTypes.bool,
    prices: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            symbol: PropTypes.string,
            amount: PropTypes.number,
            base: PropTypes.number,
        }),
    ).isRequired,
    l: PropTypes.func.isRequired,
};

function PriceList({ prices }) {
    return (
        <ul className="ul-drop-list-styles orderPrices-items" data-seleniumid="order__prices">
            {prices.map(price => (
                <li key={price.name} className="orderPrices-item">
                    <span className="orderPrices-name">{price.name}</span>
                    {price.symbol}
                    <span data-seleniumid={`order__price_${price.name}`}>{toPriceListFormat(price.amount)}</span>
                </li>
            ))}
        </ul>
    );
}

PriceList.propTypes = {
    prices: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            symbol: PropTypes.string,
            amount: PropTypes.number,
        }),
    ).isRequired,
};

export { DiscountedPriceList, PriceList };
