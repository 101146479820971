import React from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import { BasicView } from 'app/blocks/views/WidgetView';
import ArticlePageContext from 'app/pages/article/ArticlePage.context';
import DefaultActions from '../actions';

const panel = 'license';

function SignLicense({ l }: { l: l }): React.ReactElement {
    const item = React.useContext(ArticlePageContext);
    const { getAllActions, getIcon, getParameters, getStatus, getTitle, hasMultipleAuthors, hasRequired } =
        item.helpers;
    const continueSignLicense = getParameters(panel).CONTINUE_SIGN_LICENSE === 'true';
    const { article, journal } = item;
    const allActions = getAllActions(
        panel,
        {
            SIGN_LICENSE: continueSignLicense && l('WIDGETS.SIGN_LICENSE.CONTINUE_BUTTON_TEXT'),
        },
        { article, journal },
    );

    return (
        <BasicView
            actions={<DefaultActions buttons={allActions} />}
            icon={getIcon(panel, 'sign')}
            required={hasRequired(panel)}
            seleniumid="license"
            title={getTitle(panel)}
        >
            <div dangerouslySetInnerHTML={{ __html: getStatus(panel) }} />
            {hasMultipleAuthors() && (
                <div className="extended-message" data-seleniumid="extended-message">
                    {l('WIDGETS.SIGN_LICENSE.EXTENDED_TEXT')}
                </div>
            )}
        </BasicView>
    );
}

export default withCodes(SignLicense, ID.WIDGETS);
