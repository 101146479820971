import React from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import { GrayView, BasicView } from 'app/blocks/views/WidgetView';
import { useArticlePage } from 'app/pages/article/ArticlePage.context';
import DefaultActions from '../actions';

const panel = 'license';

function DownloadLicenseWidget({ l }: { l: l }): React.ReactElement {
    const item = useArticlePage();
    const { getAllActions, getParameters, getStatus } = item.helpers;

    const licenseType: 'CTA' | 'CC' = getParameters(panel).LICENSE_TYPE || 'CTA';

    const View = item.status.published ? GrayView : BasicView;
    return (
        <View
            actions={<DefaultActions buttons={getAllActions(panel)} />}
            icon={`license${licenseType}`}
            seleniumid={`downloadLicense-${licenseType}`}
            title={l('WIDGETS.DOWNLOAD_LICENSE.TITLE')}
        >
            {getStatus(panel)}
        </View>
    );
}

export default withCodes(DownloadLicenseWidget, ID.WIDGETS);
