import cn from 'classnames';
import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import OrdersDataItem from 'app/pages/orders/data-item';
import flow from 'app/pages/orders/orders.flow';
import { displayVAT } from 'app/pages/orders/panel/vat-tax';
import ReviewPanelHeader from './ReviewPanelHeader';

function isEmpty(obj) {
    return obj && Object.values(obj).every(x => !x);
}

function VatTaxReviewPanel(props) {
    const DataItemAndAuxInfo = React.useMemo(
        () => OrdersDataItem.create(props.order, props.initOrder),
        [props.order, props.initOrder],
    );

    const countryCode = flow.getBillingCountryCode(props.order);

    if (displayVAT(countryCode)) {
        return (
            <section className={cn('payment-panel', props.panelName)} data-seleniumid="order-vatTax-panel-review">
                <ReviewPanelHeader onEdit={props.onEdit} panelName={props.panelName}>
                    {props.l('ORDER_PANELS.PANEL_VAT_TAX_REVIEW.VAT_DETAILS')}
                </ReviewPanelHeader>

                {(isEmpty(DataItemAndAuxInfo.auxInfo.order.vatTaxDetails) ||
                    !DataItemAndAuxInfo.auxInfo.order.vatTaxDetails.taxExemptionNumber) && (
                    <div className="mt-base" data-seleniumid="order-vatTax-panel-review--empty">
                        {props.l('ORDER_PANELS.PANEL_VAT_TAX_REVIEW.EMPTY')}
                    </div>
                )}

                {DataItemAndAuxInfo.auxInfo.order.vatTaxDetails.vatIdNumber && (
                    <DataItemAndAuxInfo.OrdersDataItem
                        className={`${props.panelName}-ContentRow`}
                        innerInfo={[['vatTaxDetails.vatIdNumber']]}
                        seleniumid="order-review-vat-idNumber"
                        titleCls="order-article"
                        titleTxt={props.l('ORDER_PANELS.PANEL_VAT_TAX.VAT_NUMBER')}
                    />
                )}
            </section>
        );
    }

    return null;
}

export default withCodes2(ID.ORDER_PANELS)(VatTaxReviewPanel);
