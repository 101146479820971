import cn from 'classnames';
import has from 'lodash.has';
import head from 'lodash.head';
import React from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import OrderCurrencyInfo from 'app/blocks/PricesSection/OrderCurrencyInfo';
import { DiscountedPriceList, PriceList } from 'app/blocks/PricesSection/PriceLists';
import { SubTitle } from 'app/blocks/titles/titles';
import PriceTable from './PriceTable';
import './pricesSection.scss';

const hasBase = item => (item ? has(item, 'base') : false);
const hasInstitutionalDiscount = prices => hasBase(head(prices));

type Props = {
    className?: string;
    isVertical?: boolean;
    isSecondaryText?: boolean;
    isTransparent?: boolean;
    l: l;
    prices: Array<{
        amount: number;
        base?: number;
        currency?: string;
        name: string;
        symbol: string;
    }>;
    title?: string;
};

function PricesVertical({
    className,
    isDiscounted,
    isSecondaryText,
    isTransparent,
    l,
    prices,
    title,
}: { isDiscounted?: boolean } & Omit<Props, 'isVertical'>) {
    const list = isDiscounted ? (
        <DiscountedPriceList isSecondaryText={isSecondaryText} l={l} prices={prices} />
    ) : (
        <PriceList prices={prices} />
    );

    const cns = cn('orderPrices orderPrices--vertical', { 'orderPrices--transparent': isTransparent }, className);
    return (
        <section className={cns} data-seleniumid="order__section_prices">
            {title && <SubTitle className="headingText">{title}</SubTitle>}
            {list}
        </section>
    );
}

function PricesHorizontal({
    className,
    l,
    prices,
}: Pick<Props, 'className' | 'l' | 'prices'> &
    Omit<Props, 'isSecondaryText'> &
    Omit<Props, 'isVertical'> &
    Omit<Props, 'title'>) {
    return (
        <section
            className={cn('orderPrices orderPrices--horizontal', className)}
            data-seleniumid="order__section_prices"
        >
            <SubTitle className="headingText" data-seleniumid="open-access-article-publication-charge-title">
                {l(`${ID.ORDERS}.PUBLICATION_CHARGE`)}
            </SubTitle>

            <ul className="ul-drop-list-styles orderPrices-items" data-seleniumid="order__prices">
                {prices.map(price => (
                    <li key={price.name} className="orderPrices-item">
                        <span className="orderPrices-name">{price.name}</span>
                        {price.symbol}
                        <span data-seleniumid={`order__price_${price.name}`}>{price.amount}</span>
                    </li>
                ))}
            </ul>

            <OrderCurrencyInfo />
        </section>
    );
}

function PricesSection({
    className,
    isSecondaryText,
    isTable,
    isTransparent,
    isVertical = false,
    l,
    prices,
    tableOptions,
    title,
}: Props & { tableOptions?: { hideEmpty: boolean; inRow: boolean }; isTable?: boolean }) {
    if (isTable) {
        const { hideEmpty, inRow } = tableOptions;
        return <PriceTable hideEmpty={hideEmpty} inRow={inRow} prices={prices as Price[]} />;
    }

    if (hasInstitutionalDiscount(prices)) {
        return (
            <PricesVertical
                className={className}
                isDiscounted
                isSecondaryText={isSecondaryText}
                isTransparent={isTransparent}
                l={l}
                prices={prices}
                title={title}
            />
        );
    }

    if (isVertical) {
        return (
            <PricesVertical
                className={className}
                isSecondaryText={isSecondaryText}
                l={l}
                prices={prices}
                title={title}
            />
        );
    }

    return <PricesHorizontal className={className} l={l} prices={prices} />;
}

export { PricesHorizontal, PricesVertical };
export default withCodes(PricesSection, ID.LICENSE_SIGNING, ID.ORDERS);
