import { useMemo } from 'react';
import { l } from 'app/blocks/common/codes';
import { template } from 'app/blocks/common/utils';
import { Discount } from 'app/pages/license-signing-process/DiscountAndBillingDetails/types';
import DiscountType, { byDiscountVisibility, byVisibility } from 'app/pages/license-signing-process/DiscountType';
import { roundDiscountValue } from '../formatters';

type UseDiscountLabelsType = {
    isDiscountsWidgetVisible: boolean;
    isAppliedDiscountsVisible: boolean;
    isAvailableDiscountsVisible: boolean;
    isDiscountsAdditionalInformationVisible: boolean;
    isHighestDiscountVisible: boolean;
    isMultipleDiscountsVisible: boolean;
    isDiscountsInputVisible: boolean;
    isJournalDiscountsVisible: boolean;
    isJournalAdditionalDiscountsVisible: boolean;
    isProvideDiscountVisible: boolean;
    isSpacerVisible: boolean;
    isDiscountDetailsSectionVisible: boolean;
    discountsAdditionalInformation: string;
    header: string;
    highestDiscount: string;
    journalDiscounts: string;
    journalAdditionalDiscounts: string;
    multipleDiscounts: string;
    noAppliedDiscounts: string;
    provideDiscount: string;
};
export const useDiscountLabels = (
    appliedDiscounts = [],
    societyDiscounts = [],
    wileyDiscounts = [],
    journalDiscountsSuccess = true,
): UseDiscountLabelsType => {
    return useMemo(() => {
        const visibleAppliedDiscounts = appliedDiscounts.filter(byDiscountVisibility);
        const isWaiverApplied =
            appliedDiscounts.filter(({ percentageDiscount }) => percentageDiscount === 100).length > 0;
        return {
            discountsAdditionalInformation: ` ${l(
                'LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.DISCOUNTS_ADDITIONAL_INFORMATION',
            )}`,
            header: l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.DISCOUNT_HEADER'),
            highestDiscount: l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.HIGHEST_DISCOUNT'),
            isAppliedDiscountsVisible: !!visibleAppliedDiscounts.length,
            isAvailableDiscountsVisible: !!societyDiscounts.length,
            isDiscountDetailsSectionVisible: !isWaiverApplied && journalDiscountsSuccess,
            isDiscountsAdditionalInformationVisible: !!societyDiscounts.length,
            isDiscountsInputVisible: !!societyDiscounts.length || !!wileyDiscounts.length,
            isDiscountsWidgetVisible: !!(
                appliedDiscounts?.filter(byVisibility)?.length ||
                wileyDiscounts?.length ||
                societyDiscounts?.length ||
                !journalDiscountsSuccess
            ),
            isHighestDiscountVisible: (!!societyDiscounts.length || !!wileyDiscounts.length) && !isWaiverApplied,
            isJournalAdditionalDiscountsVisible: !!societyDiscounts.length && !!wileyDiscounts.length,
            isJournalDiscountsVisible: !!societyDiscounts.length && !wileyDiscounts.length,

            isMultipleDiscountsVisible: !!societyDiscounts.length || !!wileyDiscounts.length,
            isProvideDiscountVisible: !!wileyDiscounts.length,
            isSpacerVisible:
                !!visibleAppliedDiscounts.length &&
                (!!wileyDiscounts.length || !!societyDiscounts.length) &&
                !isWaiverApplied,
            journalAdditionalDiscounts: l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.JOURNAL_ADDITIONAL_DISCOUNTS'),
            journalDiscounts: l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.JOURNAL_DISCOUNTS'),
            multipleDiscounts: l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.PROVIDE_MULTIPLE_DISCOUNTS'),
            noAppliedDiscounts: l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.ANY_DISCOUNT_TIP'),
            provideDiscount: l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.PROVIDE_DISCOUNT'),
        };
    }, [appliedDiscounts, societyDiscounts, wileyDiscounts, journalDiscountsSuccess]);
};

export const composeAppliedDiscountText = (
    {
        absoluteDiscount,
        discountDescription,
        discountType,
        eligibleInstitution,
        isStackedDiscount,
        percentageDiscount,
    }: Discount,
    discountCountryName: string = '',
): string => {
    switch (discountType) {
        case DiscountType.ArticleType: {
            if (percentageDiscount === 100) {
                return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.ARTICLE_TYPE_WAIVER_TEXT'));
            }
            return null;
        }
        case DiscountType.Institutional: {
            if (percentageDiscount !== undefined && !isStackedDiscount) {
                return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.INSTITUTIONAL_PERCENTAGE_DISCOUNT'), {
                    institutionName: eligibleInstitution.name,
                    percentageValue: roundDiscountValue(percentageDiscount),
                });
            }
            if (absoluteDiscount !== undefined || isStackedDiscount) {
                return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.INSTITUTIONAL_ABSOLUTE_DISCOUNT'), {
                    institutionName: eligibleInstitution.name,
                });
            }
            return '';
        }
        case DiscountType.Society: {
            return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.SOCIETY_DISCOUNT'), {
                discountDescription,
            });
        }
        case DiscountType.WileyPromoCode: {
            return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.WILEY_DISCOUNT'), { discountDescription });
        }
        case DiscountType.ReferralDiscount:
        case DiscountType.TransferDiscount: {
            if (percentageDiscount !== undefined) {
                return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.REFERRAL_TRANSFER_DISCOUNT'), {
                    percentageValue: roundDiscountValue(percentageDiscount),
                });
            }
            return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.REFERRAL_TRANSFER_DEFAULT_DISCOUNT'));
        }
        case DiscountType.GeographicalDiscount: {
            if (percentageDiscount === 100) {
                return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.GEO_WAIVER_TEXT'), {
                    discountCountryName,
                });
            }
            return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.GEO_DISCOUNT_TEXT'), {
                discountCountryName,
                percentageDiscount,
            });
        }
        case DiscountType.EditorialDiscount: {
            if (percentageDiscount === 100) {
                return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.EDITORIAL_WAIVER_TEXT'));
            }
            return '';
        }
        case DiscountType.Custom: {
            if (percentageDiscount === 100) {
                return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.CUSTOM_WAIVER_TEXT'));
            }
            return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.CUSTOM_DISCOUNT'));
        }
        default: {
            return '';
        }
    }
};

export const composeAppliedDiscountMessage = ({
    absoluteDiscount,
    discountDescription = '',
    discountType,
    eligibleInstitution,
    percentageDiscount,
}: Discount): string => {
    switch (discountType) {
        case DiscountType.ArticleType: {
            return null;
        }
        case DiscountType.Institutional: {
            if (percentageDiscount !== undefined) {
                return template(
                    l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.INSTITUTIONAL_PERCENTAGE_DISCOUNT_MESSAGE'),
                    {
                        institutionName: eligibleInstitution.name,
                        percentageValue: roundDiscountValue(percentageDiscount),
                    },
                );
            }
            if (absoluteDiscount !== undefined) {
                return template(
                    l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.INSTITUTIONAL_ABSOLUTE_DISCOUNT_MESSAGE'),
                    {
                        institutionName: eligibleInstitution.name,
                    },
                );
            }
            return '';
        }
        case DiscountType.Society: {
            return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.SOCIETY_DISCOUNT_MESSAGE'), {
                discountDescription,
            });
        }
        case DiscountType.WileyPromoCode: {
            return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.WILEY_DISCOUNT_MESSAGE'), {
                discountDescription,
            });
        }
        case DiscountType.ReferralDiscount:
        case DiscountType.TransferDiscount: {
            if (percentageDiscount !== undefined) {
                return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.REFERRAL_TRANSFER_DISCOUNT_MESSAGE'), {
                    percentageValue: roundDiscountValue(percentageDiscount),
                });
            }
            return template(
                l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.REFERRAL_TRANSFER_DEFAULT_DISCOUNT_MESSAGE'),
            );
        }
        case DiscountType.Custom: {
            return template(l('LICENSE_SIGNING.PAYMENT.DISCOUNT_AND_BILLING.CUSTOM_DISCOUNT_MESSAGE'));
        }
        default: {
            return '';
        }
    }
};
