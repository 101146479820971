import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

function PaymentAmountInfo({ amount, isAccented, isGray, seleniumId, subHeader, title }) {
    return (
        <div className={cn('PaymentAmountItem', { 'PaymentAmountItem--gray': isGray })}>
            <div className="PaymentAmountText" data-seleniumid={`paymentAmountText-${seleniumId}`}>
                <span className={cn('PaymentAmountMainText', { 'PaymentAmountMainText--accented': isAccented })}>
                    {title}
                </span>
                <span className="PaymentAmountAdditionalText">{subHeader}</span>
            </div>
            <span
                className={cn('PaymentAmountSum', { 'PaymentAmountSum--accented': isAccented })}
                data-seleniumid={seleniumId}
            >
                {amount}
            </span>
        </div>
    );
}

PaymentAmountInfo.propTypes = {
    title: PropTypes.string.isRequired,
    subHeader: PropTypes.string,
    amount: PropTypes.string.isRequired,
    isAccented: PropTypes.bool,
    isGray: PropTypes.bool,
};

PaymentAmountInfo.defaultProps = {
    isAccented: false,
    isGray: false,
    subHeader: '',
};

export default PaymentAmountInfo;
