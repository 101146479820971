import React from 'react';

type InnerRefProps = {
    innerRef?: React.ForwardedRef<unknown>;
};
const withInnerRef = (WrappedComponent: React.ComponentType<InnerRefProps>): React.ForwardRefExoticComponent<unknown> =>
    React.forwardRef((props, ref) => {
        // @ts-ignore
        return <WrappedComponent innerRef={ref} {...props} />;
    });

export default withInnerRef;
