import React from 'react';
import { LinkButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import DiscountBanner from 'app/blocks/discount-banner/DiscountBanner';
import { BasicView, GrayView } from 'app/blocks/views/WidgetView';
import { useArticlePage } from 'app/pages/article/ArticlePage.context';
import { WaiverType } from 'app/pages/license-signing-process/DiscountAndBillingDetails/types';
import DefaultActions from '../actions';

const panel = 'OAOrder';

function OAOrder({ l }: { l: l }): React.ReactElement {
    const item = useArticlePage();
    const {
        getActions,
        getAllActions,
        getIcon,
        getParameters,
        getStatus,
        getTitle,
        hasCitations,
        hasImportant,
        hasRequired,
    } = item.helpers;

    const downloadInvoiceLink = getActions(panel).DOWNLOAD_INVOICE;
    const downloadProformaLink = getActions(panel).DOWNLOAD_PROFORMA;
    const isInvoiceOrProforma = downloadInvoiceLink || downloadProformaLink;

    const View = item.status.published && !hasRequired(panel) && hasCitations() ? GrayView : BasicView;

    const { article, journal } = item;
    const isGeoDiscount = ({ params }) => {
        return 'GEO_DISCOUNT_PERCENTAGE' in params;
    };

    const waiverType = ({ params }) => {
        return params.WAIVER_TYPE || '';
    };

    const isInstitutionalDiscount = ({ params }) => {
        return 'WOA_OA_INS_DISCOUNT' in params;
    };

    const isGeoWaiver = ({ params }) => {
        return 'WAIVER_TYPE' in params && params.WAIVER_TYPE === WaiverType.Geographical;
    };

    const DiscountBannerContent = ({ oaPanel }) => {
        const params = getParameters(oaPanel);
        if (isGeoDiscount({ params })) {
            const geoDiscountPercentage = parseFloat(params.GEO_DISCOUNT_PERCENTAGE);
            const percentageString = `${geoDiscountPercentage.toFixed(0)}%`;
            const discountCountry = params.GEO_DISCOUNT_COUNTRY;

            const title = `${percentageString} geographical discount`;
            const descriptionWithAnchor = `${l('DASHBOARD.GEO_DISCOUNT.DESCRIPTION', { discountCountry })}
            <br />
            <br />
            ${l('DASHBOARD.GEO_DISCOUNT.DESCRIPTION_LINK')}`;

            return (
                <DiscountBanner
                    discountDescription={descriptionWithAnchor}
                    icon="globe"
                    isTransparent={false}
                    title={title}
                />
            );
        }
        if (waiverType({ params }) === WaiverType.ArticleType) {
            const discountDescription = `${l('DASHBOARD.ARTICLE_TYPE_WAIVER.DESCRIPTION')}`;
            const title = `${l('DASHBOARD.ARTICLE_TYPE_WAIVER.TITLE')}`;
            return (
                <DiscountBanner
                    discountDescription={discountDescription}
                    icon="waiver"
                    isTransparent={false}
                    title={title}
                />
            );
        }
        if (waiverType({ params }) === WaiverType.Editorial) {
            const discountDescription = `${l('DASHBOARD.EDITORIAL_WAIVER.DESCRIPTION')}`;
            const title = `${l('DASHBOARD.EDITORIAL_WAIVER.TITLE')}`;
            return (
                <DiscountBanner
                    discountDescription={discountDescription}
                    icon="waiver"
                    isTransparent={false}
                    title={title}
                />
            );
        }
        if (waiverType({ params }) === WaiverType.Custom) {
            const discountDescription = `${l('DASHBOARD.CUSTOM_WAIVER.DESCRIPTION')}`;
            const title = `${l('DASHBOARD.CUSTOM_WAIVER.TITLE')}`;
            return (
                <DiscountBanner
                    discountDescription={discountDescription}
                    icon="waiver"
                    isTransparent={false}
                    title={title}
                />
            );
        }
        if (isInstitutionalDiscount({ params })) {
            const title = l('DASHBOARD.WOA_OA_INS_DISCOUNT.TITLE');
            const description = l('DASHBOARD.WOA_OA_INS_DISCOUNT.MESSAGE');
            return <DiscountBanner discountDescription={description} isTransparent={false} title={title} />;
        }
        if (isGeoWaiver({ params })) {
            const discountCountry = params.GEO_DISCOUNT_COUNTRY;
            const title = 'Geographical waiver';
            const description = `${l('DASHBOARD.GEO_WAIVER.DESCRIPTION', { discountCountry })}
            <br />
            <br />
            ${l('DASHBOARD.GEO_WAIVER.DESCRIPTION_LINK')}`;

            return (
                <DiscountBanner
                    discountDescription={description}
                    icon="globeOnPalm"
                    isTransparent={false}
                    title={title}
                />
            );
        }
        return null;
    };

    return (
        <View
            actions={<DefaultActions buttons={getAllActions(panel, {}, { article, journal })} />}
            additionalActions={
                isInvoiceOrProforma &&
                (downloadInvoiceLink ? (
                    <LinkButton
                        data-seleniumid="INVOICE_HELP"
                        href={l('WIDGETS.INVOICE_HELP_URL')}
                        isLinkTag
                        target="_blank"
                    >
                        {l('WIDGETS.INVOICE_HELP')}
                    </LinkButton>
                ) : (
                    <LinkButton
                        data-seleniumid="PROFORMA_HELP"
                        href={l('WIDGETS.PROFORMA_HELP_URL')}
                        isLinkTag
                        target="_blank"
                    >
                        {l('WIDGETS.PROFORMA_HELP')}
                    </LinkButton>
                ))
            }
            icon={getIcon(panel, 'payOA')}
            required={hasRequired(panel) || hasImportant(panel)}
            seleniumid="OAOrder"
            title={getTitle(panel)}
        >
            <DiscountBannerContent oaPanel={panel} />
            <div dangerouslySetInnerHTML={{ __html: getStatus(panel) }} />
        </View>
    );
}

export default withCodes(OAOrder, ID.WIDGETS);
