import { POST } from './utils';

export const assignArticle = (articleId, data): Promise<any> =>
    POST('/manualArticleAssignment', {
        articleId,
        correspondingAuthor: data.correspondingAuthor,
        selectedParticipantId: data.selectedParticipantId,
        note: data.note,
    });

export const notifyAdmin = (journalId, articleTitle, other, correspondingAuthor): Promise<any> =>
    POST('/manualArticleAssignment', {
        journalId,
        articleTitle,
        articleDoi: other.articleDoi,
        articleManuscriptId: other.articleManuscriptId,
        submissionEmailAddress: other.submissionEmailAddress,
        correspondingAuthor,
    });

export const notifyAdminMissingArticle = (data): Promise<void> => POST('/manualArticleAssignment', data);
