import React from 'react';
import doesAuthorHaveCorrespondingPrimaryRole from 'app/blocks/AuthorRole';
import { LinkButton } from 'app/blocks/buttons/buttons';
import { ID, l, withCodes } from 'app/blocks/common/codes';
import { enrichPrices } from 'app/blocks/common/getCurrencySymbol';
import EligibilityMessage from 'app/blocks/EligibilityMessage/EligibilityMessage';
import PricesSection from 'app/blocks/PricesSection';
import { BasicView, GrayView, PurpleView } from 'app/blocks/views/WidgetView';
import DefaultActions from 'app/pages/article/actions';
import ArticlePageContext from 'app/pages/article/ArticlePage.context';
import DashboardEligibilityMessage from 'app/pages/dashboard/DashboardEligibilityMessage/DashboardEligibilityMessage';
import DashboardActionCodes from 'app/types/dashboard-action-codes';

import './PricesSection.scss';

const panel = 'OOOrder';
const HIDE_DISCOUNT_ACTIONS = [
    DashboardActionCodes.VIEW_EDIT_OO_ORDER,
    DashboardActionCodes.VIEW_OO_ORDER,
    DashboardActionCodes.DOWNLOAD_INVOICE,
    DashboardActionCodes.CONTACT_SUPPORT,
];

const FullCoverageContent = ({ eligibility }) => (
    <DashboardEligibilityMessage eligibility={eligibility} isTransparent />
);
const InstitutionalDiscountContent = ({ eligibility, prices }) => {
    if (prices?.length) {
        return (
            <>
                <EligibilityMessage
                    message={eligibility.message}
                    title={l('WIDGETS.OO_ORDER.INSTITUTIONAL_DISCOUNT_MESSAGE_TITLE')}
                />
                <PricesSection
                    className="PricesSection--bordered"
                    isSecondaryText
                    isTransparent
                    prices={enrichPrices(prices)}
                />
                <div className="PricesText" data-seleniumid="prices-text">
                    {l('WIDGETS.PRICES.TEXT')}
                </div>
            </>
        );
    }

    return (
        <DashboardEligibilityMessage eligibility={{ ...eligibility, isInstitutionalDiscount: true }} isTransparent />
    );
};

const FullPriceContent = ({ item }) => {
    const { getStatus, hasAction } = item.helpers;
    let hideEmpty = false;
    HIDE_DISCOUNT_ACTIONS.forEach(action => {
        if (hasAction(panel, action) || !item[panel]?.actions.length) hideEmpty = true;
    });

    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: getStatus(panel) }} />
            <PricesSection
                isTable
                prices={item.journal.prices}
                tableOptions={{ hideEmpty, inRow: item.status.published }}
            />
        </>
    );
};

const isFullCoverageContent = ({ hasEligibility, isInstitutionalDiscount }) =>
    hasEligibility && !isInstitutionalDiscount;
const isInstitutionalDiscountContent = ({ hasEligibility, isInstitutionalDiscount }) =>
    hasEligibility && isInstitutionalDiscount;

const OOWidgetContent = ({ item }) => {
    const { eligibility, participantRole } = item;
    const hasEligibility = eligibility?.message && doesAuthorHaveCorrespondingPrimaryRole(participantRole);
    const isInstitutionalDiscount = !!eligibility?.isInstitutionalDiscount;

    if (isFullCoverageContent({ hasEligibility, isInstitutionalDiscount })) {
        return FullCoverageContent({ eligibility });
    }

    if (isInstitutionalDiscountContent({ hasEligibility, isInstitutionalDiscount })) {
        return InstitutionalDiscountContent({ eligibility, prices: item.journal.prices });
    }

    return FullPriceContent({ item });
};

const getButtonLabel = ({ hasEligibility, isInstitutionalDiscount }) => {
    if (isFullCoverageContent({ hasEligibility, isInstitutionalDiscount }))
        return l('WIDGETS.OO_ORDER.SELECT_OPEN_ACCESS_BUTTON_TEXT');
    if (isInstitutionalDiscountContent({ hasEligibility, isInstitutionalDiscount }))
        return l('WIDGETS.OO_ORDER.SELECT_OPEN_ACCESS_BUTTON_TEXT_INSTITUTIONAL_DISCOUNT');
    return '';
};

function OOOrder() {
    const item = React.useContext(ArticlePageContext);
    const { getActions, getAllActions, getTitle, hasCitations, hasRequired } = item.helpers;
    const { article, eligibility, journal, participantRole } = item;
    const hasEligibility = eligibility?.message && doesAuthorHaveCorrespondingPrimaryRole(participantRole);
    const isInstitutionalDiscount = eligibility?.isInstitutionalDiscount;
    const allActions = getAllActions(
        panel,
        {
            [DashboardActionCodes.CREATE_OO_ORDER]: getButtonLabel({ hasEligibility, isInstitutionalDiscount }),
            [DashboardActionCodes.CREATE_OO_ORDER_VCH]: getButtonLabel({ hasEligibility, isInstitutionalDiscount }),
        },
        { article, journal },
    );
    const downloadInvoiceLink = getActions(panel).DOWNLOAD_INVOICE;

    const title = getTitle(panel, hasEligibility && l('WIDGETS.OO_ORDER.SELECT_OPEN_ACCESS_TITLE'));

    let View = item.status.published && !hasRequired(panel) && hasCitations() ? GrayView : BasicView;
    if (!hasRequired(panel)) View = PurpleView;

    return (
        <View
            actions={<DefaultActions buttons={allActions} />}
            additionalActions={
                downloadInvoiceLink && (
                    <LinkButton
                        data-seleniumid="INVOICE_HELP"
                        href={l('WIDGETS.INVOICE_HELP_URL')}
                        isLinkTag
                        target="_blank"
                    >
                        {l('WIDGETS.INVOICE_HELP')}
                    </LinkButton>
                )
            }
            icon="openAccess"
            required={hasRequired(panel)}
            seleniumid="OOOrder"
            title={title}
        >
            <OOWidgetContent item={item} />
        </View>
    );
}

export default withCodes(OOOrder, ID.WIDGETS);
