import _ from 'underscore';

function Currency() {}
Currency.prototype = {
    toString() {
        return this.key;
    },
    is(str) {
        // eslint-disable-next-line eqeqeq
        return this == str;
    },
    not(str) {
        return !this.is(str);
    },
};

const currency = {
    EUR: _.extend(new Currency(), {
        key: 'EUR',
        sign: '€',
    }),
    USD: _.extend(new Currency(), {
        key: 'USD',
        sign: '$',
    }),
    GBP: _.extend(new Currency(), {
        key: 'GBP',
        sign: '£',
    }),
    RMB: _.extend(new Currency(), {
        key: 'RMB',
        sign: '¥',
    }),
};

currency.all = [currency.EUR, currency.USD, currency.GBP, currency.CNY];

function getCurrencySign(key) {
    const foundCurrency = currency[key];
    return (foundCurrency && foundCurrency.sign) || '';
}

export default _.extend(currency, { getCurrencySign });
