import React from 'react';
import { PrimaryButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import StepTracker from 'app/blocks/step-tracker/StepTracker';
import { BackButton, ForwardButton, BackAndForthButtons } from 'app/pages/license-signing-process/buttons';
import { SectionTitle } from 'app/pages/license-signing-process/styles';
import CheckboxButton from 'app/ui/form/CheckboxButton';

import './SingleAuthor.scss';

function SingleAuthorView({
    l,
    currentStep,
    textParams,
    isObtained,
    setIsObtained,
    submitLicense,
    canConfirm,
    confirmStep,
    setCurrentStep,
    stepTracker,
    uploader,
    getPrevStep,
    goBack,
    isFlowStarted,
}) {
    const { isPdf, currentSubfolder, longOwner, currentText, standardLink } = textParams;
    const status = l(`LICENSE_SUBMISSION.LABELS.PROGRESS.${isFlowStarted ? 'IN_PROGRESS' : 'NOT_STARTED'}`);

    return (
        <>
            {currentStep === 0 && (
                <>
                    <SectionTitle data-seleniumid="license-submission-page-single-author-title">
                        {l('LICENSE_SUBMISSION.LABELS.TITLE')}
                    </SectionTitle>
                    <div className="LicenseSubmission-SubTitle">{l('LICENSE_SUBMISSION.LABELS.SUBTITLE')}</div>
                    <div className="LicenseSubmission-Container">
                        <div className="LicenseName">
                            <div>
                                <div className="LicenseName-GrayText">{l('LICENSE_SUBMISSION.LABELS.GRAY_TEXT')}</div>
                                <div className="LicenseName-Name" data-seleniumid="license-submission-ownership">
                                    {longOwner}
                                </div>
                            </div>
                            <div className="StatusContainer">
                                <div className="StatusContainer-Text">Status:</div>
                                <div className="StatusContainer-Status" data-seleniumid="license-submission-status">
                                    {status}
                                </div>
                            </div>
                        </div>
                        <div className="InitiateContainer">
                            <div className="InitiateContainer-Text" dangerouslySetInnerHTML={{ __html: currentText }} />
                            <div className="InitiateContainer-Button">
                                <PrimaryButton
                                    className="btn-left-icon"
                                    data-seleniumid="goForthButton"
                                    onClick={() => confirmStep()}
                                >
                                    <SvgIcon.edit />
                                    {l(`LICENSE_SUBMISSION.LABELS.${currentSubfolder}.BUTTON_TEXT`)}
                                </PrimaryButton>
                            </div>
                        </div>
                    </div>
                    <div className="LicenseSubmission-Actions">
                        <BackButton onClick={goBack}>{getPrevStep().name}</BackButton>
                    </div>
                </>
            )}
            {currentStep > 0 && (
                <div className="LicenseSubmission-StepsContainer">
                    <div className="LicenseName">
                        <div>
                            <div className="LicenseName-Title">{l('LICENSE_SUBMISSION.LABELS.TITLE')}</div>
                            <div className="LicenseName-Name" data-seleniumid="license-submission-ownership">
                                {longOwner}
                            </div>
                        </div>
                        <div className="StatusContainer">
                            <div className="StatusContainer-Text">Status:</div>
                            <div className="StatusContainer-Status" data-seleniumid="license-submission-status">
                                {status}
                            </div>
                        </div>
                        <div className="LicenseName-ProgressText">{l('LICENSE_SUBMISSION.LABELS.PROGRESS_TEXT')}</div>
                    </div>
                    <StepTracker
                        currentStep={currentStep}
                        isPdf={isPdf}
                        setCurrentStep={setCurrentStep}
                        stepTracker={stepTracker}
                    />
                    {currentStep === 1 && isPdf && (
                        <div className="DownloadContainer">
                            <div className="DownloadContainer-Caption">
                                {l(`LICENSE_SUBMISSION.LABELS.${currentSubfolder}.CAPTION_DOWNLOAD`, textParams)}
                            </div>
                            <div className="DownloadContainer-Download">
                                <PrimaryButton
                                    className="btn-left-icon"
                                    data-seleniumid="license-submission-download-pdf"
                                    href={standardLink}
                                    isLinkTag
                                    onClick={() => setIsObtained(true)}
                                    target="_blank"
                                >
                                    <SvgIcon.download />
                                    {l(`LICENSE_SUBMISSION.LABELS.${currentSubfolder}.DOWNLOAD_LABEL`, textParams)}
                                </PrimaryButton>
                                <div className="Hint">
                                    {l(`LICENSE_SUBMISSION.LABELS.${currentSubfolder}.DOWNLOAD_HINT`)}
                                </div>
                            </div>
                        </div>
                    )}
                    {currentStep === 1 && !isPdf && (
                        <div className="ObtainContainer">
                            <div className="ObtainContainer-Caption">
                                {l(`LICENSE_SUBMISSION.LABELS.${currentSubfolder}.CAPTION_OBTAIN`, textParams)}
                            </div>
                            <div className="ObtainContainer-Text" dangerouslySetInnerHTML={{ __html: currentText }} />
                            <div className="ObtainContainer-CheckBox">
                                <CheckboxButton
                                    checked={isObtained}
                                    data-seleniumid="license-submission-obtain-pdf"
                                    label={
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: l(
                                                    `LICENSE_SUBMISSION.LABELS.${currentSubfolder}.CHECKBOX_LABEL`,
                                                    textParams,
                                                ),
                                            }}
                                        />
                                    }
                                    onChange={() => setIsObtained(!isObtained)}
                                />
                            </div>
                        </div>
                    )}
                    {currentStep === 2 && (
                        <div className="InstructionsContainer">
                            <div className="InstructionsContainer-Caption">
                                {l(`LICENSE_SUBMISSION.LABELS.${currentSubfolder}.CAPTION`, textParams)}
                            </div>
                            <div
                                className="InstructionsContainer-Text"
                                dangerouslySetInnerHTML={{ __html: currentText }}
                                data-seleniumid="license-submission-instructions-text"
                            />
                        </div>
                    )}
                    {currentStep === 3 && (
                        <div className="UploadContainer">
                            <div className="UploadContainer-Caption">
                                {l(`LICENSE_SUBMISSION.LABELS.${currentSubfolder}.CAPTION`, textParams)}
                            </div>
                            <div className="UploadContainer-Text" dangerouslySetInnerHTML={{ __html: currentText }} />

                            {uploader}
                        </div>
                    )}
                    {currentStep < 3 && (
                        <div key={currentStep} className="Actions">
                            <hr className="Actions-Spacer" />
                            <div className="Actions-ButtonsContainer">
                                {currentStep === 1 ? (
                                    <BackButton onClick={goBack}>{getPrevStep().name}</BackButton>
                                ) : (
                                    <BackButton onClick={() => setCurrentStep(currentStep - 1)}>
                                        {l(
                                            `LICENSE_SUBMISSION.LABELS.OBTAIN.${
                                                isPdf ? 'DOWNLOAD' : 'OBTAIN'
                                            }_BACK_BUTTON_TEXT`,
                                        )}
                                    </BackButton>
                                )}

                                <ForwardButton disabled={!isObtained} onClick={() => confirmStep()}>
                                    {l('LICENSE_SUBMISSION.LABELS.BUTTON_TEXT')}
                                </ForwardButton>
                            </div>
                        </div>
                    )}
                    {currentStep === 3 && (
                        <BackAndForthButtons
                            buttonCancelLabel={l('LICENSE_SUBMISSION.LABELS.INSTRUCTIONS.BACK_BUTTON_TEXT')}
                            buttonConfirmLabel={l('LICENSE_SUBMISSION.LABELS.BUTTON_TEXT')}
                            canConfirm={canConfirm}
                            className="Actions-ButtonsContainer"
                            onCancel={() => setCurrentStep(2)}
                            onConfirm={submitLicense}
                        />
                    )}
                </div>
            )}
        </>
    );
}

export default withCodes(SingleAuthorView, ID.LICENSE_SUBMISSION);
