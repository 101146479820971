import React from 'react';
import { Navigate } from 'react-router-dom';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { useAuth } from 'app/blocks/common/providers/AuthProvider';
import { compose } from 'app/blocks/common/utils';
import withConfirmLeaving, { onLeavePage } from 'app/blocks/common/withConfirmLeaving';
import withScrollToTop from 'app/blocks/common/withScrollToTop';
import OrdersRow from 'app/blocks/Row/Row';
import { BackAndForthButtons } from 'app/pages/license-signing-process/buttons';
import { useLicenseFlow } from 'app/pages/license-signing-process/context/Context';
import { BackAndForthContainer, SectionPrompt, SectionTitle } from 'app/pages/license-signing-process/styles';
import { navigate } from 'app/route/history';
import ConfirmAuthorRolesCheckboxList from './ConfirmAuthorRolesCheckboxList';
import './IncorrectList.scss';

const labels = {
    'Author has changed name': 'LICENSE_SIGNING.CONFIRM_AUTHORS.INCORRECT_LIST.AUTHOR_HAS_CHANGED_NAME',
    'List is out of order': 'LICENSE_SIGNING.CONFIRM_AUTHORS.INCORRECT_LIST.LIST_IS_OUT_OF_ORDER',
    'One or more authors are missing': 'LICENSE_SIGNING.CONFIRM_AUTHORS.INCORRECT_LIST.ONE_OR_MORE_AUTHORS_ARE_MISSING',
    'Someone listed but should not': 'LICENSE_SIGNING.CONFIRM_AUTHORS.INCORRECT_LIST.SOMEONE_LISTED_BUT_SHOULD_NOT',
    'Something else': 'LICENSE_SIGNING.CONFIRM_AUTHORS.INCORRECT_LIST.SOMETHING_ELSE',
    'Spelled or formatted incorrectly':
        'LICENSE_SIGNING.CONFIRM_AUTHORS.INCORRECT_LIST.SPELLED_OR_FORMATTED_INCORRECTLY',
};

function getOptions(l) {
    return [
        'One or more authors are missing',
        'Someone listed but should not',
        'List is out of order',
        'Author has changed name',
        'Spelled or formatted incorrectly',
        'Something else',
    ].map(value => ({
        label: l(labels[value]),
        value,
    }));
}

function isReasonCritical(reason) {
    return ['One or more authors are missing', 'Someone listed but should not', 'Something else'].includes(reason);
}

function IncorrectList({ confirmIncorrectAuthorList, l, setChangesChecker, unsetChangesChecker }) {
    React.useEffect(() => {
        // no not trigger modal in case of fixing browser address bar in Context
        const timeout = setTimeout(() => setChangesChecker(() => true, onLeavePage), 1000);

        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { article, isIncorrectAuthorListConfirming } = useLicenseFlow();
    const [reasons, setReasons] = React.useState([]);
    const [message, setMessage] = React.useState('');

    const { user } = useAuth();
    const options = React.useMemo(() => getOptions(l), [l]);

    const shouldShowTextarea = reasons.some(isReasonCritical);

    const canConfirm = reasons.length > 0 && (!shouldShowTextarea || message.length > 0);

    if (user.disableIncorrectList) {
        return <Navigate to={`/license-signing/${article.id}`} />;
    }

    return (
        <>
            <section className="IncorrectList">
                <SectionTitle>{l('LICENSE_SIGNING.CONFIRM_AUTHORS.INCORRECT_LIST.TITLE')}</SectionTitle>

                <SectionPrompt>{l('LICENSE_SIGNING.CONFIRM_AUTHORS.INCORRECT_LIST.PROMPT')}</SectionPrompt>

                <div className="IncorrectList-Reasons">
                    <ConfirmAuthorRolesCheckboxList onChange={setReasons} options={options} values={reasons} />

                    {shouldShowTextarea && (
                        <OrdersRow
                            className="IncorrectList-TextArea"
                            formSeleniumid="address"
                            label={l('LICENSE_SIGNING.CONFIRM_AUTHORS.INCORRECT_LIST.TELL_US')}
                            multiline
                            onChange={setMessage}
                            value={message}
                        />
                    )}
                </div>
            </section>

            <BackAndForthContainer>
                <BackAndForthButtons
                    buttonCancelLabel={l('LICENSE_SIGNING.BUTTON.CORRESPONDING_AUTHORS')}
                    buttonConfirmLabel={l('BUTTONS.CONTINUE')}
                    canConfirm={canConfirm}
                    isConfirming={isIncorrectAuthorListConfirming}
                    onCancel={() => {
                        unsetChangesChecker();
                        navigate(`/license-signing/${article.id}/confirmAuthors`);
                    }}
                    onConfirm={async () => {
                        const canContinue = await confirmIncorrectAuthorList(reasons, message);

                        unsetChangesChecker();

                        if (canContinue) {
                            navigate(`/license-signing/${article.id}/confirmAuthors/continue`);
                        }
                    }}
                    processingLabel={l('BUTTONS.CONFIRMING')}
                />
            </BackAndForthContainer>
        </>
    );
}

export default compose(
    withConfirmLeaving,
    withScrollToTop,
    withCodes2(ID.BUTTONS, ID.LICENSE_SIGNING),
    React.memo,
)(IncorrectList);
