import cn from 'classnames';
import React from 'react';
import RadioButton from '../RadioButton';
import './RadioList.scss';

export default function RadioList({
    className,
    onChange,
    options,
    value,
    style,
    ...props
}: {
    className?: string;
    options: Array<{ value: string; label: string }>;
    onChange: (value: string) => void;
    value: string;
    style?: Record<string, string>;
    [prop: string]: any;
}): React.ReactElement {
    return (
        <div className={cn('RadioList', className)} style={style}>
            {options.map(({ value: optionValue, label }) => (
                <RadioButton
                    {...props}
                    key={optionValue}
                    checked={optionValue === value}
                    data-seleniumid={`Radio-${optionValue}`}
                    label={label}
                    onChange={() => onChange(optionValue)}
                    value={optionValue}
                />
            ))}
        </div>
    );
}
