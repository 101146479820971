import React from 'react';
import TopMenu from 'app/blocks/page/TopMenu/TopMenu';

function withTopMenu<P>(Component: React.ComponentType<P>): React.ComponentType<P> {
    return function (props: P) {
        return (
            <TopMenu {...props}>
                <Component {...props} />
            </TopMenu>
        );
    };
}

export { withTopMenu };

export default Component => withTopMenu(Component);
