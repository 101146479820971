import { REST } from './utils';

const { GET, POST } = REST('/article');

type Author = {
    participantId: string;
    firstName: string;
    lastName: string;
    name: string;
    email: string;
    role: ParticipantRole;
};

type ResearchProgram = {
    researchFunder: {
        name: string;
        parentId: string;
        doi: string;
        id: string;
    };
    authors: Author[];
    grantNumbers: string[];
};

export interface ResearchProgramPayload {
    latestChangeByUser: boolean;
    licenseSigned: boolean;
    researchPrograms: ResearchProgram[];
}

export const getResearchPrograms = (articleId: string): Promise<ResearchProgramPayload> =>
    GET(`/${articleId}/researchFunders`);

export const saveResearchPrograms = (articleId: string, researchPrograms): Promise<ResearchProgramPayload> =>
    POST(`/${articleId}/researchFunders`, researchPrograms);

export const checkForLicenseReset = (articleId: string, funderId: string): Promise<boolean> =>
    POST(`/${articleId}/researchFunders/checkForLicenseReset/${funderId}`);

export const getCountriesForArticle = (articleId: string): Promise<{ content: string[] }> =>
    GET(`/${articleId}/affiliations/countries`);
