import { autobind } from 'core-decorators';
import React from 'react';
import { LinkButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import processing from 'app/blocks/common/decorators/processing';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import PaymentPanelEnum from 'app/pages/license-signing-process/Payment/PaymentPanelEnum';

@autobind
class ContactReviewPanel extends React.Component {
    state = { isEditing: false };

    @processing('isEditing')
    async onRequestEditOrder() {
        await this.props.onRequestEditOrder(PaymentPanelEnum.CONTACT);
    }

    render() {
        const { l, DataItemAndAuxInfo, showContactAddress, showBillingAddress, isEditable } = this.props;
        const { isEditing } = this.state;

        return (
            <div className="row order-btm-border" data-seleniumid="order-contact-panel-review">
                <div className="col-md-10 col-12 nopadding">
                    <div className="m-btm_md">
                        <h5>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {showContactAddress && showBillingAddress
                                ? 'Contact and Billing Information'
                                : showBillingAddress
                                ? 'Billing Information'
                                : 'Contact Information'}
                        </h5>
                    </div>
                    <div className="row">
                        <div
                            className={`${showBillingAddress ? 'col-12 col-md-6' : 'col-12'} m-btm_md`}
                            hidden={!showContactAddress}
                        >
                            <div className="text_md m-btm_mini" hidden={!showBillingAddress}>
                                <span className="bluetxt">
                                    {l('ORDER_PANELS.PANEL_CONTACT_REVIEW.CONTACT_ADDRESS')}
                                </span>
                            </div>
                            <div className="order-article">
                                <DataItemAndAuxInfo.OrdersDataItem
                                    innerInfo={[['correspondenceAddress.fullName']]}
                                    seleniumid="order-review-correspondenceAddress-fullName"
                                />
                            </div>
                            <DataItemAndAuxInfo.OrdersDataItem
                                innerInfo={[['correspondenceAddress.institutionName']]}
                                seleniumid="order-review-correspondenceAddress-institutionName"
                            />

                            <DataItemAndAuxInfo.OrdersDataItem
                                innerInfo={[['correspondenceAddress.departmentName']]}
                                seleniumid="order-review-correspondenceAddress-departmentName"
                            />

                            <DataItemAndAuxInfo.OrdersDataItem
                                innerInfo={[
                                    ['correspondenceAddress.addressLine1'],
                                    ['correspondenceAddress.addressLine2', ', '],
                                ]}
                                seleniumid="order-review-correspondenceAddress-addressLine"
                            />
                            <DataItemAndAuxInfo.OrdersDataItem
                                innerInfo={[
                                    ['correspondenceAddress.city'],
                                    ['correspondenceAddress.stateName', ', '],
                                    ['correspondenceAddress.zipCode', ' '],
                                ]}
                                seleniumid="order-review-correspondenceAddress-cityStateZipCode"
                            />

                            <div className="m-btm_mini">
                                <DataItemAndAuxInfo.OrdersDataItem
                                    innerInfo={[['correspondenceAddress.countryName']]}
                                    seleniumid="order-review-correspondenceAddress-countryName"
                                />
                            </div>
                            <DataItemAndAuxInfo.OrdersDataItem
                                innerInfo={[['correspondenceAddress.phoneNumber']]}
                                seleniumid="order-review-correspondenceAddress-phoneNumber"
                            />
                            <DataItemAndAuxInfo.OrdersDataItem
                                innerInfo={[['correspondenceAddress.primaryEmail']]}
                                seleniumid="order-review-correspondenceAddress-primaryEmail"
                            />
                        </div>
                        <div
                            className={`${showContactAddress ? 'col-12 col-md-6' : 'col-12'} m-btm_md`}
                            hidden={!showBillingAddress}
                        >
                            <div className="text_md m-btm_mini" hidden={!showContactAddress}>
                                <span className="bluetxt">
                                    {l('ORDER_PANELS.PANEL_CONTACT_REVIEW.BILLING_ADDRESS')}
                                </span>
                            </div>
                            <div className="order-article">
                                <DataItemAndAuxInfo.OrdersDataItem
                                    innerInfo={[['billingAddress.fullName']]}
                                    seleniumid="order-review-billingAddress-fullName"
                                />
                            </div>
                            <DataItemAndAuxInfo.OrdersDataItem
                                innerInfo={[['billingAddress.institutionName']]}
                                seleniumid="order-review-billingAddress-institutionName"
                            />
                            <DataItemAndAuxInfo.OrdersDataItem
                                innerInfo={[['billingAddress.departmentName']]}
                                seleniumid="order-review-billingAddress-departmentName"
                            />
                            <DataItemAndAuxInfo.OrdersDataItem
                                innerInfo={[['billingAddress.addressLine1'], ['billingAddress.addressLine2', ', ']]}
                                seleniumid="order-review-billingAddress-addressLine"
                            />
                            <DataItemAndAuxInfo.OrdersDataItem
                                innerInfo={[
                                    ['billingAddress.city'],
                                    ['billingAddress.stateName', ', '],
                                    ['billingAddress.zipCode', ' '],
                                ]}
                                seleniumid="order-review-billingAddress-cityStateZipCode"
                            />
                            <div className="m-btm_mini">
                                <DataItemAndAuxInfo.OrdersDataItem
                                    innerInfo={[['billingAddress.countryName']]}
                                    seleniumid="order-review-billingAddress-countryName"
                                />
                            </div>
                            <DataItemAndAuxInfo.OrdersDataItem
                                innerInfo={[['billingAddress.phoneNumber']]}
                                seleniumid="order-review-billingAddress-phoneNumber"
                            />
                            <DataItemAndAuxInfo.OrdersDataItem
                                innerInfo={[['billingAddress.primaryEmail']]}
                                seleniumid="order-review-billingAddress-primaryEmail"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-2 col-12 m-btm_mini text-right nopadding">
                    <div hidden={!isEditable}>
                        <LinkButton
                            data-seleniumid="order-review-address-edit-button"
                            disabled={isEditing}
                            isBlack
                            onClick={this.onRequestEditOrder}
                        >
                            <SvgIcon.edit iconTitle="Edit" />
                            {isEditing ? l('BUTTONS.LOADING') : l('ORDER_PANELS.PANEL_CONTACT_REVIEW.EDIT_BTN')}
                        </LinkButton>
                    </div>
                </div>
            </div>
        );
    }
}

export default withCodes2(ID.ORDER_PANELS, ID.BUTTONS)(ContactReviewPanel);
