import React from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import { BasicView } from 'app/blocks/views/WidgetView';
import { useArticlePage } from 'app/pages/article/ArticlePage.context';
import DefaultActions from '../actions';

const panel = 'funding';

function Funders(): React.ReactElement {
    const item = useArticlePage();
    const { getAllActions, getStatus, getTitle, hasRequired } = item.helpers;

    return (
        <BasicView
            actions={<DefaultActions buttons={getAllActions(panel)} />}
            icon="sign"
            required={hasRequired(panel)}
            seleniumid="funders"
            title={getTitle(panel)}
        >
            {getStatus(panel)}
        </BasicView>
    );
}

export default withCodes(Funders, ID.WIDGETS);
