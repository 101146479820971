import { matchPath } from 'react-router-dom';
import appPages from 'app/appPages';
import routes from 'app/route/routes';

const pages = {
    ArticlePage: appPages.ARTICLE_PAGE,
    AssignArticle: appPages.ASSIGN_ARTICLE,
    ChangePrimaryEmail: appPages.CHANGE_PRIMARY_EMAIL,
    Dashboard: appPages.DASHBOARD,
    EmailAlertsPage: appPages.EMAIL_ALERTS,
    Home: appPages.HOME,
    LicenseSigning: appPages.LICENSE_SIGNING,
    Login: appPages.LOGIN,
    OpenAccessConfirmation: appPages.OPEN_ACCESS_CONFIRMATION,
    Order: appPages.ORDER,
    Orders: appPages.ORDERS,
    OrdersFocusOnArticle: appPages.ORDERS_FOCUS_ON_ARTICLE,
    Profile: appPages.PROFILE,
    PubEditorReview: appPages.PUB_EDITOR_REVIEW,
    RecoverPassword: appPages.RECOVER_PASSWORD,
    Registration: appPages.REGISTRATION,
    RegistrationByInvite: appPages.REGISTRATION_BY_INVITE,
    ResetPassword: appPages.RESET_PASSWORD,
    ReviewPDF: appPages.REVIEW_PDF,
    Search: appPages.SEARCH,
    TermsOfService: appPages.TERMS_OF_SERVICE,
    VerifyEmail: appPages.VERIFY_EMAIL,
    Version: appPages.VERSION,
};

const getCurrentPage = () => {
    const path = `/${document.location.hash.replace(/^#\/?/, '')}`;

    const route = routes.find(({ props }) => matchPath(props, path || ''));
    return pages[route?.key] ?? route?.key;
};

export default getCurrentPage;
