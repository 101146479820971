import cn from 'classnames';
import React from 'react';
import NotifyAffiliationsPopup from 'app/blocks/article-affiliations-editor/article-affiliations-panel/NotifyAffiliationsPopup';
import NotifyAffiliationsSuccessPopup from 'app/blocks/article-affiliations-editor/article-affiliations-panel/NotifyAffiliationsSuccessPopup';
import { LinkButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import { isOnlineOpenJournal } from 'app/blocks/common/products';
import StatusPopup from 'app/blocks/StatusPopup/StatusPopup';
import ConfirmCountryBlock from './ConfirmCountryBlock/ConfirmCountryBlock';
import Geoblock from './Geoblock/Geoblock';
import Header from './Header/Header';
import Items from './Institutions/Institutions';
import NewItem from './NewInstitution/NewInstitution';
import NoAffiliations from './NoAffiliations';
import './articleAffiliationsPanel.scss';

type Props = {
    articleAffiliations: any;
    geoTargetedMessage?: string;
    isDisabled?: boolean;
    isEmptyAffiliations?: boolean;
    journal: Product.Journal;
    l: l;
    newAffiliation?: boolean;
    onAdd: () => void;
    onCancel: () => void;
    onCloseStatusPopup: GetProps<typeof StatusPopup>['onCloseStatusPopup'];
    noGeoWaiver: boolean;
    onNoGeoWaiverChange: (value: boolean) => void;
    onPriceProposalCountryChange: (value: boolean) => void;
    onCountryChange: (countryName: string) => void;
    statusPopup?: {
        text: string;
        type: GetProps<typeof StatusPopup>['type'];
    };
    countryDropdownVisible?: boolean;
} & GetProps<typeof Items> &
    GetProps<typeof NoAffiliations>;

function ArticleAffiliationsPanel({
    affiliations,
    article,
    articleAffiliations,
    blacklist,
    countryDropdownVisible,
    geoTargetedMessage,
    institutionsIdFromProfile,
    isDisabled,
    isEmptyAffiliations,
    journal,
    l,
    newAffiliation,
    noAffiliations,
    noGeoWaiver,
    onAdd,
    onCancel,
    onChange,
    onCloseStatusPopup,
    onCountryChange,
    onDelete,
    onNoAffiliationsChange,
    onNoGeoWaiverChange,
    onPriceProposalCountryChange,
    statusPopup,
    step,
    zeroPriceOrderExists,
}: Props) {
    const onNotifyAffiliations = () => {
        showDialog.modal(closeAnd => ({
            affiliations,
            articleId: article?.id,
            modalHeading: l('LICENSE_SIGNING.NOTIFYAFFILIATION.LABEL.HEADING'),
            onClick: closeAnd(() => {
                showDialog.modal(_closeAnd => ({
                    modalHeading: l('LICENSE_SIGNING.NOTIFYAFFILIATION.LABEL.HEADING'),
                    onClose: _closeAnd(),
                    type: NotifyAffiliationsSuccessPopup,
                }));
            }),
            onClose: closeAnd(),
            type: NotifyAffiliationsPopup,
        }));
    };

    const isRevenueModel_OA_OR_OO =
        journal &&
        journal?.revenueModel &&
        (journal?.revenueModel === 'OA' ||
            journal?.openAccess ||
            journal?.revenueModel === 'OO' ||
            journal?.onlineOpen);

    const isRevenueModel_OA =
        journal && journal?.revenueModel && (journal?.revenueModel === 'OA' || journal?.openAccess);
    const { cancelableAuthorPaidOrderExists, countryName, geoWaiverExists } = articleAffiliations;

    return (
        <>
            <Header
                isEmptyAffiliations={isEmptyAffiliations}
                isOnlineOpenJournal={isOnlineOpenJournal(journal)}
                newAffiliation={newAffiliation}
                step={step}
            />

            {geoTargetedMessage && <Geoblock geoTargetedMessage={geoTargetedMessage} />}
            {statusPopup && (
                <StatusPopup
                    onCloseStatusPopup={onCloseStatusPopup}
                    seleniumid="article-affiliations-status-popup"
                    type={statusPopup.type}
                >
                    {statusPopup.text}
                </StatusPopup>
            )}

            <Items
                affiliations={affiliations}
                article={article}
                blacklist={blacklist}
                cancelableAuthorPaidOrderExists={cancelableAuthorPaidOrderExists}
                institutionsIdFromProfile={institutionsIdFromProfile}
                onChange={onChange}
                onDelete={onDelete}
                zeroPriceOrderExists={zeroPriceOrderExists}
            />

            {!newAffiliation && (
                <div
                    className={cn('NewAffiliation', {
                        'text_align-right': !isEmptyAffiliations,
                        text_center: isEmptyAffiliations,
                    })}
                >
                    <button
                        className="btn btn-white"
                        data-seleniumid="article-affiliations-add"
                        disabled={isDisabled || noAffiliations}
                        onClick={onAdd}
                        type="button"
                    >
                        {l('ARTICLE_AFFILIATION_EDITOR.NEW_AFFILIATION_BUTTON')}
                    </button>
                </div>
            )}
            {newAffiliation && (
                <NewItem
                    blacklist={blacklist}
                    institutionsIdFromProfile={institutionsIdFromProfile}
                    onCancel={onCancel}
                    onChange={onChange}
                />
            )}
            {isEmptyAffiliations && (
                <NoAffiliations noAffiliations={noAffiliations} onNoAffiliationsChange={onNoAffiliationsChange} />
            )}
            {isRevenueModel_OA_OR_OO && (
                <div className="mt-20">
                    <LinkButton
                        className="notify-btn"
                        data-seleniumid="goToaffiliationfeedback"
                        isUnderline
                        onClick={onNotifyAffiliations}
                    >
                        {l('LICENSE_SIGNING.NOTIFYAFFILIATION.CONTENT.LINK')}
                    </LinkButton>{' '}
                    <span>{l('LICENSE_SIGNING.NOTIFYAFFILIATION.CONTENT.LINK_POSTFIX')}</span>
                </div>
            )}
            {isRevenueModel_OA && countryDropdownVisible && (
                <div className="confirmCountryBlockHr" data-seleniumid="article-affiliations-confirm-country">
                    <ConfirmCountryBlock
                        data-seleniumid="article-affiliations-confirm-country"
                        discountCountry={countryName !== null ? countryName : undefined}
                        geoWaiverExists={geoWaiverExists}
                        noGeoWaiver={noGeoWaiver}
                        onCountryChange={onCountryChange}
                        onNoGeoWaiverChange={onNoGeoWaiverChange}
                        onPriceProposalCountryChange={onPriceProposalCountryChange}
                        step="confirmCountry"
                    />
                </div>
            )}
        </>
    );
}

export default withCodes(ArticleAffiliationsPanel, ID.ARTICLE_AFFILIATION_EDITOR);
