import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import { Layout } from 'app/blocks/blocks';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { compose } from 'app/blocks/common/utils';
import withScrollToTop from 'app/blocks/common/withScrollToTop';
import FunderPanelContext2 from 'app/blocks/Panel/funder/FunderPanelContext2';
import PromptAction from 'app/blocks/Prompt/_action/prompt_action';
import StatusPopup from 'app/blocks/StatusPopup/StatusPopup';
import { BackAndForthButtons } from 'app/pages/license-signing-process/buttons';
import GrantDetails from 'app/pages/license-signing-process/Funders/GrantDetails';
import Institutions from 'app/pages/license-signing-process/Funders/Institutions/Institutions';
import NoFunders from 'app/pages/license-signing-process/Funders/NoFunders';
import { SectionPrompt, SectionTitle } from 'app/pages/license-signing-process/styles';
import Context from '../context/Context';
import './Funders.scss';

const FundersStepEnum = {
    FUNDERS: 'FUNDERS',
    GRANT_DETAILS: 'GRANT_DETAILS',
};

class Funders extends React.PureComponent {
    static propTypes = {
        addResearchFunder: PropTypes.func.isRequired,
        article: PropTypes.shape({}).isRequired,
        data: PropTypes.shape({
            isEditing: PropTypes.bool,
            noGrants: PropTypes.bool,
            program: PropTypes.shape({}),
        }).isRequired,
        canConfirmGrants: PropTypes.bool.isRequired,
        canConfirmResearchFunders: PropTypes.bool.isRequired,
        confirmConfirm: PropTypes.func.isRequired,
        getPrevStep: PropTypes.func.isRequired,
        goBack: PropTypes.func.isRequired,
        isFundersConfirming: PropTypes.bool.isRequired,
        isProcessing: PropTypes.bool.isRequired,
        journal: PropTypes.shape({ revenueModel: PropTypes.string }).isRequired,
        l: PropTypes.func.isRequired,
        noResearchPrograms: PropTypes.bool.isRequired,
        onCloseStatusPopup: PropTypes.func.isRequired,
        statusPopup: PropTypes.shape({
            text: PropTypes.string,
            type: PropTypes.string,
        }),
    };

    static defaultProps = {
        statusPopup: undefined,
    };

    state = {
        fundersStep: FundersStepEnum.FUNDERS,
    };

    getNoFundersPrompt(l, areThereAnyPrograms) {
        return !areThereAnyPrograms ? (
            <div className="NoFunder-Info" data-seleniumid="funder-banner">
                <SvgIcon.info className="InfoIcon" />
                {l('LICENSE_SIGNING.FUNDERS.FUNDERS.PROMPT.SPECIFY.NO_FUNDER_INFO')}
            </div>
        ) : null;
    }

    render() {
        const {
            addResearchFunder,
            canConfirmGrants,
            canConfirmResearchFunders,
            data,
            isProcessing,
            l,
            noResearchPrograms,
            onCloseStatusPopup,
            onLoadSubstep,
            statusPopup,
        } = this.props;
        const { fundersStep } = this.state;

        if (isProcessing) {
            return (
                <section className="funders">
                    <Layout isLoading />
                </section>
            );
        }

        const programs = Object.values(data).map(x => x.program);
        const isDisabled = false;
        const isEmptyFunders = programs.length === 0;
        const emptyProgram = programs.find(x => !x.researchFunder);

        return (
            <>
                <section className="funders">
                    {fundersStep === FundersStepEnum.FUNDERS && (
                        <>
                            <header className="licenseSigningProcessPage-sectionHeader">
                                <SectionTitle data-seleniumid="funders-blockTitle">
                                    {l(
                                        `LICENSE_SIGNING.FUNDERS.FUNDERS.TITLE${
                                            programs.length > 0 ? '.CONFIRM' : '.SPECIFY'
                                        }`,
                                    )}
                                </SectionTitle>
                                <PromptAction nodeId="funderEditor" seleniumid="showFundersHintButton" />
                            </header>

                            <SectionPrompt data-seleniumid="correctly-declare-info">
                                {l('LICENSE_SIGNING.FUNDERS.FUNDERS.PROMPT.CORRECTLY_DECLARE_INFO')}
                            </SectionPrompt>

                            {this.getNoFundersPrompt(l, programs.length > 0)}

                            {statusPopup && (
                                <StatusPopup
                                    onCloseStatusPopup={onCloseStatusPopup}
                                    seleniumid="status-popup"
                                    type={statusPopup.type}
                                >
                                    {statusPopup.text}
                                </StatusPopup>
                            )}

                            <Institutions
                                onLoadSubstep={onLoadSubstep}
                                page={FundersStepEnum.FUNDERS}
                                substep={fundersStep.toLowerCase()}
                            />

                            {!emptyProgram && (
                                <div
                                    className={cn('NewFunder', {
                                        text_center: isEmptyFunders,
                                        'text_align-right': !isEmptyFunders,
                                    })}
                                >
                                    <button
                                        className="btn btn-left-icon btn-white"
                                        data-seleniumid="addFunderButton"
                                        disabled={isDisabled || noResearchPrograms}
                                        onClick={addResearchFunder}
                                        type="button"
                                    >
                                        <SvgIcon.add iconTitle="Add Icon" />
                                        {l('LICENSE_SIGNING.BUTTON.ADD_FUNDER')}
                                    </button>
                                </div>
                            )}

                            {isEmptyFunders && <NoFunders />}
                        </>
                    )}

                    {fundersStep === FundersStepEnum.GRANT_DETAILS && (
                        <>
                            <SectionTitle>{l('LICENSE_SIGNING.FUNDERS.GRANT_DETAILS.TITLE')}</SectionTitle>

                            <SectionPrompt>{l('LICENSE_SIGNING.FUNDERS.GRANT_DETAILS.PROMPT')}</SectionPrompt>

                            <GrantDetails
                                onLoadSubstep={onLoadSubstep}
                                page={FundersStepEnum.GRANT_DETAILS}
                                substep={fundersStep.toLowerCase()}
                            />
                        </>
                    )}
                </section>
                <div
                    className={cn('licenseSigningProcessPage-backAndForth', {
                        'licenseSigningProcessPage-backAndForth--noBorder':
                            fundersStep === FundersStepEnum.GRANT_DETAILS,
                    })}
                >
                    {fundersStep === FundersStepEnum.FUNDERS && (
                        <BackAndForthButtons
                            buttonCancelLabel={this.props.getPrevStep().name}
                            buttonConfirmLabel={
                                noResearchPrograms
                                    ? l('LICENSE_SIGNING.BUTTON.CONFIRM_AND_PROCEED')
                                    : l('LICENSE_SIGNING.BUTTON.GO_TO_GRANTS_SUBSTEP')
                            }
                            canConfirm={canConfirmResearchFunders}
                            isConfirming={this.props.isFundersConfirming}
                            onCancel={() => this.props.goBack()}
                            onConfirm={() => {
                                if (noResearchPrograms) {
                                    this.props.confirmConfirm();
                                } else {
                                    this.props.onConfirmSubstep();
                                    this.setState({ fundersStep: FundersStepEnum.GRANT_DETAILS });
                                }
                            }}
                        />
                    )}

                    {fundersStep === FundersStepEnum.GRANT_DETAILS && (
                        <BackAndForthButtons
                            buttonCancelLabel={l('LICENSE_SIGNING.BUTTON.GO_TO_FUNDER_SUBSTEP')}
                            buttonConfirmLabel={l('LICENSE_SIGNING.BUTTON.CONFIRM_AND_PROCEED')}
                            canConfirm={canConfirmGrants}
                            isConfirming={this.props.isFundersConfirming}
                            onCancel={() => this.setState({ fundersStep: FundersStepEnum.FUNDERS })}
                            onConfirm={() => {
                                this.props.confirmConfirm();
                            }}
                        />
                    )}
                </div>
            </>
        );
    }
}

export { Funders };
export default compose(
    withScrollToTop,
    Context.withContext(state => ({
        article: state.article,
        getPrevStep: state.getPrevStep,
        goBack: state.goBack,
        isFundersConfirming: !!state.isFundersConfirming,
        journal: state.journal,
        onConfirmSubstep: state.onConfirmSubstep,
        onLoadSubstep: state.onLoadSubstep,
    })),
    FunderPanelContext2.withProvider,
    FunderPanelContext2.withContext(state => ({
        addResearchFunder: state.addResearchFunder,
        canConfirmGrants: Object.values(state.data).every(
            x => x.noGrants || x.program.grants.some(y => !!y.grantNumber),
        ),
        canConfirmResearchFunders:
            (!_.isEmpty(state.data) && !Object.values(state.data).some(x => x.isEditing)) || state.noResearchPrograms,
        confirmConfirm: state.confirmConfirm,
        data: state.data,
        isProcessing: state.isLoading,
        noResearchPrograms: state.noResearchPrograms,
        onCloseStatusPopup: state.onCloseStatusPopup,
        statusPopup: state.statusPopup,
    })),
    withCodes2(ID.LICENSE_SIGNING),
)(Funders);
