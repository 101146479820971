/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { LinkButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import showDialog from 'app/blocks/common/jsx/dialogModal';

function OwnershipBlock(props) {
    const { currentStep, l, children, textParams, seleniumId } = props;
    const { longOwner, instructionsText, hintText } = textParams;

    return (
        <div className="OwnershipContainer" data-seleniumid={seleniumId}>
            <div className="LicenseName">
                <div className="LicenseName-Title">{l('LICENSE_SUBMISSION.LABELS.GRAY_TEXT')}</div>
                <div className="LicenseName-Name" data-seleniumid="license-submission-ownership">
                    {longOwner}
                </div>
            </div>
            {currentStep === 0 ? (
                <div className="ObtainContainer">
                    <div className="ObtainContainer-Caption" data-seleniumid="obtain-container-caption">
                        {l(`LICENSE_SUBMISSION.LABELS.INSTRUCTIONS.CAPTION`)}
                    </div>
                    <div
                        className="ObtainContainer-Text"
                        dangerouslySetInnerHTML={{ __html: instructionsText }}
                        data-seleniumid="obtain-container-text"
                    />
                </div>
            ) : (
                <div className="UploadContainer" data-seleniumid="upload-container">
                    <div>
                        {hintText}{' '}
                        <LinkButton
                            className="UploadContainer-InstructionsLink"
                            data-seleniumid="license-submission-instructions-link"
                            isLinkTag
                            onClick={() => {
                                showDialog.modal(closeAnd => ({
                                    type: 'info',
                                    innerContent: <MultiOwnershipWrapped currentStep={0} textParams={textParams} />,
                                    onClose: closeAnd(),
                                }));
                            }}
                            style={{ display: 'inline' }}
                        >
                            {l('LICENSE_SUBMISSION.LABELS.UPLOAD.HINT_LINK_TEXT')}
                        </LinkButton>
                    </div>
                    <div className="UploadContainer-DropzoneContainer">{children}</div>
                </div>
            )}
        </div>
    );
}

const MultiOwnershipWrapped = withCodes2(ID.LICENSE_SUBMISSION)(OwnershipBlock);
export default MultiOwnershipWrapped;
