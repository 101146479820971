import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { compose } from 'app/blocks/common/utils';
import withScrollToTop from 'app/blocks/common/withScrollToTop';
import { ForwardButton } from 'app/pages/license-signing-process/buttons';
import { BackAndForthContainer, SectionPrompt, SectionTitle } from 'app/pages/license-signing-process/styles';

function IncorrectListContinue({ continueFromIncorrectList, l }) {
    return (
        <>
            <section className="IncorrectListContinueInterim">
                <SectionTitle>{l('LICENSE_SIGNING.CONFIRM_AUTHORS.INCORRECT_LIST.CONTINUE_TITLE')}</SectionTitle>

                <SectionPrompt>{l('LICENSE_SIGNING.CONFIRM_AUTHORS.INCORRECT_LIST.CONTINUE_PROMPT')}</SectionPrompt>
            </section>

            <BackAndForthContainer className="licenseSigningProcessPage-backAndForth--AuthorRoles">
                <div className="page-controls">
                    <ForwardButton onClick={continueFromIncorrectList}>{l('BUTTONS.CONTINUE')}</ForwardButton>
                </div>
            </BackAndForthContainer>
        </>
    );
}

export default compose(withScrollToTop, withCodes2(ID.BUTTONS, ID.LICENSE_SIGNING))(IncorrectListContinue);
