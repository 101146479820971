enum DiscountType {
    ArticleType = 'ArticleType',
    Institutional = 'Institutional',
    Society = 'Society',
    WileyPromoCode = 'WileyPromoCode',
    HybridOaWaiver = 'HybridOaWaiver',
    GeographicalDiscount = 'GeographicalDiscount',
    EditorialDiscount = 'EditorialDiscount',
    ReferralDiscount = 'ReferralDiscount',
    TransferDiscount = 'TransferDiscount',
    Custom = 'Custom',
}

const byDiscountVisibility = ({ discountType }) => discountType !== DiscountType.ArticleType;
const byVisibility = ({ discountType, percentageDiscount = 0 }) =>
    percentageDiscount === 100 || discountType !== DiscountType.ArticleType;

export default DiscountType;

export { byDiscountVisibility, byVisibility };
