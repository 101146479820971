import React from 'react';
import withContext from 'app/pages/license-signing-process/context/withContext';

const Context = React.createContext({});

function withProvider(isNew) {
    return function (WrappedComponent) {
        return function ContextWrapper(props) {
            return (
                <Context.Provider value={{ isNew }}>
                    <WrappedComponent {...props} />
                </Context.Provider>
            );
        };
    };
}

export default {
    withProvider,
    withContext: withContext(Context),
};
