import cn from 'classnames';
import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import OrdersDataItem from 'app/pages/orders/data-item';
import ReviewPanelHeader from './ReviewPanelHeader';

function isEmpty(obj) {
    return obj && Object.values(obj).every(x => !x);
}

function DiscountReviewPanel(props) {
    const DataItemAndAuxInfo = React.useMemo(
        () => OrdersDataItem.create(props.order, props.initOrder),
        [props.order, props.initOrder],
    );

    return (
        <section className={cn('payment-panel', props.panelName)} data-seleniumid="order-discount-panel-review">
            <ReviewPanelHeader onEdit={props.onEdit} panelName={props.panelName}>
                {props.l('ORDER_PANELS.PANEL_DISCOUNT_REVIEW.TITLE')}
            </ReviewPanelHeader>

            {isEmpty(DataItemAndAuxInfo.auxInfo.order.discountDetails) && (
                <div className="mt-base" data-seleniumid="order-discount-panel-review--empty">
                    {props.l('ORDER_PANELS.PANEL_DISCOUNT.REVIEW_EMPTY')}
                </div>
            )}
            <DataItemAndAuxInfo.OrdersDataItem
                className={`${props.panelName}-ContentRow`}
                innerInfo={[['discountDetails.societyPromoCode', ' (', ')']]}
                seleniumid="order-review-discount-societyNameAndPromoCode"
                titleCls="order-article"
                titleTxt="Society Member Discount"
            />
            <DataItemAndAuxInfo.OrdersDataItem
                className={`${props.panelName}-ContentRow`}
                innerInfo={[['discountDetails.institutionName']]}
                seleniumid="order-review-discount-institutionName"
                titleCls="order-article"
                titleTxt="Institution Discount"
            />
            <DataItemAndAuxInfo.OrdersDataItem
                className={`${props.panelName}-ContentRow`}
                innerInfo={[['discountDetails.wileyPromoCode', ' (', ')']]}
                seleniumid="order-review-discount-otherDiscounts"
                titleCls="order-article"
                titleTxt="Other Discounts"
            />
        </section>
    );
}

export default withCodes2(ID.ORDER_PANELS)(DiscountReviewPanel);
