import React from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import { isArticlePublished } from 'app/blocks/common/products';
import Image from 'app/blocks/image/image';
import PanelActionsButtons from 'app/blocks/Panel/actions/buttons/panel_actions__buttons';
import { RevenueBadge, TypeBadge } from 'app/pages/search/_item/badges';
import ResultAttribute from 'app/pages/search/search_result__attribute';

const ResultArticle: React.VFC<{
    getContext: () => any;
    item: Search.Article;
    l: l;
}> = ({ getContext, item, l }) => {
    return (
        <div className="search_Item">
            <RevenueBadge seleniumid={item.id}>
                {l(`SEARCH_CODES.oaStatus.${item.doc.journalRevenueModel}`)}
            </RevenueBadge>

            <div className="row">
                <div className="search_Item__text col-12 col-md-8">
                    <div className="search-item__pic">
                        <Image
                            className="search_Item__img"
                            src={item.doc.journalCoverImage}
                            type={Image.TYPES.JOURNAL}
                        />
                    </div>
                    <div className="text_bold" data-seleniumid={`search-results-article-journal-name-${item.id}`}>
                        <a
                            className="search_Item_journalName"
                            dangerouslySetInnerHTML={{ __html: item.doc.journalTitle }}
                            href={item.doc.journalWolUrl}
                            rel="noopener noreferrer"
                            target="_blank"
                        />
                    </div>

                    <div
                        className="mt-two-thirds text_bold search_article_category"
                        data-seleniumid={`search-results-article-category-${item.id}`}
                    >
                        {item.doc.category}
                    </div>

                    <div className="mt-two-thirds text_bold" data-seleniumid={`search-results-article-name-${item.id}`}>
                        {isArticlePublished(item.doc) ? (
                            <a
                                dangerouslySetInnerHTML={{ __html: item.doc.title }}
                                href={item.doc.shareUrl}
                                rel="noopener noreferrer"
                                target="_blank"
                            />
                        ) : (
                            <span dangerouslySetInnerHTML={{ __html: item.doc.title }} />
                        )}
                    </div>

                    <ResultAttribute className="mt-two-thirds" id={item.id} name="DOI:" value={item.doc.doi} />

                    <ResultAttribute
                        className="mt-two-thirds"
                        id={item.id}
                        name="Editorial Reference Code:"
                        value={item.doc.editorialRefCode}
                    />

                    {item.doc.journalFormerTitle && (
                        <ResultAttribute
                            className="mt-two-thirds"
                            id={item.id}
                            name="Journal Former Title:"
                            value={item.doc.journalFormerTitle}
                        />
                    )}
                </div>

                <div className="col-md-4 mt-base mb-base">
                    <div className="text-right">
                        {item.doc.actions && item.doc.actions.length > 0 && (
                            <PanelActionsButtons
                                getContext={getContext}
                                handler={() => {}}
                                inline={false}
                                items={item.doc.actions}
                                name="search"
                                params={{
                                    ARTICLE_ID: item.id,
                                }}
                                seleniumid={`search-results-article-${item.id}`}
                            />
                        )}
                    </div>
                </div>
            </div>

            <TypeBadge seleniumid={item.id}>{l('SEARCH_CODES.ARTICLE_LABEL')}</TypeBadge>
        </div>
    );
};

export default withCodes(ResultArticle, ID.SEARCH_CODES);
