import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import OrdersPanelPriceInfo from 'app/pages/orders/panel/price-info';
import ReviewPanelHeader from './ReviewPanelHeader';

function PaymentAmountReviewPanel(props) {
    return (
        <section className={cn('payment-panel', props.panelName)} data-seleniumid="order-price-panel-review">
            <ReviewPanelHeader isEditable={false} panelName={props.panelName}>
                {props.l('ORDER_PANELS.OO_AMOUNT_PAYABLE.TITLE')}
            </ReviewPanelHeader>

            {props.order.pricingDetails.pricesUnavailable ? (
                <div className="mt-base" data-seleniumid="price-simulation-unavailable-text" style={{ color: 'red' }}>
                    {props.l('ORDER_PANELS.OO_AMOUNT_PAYABLE.UNAVAILABLE')}
                </div>
            ) : (
                <OrdersPanelPriceInfo
                    className="mt-base"
                    isNew
                    isShowTax
                    labels={{ amountPayable: props.l('ORDER_PANELS.OO_AMOUNT_PAYABLE.AMOUNT_PAYABLE') }}
                    prices={props.order.pricingDetails}
                />
            )}
        </section>
    );
}

export default withCodes2(ID.ORDER_PANELS)(PaymentAmountReviewPanel);

PaymentAmountReviewPanel.propTypes = {
    l: PropTypes.func.isRequired,
    order: PropTypes.shape({}).isRequired,
    panelName: PropTypes.string.isRequired,
};
