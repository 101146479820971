import { lAsync } from 'app/blocks/common/codes';
import httpStatuses from 'app/blocks/common/http-statuses';
import { ValidationError } from 'app/blocks/common/utils-errortypes';
import validation from 'app/blocks/common/validation';
import * as middleware from 'app/blocks/middleware/middleware';
import flow from 'app/pages/orders/orders.flow';

const { HTTP_CODE_404, HTTP_CODE_502 } = httpStatuses;

export default async order => {
    const { vatTaxDetails } = order;

    const vatTaxRelevanceDetails = await flow.getVatTaxRelevanceDetails(order);

    if (
        vatTaxDetails.vatIdNumber &&
        !validation.validateVatIdNumber(vatTaxDetails.vatIdNumber, vatTaxRelevanceDetails.regex)
    ) {
        throw new ValidationError(await lAsync('ERROR.VAT_TAX_VAT_ID_IN_VALID'));
    }

    const orderCategory = order.journal.openAccess ? 'openaccess' : 'onlineopen';

    try {
        await middleware[orderCategory].validateTaxData(order);
    } catch (error) {
        if ([HTTP_CODE_502, HTTP_CODE_404].includes(error.code)) {
            throw new Error(await lAsync('ERROR.VAT_TAX_SERVICE_FAILURE'));
        }

        throw error;
    }
};
