import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { AbstractButton, PrimaryButton } from 'app/blocks/buttons/buttons';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';

function BackButton({ className, children, ...props }) {
    return (
        <AbstractButton {...props} className={cn('btn-white btn-left-icon', className)} data-seleniumid="goBackButton">
            <SvgIcon.previous iconTitle="Back Icon" style={{ marginLeft: '-4px' }} />
            {children}
        </AbstractButton>
    );
}

function ForwardButton({ children, 'data-seleniumid': seleniumid = 'goForthButton', ...props }) {
    return (
        <PrimaryButton className="btn-right-icon" {...props} data-seleniumid={seleniumid}>
            {children}
            <SvgIcon.next iconTitle="Forward Icon" style={{ marginRight: '-4px' }} />
        </PrimaryButton>
    );
}

function ForwardButtonWithOkIcon({ children, 'data-seleniumid': seleniumid = 'goForthButton', ...props }) {
    return (
        <PrimaryButton className="btn-right-icon" {...props} data-seleniumid={seleniumid}>
            {children}
            <SvgIcon.ok iconTitle="ok Icon" style={{ marginRight: '-4px' }} />
        </PrimaryButton>
    );
}

function BackAndForthButtons(props) {
    return (
        <div className={cn('page-controls', props.className)}>
            <BackButton
                disabled={!props.canCancel}
                isProcessing={props.isCanceling}
                onClick={props.onCancel}
                processingLabel={props.buttonCancelingLabel}
            >
                {props.buttonCancelLabel}
            </BackButton>

            <ForwardButton
                disabled={!props.canConfirm}
                isProcessing={props.isConfirming}
                onClick={props.onConfirm}
                processingLabel={props.buttonConfirmingLabel}
            >
                {props.buttonConfirmLabel}
            </ForwardButton>
        </div>
    );
}

BackAndForthButtons.propTypes = {
    buttonCancelLabel: PropTypes.string,
    buttonCancelingLabel: PropTypes.string,
    buttonConfirmLabel: PropTypes.string,
    buttonConfirmingLabel: PropTypes.string,
    canCancel: PropTypes.bool,
    canConfirm: PropTypes.bool,
    className: PropTypes.string,
    isCanceling: PropTypes.bool,
    isConfirming: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

BackAndForthButtons.defaultProps = {
    buttonCancelLabel: 'Cancel',
    buttonCancelingLabel: 'Canceling...',
    buttonConfirmLabel: 'Confirm',
    buttonConfirmingLabel: 'Processing...',
    canCancel: true,
    canConfirm: true,
    className: undefined,
    isCanceling: false,
    isConfirming: false,
};

export { BackAndForthButtons, BackButton, ForwardButton, ForwardButtonWithOkIcon };
