import React from 'react';

function RevenueBadge({ children, seleniumid }) {
    return (
        <div className="search_Item_badge" style={{ position: 'absolute', top: '0', right: '0' }}>
            <div className="pull-left search_Item_type-container">
                <div className="pull-left search_Item_triangle--purple" />
            </div>
            <div className="search_Item_oa" data-seleniumid={`search-results-revenue-item-${seleniumid}`}>
                {children}
            </div>
        </div>
    );
}

function TypeBadge({ children, seleniumid }) {
    return (
        <div className="search_Item_badge" style={{ position: 'absolute', bottom: '0', left: '0' }}>
            <div className="pull-left search_Item_type-container">
                <div className="pull-left search_Item_triangle" />
            </div>
            <div className="search_Item_type" data-seleniumid={`search-results-type-item-${seleniumid}`}>
                {children}
            </div>
        </div>
    );
}

export { RevenueBadge, TypeBadge };
