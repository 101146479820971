import { autobind } from 'core-decorators';
import { detect } from 'detect-browser';
import update from 'immutability-helper';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import { Layout } from 'app/blocks/blocks';
import { processing, tryCatch } from 'app/blocks/common/decorators';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import { compose, getQueryParamSafe, supportCreditCardPayment } from 'app/blocks/common/utils';
import withAuth from 'app/blocks/common/withAuth';
import withConfirmLeaving from 'app/blocks/common/withConfirmLeaving';
import middlewareCPS from 'app/blocks/middleware/cps';
import * as licenseMiddleware from 'app/blocks/middleware/license';
import * as middleware from 'app/blocks/middleware/middleware';
import LicenseSigningProcessContext from 'app/pages/license-signing-process/context/Context';
import withContext from 'app/pages/license-signing-process/context/withContext';
import { confirmDraftDeletion } from 'app/pages/license-signing-process/Payment/utils';
import StepEnum from 'app/pages/license-signing-process/StepEnum';
import flow from 'app/pages/orders/orders.flow';
import payment from 'app/pages/orders/orders.payment';
import { confirmOrderChanges, notifyUserThatOrderIsSaved } from 'app/pages/orders/orders.utils';
import routes from 'app/pages/routes';

import PaymentPanelEnum from './PaymentPanelEnum';
import PaymentStepEnum from './PaymentStepEnum';
import middlewareWPG from '../../../blocks/middleware/wpg';
import { getContactState } from '../DiscountAndBillingDetails/utils';

const Context = React.createContext({});

function resetInititateState(initiate, order) {
    return update(initiate, {
        [PaymentPanelEnum.DISCOUNT]: { discountDetails: { $set: { ...order?.discountDetails } } },
        [PaymentPanelEnum.CONTACT]: (function () {
            const addresses = getContactState(order);

            return _.object(
                Object.keys(addresses),
                Object.values(addresses).map(value => ({ $set: value })),
            );
        })(),
        [PaymentPanelEnum.VAT_TAX]: { vatTaxDetails: { $set: { ...order?.vatTaxDetails } } },
        statusPopupError: { $set: undefined },
    });
}

function withProvider(WrappedComponent) {
    @autobind
    class OnlineOpenPaymentContext extends React.PureComponent {
        static propTypes = {
            article: PropTypes.shape({ id: PropTypes.string }).isRequired,
            canChangePaymentMethod: PropTypes.bool.isRequired,
            canDeleteDraft: PropTypes.bool.isRequired,
            confirmPayment: PropTypes.func.isRequired,
            deleteOnlineOpenDraft: PropTypes.func.isRequired,
            isOrderExisting: PropTypes.bool.isRequired,
            journal: PropTypes.shape({}).isRequired,
            location: PropTypes.shape({ href: PropTypes.string }),
            order: PropTypes.shape({}).isRequired,
            reload: PropTypes.func.isRequired,
            setChangesChecker: PropTypes.func.isRequired,
            substep: PropTypes.string,
            unsetChangesChecker: PropTypes.func.isRequired,
        };

        static defaultProps = {
            location: window.location,
            substep: PaymentStepEnum.INITIATE,
        };

        state = {
            ignoredCpsErrors: ['CANCELLED'],
            initiate: {
                [PaymentPanelEnum.DISCOUNT]: {},
                [PaymentPanelEnum.CONTACT]: {},
                [PaymentPanelEnum.VAT_TAX]: {},
            },
            isProcessing: true,
            prices: [],
            review: {},
            specificCpsErrors: ['TIMEOUT', 'FAILED'],
        };

        @tryCatch.showPopUpAndGoToDashboard
        @processing
        async componentDidMount() {
            const params = getQueryParamSafe(window.location.href);

            let cpsError;
            if (params.code) {
                if (params.status) {
                    cpsError = params.status;
                } else {
                    showDialog.error({
                        code: params.code,
                        message: params.message && decodeURIComponent(params.message.replace(/\+/g, '%20')),
                        refId: params.refId,
                    });
                }
            }

            const aid = this.props.article.id;
            const { order } = this.props;

            const [profileAddresses, emailDetails, prices, socopayEnabled] = await Promise.all([
                middleware.profile.getAddresses(),
                middleware.profile.getEmailDetails(),
                licenseMiddleware.getBasePrices(aid),
                middleware.payment.getSocopayEnabled(),
            ]);

            if (!order.pricingDetails.currency) {
                order.pricingDetails.currency = get(prices, '[0].currency');
            }

            this.setState(state => ({
                cpsError,
                emailDetails,
                initOrder: { ...order },
                initiate: resetInititateState(state.initiate, order),
                order,
                prices,
                profileAddresses,
                socopayEnabled,
            }));

            this.panels = [];

            if (params.scrollTo) {
                setTimeout(() => this.scrollTo(params.scrollTo), 300);
            }
        }

        setInitiateState(patch, callback = () => {}) {
            this.setState(state => ({ initiate: { ...state.initiate, ...patch(state.initiate) } }), callback);
        }

        setReviewState(patch) {
            this.setState(state => ({ review: { ...state.review, ...patch } }));
        }

        // initiate things
        registerPanel(panel) {
            this.panels.push(panel);
        }

        unregisterPanel(panel) {
            this.panels = this.panels.filter(x => x.panelName === panel.panelName);
        }

        scrollTo(type) {
            this.panels.find(panel => panel.panelName === type).scrollToPanel();
        }

        setPaymentState(patch, callback) {
            this.setInitiateState(state => ({ payment: { ...state.payment, ...patch } }), callback);
        }

        isPaymentMethodSupportedByBrowser() {
            return (
                this.state?.initiate?.payment?.paymentDetails?.paymentMethod !== payment.method.CREDIT_CARD.key ||
                supportCreditCardPayment(detect())
            );
        }

        isSaveAndPreviewAllowed() {
            if (!this.isPaymentMethodSupportedByBrowser()) {
                return false;
            }

            return [get(this.state, 'initiate.payment.paymentDetails.paymentMethod')].every(x => !!x);
        }

        getOrderWithUpdates() {
            return {
                ...this.state.order,
                correspondenceAddress: get(this.state, 'initiate.addresses.mainAddress'),
                discountDetails: get(this.state, 'initiate.discount.discountDetails'),
                paymentDetails: get(this.state, 'initiate.payment.paymentDetails'),
                vatTaxDetails: get(this.state, 'initiate.vatTax.vatTaxDetails'),
            };
        }

        @tryCatch.popUpOnly
        @processing('initiate.isDraftBeingDeleted')
        async deleteDraft() {
            if (await confirmDraftDeletion()) {
                await this.props.deleteOnlineOpenDraft();
            }
        }

        @tryCatch.popUpOnly
        @processing('initiate.isSavingForLater')
        async saveForLater() {
            const order = this.getOrderWithUpdates();
            await middleware.onlineopen.saveForLater(order);
            this.setState({ order });
            await notifyUserThatOrderIsSaved('ONLINE_OPEN', 'initiate');

            this.props.unsetChangesChecker();

            routes.navigateToUrl(`article/${this.state.order.article.id}`);
        }

        // review things
        onRequestEditOrder(panel) {
            if (panel === PaymentPanelEnum.PAYMENT) {
                routes.navigateToUrl(
                    `/license-signing/${this.props.article.id}/${StepEnum.PAYMENT}/${PaymentStepEnum.REVIEW_FINAL_CHARGES}`,
                );
                setTimeout(() => this.scrollTo(panel), 300);
            } else {
                routes.navigateToUrl(
                    `/license-signing/${this.props.article.id}/${StepEnum.DISCOUNT_AND_BILLING}`,
                    { replace: false },
                    panel,
                );
            }
        }

        onPaymentDataCorrectChange(isPaymentDataCorrect) {
            this.setReviewState({ isPaymentDataCorrect });
        }

        onAlipayAgreeChange(isAlipayDataCorrect) {
            this.setReviewState({ isAlipayDataCorrect });
        }

        onReviewCancel() {
            routes.navigateToUrl(
                `/license-signing/${this.props.article.id}/${StepEnum.PAYMENT}/${PaymentStepEnum.REVIEW_FINAL_CHARGES}`,
            );
        }

        async updateOrderWithPaymentMethod() {
            return {
                ...this.state.order,
                paymentDetails: get(this.state, 'initiate.payment.paymentDetails'),
            };
        }

        @tryCatch.popUpOnly
        @processing('initiate.isConfirming')
        async onReviewFinalChargesConfirm() {
            this.props.onConfirmSubstep();
            try {
                const order = await this.updateOrderWithPaymentMethod();
                const savedOrder = await middleware.onlineopen.saveAndPreview(order);

                this.setState(
                    state => ({
                        initiate: resetInititateState(state.initiate, savedOrder),
                        order: savedOrder,
                    }),
                    async () => {
                        routes.navigateToUrl(
                            `/license-signing/${this.props.article.id}/${StepEnum.PAYMENT}/${PaymentStepEnum.REVIEW}`,
                        );
                    },
                );

                // Update order in a Context's state
                // TODO fix that hack
                await this.props.reload();
            } catch (error) {
                // instanceof does not work
                if (error.name === 'ValidationError') {
                    this.setInitiateState(() => ({
                        statusPopupError: new Error(
                            'There was an error with your submission. Please scroll down to review',
                        ),
                    }));
                } else if (error.message === 'VatTaxShouldBeReentered') {
                    this.scrollTo(PaymentPanelEnum.VAT_TAX);
                } else {
                    throw error;
                }
            }
        }

        @tryCatch.popUpOnly
        @processing('review.isConfirming')
        async onReviewConfirm() {
            const { isOrderExisting } = this.props;
            const { order } = this.state;

            if (isOrderExisting && !(await confirmOrderChanges('ONLINE_OPEN'))) {
                return;
            }

            if (flow.isCheckoutNeededByDraft(order, isOrderExisting)) {
                this.props.onPaymentCheckout();
                if (flow.isPaymentMethodAlipay(order)) {
                    await middlewareCPS.getCPSConfigAndSubmitForm(order.chinaPayPricingDetails.socopayOrderNumber);
                } else {
                    await middlewareWPG.getOnlineOpenWPGConfigAndSubmitForm(order.article.id);
                }
            } else {
                await this.props.confirmPayment(order);
            }
        }

        render() {
            if (this.state.isProcessing) {
                return <Layout isLoading />;
            }

            return (
                <Context.Provider
                    value={{
                        ...this.state,
                        article: this.props.article,
                        canChangePaymentMethod: this.props.canChangePaymentMethod,
                        canDeleteDraft: this.props.canDeleteDraft,
                        deleteDraft: this.deleteDraft,
                        initiate: {
                            ...this.state.initiate,
                            isSaveAndPreviewAllowed: this.isSaveAndPreviewAllowed(),
                        },
                        isCheckoutNeeded: flow.isCheckoutNeededByDraft(this.state.order, this.props.isOrderExisting),
                        isOrderExisting: this.props.isOrderExisting,
                        isPaymentMethodSupportedByBrowser: this.isPaymentMethodSupportedByBrowser(),
                        journal: this.props.journal,
                        onAlipayAgreeChange: this.onAlipayAgreeChange,
                        onPaymentDataCorrectChange: this.onPaymentDataCorrectChange,
                        onRequestEditOrder: this.onRequestEditOrder,
                        onReviewCancel: this.onReviewCancel,
                        onReviewConfirm: this.onReviewConfirm,
                        onReviewFinalChargesConfirm: this.onReviewFinalChargesConfirm,
                        registerPanel: this.registerPanel,
                        saveForLater: this.saveForLater,
                        setPaymentState: this.setPaymentState,
                        unregisterPanel: this.unregisterPanel,
                    }}
                >
                    <WrappedComponent {...this.props} />
                </Context.Provider>
            );
        }
    }

    return OnlineOpenPaymentContext;
}

const useOnlineOpenPaymentFlow = () => React.useContext(Context);

export { withProvider, useOnlineOpenPaymentFlow };
export default {
    withContext: withContext(Context),
    withProvider: Component =>
        compose(
            LicenseSigningProcessContext.withContext(state => ({
                article: state.article,
                canChangePaymentMethod: get(state, 'all.onlineOpenPayment.canChangePaymentMethod', true),
                canDeleteDraft: get(state, 'all.onlineOpenPayment.canDeleteDraft', false),
                confirmPayment: state.confirmPayment,
                deleteOnlineOpenDraft: state.deleteOnlineOpenDraft,
                isOrderExisting: state.all.onlineOpenPayment.isEditOrderFlow,
                journal: state.journal,
                onConfirmSubstep: state.onConfirmSubstep,
                onLoadSubstep: state.onLoadSubstep,
                onPaymentCheckout: state.onPaymentCheckout,
                order: state.all.onlineOpenPayment.onlineOpenOrder,
                reload: state.reload,
            })),
            withConfirmLeaving,
            withAuth,
            withProvider,
        )(Component),
};
