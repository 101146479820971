import { GET, POST } from './utils';

const cache = {};
const journalCache = {};

export async function getDiscountHelpTexts(params: {
    currency: string;
    discountType: string;
    institutionId?: string;
    journalId: string;
    societyId?: string;
}): Promise<string[]> {
    const cacheStr = JSON.stringify(params);

    cache[cacheStr] =
        cache[cacheStr] || GET(`/onlineOpenOrder/getDiscountHelpTexts/${params.journalId}/${params.discountType}/`);

    return cache[cacheStr];
}

export async function getDiscountWidgetTexts(journalId: string, discountType: DiscountType): Promise<string[]> {
    const cacheStr = `${journalId}-${discountType}`;

    cache[cacheStr] = cache[cacheStr] || GET(`/onlineOpenOrder/getDiscountHelpTexts/${journalId}/${discountType}/`);

    return cache[cacheStr];
}

export async function getDiscounts(journalUuid: string): Promise<object[]> {
    journalCache[journalUuid] = journalCache[journalUuid] || (await GET(`/discounts/${journalUuid}`));

    return journalCache[journalUuid];
}

export async function applyDiscounts(articleID: string, discountCodes: string[]) {
    return await POST(`/articles/${articleID}/combinedFlow/discounts`, { discountCodes });
}
