import { lAsync } from 'app/blocks/common/codes';
import ContentUtils from 'app/blocks/common/content-utils';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import Alerts from 'app/blocks/common/spinner';
import * as middlewareRegister from 'app/blocks/middleware/register';

const LoginUtils = {
    async resendEmailRequest(email: string): Promise<void> {
        try {
            Alerts.renderSpinner();

            await middlewareRegister.resendVerificationEmail(email);

            showDialog.success({
                message: await lAsync(
                    'RECOVER_PASSWORD.confirmationMessages.PWD_RECOVERY_RESEND_ACTIVATION_EMAIL_SUCCESS_TEXT',
                ),
            });
        } catch (error) {
            showDialog.error({
                message: error.code
                    ? await ContentUtils.getServerErrorMessage(null, error)
                    : await lAsync('RECOVER_PASSWORD.errorMessages.PWD_RECOVERY_RESEND_ACTIVATION_EMAIL_FAILED_TEXT'),
            });
        } finally {
            Alerts.removeSpinner();
        }
    },
};

export default LoginUtils;
