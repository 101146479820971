import React from 'react';
import { addLoginValue as sendAnalytics } from 'app/blocks/analytics/storage';
import { useAuth } from 'app/blocks/common/providers/AuthProvider';
import { getQueryParam } from 'app/blocks/common/utils';
import LoginPage from 'app/pages/login/login-page';

export default function withAuthRequiredPage<P>(Component: React.ComponentType<P>, page = ''): React.ComponentType<P> {
    return function WithAuthRequiredPage(props) {
        const { isAuth } = useAuth();

        if (isAuth) {
            return <Component {...props} />;
        }

        sendAnalytics(page);
        return <LoginPage queryParams={getQueryParam(window.location.href)} />;
    };
}
