import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { compose } from 'app/blocks/common/utils';
import withScrollToTop from 'app/blocks/common/withScrollToTop';
import ErrorLabel from 'app/blocks/ErrorLabel/ErrorLabel';
import { BackAndForthButtons } from 'app/pages/license-signing-process/buttons';
import LicenseSigningContext from 'app/pages/license-signing-process/context/Context';
import Context from 'app/pages/license-signing-process/Payment/GOA/Context';
import PaymentPanelEnum from 'app/pages/license-signing-process/Payment/PaymentPanelEnum';
import StepEnum from 'app/pages/license-signing-process/StepEnum';
import { SectionTitle } from 'app/pages/license-signing-process/styles';
import flow from 'app/pages/orders/orders.flow';
import CheckboxButton from 'app/ui/form/CheckboxButton';
import { BillingAddressReviewPanel } from './ContactReviewPanel';
import PaymentAmountBreakdown from './PaymentAmountBreakdown';
import PaymentAmountReviewPanel from './PaymentAmountReviewPanel';
import PaymentReviewPanel from './PaymentReviewPanel';
import VatTaxReviewPanel from './VatTaxReviewPanel';

const shouldDisablePaymentDataCorrectCheckbox = order => order.pricingDetails && order.pricingDetails.sapError;

class ReviewStep extends React.PureComponent {
    render() {
        const {
            hasLicenseSubmissionStep,
            initOrder,
            isCheckoutNeeded,
            isConfirming,
            isPaymentDataCorrect,
            isAlipayDataCorrect,
            isPreviewConfirmAllowed,
            l,
            onPaymentDataCorrectChange,
            onAlipayAgreeChange,
            onRequestEditOrder,
            onReviewCancel,
            onReviewConfirm,
            order,
            cpsError,
            ignoredCpsErrors,
            specificCpsErrors,
        } = this.props;

        const shouldShowCpsError = cpsError && !ignoredCpsErrors.includes(cpsError);
        const isPaymentMethodAlipay = flow.isPaymentMethodAlipay(order);

        return (
            <>
                <section className="payment payment--review">
                    {shouldShowCpsError && (
                        <div className="PaymentError">
                            <ErrorLabel>
                                <p className="text" data-seleniumid="reviewOrder-errorLabel">
                                    {l(
                                        `LICENSE_SIGNING.PAYMENT.CPS_ERRORS.${
                                            specificCpsErrors.includes(cpsError) ? cpsError : 'GENERIC'
                                        }`,
                                    )}
                                </p>
                            </ErrorLabel>
                        </div>
                    )}
                    <SectionTitle data-seleniumid="reviewOrder-blockTitle">
                        {l('LICENSE_SIGNING.PAYMENT.TITLE.REVIEW_ORDER')}
                    </SectionTitle>

                    <BillingAddressReviewPanel
                        initOrder={initOrder}
                        onEdit={() => onRequestEditOrder(PaymentPanelEnum.CONTACT)}
                        order={order}
                        panelName="ContactPanel"
                    />

                    <PaymentReviewPanel
                        initOrder={initOrder}
                        onEdit={() => onRequestEditOrder(PaymentPanelEnum.PAYMENT)}
                        order={order}
                        panelName="PaymentPanel"
                    />

                    <VatTaxReviewPanel
                        initOrder={initOrder}
                        onEdit={() => onRequestEditOrder(PaymentPanelEnum.CONTACT)}
                        order={order}
                        panelName="VatTaxPanel"
                    />

                    {isPaymentMethodAlipay && (
                        <PaymentAmountBreakdown
                            chinaPayDetails={order.chinaPayPricingDetails}
                            isAlipayDataCorrect={isAlipayDataCorrect}
                            l={l}
                            onAlipayAgreeChange={onAlipayAgreeChange}
                            order={order}
                            panelName="PaymentAmountPanel"
                        />
                    )}

                    {!isPaymentMethodAlipay && (
                        <PaymentAmountReviewPanel order={order} panelName="PaymentAmountPanel" />
                    )}

                    <CheckboxButton
                        checked={isPaymentDataCorrect}
                        className="payment-AcceptCheckBox"
                        data-seleniumid="paymentDataCorrectCheckbox"
                        disabled={shouldDisablePaymentDataCorrectCheckbox(order)}
                        id="paymentDataCorrectCheckbox"
                        label={l('LICENSE_SIGNING.PAYMENT.LABEL.DATA_CORRECT_CHECKBOX')}
                        onChange={event => onPaymentDataCorrectChange(event.target.checked)}
                    />

                    <div
                        className="payment-AcceptCheckBoxTip"
                        dangerouslySetInnerHTML={{ __html: l('LICENSE_SIGNING.PAYMENT.LABEL.DATA_CORRECT_TIP') }}
                        data-seleniumid="paymentDataCorrect-text"
                    />
                </section>

                <div className="licenseSigningProcessPage-backAndForth">
                    <BackAndForthButtons
                        buttonCancelLabel={l('BUTTONS.BACK')}
                        buttonConfirmingLabel={l('BUTTONS.PROCESSING')}
                        buttonConfirmLabel={
                            // eslint-disable-next-line no-nested-ternary
                            isCheckoutNeeded
                                ? flow.isPaymentMethodAlipay(order)
                                    ? l('LICENSE_SIGNING.BUTTON.GO_TO_PAYMENT')
                                    : l('LICENSE_SIGNING.BUTTON.GO_TO_CHECKOUT')
                                : hasLicenseSubmissionStep
                                ? l('LICENSE_SIGNING.BUTTON.GO_TO_LICENSE_SIGNING')
                                : l('LICENSE_SIGNING.BUTTON.SUBMIT_ORDER_AND_FINISH')
                        }
                        canConfirm={isPreviewConfirmAllowed && !isConfirming}
                        onCancel={onReviewCancel}
                        onConfirm={onReviewConfirm}
                    />
                </div>
            </>
        );
    }
}

export { ReviewStep };
export default compose(
    withScrollToTop,
    LicenseSigningContext.withContext(state => ({
        hasLicenseSubmissionStep: !!state.steps.find(x => x.id === StepEnum.LICENSE_SUBMISSION),
    })),
    Context.withContext(state => ({
        initOrder: state.initOrder,
        isCheckoutNeeded: state.isCheckoutNeeded,
        isConfirming: state.review.isConfirming,
        isPaymentDataCorrect: state.review.isPaymentDataCorrect,
        isAlipayDataCorrect: state.review.isAlipayDataCorrect,
        isPreviewConfirmAllowed:
            !!state.review.isPaymentDataCorrect &&
            (!flow.isPaymentMethodAlipay(state.order) || !!state.review.isAlipayDataCorrect),
        onReviewCancel: state.onReviewCancel,
        onReviewConfirm: state.onReviewConfirm,
        onPaymentDataCorrectChange: state.onPaymentDataCorrectChange,
        onAlipayAgreeChange: state.onAlipayAgreeChange,
        onRequestEditOrder: state.onRequestEditOrder,
        order: state.order,
        cpsError: state.cpsError,
        ignoredCpsErrors: state.ignoredCpsErrors,
        specificCpsErrors: state.specificCpsErrors,
    })),
    withCodes2(ID.BUTTONS, ID.LICENSE_SIGNING),
)(ReviewStep);
