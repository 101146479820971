import { useCallback, useState } from 'react';
import showDialog from 'app/blocks/common/jsx/dialogModal';

export default function useAsyncCallback(callback, deps?: any[]): [(...args: any[]) => void, boolean] {
    const [loading, setLoading] = useState(false);

    const newCallback = useCallback(async (...args) => {
        try {
            setLoading(true);
            await callback(...args);
        } catch (error) {
            showDialog.error(error);
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    return [newCallback, loading];
}
