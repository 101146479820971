import React, { ReactNode } from 'react';
import { processPageLoad as sendAnalytics } from 'app/blocks/analytics/analytics';
import { l } from 'app/blocks/common/codes';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import getCurrentPage from 'app/getCurrentPage';

export function showCriticalError(error: Error, suggestReload: boolean = false) {
    function goHome() {
        window.location.href = '/';
    }
    function goDashboard() {
        window.location.href = '/index.html#dashboard';
        setTimeout(() => window.location.reload(), 0);
    }

    try {
        sendAnalytics(getCurrentPage(), { error });
        // eslint-disable-next-line no-empty
    } catch {}

    if (suggestReload) {
        showDialog.modal(closeAnd => ({
            cancelBtnLabel: l('BUTTONS.RELOAD_PAGE'),
            confirmBtnLabel: l('BUTTONS.GO_TO_HOME_PAGE'),
            extendedMessage: String(error),
            html: l('ERROR.OTHER_ERROR'),
            onApprove: closeAnd(() => goHome()),
            onIgnoreButtons: closeAnd(() => goHome()),
            onReject: closeAnd(() => window.location.reload()),
            type: 'confirmation',
        }));
    } else {
        const isOnDashboard = window.location.href.match(/#\/?dashboard/);

        showDialog.modal(closeAnd => ({
            closeBtnLabel: isOnDashboard ? l('BUTTONS.GO_TO_HOME_PAGE') : l('BUTTONS.BACK_TO_DASHBOARD'),
            extendedMessage: String(error),
            html: l('ERROR.OTHER_ERROR'),
            onClose: closeAnd(() => (isOnDashboard ? goHome() : goDashboard())),
            onIgnoreButtons: closeAnd(() => (isOnDashboard ? goHome() : goDashboard())),
            type: 'error',
        }));
    }

    console.error(error);
}

export default class ErrorBoundary extends React.Component<{ children: ReactNode }, { error: string }> {
    state = { error: null };

    componentDidCatch(error) {
        showCriticalError(error);
        this.setState({ error });
    }

    render() {
        return this.state.error ? null : this.props.children;
    }
}
