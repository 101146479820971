import PropTypes from 'prop-types';
import React from 'react';
import { WhiteBlock } from 'app/blocks/blocks';
import ResultArticle from 'app/pages/search/_item/__article/search_item__article';
import ResultJournal from 'app/pages/search/_item/__journal/search_item__journal';
import ResultStatic from 'app/pages/search/_item/__static/search_item__static';

const resultItemComponent = item => {
    const { type } = item;
    if (type === 'article') {
        return ResultArticle;
    }
    if (type === 'journal') {
        return ResultJournal;
    }
    if (type === 'static') {
        return ResultStatic;
    }

    return null;
};

function SearchResult({ items, getContext }) {
    return (
        <div>
            {(items || []).map(item => {
                const C = resultItemComponent(item);

                return C ? (
                    <WhiteBlock
                        key={`${item.id}-${item.type}`}
                        className="page p_none m-btm_md"
                        data-seleniumid={`search-results-item-${item.id}`}
                    >
                        <C getContext={getContext} item={item} />
                    </WhiteBlock>
                ) : (
                    <div>
                        Not supported type:
                        {item.type}
                    </div>
                );
            })}
        </div>
    );
}

SearchResult.defaultProps = {
    items: undefined,
};

SearchResult.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, type: PropTypes.string })),
    getContext: PropTypes.func.isRequired,
};

export default SearchResult;
