import PropTypes from 'prop-types';
import React from 'react';
import ArticleAffiliationsHelpWidget from 'app/blocks/ArticleAffiliationsHelpWidget/ArticleAffiliationsHelpWidget';
import { Layout } from 'app/blocks/blocks';
import { LinkButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { compose } from 'app/blocks/common/utils';
import withAuthRequiredPage from 'app/blocks/common/withAuthRequiredPage';
import withScrollToTop from 'app/blocks/common/withScrollToTop';
import FormContext from 'app/pages/license-signing-process/context/FormContext';
import routes from 'app/pages/routes';
import Affiliations from './Affiliations/Affiliations';
import Article from './Article/Article';
import Confirmation from './Confirmation/Confirmation';
import ConfirmAuthors from './ConfirmAuthors/ConfirmAuthors';
import Context from './context/Context';
import CopyrightOwnership from './CopyrightOwnership/CopyrightOwnership';
import Funders from './Funders/Funders';
import LicenseSubmission from './LicenseSubmission/LicenseSubmission';
import LicenseTypes from './LicenseTypes/LicenseTypes';
import OpenAccessOptions from './OpenAccessOptions/OpenAccessOptions';
import GOAPayment from './Payment/GOA/Payment';
import Payment from './Payment/Payment';
import StepEnum from './StepEnum';
import StepsNavigation from './StepsNavigation/StepsNavigation';
import './LicenseSigningProcessPage.scss';
// eslint-disable-next-line import/order
import DiscountAndBillingDetails from './DiscountAndBillingDetails/DiscountAndBillingDetails';
// eslint-disable-next-line import/order
import GOADiscountAndBillingDetails from './DiscountAndBillingDetails/GOA/DiscountAndBillingDetails';

const getDiscountStepComponent = journal =>
    journal?.revenueModel === 'OA' ? <GOADiscountAndBillingDetails /> : <DiscountAndBillingDetails />;

const getPaymentStepComponent = (journal, substep) =>
    journal?.revenueModel === 'OA' ? <GOAPayment substep={substep} /> : <Payment substep={substep} />;

class LicenseSigningProcessPage extends React.PureComponent {
    static propTypes = {
        article: PropTypes.shape({}),
        isProcessing: PropTypes.bool.isRequired,
        journal: PropTypes.shape({}),
        l: PropTypes.func.isRequired,
        step: PropTypes.string,
        steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    };

    static defaultProps = {
        article: undefined,
        step: StepEnum.AFFILIATIONS,
        journal: undefined,
    };

    renderStep(step, substep, article, journal) {
        const { onConfirmSubstep, onLoadSubstep } = this.props;
        switch (step) {
            case StepEnum.CONFIRM_AUTHORS:
                return <ConfirmAuthors onLoadSubstep={onLoadSubstep} step={substep} />;
            case StepEnum.AFFILIATIONS:
                return <Affiliations />;
            case StepEnum.FUNDERS:
                return <Funders article={article} journal={journal} />;
            case StepEnum.COPYRIGHT:
                return <CopyrightOwnership onConfirmSubstep={onConfirmSubstep} onLoadSubstep={onLoadSubstep} />;
            case StepEnum.OPEN_ACCESS_OPTIONS:
                return <OpenAccessOptions onLoadSubstep={onLoadSubstep} />;
            case StepEnum.LICENSE_TYPES:
                return <LicenseTypes />;
            case StepEnum.DISCOUNT_AND_BILLING:
                return getDiscountStepComponent(journal);
            case StepEnum.PAYMENT:
                return getPaymentStepComponent(journal, substep);
            case StepEnum.LICENSE_SUBMISSION:
                return <LicenseSubmission />;
            default:
                return null;
        }
    }

    renderSideBar(step) {
        if (step === StepEnum.AFFILIATIONS) {
            return <ArticleAffiliationsHelpWidget />;
        }

        return null;
    }

    renderBackToArticleButton() {
        const { articleId, l } = this.props;

        // TODO change seleniumid
        return (
            <LinkButton
                data-seleniumid="goToDashboardButton"
                onClick={() => routes.navigateToUrl(`/article/${articleId}`)}
            >
                <SvgIcon.previous iconTitle="Back Icon" />
                {l('BUTTONS.BACK_TO_ARTICLE_PAGE')}
            </LinkButton>
        );
    }

    render() {
        const { article, isProcessing, journal, step, steps, substep, l } = this.props;

        if (isProcessing) {
            return <Layout isLoading />;
        }

        return (
            <div className="licenseSigningProcessPage container">
                {step !== StepEnum.CONFIRMATION && (
                    <>
                        {this.renderBackToArticleButton()}
                        <Article article={article} className="mb-4x" journal={journal} />
                    </>
                )}

                <div className="licenseSigningProcessPage-body">
                    {step !== StepEnum.CONFIRMATION && (
                        <StepsNavigation
                            activeStep={step}
                            activeSubStep={substep}
                            steps={steps.filter(x => x.id !== StepEnum.CONFIRMATION)}
                            title={l('LICENSE_SIGNING.STEP.STEP_HEADER')}
                        />
                    )}

                    <div className="licenseSigningProcessPage-stepContent">
                        {step !== StepEnum.CONFIRMATION ? (
                            this.renderStep(step, substep, article, journal)
                        ) : (
                            <Confirmation />
                        )}
                    </div>

                    {step !== StepEnum.CONFIRMATION && this.renderSideBar(step)}
                </div>
            </div>
        );
    }
}

export default compose(
    Context.withProvider,
    FormContext.withProvider(true),
    Context.withContext(['all', 'article', 'isProcessing', 'journal', 'onConfirmSubstep', 'onLoadSubstep', 'steps']),
    withScrollToTop,
    withAuthRequiredPage,
    withCodes2(ID.BUTTONS, ID.LICENSE_SIGNING),
)(LicenseSigningProcessPage);
