import React from 'react';
import { LinkButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { SubTitle } from 'app/blocks/titles/titles';

function ReviewPanelHeader({ children, isEditable = true, l, onEdit, panelName }) {
    return (
        <header className={`${panelName}-Title-WithAction`}>
            <SubTitle className={`${panelName}-Title`}>{children}</SubTitle>
            {isEditable && (
                <LinkButton onClick={onEdit} style={{ height: 'auto' }}>
                    <SvgIcon.edit iconTitle="Edit icon" />
                    {l('BUTTONS.EDIT')}
                </LinkButton>
            )}
        </header>
    );
}

export default withCodes2(ID.BUTTONS)(ReviewPanelHeader);
