import React from 'react';
import { Button, LinkButton } from 'app/blocks/buttons/buttons';
import DialogMessage from 'app/blocks/dialog/dialog__message';
import Modal from 'app/blocks/Modal/Modal';

type Props = {
    message: string;
    cancelBtnLabel: string;
    confirmBtnLabel: string;
    onReject: () => void;
    onApprove: () => void;
};

function ConfirmationDialog({
    cancelBtnLabel,
    confirmBtnLabel,
    message,
    onApprove,
    onReject,
}: Props): React.ReactElement {
    const type = 'affiliationConfirmation';
    const seleniumIdPrefix = `${type}Dialog`;

    return (
        <Modal className="Dialog" ignoreBackdropClicks onClose={onReject} type={type}>
            <div className="modal-body Dialog-Body" data-seleniumid="modal-body">
                <DialogMessage html={message} type={type} />
                <div className="Dialog-BodyActions Dialog-BodyActions-Column">
                    <Button
                        className="noStretch"
                        data-seleniumid={`${seleniumIdPrefix}-dialog-cancel-button`}
                        onClick={onReject}
                    >
                        {cancelBtnLabel}
                    </Button>
                    <LinkButton data-seleniumid={`${seleniumIdPrefix}-dialog-confirm-link`} onClick={onApprove}>
                        {confirmBtnLabel}
                    </LinkButton>
                </div>
            </div>
        </Modal>
    );
}

export default ConfirmationDialog;
