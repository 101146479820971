import React from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import { BasicView, GrayView } from 'app/blocks/views/WidgetView';
import { useArticlePage } from 'app/pages/article/ArticlePage.context';
import DefaultActions from '../actions';

const panel = 'proofing';

function Proofing(): React.ReactElement {
    const item = useArticlePage();
    const { getAllActions, getStatus, getTitle, hasRequired } = item.helpers;
    const required = hasRequired(panel);
    const View = required ? BasicView : GrayView;

    return (
        <View
            actions={<DefaultActions buttons={getAllActions(panel)} />}
            icon="sign"
            required={required}
            seleniumid="proofing"
            title={getTitle(panel)}
        >
            {getStatus(panel)}
        </View>
    );
}

export default withCodes(Proofing, ID.WIDGETS);
