import React, { Component } from 'react';
import { PrimaryButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import Alerts from 'app/blocks/common/spinner';
import * as Utils from 'app/blocks/common/utils';
import { getUrlParam, invalidateUrlParam } from 'app/blocks/middleware/url-params';
import routes from 'app/pages/routes';

type Props = {
    l: l;
    verificationNotRequired?: boolean;
    existsAsSecondary?: boolean;
    existsAsPrimary?: boolean;
};

class RegistrationLoginMessage extends Component<Props> {
    static defaultProps = {
        verificationNotRequired: false,
        existsAsSecondary: false,
        existsAsPrimary: false,
    };

    getContent = () => {
        const { verificationNotRequired, existsAsSecondary, existsAsPrimary } = this.props;

        if (verificationNotRequired) {
            return {
                message: 'REGISTRATION.uiLabelMessages.REGISTER_BY_INVITATION_SUCCEED_LABEL',
                help: 'REGISTRATION.inlineHelp.REGISTRATION_LOGIN_WITH_EMAIL_INLINE_HELP',
            };
        }

        if (existsAsSecondary) {
            return {
                message: 'REGISTRATION.errorMessages.REGISTRATION_EMAIL_ALREADY_EXISTS_AS_SECONDARY_ERR_TEXT',
                help: 'REGISTRATION.inlineHelp.REGISTRATION_LOGIN_WITH_PRIMARY_EMAIL_INLINE_HELP',
            };
        }

        if (existsAsPrimary) {
            return {
                message: 'REGISTRATION.errorMessages.REGISTRATION_EMAIL_ALREADY_EXISTS_ERR_TEXT',
                help: 'REGISTRATION.inlineHelp.REGISTRATION_LOGIN_WITH_EMAIL_INLINE_HELP',
            };
        }

        return null;
    };

    onClickLogin = () => {
        const returnUrl = getUrlParam('returnUrl');
        if (returnUrl) {
            Alerts.renderSpinner();
            invalidateUrlParam('returnUrl');
            Utils.windowLocationReplace(returnUrl);
        } else {
            routes.toLogin();
        }
    };

    render() {
        const { l } = this.props;
        const returnUrl = getUrlParam('returnUrl');
        const content = this.getContent();

        if (!content) return null;

        return (
            <div className="RegisterPage-LoginMessage">
                <div className="Message" data-seleniumid="register-login-message">
                    {l(content.message)}
                </div>
                <div className="Help">{l(content.help)}</div>
                <div className="Action">
                    <PrimaryButton data-seleniumid="registration-message-login-button" onClick={this.onClickLogin}>
                        {returnUrl
                            ? l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_REDIRECT_BUTTON_LABEL`)
                            : l(`${ID.LOGIN}.uiLabelMessages.LOGIN_LOGIN_BUTTON_LABEL`)}
                    </PrimaryButton>
                </div>
            </div>
        );
    }
}

export { RegistrationLoginMessage };
export default withCodes(RegistrationLoginMessage, ID.REGISTRATION, ID.LOGIN);
