import _ from 'underscore';
import { REST } from './utils';

const { GET } = REST('/payment');

function createCpsForm(url, cpsConfig) {
    const form = document.createElement('form');
    form.id = `paymentGatewayForm${_.uniqueId()}`;
    form.method = 'post';
    form.action = url;

    Object.entries(cpsConfig).forEach(([name, value]) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = name;
        // @ts-ignore
        input.value = value;
        form.appendChild(input);
    });

    document.body.appendChild(form);

    return form;
}

const self = {
    async getCPSConfigAndSubmitForm(socopayOrderNumber: number) {
        const [url, config] = await Promise.all([self.getGatewayUrl(), self.getGatewayConfig(socopayOrderNumber)]);
        const $form = createCpsForm(url, config);
        $form.submit();
    },

    getGatewayConfig(socopayOrderNumber: number) {
        return GET(`/cps/config/${socopayOrderNumber}`);
    },

    getGatewayUrl() {
        return GET('/cps/url');
    },
};

export default self;
