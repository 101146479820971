import React from 'react';
import { Button } from 'app/blocks/buttons/buttons';
import { ID, withCodes } from 'app/blocks/common/codes';
import { GrayView, BasicView } from 'app/blocks/views/WidgetView';
import { useArticlePage } from 'app/pages/article/ArticlePage.context';

function VideoAbstractWidget({ l }: { l: l }): React.ReactElement {
    const item = useArticlePage();

    const button = (
        <Button
            data-seleniumid="MAKE_VIDEO_ABSTRACT"
            href={l('WIDGETS.MAKE_VIDEO_ABSTRACT.URL')}
            isLinkTag
            target="_blank"
        >
            {l('WIDGETS.MAKE_VIDEO_ABSTRACT.BUTTON')}
        </Button>
    );

    const View = item.status.published ? GrayView : BasicView;
    return (
        <View actions={button} icon="video" seleniumid="videoAbstract" title={l('WIDGETS.MAKE_VIDEO_ABSTRACT.TITLE')}>
            <div dangerouslySetInnerHTML={{ __html: l('WIDGETS.MAKE_VIDEO_ABSTRACT.CONTENT') }} />
        </View>
    );
}

export default withCodes(VideoAbstractWidget, ID.WIDGETS);
