import cn from 'classnames';
import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import processing from 'app/blocks/common/decorators/processing';
import { compose } from 'app/blocks/common/utils';
import ErrorLabel from 'app/blocks/ErrorLabel/ErrorLabel';
import withLightPanel from 'app/blocks/Panel/advanced/withLightPanel';
import VatOrTaxForm from 'app/pages/orders/panel/vat-tax';
import validationVatTax from 'app/pages/orders/panel/vat-tax/validation';
import DiscountAndBillingContext from './DiscountAndBillingContext';

@autobind
class VatTaxPanel extends React.PureComponent {
    static propTypes = {
        countryCode: PropTypes.string,
        orderWithUpdates: PropTypes.shape({ vatTaxDetails: PropTypes.shape({}) }).isRequired,
        setVatTaxState: PropTypes.func.isRequired,
        setValidationHandler: PropTypes.func.isRequired,
        vatTaxDetails: PropTypes.shape({}),
        journal: PropTypes.shape({}),
        revenueModel: PropTypes.string,
    };

    static defaultProps = {
        vatTaxDetails: {},
        journal: {},
        countryCode: undefined,
        revenueModel: undefined,
    };

    state = {
        isValidating: false,
        validationResults: undefined,
    };

    componentDidMount() {
        this.props.setValidationHandler(this.validate);
    }

    @processing('isValidating')
    async validate() {
        try {
            const { orderWithUpdates, journal } = this.props;
            await validationVatTax({ ...orderWithUpdates, journal });
            this.setState({ validationResults: undefined });
        } catch (error) {
            if (error.name === 'ValidationError') {
                this.setState({ validationResults: error });
            }

            throw error;
        }
    }

    async updateVatIdNumber(vatId) {
        const vatIdNumber = vatId ? vatId.trim() : '';

        this.props.setVatTaxState({ vatTaxDetails: { ...this.props.vatTaxDetails, vatIdNumber } });
    }

    updateTaxExemptionNumber(taxExemptionNumber) {
        this.props.setVatTaxState({ vatTaxDetails: { ...this.props.vatTaxDetails, taxExemptionNumber } });
    }

    render() {
        const {
            countryCode,
            vatTaxDetails: { vatIdNumber, taxExemptionNumber },
            revenueModel,
        } = this.props;
        const { isValidating, validationResults } = this.state;

        return (
            <section
                className={cn('payment-panel VatTaxPanel', { 'payment-panel--error': validationResults })}
                data-seleniumid="VatTaxPanel"
            >
                {validationResults && <ErrorLabel text={validationResults.message} />}

                <VatOrTaxForm
                    className="VatTaxPanel"
                    countryCode={countryCode}
                    isValidating={isValidating}
                    revenueModel={revenueModel}
                    taxExemptionNumber={taxExemptionNumber}
                    updateTaxExemptionNumber={this.updateTaxExemptionNumber}
                    updateVatIdNumber={this.updateVatIdNumber}
                    vatIdNumber={vatIdNumber}
                />
            </section>
        );
    }
}

export default compose(
    DiscountAndBillingContext.withContext(state => ({
        ...state.initiate.vatTax,
        journal: state.journal,
        countryCode: state?.initiate?.addresses?.mainAddress?.countryCode,
        orderWithUpdates: state.orderWithUpdates,
        register: state.registerPanel,
        unregister: state.unregisterPanel,
        setVatTaxState: state.setVatTaxState,
        revenueModel: state.journal.revenueModel,
    })),
    withLightPanel('vatTax'),
    withCodes2(ID.ERROR),
)(VatTaxPanel);
