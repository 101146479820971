import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { MainTitle } from 'app/blocks/titles/titles';
import GetStarted from './GetStarted';
import MissingArticle from './MissingArticle';

function EmptyDashboard({ l }: { l: l }) {
    return (
        <div className="EmptyDashboard" data-seleniumid="empty-dashboard">
            <div className="EmptyDashboard-Row">
                <MainTitle className="EmptyDashboard-Title" data-seleniumid="empty-dashboard-text">
                    {l('DASHBOARD.DASHBOARD_EMPTY')}
                </MainTitle>
            </div>
            <div className="EmptyDashboard-Row">
                <MissingArticle />
            </div>
            <div className="EmptyDashboard-Row">
                <GetStarted />
            </div>
        </div>
    );
}

export default withCodes2(ID.DASHBOARD)(EmptyDashboard);
