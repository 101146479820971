import React from 'react';
import AuthorRoleEnum from 'app/blocks/AuthorRoleEnum';
import { useLicenseFlow } from 'app/pages/license-signing-process/context/Context';
import StepEnum from 'app/pages/license-signing-process/StepEnum';
import { navigate } from 'app/route/history';
import ConfirmAuthorEmails from './ConfirmAuthorRoles/ConfirmAuthorEmails';
import ConfirmCorrAuthors from './ConfirmAuthorRoles/ConfirmCorrAuthors';
import IncorrectList from './ConfirmAuthorRoles/IncorrectList';
import IncorrectListContinue from './ConfirmAuthorRoles/IncorrectListContinue';
import ConfirmAuthorsStepEnum from './ConfirmAuthorsStepEnum';
import ConfirmRCA from './ConfirmRCA/ConfirmRCA';
import ConfirmRCAChange from './ConfirmRCA/ConfirmRCAChange';

const steps = {
    [ConfirmAuthorsStepEnum.CONFIRM_EMAILS]: ConfirmAuthorEmails,
    [ConfirmAuthorsStepEnum.WRONG_LIST]: IncorrectList,
    [ConfirmAuthorsStepEnum.CONTINUE]: IncorrectListContinue,
    [ConfirmAuthorsStepEnum.CONFIRM_RCA]: ConfirmRCA,
    [ConfirmAuthorsStepEnum.CONFIRM_RCA_CHANGE]: ConfirmRCAChange,
};

export default function ConfirmAuthors({ step }) {
    const {
        all,
        article,
        isRCAChangeConfirming,
        confirmRCAChange,
        confirmIncorrectAuthorList,
        onConfirmSubstep,
        onLoadSubstep,
    } = useLicenseFlow();

    React.useEffect(() => {
        onLoadSubstep(step);
    }, [onLoadSubstep, step]);

    React.useEffect(() => {
        if (step) {
            navigate(`/license-signing/${article.id}/confirmAuthors`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [article.id]);

    const { rcaConfirmationData } = all[StepEnum.CONFIRM_AUTHORS];

    const [correspondingAuthors, setCorrespondingAuthors] = React.useState(
        rcaConfirmationData.filter(x => x.role.primaryRole === AuthorRoleEnum.CORRESPONDING_AUTHOR),
    );
    const [missedEmails, setMissedEmails] = React.useState([]);
    const [rca, setRCA] = React.useState(undefined);
    const [incorrectListReported, setIncorrectListReported] = React.useState(false);

    const [isCorrespondingAuthorsConfirming, setCorrespondingAuthorsConfirming] = React.useState(false);

    const confirmCorrespondingAuthors = async ids => {
        const newCorrespondingAuthors = rcaConfirmationData
            .filter(x => ids.includes(x.participantId))
            .map(x => {
                const email = missedEmails.find(y => y.participantId === x.participantId)?.email;

                if (email) {
                    return { ...x, email };
                }

                return x;
            });

        setCorrespondingAuthors(newCorrespondingAuthors);
        setRCA(undefined);

        if (newCorrespondingAuthors.some(x => !x.email)) {
            onConfirmSubstep();
            setTimeout(() => navigate(`/license-signing/${article.id}/confirmAuthors/confirmEmails`), 0);
        } else if (newCorrespondingAuthors.length === 1) {
            try {
                setCorrespondingAuthorsConfirming(true);
                setRCA(newCorrespondingAuthors[0]);

                await confirmRCAChange(newCorrespondingAuthors, newCorrespondingAuthors[0]);
            } finally {
                setCorrespondingAuthorsConfirming(false);
            }
        } else {
            onConfirmSubstep();
            setTimeout(() => navigate(`/license-signing/${article.id}/confirmAuthors/confirmRCA`), 0);
        }
    };

    const confirmAuthorsEmails = values => {
        onConfirmSubstep();
        const newCorrespondingAuthors = correspondingAuthors.map(x => {
            const email = values.find(y => y.participantId === x.participantId)?.email;

            if (email) {
                return { ...x, email };
            }

            return x;
        });

        setMissedEmails(values);
        setCorrespondingAuthors(newCorrespondingAuthors);

        if (newCorrespondingAuthors.length === 1) {
            setRCA(newCorrespondingAuthors[0]);
            navigate(`/license-signing/${article.id}/confirmAuthors/confirmRCAChange`);
        } else {
            setRCA(undefined);
            navigate(`/license-signing/${article.id}/confirmAuthors/confirmRCA`);
        }
    };

    const continueFromIncorrectList = () => {
        onConfirmSubstep();
        setIncorrectListReported(true);
        navigate(`/license-signing/${article.id}/confirmAuthors`);
    };

    const confirmRCA = id => {
        onConfirmSubstep();
        setRCA(correspondingAuthors.find(x => x.participantId === id));
        navigate(`/license-signing/${article.id}/confirmAuthors/confirmRCAChange`);
    };

    const onConfirmRCAChange = () => {
        confirmRCAChange(correspondingAuthors, rca);
    };

    const Component = steps[step] ?? ConfirmCorrAuthors;

    return (
        <Component
            confirmAuthorsEmails={confirmAuthorsEmails}
            confirmCorrespondingAuthors={confirmCorrespondingAuthors}
            confirmIncorrectAuthorList={confirmIncorrectAuthorList}
            confirmRCA={confirmRCA}
            confirmRCAChange={onConfirmRCAChange}
            continueFromIncorrectList={continueFromIncorrectList}
            correspondingAuthors={correspondingAuthors}
            incorrectListReported={incorrectListReported}
            isCorrespondingAuthorsConfirming={isCorrespondingAuthorsConfirming}
            isRCAChangeConfirming={isRCAChangeConfirming}
            onConfirmSubstep={onConfirmSubstep}
            rca={rca}
        />
    );
}
