import React, { useEffect } from 'react';
import { processSignLicenseClick as sendAnalyticsOnClick } from 'app/blocks/analytics/analytics';
import { removePaymentCheckout } from 'app/blocks/analytics/storage';
import PageEnum from 'app/blocks/common/PageEnum';
import { useLicenseFlow } from 'app/pages/license-signing-process/context/Context';
import SigningMethodEnum from 'app/pages/license-signing-process/SigningMethodEnum';
import ElectronicLicenseSubmission from './Electronic/ElectronicLicenseSubmission';
import MultiAuthors from './Print/MultiAuthors';
import SingleAuthor from './Print/SingleAuthor';

const pages = {
    [SigningMethodEnum.ELECTRONIC]: PageEnum.LICENSE_SUBMISSION_ELECTRONIC,
    [SigningMethodEnum.PRINT]: PageEnum.LICENSE_SUBMISSION_PRINT,
};
function LicenseSubmission(): React.ReactElement {
    const { all, onLoadSubstep } = useLicenseFlow();
    const signingMethod = all?.licenseSubmission?.signingMethod;

    useEffect(() => {
        const data = removePaymentCheckout();
        if (data) {
            sendAnalyticsOnClick(data);
        }
        if (onLoadSubstep && typeof onLoadSubstep === 'function') {
            onLoadSubstep(null, pages[signingMethod]);
        }
    }, [onLoadSubstep, signingMethod]);

    if (signingMethod === SigningMethodEnum.ELECTRONIC) {
        return <ElectronicLicenseSubmission />;
    }
    if (signingMethod === SigningMethodEnum.PRINT) {
        return all?.copyrightOwnership?.copyrightOwnerships?.length === 1 ? <SingleAuthor /> : <MultiAuthors />;
    }

    throw new Error('Unknow signing method');
}

export default LicenseSubmission;
