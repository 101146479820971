import _ from 'underscore';
import { ID, l, lAsync, loadCodes } from 'app/blocks/common/codes';

const MULTI_ERROR_ARRAY_CODE = 'MULTI_ERROR_ARRAY';

async function getServerErrorMessage(
    codeId: string,
    error: { code?: string; errorCode?: string; message?: string; errorMessage?: string },
    payload = {},
): Promise<string> {
    if (!error) {
        return await lAsync('COMMON.errorMessages.COMMON_SERVICES_ERR_TEXT');
    }

    const errorCode = error.code || error.errorCode;
    const errorMessage = error.message || error.errorMessage;

    if (errorCode === MULTI_ERROR_ARRAY_CODE && !_.isEmpty(payload)) {
        await loadCodes([ID.ERROR, ID.FIELD_NAMES]);

        return _.map(payload, (errors, name) => {
            const fieldName = l(`FIELD_NAMES.${name}`, null, name);
            const errorMsg = _.map(errors, error2 => l(`ERROR.${error2.code}`, null, error2.message)).join('<br />');

            return `<strong>${fieldName}</strong>: ${errorMsg}`;
        }).join('<br />');
    }

    return (
        (await lAsync(`${codeId}.serverContent.serverMessages.${errorCode}`, null, null)) ||
        (await lAsync(`ERROR.${errorCode}`, null, null)) ||
        (await lAsync(`COMMON.errorMessages.${errorCode}`, null, null)) ||
        errorMessage ||
        (await lAsync('COMMON.errorMessages.COMMON_SERVICES_ERR_TEXT'))
    );
}

export default {
    getServerErrorMessage,
};
