import cn from 'classnames';
import React from 'react';
import ComboboxWithChevron from 'app/blocks/ComboboxWithChevron/ComboboxWithChevron';
import InfoLabel from 'app/blocks/InfoLabel/InfoLabel';
import Layout from 'app/blocks/Layout/Layout';
import Modal from 'app/blocks/Modal/Modal';
import Title from 'app/blocks/page/__title/page__title';
import Prompt from 'app/blocks/Prompt/Prompt';

const WhiteBlock = React.forwardRef<HTMLDivElement, { children: React.ReactNode; className?: string }>(
    function WhiteBlock({ children, className, ...props }, ref) {
        return (
            <div {...props} ref={ref} className={cn('paper', className)}>
                {children}
            </div>
        );
    },
);

function getOneColumnRowComponent(columnClassName) {
    return function ({ children, className }) {
        return (
            <div className={cn('row', className)}>
                <div className={columnClassName}>{children}</div>
            </div>
        );
    };
}

const RowLg7 = getOneColumnRowComponent('col-xl-7');
const RowMd7 = getOneColumnRowComponent('col-lg-7');

export {
    ComboboxWithChevron,
    getOneColumnRowComponent,
    InfoLabel,
    Layout,
    Modal,
    RowLg7,
    RowMd7,
    Prompt,
    Title,
    WhiteBlock,
};
