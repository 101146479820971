import cn from 'classnames';
import React from 'react';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import './ErrorLabel.scss';

type Props = Either<{ children: React.ReactNode; text: string }> & {
    className?: string;
    isBold?: boolean;
    withoutSvg?: boolean;
    id?: string;
    [props: string]: any;
};

function ErrorLabel({
    children,
    text,
    className,
    isBold = false,
    withoutSvg = false,
    id,
    ...props
}: Props): React.ReactElement {
    return (
        <div
            className={cn('ErrorLabel', className, {
                'ErrorLabel-Bold': isBold,
            })}
            id={id}
        >
            {!withoutSvg && <SvgIcon.error iconTitle="Error Icon" />}
            {text ? (
                <small
                    data-seleniumid="validation-error-label-text"
                    {...props}
                    dangerouslySetInnerHTML={{ __html: text }}
                />
            ) : (
                children
            )}
        </div>
    );
}

export default ErrorLabel;
