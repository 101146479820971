import React from 'react';
import { createRoot } from 'react-dom/client';
import WaitingDialog from 'app/blocks/common/jsx/waiting-dialog';

let root;

const Alerts = {
    removeSpinner: () => {
        if (root) {
            root.render(null);
        }
    },
    renderSpinner: () => {
        root = root || createRoot(document.getElementById('waitingDialog'));
        root.render(<WaitingDialog />);
    },
};

export default Alerts;
