/* eslint-disable react/no-unused-class-component-methods */
import { autobind } from 'core-decorators';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import { Layout } from 'app/blocks/blocks';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { processing } from 'app/blocks/common/decorators';
import { compose } from 'app/blocks/common/utils';
import * as middleware from 'app/blocks/middleware/middleware';
import withLightPanel from 'app/blocks/Panel/advanced/withLightPanel';
import validation from 'app/pages/orders/panel/discount/validation';
import DiscountAndBillingContext from './DiscountAndBillingContext';

const defaultState = {
    discountDetails: {},
};

function withDiscountPanelContainer(Component) {
    @autobind
    class DiscountPanelContainer extends React.PureComponent {
        static propTypes = {
            discountDetails: PropTypes.shape({}),
            isValidating: PropTypes.bool,
            journalId: PropTypes.string.isRequired,
            orderWithUpdates: PropTypes.shape({ discountDetails: PropTypes.shape({}) }).isRequired,
            setState: PropTypes.func.isRequired,
            setValidationHandler: PropTypes.func.isRequired,
        };

        static defaultProps = {
            ...defaultState,
            isValidating: false,
        };

        @processing
        async componentDidMount() {
            this.props.setValidationHandler(this.validate);
            this.props.setState({ discountsError: false });

            const catchDiscountError = () => {
                this.props.setState({ discountsError: true });
                return false;
            };
            const [hasAdditionalDiscounts, hasSocietyDiscounts] = await Promise.all([
                this.hasAdditionalDiscountsPromise().catch(catchDiscountError),
                this.hasSocietyDiscountsPromise().catch(catchDiscountError),
            ]);

            this.props.setState({
                hasAdditionalDiscounts,
                hasSocietyDiscounts,
            });
        }

        setState(patch) {
            this.props.setState(patch);
        }

        @processing('isValidating')
        async validate() {
            try {
                await validation(this.props.orderWithUpdates);
                this.props.setState({ validationResults: undefined });
            } catch (error) {
                if (error.name === 'ValidationError') {
                    this.props.setState({ validationResults: error.errors });
                }

                throw error;
            }
        }

        isChanged() {
            return Object.keys(defaultState).some(
                key => this.props[key] && !_.isEqual(defaultState[key], this.props[key]),
            );
        }

        updateDiscount(discountDetails) {
            this.props.setState({ discountDetails: { ...this.props.discountDetails, ...discountDetails } });
        }

        handleSocietyPromoCodeChange(societyPromoCode) {
            this.updateDiscount({ societyPromoCode });
        }

        handleOtherPromoCodeChange(wileyPromoCode) {
            this.updateDiscount({ wileyPromoCode });
        }

        onSocietyPromoCodeFocus() {
            this.props.setState({
                validationResults: update(this.props.validationResults || {}, {
                    societyPromoCode: { $set: undefined },
                }),
            });
        }

        onWileyPromoCodeFocus() {
            this.props.setState({
                validationResults: update(this.props.validationResults || {}, { wileyPromoCode: { $set: undefined } }),
            });
        }

        hasAdditionalDiscountsPromise = middleware.onlineopen.hasAdditionalDiscounts.bind(null, this.props.journalId);

        hasSocietyDiscountsPromise = middleware.onlineopen.hasSocietyDiscounts.bind(null, this.props.journalId);

        render() {
            if (this.props.isProcessing) {
                return <Layout isLoading />;
            }

            return (
                <Component
                    {...this.props}
                    currency={this.props.currency}
                    discountDetails={this.props.discountDetails}
                    discountsError={this.props.discountsError}
                    handleOtherPromoCodeChange={this.handleOtherPromoCodeChange}
                    handleSocietyPromoCodeChange={this.handleSocietyPromoCodeChange}
                    hasAdditionalDiscounts={this.props.hasAdditionalDiscounts}
                    hasSocietyDiscounts={this.props.hasSocietyDiscounts}
                    isValidating={this.props.isValidating}
                    journalId={this.props.journalId}
                    l={this.props.l}
                    onSocietyPromoCodeFocus={this.onSocietyPromoCodeFocus}
                    onWileyPromoCodeFocus={this.onWileyPromoCodeFocus}
                />
            );
        }
    }

    return DiscountPanelContainer;
}

export { withDiscountPanelContainer };
export default Component =>
    compose(
        DiscountAndBillingContext.withContext(state => ({
            ...state.initiate.discount,
            currency: state.order.pricingDetails.currency,
            journalId: state.journal.id,
            orderWithUpdates: state.orderWithUpdates,
            register: state.registerPanel,
            setState: state.setDiscountState,
            unregister: state.unregisterPanel,
        })),
        withLightPanel('discount'),
        withCodes2(ID.ORDER_PANELS, ID.TOOLTIP),
        withDiscountPanelContainer,
    )(Component);
