import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'underscore';
import { ID, withCodes } from 'app/blocks/common/codes';
import * as Utils from 'app/blocks/common/utils';
import InstitutionPicker from 'app/blocks/institution-picker/institution-picker';
import { NONE_VALUE } from 'app/blocks/institution-picker/institution-picker-view';
import RowDropdown from 'app/blocks/Row/_Dropdown/Row_Dropdown';
import CountryPickerWithTooltip from 'app/blocks/Row/_DropdownWithTooltip/Row__Dropdown_WithTooltip';
import OrdersRow from 'app/blocks/Row/Row';
import { addressInputMaxLength } from 'app/pages/orders/orders.constants';
import { isStateRequired, isPostalCodeHelpTextRequired } from 'app/pages/orders/panel/contact/validation';

class OrdersPanelEditAddress extends Component {
    static propTypes = {
        address: PropTypes.shape({}).isRequired,
        panelName: PropTypes.string,
        codes: PropTypes.shape({}).isRequired,
        handleAddressChange: PropTypes.func.isRequired,
        l: PropTypes.func.isRequired,
        seleniumid: PropTypes.string.isRequired,
        isOpenAccessOrder: PropTypes.bool,
        onFieldFocus: PropTypes.func,
        validationResults: PropTypes.oneOfType([
            PropTypes.shape({}),
            PropTypes.bool, // legacy of boolean checking validateAddresses
        ]),
        shouldClearAddress: PropTypes.bool,
        supportDataProvider: PropTypes.shape({}).isRequired,
    };

    static defaultProps = {
        panelName: undefined,
        onFieldFocus: () => {},
        validationResults: {},
        isOpenAccessOrder: false,
        shouldClearAddress: false,
    };

    state = {};

    handleFullNameChange = fullName => {
        this.updateAddressField('fullName', fullName);
    };

    handleFirstNameChange = firstName => {
        this.updateAddressField('firstName', firstName);
    };

    handleLastNameChange = lastName => {
        this.updateAddressField('lastName', lastName);
    };

    handleInstitutionChange = institution => {
        const fields = _.extend(Utils.readPickField(institution, 'institution', 'Code'), { departmentName: undefined });
        this.updateAddressFields(fields);
    };

    handleDepartmentChange = departmentName => {
        this.updateAddressField('departmentName', departmentName);
    };

    handleAddressLine1Change = addressLine1 => {
        this.updateAddressField('addressLine1', addressLine1);
    };

    handleAddressLine2Change = addressLine2 => {
        this.updateAddressField('addressLine2', addressLine2);
    };

    handleCityChange = city => {
        this.updateAddressField('city', city);
    };

    handleCountryChange = async country => {
        const { shouldClearAddress } = this.props;
        const fields =
            shouldClearAddress && !!this.props?.address?.countryCode
                ? _.extend(
                      Utils.readPickField(country, 'country', 'Code'),
                      Utils.readPickField(undefined, 'state', 'Code'),
                      Utils.readPickField(undefined, 'zip', 'Code'),
                      { addressLine1: undefined },
                      { addressLine2: undefined },
                      { city: undefined },
                  )
                : _.extend(
                      Utils.readPickField(country, 'country', 'Code'),
                      Utils.readPickField(undefined, 'state', 'Code'),
                  );
        this.updateAddressFields(fields);
    };

    handleStateChange = state => {
        const fields = Utils.readPickField(state, 'state', 'Code');
        this.updateAddressFields(fields);
    };

    handleZipCodeChange = zipCode => {
        this.updateAddressField('zipCode', zipCode);
    };

    handlePhoneNumberChange = phoneNumber => {
        this.updateAddressField('phoneNumber', phoneNumber.trim());
    };

    handleMailChange = email => {
        this.updateAddressField('primaryEmail', email);
    };

    updateAddressFields = properties => {
        const newAddress = Utils.updateProperties(this.props.address, properties);
        this.updateAddress(newAddress);
    };

    updateAddressField = (property, value) => {
        const newAddress = Utils.updateProperty(this.props.address, property, value);
        this.updateAddress(newAddress);
    };

    updateAddress = newAddress => {
        this.props.handleAddressChange(newAddress);
    };

    onFocus = e => {
        this.props.onFieldFocus(e.target.name);
    };

    institutionPickerRenderer = props => (
        <InstitutionPicker
            changeHandler={props.changeHandler}
            className="lName"
            isClearable
            isError={!!props.error}
            minLengthToSearch={0}
            noneLabel={props.noneLabel}
            onFocus={props.onFocus}
            placeholder={props.placeholder}
            selectedItem={props.selectedItem}
            seleniumid={`${props.formSeleniumid}-${props.name}`}
            showNone
        />
    );

    render() {
        const { l, address, panelName, codes, seleniumid, isOpenAccessOrder, supportDataProvider, validationResults } =
            this.props;

        const profileErrorCodes = codes[ID.PROFILE_ACCOUNT].errorMessages;

        const itemNullName = l('ORDER_PANELS.PANEL_CONTACT.NO_AFFILIATIONS');
        const institutionName = Utils.createPickField(address, 'institution', 'Name').name;
        const countryCode = Utils.createPickField(address, 'country', 'Code').id;
        const shouldSetState = countryCode && isStateRequired(countryCode, isOpenAccessOrder);
        const shouldShowPostalCodeHelpText = countryCode && isPostalCodeHelpTextRequired(countryCode);

        const isIndividual = address.billToType !== 'ORGANIZATION';

        const departmentDisable =
            institutionName === undefined ||
            institutionName === null ||
            institutionName === '' ||
            institutionName === NONE_VALUE ||
            institutionName === itemNullName;

        // TODO talk with qa, replace formSeleniumId with seleniumId
        return (
            <div className={`${panelName}-Form`} data-seleniumid={seleniumid}>
                {isOpenAccessOrder && (
                    <OrdersRow
                        className={`${panelName}-Row`}
                        error={validationResults.fullName}
                        errorMessages={profileErrorCodes}
                        formSeleniumid="address"
                        isRequired
                        label={l('ORDER_PANELS.PANEL_CONTACT.FULL_NAME_LABEL')}
                        maxLength={addressInputMaxLength.FULL_NAME}
                        name="fullName"
                        onChange={this.handleFullNameChange}
                        onFocus={this.onFocus}
                        placeholder={l('ORDER_PANELS.PANEL_CONTACT.FULL_NAME_PLACEHOLDER')}
                        value={address.fullName}
                    />
                )}
                {!isOpenAccessOrder && (
                    <div className={`${panelName}-inlineInputs`}>
                        <OrdersRow
                            className={`${panelName}-Row`}
                            error={validationResults.firstName}
                            errorMessages={profileErrorCodes}
                            formSeleniumid="address"
                            isRequired
                            label={
                                isIndividual
                                    ? l('ORDER_PANELS.PANEL_CONTACT.FIRST_NAME_LABEL')
                                    : l('ORDER_PANELS.PANEL_CONTACT.RECIPIENT_FIRST_NAME_LABEL')
                            }
                            maxLength={addressInputMaxLength.FIRST_NAME}
                            name="firstName"
                            onChange={this.handleFirstNameChange}
                            onFocus={this.onFocus}
                            placeholder={
                                isIndividual
                                    ? l('ORDER_PANELS.PANEL_CONTACT.FIRST_NAME_PLACEHOLDER')
                                    : l('ORDER_PANELS.PANEL_CONTACT.RECIPIENT_FIRST_NAME_PLACEHOLDER')
                            }
                            value={address.firstName}
                        />
                        <OrdersRow
                            className={`${panelName}-Row`}
                            error={validationResults.lastName}
                            errorMessages={profileErrorCodes}
                            formSeleniumid="address"
                            isRequired
                            label={
                                isIndividual
                                    ? l('ORDER_PANELS.PANEL_CONTACT.LAST_NAME_LABEL')
                                    : l('ORDER_PANELS.PANEL_CONTACT.RECIPIENT_LAST_NAME_LABEL')
                            }
                            maxLength={addressInputMaxLength.LAST_NAME}
                            name="lastName"
                            onChange={this.handleLastNameChange}
                            onFocus={this.onFocus}
                            placeholder={
                                isIndividual
                                    ? l('ORDER_PANELS.PANEL_CONTACT.LAST_NAME_PLACEHOLDER')
                                    : l('ORDER_PANELS.PANEL_CONTACT.RECIPIENT_LAST_NAME_PLACEHOLDER')
                            }
                            value={address.lastName}
                        />
                    </div>
                )}

                {!isIndividual && (
                    <div className={`${panelName}-inlineInputs`}>
                        <OrdersRow
                            className={`${panelName}-Row`}
                            error={validationResults.primaryEmail}
                            formSeleniumid="address"
                            isRequired
                            label={l('ORDER_PANELS.PANEL_CONTACT.RECIPIENT_EMAIL_LABEL')}
                            maxLength={addressInputMaxLength.EMAIL}
                            name="email"
                            onChange={this.handleMailChange}
                            onFocus={() => this.props.onFieldFocus('primaryEmail')}
                            placeholder={l('ORDER_PANELS.PANEL_CONTACT.RECIPIENT_EMAIL_PLACEHOLDER')}
                            type="email"
                            value={address.primaryEmail}
                        />
                        <div>
                            <OrdersRow
                                className={`${panelName}-Row`}
                                error={validationResults.phoneNumber}
                                formSeleniumid="address"
                                isRequired
                                label={l('ORDER_PANELS.PANEL_CONTACT.RECIPIENT_PHONE_LABEL')}
                                maxLength={addressInputMaxLength.PHONE}
                                name="phoneNumber"
                                onChange={this.handlePhoneNumberChange}
                                onFocus={this.onFocus}
                                placeholder={l('ORDER_PANELS.PANEL_CONTACT.RECIPIENT_PHONE_PLACEHOLDER')}
                                type="tel"
                                value={address.phoneNumber}
                            />
                            <strong className={`${panelName}-Static`}>
                                {l('ORDER_PANELS.PANEL_CONTACT.PHONE_STATIC_TEXT')}
                            </strong>
                        </div>
                    </div>
                )}
                <div className={`${panelName}-inlineInputs`}>
                    <RowDropdown
                        changeHandler={this.handleInstitutionChange}
                        className={`${panelName}-Row`}
                        comboboxRenderer={this.institutionPickerRenderer}
                        error={validationResults.institution}
                        formSeleniumid="address"
                        isRequired
                        label={l('ORDER_PANELS.PANEL_CONTACT.AFFILIATION_LABEL')}
                        name="institution"
                        noneLabel={itemNullName}
                        onFocus={() => this.props.onFieldFocus('institution')}
                        placeholder={
                            !isOpenAccessOrder
                                ? l('ORDER_PANELS.PANEL_CONTACT.AFFILIATION_HOA_PLACEHOLDER')
                                : l('ORDER_PANELS.PANEL_CONTACT.AFFILIATION_PLACEHOLDER')
                        }
                        selectedItem={Utils.createPickField(address, 'institution', 'Code')}
                    />
                    <OrdersRow
                        className={`${panelName}-Row`}
                        disabled={departmentDisable}
                        error={validationResults.departmentName}
                        errorMessages={profileErrorCodes}
                        formSeleniumid="address"
                        isOptional
                        label={l('ORDER_PANELS.PANEL_CONTACT.DEPARTMENT_LABEL')}
                        maxLength={addressInputMaxLength.DEPARTMENT}
                        name="department"
                        onChange={this.handleDepartmentChange}
                        onFocus={() => this.props.onFieldFocus('departmentName')}
                        placeholder={l('ORDER_PANELS.PANEL_CONTACT.DEPARTMENT_PLACEHOLDER')}
                        value={address.departmentName}
                    />
                </div>
                <div className={`${panelName}-inlineInputs`}>
                    <OrdersRow
                        className={`${panelName}-Row`}
                        error={validationResults.addressLine1}
                        errorMessages={profileErrorCodes}
                        formSeleniumid="address"
                        isRequired
                        label={l('ORDER_PANELS.PANEL_CONTACT.STREET_ADDRESS_1_LABEL')}
                        maxLength={addressInputMaxLength.STREET_ADDRESS}
                        name="addressLine1"
                        onChange={this.handleAddressLine1Change}
                        onFocus={this.onFocus}
                        placeholder={l('ORDER_PANELS.PANEL_CONTACT.STREET_ADDRESS_1_PLACEHOLDER')}
                        value={address.addressLine1}
                    />
                    <OrdersRow
                        className={`${panelName}-Row`}
                        error={validationResults.addressLine2}
                        errorMessages={profileErrorCodes}
                        formSeleniumid="address"
                        isOptional
                        label={l('ORDER_PANELS.PANEL_CONTACT.STREET_ADDRESS_2_LABEL')}
                        maxLength={addressInputMaxLength.STREET_ADDRESS}
                        name="addressLine2"
                        onChange={this.handleAddressLine2Change}
                        onFocus={this.onFocus}
                        placeholder={l('ORDER_PANELS.PANEL_CONTACT.STREET_ADDRESS_2_PLACEHOLDER')}
                        value={address.addressLine2}
                    />
                </div>
                <div className={`${panelName}-inlineInputs`}>
                    <OrdersRow
                        className={`${panelName}-Row`}
                        error={validationResults.city}
                        errorMessages={profileErrorCodes}
                        formSeleniumid="address"
                        isRequired
                        label={l('ORDER_PANELS.PANEL_CONTACT.CITY_LABEL')}
                        maxLength={addressInputMaxLength.CITY}
                        name="city"
                        onChange={this.handleCityChange}
                        onFocus={this.onFocus}
                        placeholder={l('ORDER_PANELS.PANEL_CONTACT.CITY_PLACEHOLDER')}
                        value={address.city}
                    />
                    <div />
                </div>
                <div className={`${panelName}-inlineInputs`}>
                    <CountryPickerWithTooltip
                        changeHandler={this.handleCountryChange}
                        className={`${panelName}-Row`}
                        disabled={isOpenAccessOrder}
                        error={validationResults.country}
                        formSeleniumid="address"
                        isRequired
                        isShowTooltip={isOpenAccessOrder}
                        label={l('ORDER_PANELS.PANEL_CONTACT.COUNTRY_LABEL')}
                        name="country"
                        onFocus={() => this.props.onFieldFocus('country')}
                        placeholder={l('ORDER_PANELS.PANEL_CONTACT.COUNTRY_PLACEHOLDER')}
                        selectedItem={Utils.createPickField(address, 'country', 'Code')}
                        tooltipNodeId="country"
                    />
                    <RowDropdown
                        changeHandler={this.handleStateChange}
                        className={`${panelName}-Row`}
                        disabled={!countryCode}
                        dontLoadOnMount
                        error={validationResults.state}
                        formSeleniumid="address"
                        getListDataPromise={supportDataProvider.getStatesPromise(
                            Utils.createPickField(address, 'country', 'Code'),
                        )}
                        isOptional={!shouldSetState}
                        isRequired={shouldSetState}
                        label={l('ORDER_PANELS.PANEL_CONTACT.STATE_LABEL')}
                        name="state"
                        onFocus={() => this.props.onFieldFocus('state')}
                        placeholder={
                            shouldSetState
                                ? l('ORDER_PANELS.PANEL_CONTACT.SELECT_STATE_PLACEHOLDER')
                                : l('ORDER_PANELS.PANEL_CONTACT.SELECT_STATE_OPTIONAL_PLACEHOLDER')
                        }
                        selectedItem={Utils.createPickField(address, 'state', 'Code')}
                    />
                </div>
                <div className={`${panelName}-inlineInputs`}>
                    <OrdersRow
                        className={`${panelName}-Row`}
                        error={validationResults.zipCode}
                        errorMessages={profileErrorCodes}
                        formSeleniumid="address"
                        helpText={
                            shouldShowPostalCodeHelpText
                                ? l('ORDER_PANELS.PANEL_CONTACT.ZIP_CODE_HELP_TEXT')
                                : undefined
                        }
                        isRequired
                        label={l('ORDER_PANELS.PANEL_CONTACT.ZIP_CODE_LABEL')}
                        maxLength={addressInputMaxLength.ZIP_CODE}
                        name="zipCode"
                        onChange={this.handleZipCodeChange}
                        onFocus={this.onFocus}
                        placeholder={l('ORDER_PANELS.PANEL_CONTACT.ZIP_CODE_PLACEHOLDER')}
                        value={address.zipCode}
                    />
                    <div />
                </div>
                <div className={`${panelName}-inlineInputs`}>
                    {isIndividual && (
                        <OrdersRow
                            className={`${panelName}-Row`}
                            error={validationResults.primaryEmail}
                            formSeleniumid="address"
                            isRequired
                            label={l('ORDER_PANELS.PANEL_CONTACT.EMAIL_LABEL')}
                            maxLength={addressInputMaxLength.EMAIL}
                            name="email"
                            onChange={this.handleMailChange}
                            onFocus={() => this.props.onFieldFocus('primaryEmail')}
                            placeholder={l('ORDER_PANELS.PANEL_CONTACT.EMAIL_PLACEHOLDER')}
                            type="email"
                            value={address.primaryEmail}
                        />
                    )}
                    {isIndividual && (
                        <div>
                            <OrdersRow
                                className={`${panelName}-Row`}
                                error={validationResults.phoneNumber}
                                formSeleniumid="address"
                                isRequired
                                label={l('ORDER_PANELS.PANEL_CONTACT.PHONE_LABEL')}
                                maxLength={addressInputMaxLength.PHONE}
                                name="phoneNumber"
                                onChange={this.handlePhoneNumberChange}
                                onFocus={this.onFocus}
                                placeholder={l('ORDER_PANELS.PANEL_CONTACT.PHONE_PLACEHOLDER')}
                                type="tel"
                                value={address.phoneNumber}
                            />
                            {!isOpenAccessOrder ? (
                                <strong className={`${panelName}-Static`}>
                                    {l('ORDER_PANELS.PANEL_CONTACT.PHONE_STATIC_TEXT')}
                                </strong>
                            ) : (
                                <div />
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withCodes(OrdersPanelEditAddress, ID.ORDER_PANELS, ID.PROFILE_ACCOUNT, ID.STATE_MANDATORY_COUNTRIES);
