import React from 'react';
import { Button } from 'app/blocks/buttons/buttons';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import { compose } from 'app/blocks/common/utils';
import withScrollToTop from 'app/blocks/common/withScrollToTop';
import { ItemTitle } from 'app/blocks/titles/titles';
import { SectionPrompt, SectionTitle } from 'app/pages/license-signing-process/styles';
import { navigate } from 'app/route/history';
import './IncorrectListStop.scss';

function IncorrectListContinue({ l }) {
    return (
        <section className="IncorrectListStop">
            <SvgIcon.clock />

            <div>
                <SectionTitle>{l('LICENSE_SIGNING.CONFIRM_AUTHORS.INCORRECT_LIST.STOP_TITLE')}</SectionTitle>

                <SectionPrompt>{l('LICENSE_SIGNING.CONFIRM_AUTHORS.INCORRECT_LIST.STOP_PROMPT')}</SectionPrompt>

                <ItemTitle className="mt-2x">
                    {l('LICENSE_SIGNING.CONFIRM_AUTHORS.INCORRECT_LIST.STOP_WHATS_NEXT')}
                </ItemTitle>

                <SectionPrompt>
                    {l('LICENSE_SIGNING.CONFIRM_AUTHORS.INCORRECT_LIST.STOP_WHATS_NEXT_PROMPT')}
                </SectionPrompt>

                <Button className="mt-2x" data-seleniumid="goToDashboardButton" onClick={() => navigate('/dashboard')}>
                    {l('BUTTONS.BACK_TO_DASHBOARD')}
                </Button>
            </div>
        </section>
    );
}

export default compose(withScrollToTop, withCodes2(ID.BUTTONS, ID.LICENSE_SIGNING))(IncorrectListContinue);
