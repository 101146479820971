import { autobind } from 'core-decorators';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import { compose, trimStringProperties } from 'app/blocks/common/utils';
import * as middleware from 'app/blocks/middleware/middleware';
import withLightPanel from 'app/blocks/Panel/advanced/withLightPanel';
import validation from 'app/pages/orders/panel/contact/validation';
import DiscountAndBillingContext from '../Context';

const addressesProvider = {
    getInstitutionsPromise: middleware.address.getInstitutions,
    getStatesPromise:
        parent =>
        (...args) =>
            middleware.address.getStates(parent, ...args),
};

const defaultState = {
    mainAddress: {},
};

const DEFAULT_TAB = 'INDIVIDUAL';

function withPanel(Component) {
    @autobind
    class BillingDetailsPanel extends React.PureComponent {
        static propTypes = {
            disableDifferentCountrySelection: PropTypes.bool,
            emailDetails: PropTypes.shape({ primaryEmailAddr: PropTypes.string }).isRequired,
            mainAddress: PropTypes.shape({ countryCode: PropTypes.string }),
            setValidationHandler: PropTypes.func.isRequired,
            updateAddress: PropTypes.func.isRequired,
            updateValidationResults: PropTypes.func.isRequired,
        };

        state = {
            INDIVIDUAL: { billToType: 'INDIVIDUAL' },
            ORGANIZATION: { billToType: 'ORGANIZATION' },
        };

        onSelectBillToType = e => {
            const { updateAddress } = this.props;
            updateAddress(this.state[e.target.value]);
        };

        static defaultProps = {
            ...defaultState,
            disableDifferentCountrySelection: false,
        };

        async componentDidMount() {
            const { mainAddress } = this.props;
            const isEmpty = Object.keys(mainAddress).length === 0;

            this.setState({
                [mainAddress?.billToType || DEFAULT_TAB]: {
                    ...mainAddress,
                    billToType: mainAddress?.billToType || DEFAULT_TAB,
                },
            });

            if (!isEmpty && !mainAddress?.billToType) {
                const { updateAddress } = this.props;
                updateAddress({ ...mainAddress, billToType: DEFAULT_TAB });
            }

            const { setValidationHandler } = this.props;
            setValidationHandler(this.validate);
        }

        async validate() {
            const { mainAddress, updateValidationResults } = this.props;

            try {
                validation(
                    {
                        mainAddress: trimStringProperties(mainAddress),
                    },
                    true,
                );

                updateValidationResults(undefined);
            } catch (error) {
                if (error.name === 'ValidationError') {
                    updateValidationResults(error.errors);
                }

                throw error;
            }
        }

        // eslint-disable-next-line react/no-unused-class-component-methods
        isChanged() {
            return Object.keys(defaultState).some(
                key => this.state[key] && !_.isEqual(defaultState[key], this.props[key]),
            );
        }

        onMainAddressFieldFocus(field) {
            const { updateValidationResults, validationResults } = this.props;
            if (validationResults?.mainAddress) {
                const mainAddress = { ...validationResults?.mainAddress, [field]: undefined };
                updateValidationResults({ ...validationResults, mainAddress });
            }
        }

        updateMainAddress(mainAddress) {
            this.setState({ [mainAddress?.billToType || DEFAULT_TAB]: mainAddress });
            const { updateAddress } = this.props;
            updateAddress(mainAddress);
        }

        async updateMainAddressCountry(country, mainAddress) {
            const { updateAddress, updateCountry } = this.props;
            await updateCountry(country?.id);
            this.setState({ [mainAddress?.billToType || DEFAULT_TAB]: mainAddress });
            updateAddress(mainAddress);
        }

        render() {
            return (
                <Component
                    {...this.props}
                    addressesProvider={addressesProvider}
                    onMainAddressFieldFocus={this.onMainAddressFieldFocus}
                    onSelectBillToType={this.onSelectBillToType}
                    updateMainAddress={this.updateMainAddress}
                    updateMainAddressCountry={this.updateMainAddressCountry}
                />
            );
        }
    }

    return BillingDetailsPanel;
}

export default Component =>
    compose(
        DiscountAndBillingContext.withContext(state => ({
            ...state.initiate.addresses,
            disableDifferentCountrySelection: false,
            emailDetails: state.emailDetails,
            isWaiverApplied: state.isWaiverApplied,
            register: state.registerPanel,
            unregister: state.unregisterPanel,
            updateAddress: state.updateAddress,
            updateValidationResults: state.updateValidationResults,
        })),
        withLightPanel('addresses'),
        withPanel,
    )(Component);
