import React from 'react';
import { toPriceListFormat } from 'app/blocks/common/getCurrencySymbol';

type Props = {
    l: l;
    prices: Array<{
        base?: number;
        name: string;
        amount: number;
        symbol: string;
    }>;
};

function DiscountedPriceList({ l, prices }: Props) {
    return (
        <>
            <div className="orderPrices--text">{l('LICENSE_SIGNING.OPEN_ACCESS.LABEL.CURRENCIES_CARD_DISCOUNTED')}</div>
            <ul className="ul-drop-list-styles orderPrices-items" data-seleniumid="order__discounted-prices">
                {prices.map(price => (
                    <li key={price.name} className="orderPrices-item">
                        <span className="orderPrices-name">{price.name}</span>
                        <span className="orderPrices--base">
                            {price.symbol}
                            <span data-seleniumid={`order__base-price_${price.base}`}>
                                {toPriceListFormat(price.base)}
                            </span>
                        </span>
                        <span className="orderPrices--discounted">
                            {price.symbol}
                            <span data-seleniumid={`order__price_${price.name}`}>
                                {toPriceListFormat(price.amount)}
                            </span>
                        </span>
                    </li>
                ))}
            </ul>
        </>
    );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function PriceList({ l, prices }: Props) {
    return (
        <ul className="ul-drop-list-styles orderPrices-items" data-seleniumid="order__prices">
            {prices.map(price => (
                <li key={price.name} className="orderPrices-item">
                    <span className="orderPrices-name">{price.name}</span>
                    {price.symbol}
                    <span data-seleniumid={`order__price_${price.name}`}>{toPriceListFormat(price.amount)}</span>
                </li>
            ))}
        </ul>
    );
}

export { DiscountedPriceList, PriceList };
