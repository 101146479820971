import { autobind } from 'core-decorators';
import React from 'react';
import { LinkButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import processing from 'app/blocks/common/decorators/processing';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import PaymentPanelEnum from 'app/pages/license-signing-process/Payment/PaymentPanelEnum';
import flow from 'app/pages/orders/orders.flow';
import { displayVAT } from '../index';

@autobind
class VatTaxReviewPanel extends React.Component {
    state = { isEditing: false };

    @processing('isEditing')
    async onRequestEditOrder() {
        await this.props.onRequestEditOrder(PaymentPanelEnum.CONTACT);
    }

    render() {
        const { l, DataItemAndAuxInfo, isEditable } = this.props;
        const { isEditing } = this.state;

        const countryCode = flow.getBillingCountryCode(DataItemAndAuxInfo.auxInfo.order);

        return (
            <div data-seleniumid="order-vat-panel-review">
                {displayVAT(countryCode) && (
                    <div className="row order-btm-border">
                        <div className="col-md-10 col-12 nopadding m-btm_md">
                            <div>
                                <div className="m-btm_md">
                                    <h5>{l('ORDER_PANELS.PANEL_VAT_TAX_REVIEW.VAT_DETAILS')}</h5>
                                </div>
                                <div
                                    className="m-btm_md"
                                    hidden={!DataItemAndAuxInfo.auxInfo.order.vatTaxDetails.vatIdNumber}
                                >
                                    <DataItemAndAuxInfo.OrdersDataItem
                                        innerInfo={[['vatTaxDetails.vatIdNumber']]}
                                        seleniumid="order-review-vat-idNumber"
                                        titleCls="order-article"
                                        titleTxt={l('ORDER_PANELS.PANEL_VAT_TAX.VAT_NUMBER')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-12 m-btm_mini text-right nopadding">
                            <div hidden={!isEditable}>
                                <LinkButton
                                    className="edit"
                                    data-seleniumid="order-review-vat-edit-button"
                                    isProcessing={isEditing}
                                    onClick={this.onRequestEditOrder}
                                    processingLabel={l('BUTTONS.LOADING')}
                                >
                                    <SvgIcon.edit iconTitle="Edit" />
                                    {l('ORDER_PANELS.PANEL_VAT_TAX_REVIEW.EDIT_BTN')}
                                </LinkButton>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default withCodes2(ID.ORDER_PANELS, ID.BUTTONS)(VatTaxReviewPanel);
