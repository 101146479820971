import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Layout, Title } from 'app/blocks/blocks';
import { Button, PrimaryButton } from 'app/blocks/buttons/buttons';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import useAsyncCallback from 'app/blocks/common/hooks/useAsyncCallback';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import Alerts from 'app/blocks/common/spinner';
import { compose } from 'app/blocks/common/utils';
import withConfirmLeaving from 'app/blocks/common/withConfirmLeaving';
import { getAlerts, saveAlerts, AlertsPayload } from 'app/blocks/middleware/alerts';
import routes from 'app/pages/routes';
import { Checkbox } from 'app/ui/form';

import './email-alerts-page.scss';

const EmailAlertsPage: React.VFC<{ l: l }> = ({ l }) => {
    const { alertId: alertIdParam } = useParams();
    const [userAlertsData, setUserAlertsData] = useState<AlertsPayload | undefined>();

    const [fetchData, isFetching] = useAsyncCallback(async alertId => {
        setUserAlertsData(await getAlerts(alertId));
    }, []);

    useEffect(() => {
        fetchData(alertIdParam);
    }, [fetchData, alertIdParam]);

    const onCloseSaveConfirmation = () => {
        routes.toLogin();
    };

    /**
     * On the "onUnselectAll" state is not being updated before the onSave is called
     * to overcome this issue  alertsData data is passed on the "onUnselectAll" function call
     * @param alertsData optional
     * @returns {Promise<void>}
     */
    const [onSave, isLoading] = useAsyncCallback(
        async alerts => {
            await saveAlerts(alertIdParam, {
                alerts: alerts || userAlertsData.alerts,
                notificationEmail: userAlertsData.notificationEmail,
            });

            showDialog.success({
                message: alerts
                    ? l('EMAIL_ALERTS.confirmationMessages.ALERT_UNSELECT_ALL_SAVE')
                    : l('EMAIL_ALERTS.confirmationMessages.ALERT_PREFERENCE_SAVED'),
                onClose: onCloseSaveConfirmation,
            });
        },
        [userAlertsData],
    );

    useEffect(() => {
        if (isFetching || isLoading) {
            Alerts.renderSpinner();
        } else {
            Alerts.removeSpinner();
        }
    }, [isFetching, isLoading]);

    const onUnselectAll = async () => {
        const alerts = userAlertsData.alerts.map(alert => ({ ...alert, email: false }));

        setUserAlertsData({ ...userAlertsData, alerts });
        await onSave(alerts);
    };

    const onAlertSelectChange = (alertId, checked) => {
        const alerts = userAlertsData.alerts.map(alert =>
            alert.alertId === alertId ? { ...alert, email: checked } : alert,
        );

        setUserAlertsData({ ...userAlertsData, alerts });
    };

    const renderCheckBoxes = alerts => {
        return alerts.map((alert, i) => {
            return (
                <li key={i.toLocaleString()}>
                    <Checkbox
                        key={alert.alertId}
                        checked={alert.email}
                        className="selection-ctrl"
                        data-seleniumid={alert.alertId}
                        id={alert.alertId}
                        onChange={e => onAlertSelectChange(alert.alertId, e.target.checked)}
                        value={alert.alertId}
                    />
                    <label htmlFor={alert.alertId}>{alert.alertName}</label>
                </li>
            );
        });
    };
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    {!userAlertsData && !isLoading ? (
                        <Layout
                            className="m-top_mini"
                            errorMsg={l('EMAIL_ALERTS.errorMessages.UNABLE_TO_LOAD_CONTENT')}
                            isLoading={isLoading}
                        />
                    ) : (
                        userAlertsData && (
                            <div id="AlertsPageEmail">
                                <Title seleniumid="alerts-page">{l('EMAIL_ALERTS.PAGE_TITLE')}</Title>
                                <p>{l('EMAIL_ALERTS.PAGE_SUB_TITLE')}</p>
                                <ul id="AlertsCheckBoxes">{renderCheckBoxes(userAlertsData.alerts)}</ul>
                                <p>
                                    {l('EMAIL_ALERTS.ALERT_RECEIVING')}
                                    &nbsp;
                                    <b>{userAlertsData.notificationEmail}</b>
                                </p>
                                <p>
                                    <Button data-seleniumid="alerts-page-btn-save" onClick={() => onSave(null)}>
                                        {l('EMAIL_ALERTS.btnLabels.BTN_SAVE')}
                                    </Button>
                                    <PrimaryButton
                                        className="btnLeftMargin"
                                        data-seleniumid="alerts-page-btn-unsubscribe"
                                        onClick={onUnselectAll}
                                    >
                                        {l('EMAIL_ALERTS.btnLabels.BTN_UNSUBSCRIBE')}
                                    </PrimaryButton>
                                </p>
                                <p dangerouslySetInnerHTML={{ __html: l('EMAIL_ALERTS.NOTE') }} />
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default compose(withConfirmLeaving, withCodes2(ID.EMAIL_ALERTS))(EmailAlertsPage);
