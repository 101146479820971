import React, { Component, useEffect, useMemo, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { processSignLicenseStepLoad as sendAnalytics } from 'app/blocks/analytics/analytics';
import getPublicationStatus from 'app/blocks/article-publication-status/article-publication-status';
import { LinkButton } from 'app/blocks/buttons/buttons';
import ASInfo from 'app/blocks/common/as-info';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { processing, tryCatch, keyProps } from 'app/blocks/common/decorators';
import PageEnum from 'app/blocks/common/PageEnum';
import storage from 'app/blocks/common/storage';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import Layout from 'app/blocks/Layout/Layout';
import { getArticle, ArticlePayload } from 'app/blocks/middleware/dashboard';
import checkChanges from 'app/blocks/middleware/http/polling';
import StaticContent from 'app/blocks/static-content/static-content';
import { MainTitle } from 'app/blocks/titles/titles';
import BannerWidget from 'app/pages/article/widgets/BannerWidget';
import ArticleDetails from 'app/pages/dashboard/ArticleDetails';
import { isBlocked } from 'app/pages/dashboard/utils';
import routes from 'app/pages/routes';
import ArticlePageContext from './ArticlePage.context';
import configure from './config';
import convertItem from './utils';
import Citations from './widgets/Citations';
import ErrorWidget from './widgets/ErrorWidget';

import './ArticlePage.scss';

const TEMPORARY_BANNER_KEY = 'AS_31337_Temp_BANNER_key';

type Props = {
    articleId: string;
    isLoading: boolean;
    item: ArticlePayload;
    l: l;
};

const UiArticlePage = withCodes2(
    ID.DASHBOARD,
    ID.BUTTONS,
    ID.PANEL_STATUS,
    ID.WIDGETS,
    ID.LICENSE_SIGNING,
)(({ isLoading, item: rawItem, l }: Props): React.ReactElement => {
    const item = useMemo(() => convertItem(rawItem), [rawItem]);
    const { important, left, required, right } = useMemo(() => configure(item), [item]);
    const { rcaInfo, status } = item;
    const { getAllRequiredActions, hasCitations, hasMultipleAuthors } = item.helpers;
    const statusCode = getPublicationStatus({ ...status, rcaInfo });

    useEffect(() => {
        const { article, history, journal, publication } = item;
        sendAnalytics({ article, history, journal, publication, statusCode });
    }, [item, statusCode]);

    const [isBannerVisible, setIsBannerVisible] = useState(
        hasMultipleAuthors() &&
            new Date().getTime() <
                parseInt(
                    storage.get('TEMPORARY_BANNER_HIDE_TIME_OVERRIDE') || l('LICENSE_SIGNING.BANNER_HIDE_TIME'),
                    10,
                ) &&
            !storage.get(TEMPORARY_BANNER_KEY),
    );

    function hideTemporaryBanner() {
        storage.set(TEMPORARY_BANNER_KEY, 'closed');
        setIsBannerVisible(false);
    }

    if (isBlocked(item)) {
        return <Navigate to="/dashboard" />;
    }

    const { article, journal } = item;

    function renderGrowImpactHeader() {
        return (
            <div className="Normal-Title" data-seleniumid="not-required-widgets-title">
                <MainTitle data-seleniumid="not-required-widgets-text">
                    {l('DASHBOARD.MANAGE_ARTICLE.GROW_IMPACT')}
                </MainTitle>
            </div>
        );
    }

    function renderGrowImpact(widgets) {
        return (
            <div className="Normal Widgets" data-seleniumid="not-required-widgets">
                {renderGrowImpactHeader()}
                {widgets}
            </div>
        );
    }

    return (
        <ArticlePageContext.Provider value={item}>
            <div className="container mt-3x">
                <div>
                    <LinkButton data-seleniumid="goToDashboardButton" onClick={() => routes.navigateToDashboard()}>
                        <SvgIcon.previous iconTitle="Back Icon" />
                        {l('BUTTONS.BACK_TO_DASHBOARD')}
                    </LinkButton>
                </div>

                <MainTitle data-seleniumid="article-page-title">{l('DASHBOARD.MANAGE_ARTICLE.TITLE')}</MainTitle>
                <Layout className="ArticlePage" isLoading={isLoading}>
                    <ArticleDetails item={item} statusCode={statusCode} />

                    <div className="Content" data-seleniumid="article-page-content">
                        <div className="Content-Left" data-seleniumid="left-column">
                            {!!item?.banner && Object.keys(item.banner).length > 0 && <BannerWidget />}
                            {!!item?.errors?.length && <ErrorWidget />}
                            {!!important.length && important}
                            {isBannerVisible && (
                                <div className="BannerContainer" data-seleniumid="banner-container">
                                    <SvgIcon.thunder className="BannerContainer-Icon" />
                                    <div className="BannerContainer-Text" data-seleniumid="banner-text">
                                        {l('LICENSE_SIGNING.BANNER_TEXT')}
                                    </div>
                                    <button
                                        className="BannerContainer-Button"
                                        data-seleniumid="dismiss-banner"
                                        onClick={hideTemporaryBanner}
                                        type="button"
                                    >
                                        <SvgIcon.cancel className="ButtonIcon" />
                                    </button>
                                </div>
                            )}
                            {!!required.length && (
                                <div className="Required Widgets" data-seleniumid="required-widgets">
                                    <div className="Required-Title" data-seleniumid="required-widgets-title">
                                        <SvgIcon.error seleniumid="required-widgets-icon" />
                                        <MainTitle data-seleniumid="required-widgets-text">
                                            {l('DASHBOARD.MANAGE_ARTICLE.REQUIRED_ACTIONS')}
                                        </MainTitle>
                                    </div>
                                    {required}
                                </div>
                            )}

                            {item?.status?.published && <Citations />}

                            {!!left.length && renderGrowImpact(left)}
                        </div>

                        {!!right.length && (
                            <div className="Content-Right" data-seleniumid="right-column">
                                {item?.status?.published && hasCitations() && renderGrowImpactHeader()}
                                {right}
                            </div>
                        )}
                    </div>
                </Layout>

                <StaticContent
                    className="mt-4x"
                    seleniumid="dashboard-promo-bottom"
                    src="dashboard-promo-bottom.html"
                />
            </div>
            <ASInfo
                article={article}
                journal={journal}
                other={{
                    fullWOAAOffer: !!(item.eligibility?.message && !item.eligibility?.isInstitutionalDiscount),
                    requiredActions: item.requiredActions,
                    requiredActionsList: getAllRequiredActions(),
                }}
                page={PageEnum.ARTICLE_PAGE}
            />
        </ArticlePageContext.Provider>
    );
});

type State = {
    isLoading?: boolean;
    item: ArticlePayload;
};

@keyProps('articleId')
class ArticlePage extends Component<{ articleId: string }, State> {
    state: State = {
        isLoading: true,
        item: null,
    };

    polling = null;

    @processing('isLoading')
    @tryCatch.showPopUpAndGoToDashboard
    async componentDidMount() {
        try {
            const response = await getArticle(this.props.articleId);
            this.setState({ item: response.payload });

            this.polling = checkChanges(response);
            this.polling.watch(item => this.setState({ item }));
        } catch (error) {
            if (error.code === 'RESOURCE_NOT_FOUND') {
                routes.navigateToUrl('/dashboard', { replace: true });
            } else {
                throw error;
            }
        }
    }

    componentWillUnmount() {
        if (this.polling) {
            this.polling.stop();
        }
    }

    render() {
        const { articleId } = this.props;
        const { isLoading, item } = this.state;

        if (!item) {
            return null;
        }

        // @ts-ignore
        return <UiArticlePage articleId={articleId} isLoading={isLoading} item={item} />;
    }
}

export { UiArticlePage };
export default ArticlePage;
