import _ from 'underscore';
import { AddressDoctorDialog } from 'app/blocks/address-doctor/__dialog/address-doctor__dialog';
import { trimStringProperties } from 'app/blocks/common/utils';
import { confirmType } from 'app/blocks/Modal/utils';
import DeleteDraftModal from 'app/pages/license-signing-process/Payment/DeleteDraftModal';

async function suggestAddress(previousAddress, address) {
    return AddressDoctorDialog(previousAddress, address);
}

async function suggestAddresses(previousAddresses, enteredAddresses) {
    const cleanedEnteredAddresses = _.object(
        Object.keys(enteredAddresses),
        Object.values(enteredAddresses).map(trimStringProperties),
    );

    const out = {
        mainAddress: {
            ...cleanedEnteredAddresses.mainAddress,
            ...(await suggestAddress(previousAddresses.mainAddress, cleanedEnteredAddresses.mainAddress)),
        },
    };

    if (_.isEqual(cleanedEnteredAddresses.mainAddress, cleanedEnteredAddresses.secondaryAddress)) {
        out.secondaryAddress = { ...out.mainAddress };
    } else if (!_.isEmpty(enteredAddresses.secondaryAddress)) {
        out.secondaryAddress = {
            ...cleanedEnteredAddresses.secondaryAddress,
            ...(await suggestAddress(previousAddresses.secondaryAddress, cleanedEnteredAddresses.secondaryAddress)),
        };
    }

    return out;
}

function confirmDraftDeletion() {
    return confirmType(DeleteDraftModal);
}

export { confirmDraftDeletion, suggestAddress, suggestAddresses };
