import React from 'react';
import { Link } from 'react-router-dom';
import { ID, withCodes } from 'app/blocks/common/codes';
import StaticContent from 'app/blocks/static-content/static-content';
import { MainTitle } from 'app/blocks/titles/titles';

function MissingArticle({ l }: { l: l }) {
    return (
        <div className="MissingArticle" data-seleniumid="missing-article-block">
            <div className="MissingArticle-Title">
                <MainTitle data-seleniumid="missing-article-title">{l('DASHBOARD.MISSING_ARTICLE.TITLE')}</MainTitle>
            </div>
            <StaticContent key="missing_article" seleniumid="missing-article" src="missing_article.html" />
            <div className="MissingArticle-Actions" data-seleniumid="missing-article-actions">
                <Link className="btn btn-link" data-seleniumid="missing-article-find" to="/advancedsearch">
                    {l('DASHBOARD.MISSING_ARTICLE.BUTTON')}
                </Link>
            </div>
        </div>
    );
}

export default withCodes(MissingArticle, ID.DASHBOARD);
