import cn from 'classnames';
import React from 'react';
import middlewareStatic from 'app/blocks/middleware/static';
import StaticContent from 'app/blocks/static-content/static-content';

function PromptHeading({ children, isModal = false, plain = false, seleniumid = 'undefined' }): React.ReactElement {
    return (
        <h6
            className={cn({
                'helpout-border-popup': !plain && !isModal,
                'helpout-popup': plain || isModal,
                white: !plain && !isModal,
            })}
            data-seleniumid={`${seleniumid}-prompt-title`}
        >
            {children}
        </h6>
    );
}

type Props = Either<{ getBody: () => Promise<string>; html: string; nodeId: string }> & {
    className?: string;
    seleniumid?: string;
    isModal?: boolean;
    plain?: boolean;
    arrow?: boolean;
};

export default function Prompt({
    arrow = false,
    className,
    getBody,
    html,
    isModal = false,
    nodeId,
    plain = false,
    seleniumid = 'undefined',
}: Props): React.ReactElement {
    const [title, setTitle] = React.useState<string>(html || getBody ? null : 'Loading...');
    const [prompt, setPrompt] = React.useState<string>();

    React.useEffect(() => {
        const update = async () => {
            if (nodeId) {
                const data = await middlewareStatic.getPrompt();

                setTitle(data[nodeId].headers);
                setPrompt(data[nodeId].html);
            }
        };

        update();
    }, [nodeId]);

    const hasBody = !!(prompt || html || getBody);

    return (
        <div className={cn(`${className} helpout-alert-popup`, { plain, arrow })}>
            {title && (
                <PromptHeading isModal={isModal} plain={plain} seleniumid={seleniumid}>
                    {title}
                </PromptHeading>
            )}
            <div className="helpout-alert-popup-content">
                {hasBody ? (
                    // @ts-ignore
                    <StaticContent
                        getBody={getBody}
                        html={html}
                        seleniumid={`${seleniumid}-prompt`}
                        src={prompt ? `${prompt}.html` : undefined}
                    />
                ) : (
                    <div>Loading...</div>
                )}
            </div>
        </div>
    );
}
