import React, { useEffect, useState } from 'react';
import { Title, WhiteBlock } from 'app/blocks/blocks';
import { ID, withCodes } from 'app/blocks/common/codes';
import * as MiddlewareArticleAssignment from 'app/blocks/middleware/article-assignment';
import * as middlewareSearch from 'app/blocks/middleware/search';
import MiniSearch from 'app/blocks/mini-search/MiniSearch';
import MiniSearchItem from 'app/blocks/mini-search/MiniSearchItem';
import { SubTitle } from 'app/blocks/titles/titles';
import AdvanceSearchButtons from 'app/pages/search/advance-search-buttons/AdvanceSearchButtons';
import { navigate } from 'app/route/history';
import { Input2 } from 'app/ui/form/inputs';
import RadioButton from 'app/ui/form/RadioButton';
import MissingArticleSubmitSuccess from './MissingArticleSubmitSuccess';
import './MissingArticleDetails.scss';

function MissingArticleDetails(props) {
    const { codes } = props;
    const search = codes.SEARCH_CODES.SEARCH.MISSING_ARTICLE;
    const inputType = {
        doi: 'articleDoi',
        refCode: 'articleManuscriptId',
        journal: 'journalId',
        title: 'articleTitle',
        correspondingAuthor: 'correspondingAuthor',
    };

    const [journalSearch, setJournalSearch] = useState(null);
    const [journalApiData, setJournalApiData] = useState(null);

    const [formData, setFormData] = useState({
        articleDoi: null,
        articleManuscriptId: null,
        journalId: null,
        articleTitle: null,
        correspondingAuthor: null,
    });
    const [canSubmitForm, setCanSubmitForm] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const isNotEmpty = value => {
        return value !== null && value.length !== 0;
    };

    const handleEnableFormSubmit = data => {
        if (isNotEmpty(data.journalId) && isNotEmpty(data.articleTitle) && data.correspondingAuthor != null) {
            setCanSubmitForm(true);
        } else {
            setCanSubmitForm(false);
        }
    };

    useEffect(() => {
        handleEnableFormSubmit(formData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    const onChangeInput = (type, value) => {
        if (type) {
            setFormData({
                ...formData,
                ...{
                    [type]: value,
                },
            });
        }
    };

    const onBackToArticleSearch = () => {
        navigate('/advancedsearch');
    };

    const onBackToDashboard = () => {
        navigate('/dashboard');
    };

    const handleSearch = async query => {
        setJournalSearch(query);

        if (query === '') {
            onChangeInput(inputType.journal, null);
        }

        let data = [];
        if ((query || '').trim()) {
            data = (await middlewareSearch.journalOnlySearch({ title: query }, { size: 5, offset: 0 })).items;
            setJournalApiData(data);
        } else {
            setJournalApiData(data);
        }
    };

    const handleSearchListClick = query => {
        setJournalSearch(query);
    };

    const handleOnSubmit = async e => {
        e.preventDefault();
        await MiddlewareArticleAssignment.notifyAdminMissingArticle(formData);
        setSubmitted(true);
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <Title seleniumid="missing-article-details-title">Find your missing article</Title>
                    {submitted && <MissingArticleSubmitSuccess />}

                    {!submitted && (
                        <WhiteBlock className="page p-2x" data-seleniumid="missing-article-details-page">
                            <SubTitle className="m-btm_normal">{search.title}</SubTitle>
                            <p>{search.description}</p>
                            <div className="clearfix" />
                            <div className="MissingArticleDetailsFields">
                                <div className="fields">
                                    <label htmlFor="missing-article-doi-input">Article DOI</label>
                                    <Input2
                                        data-seleniumid="missing-article-doi-input"
                                        id="search-advanced-search-doi-input"
                                        onChange={value => onChangeInput(inputType.doi, value)}
                                    />
                                </div>
                                <div className="fields">
                                    <label htmlFor="missing-article-editorial-ref-code">Editorial Reference Code</label>
                                    <Input2
                                        data-seleniumid="missing-article-editorial-ref-code"
                                        id="missing-article-editorial-ref-code"
                                        onChange={value => onChangeInput(inputType.refCode, value)}
                                    />
                                </div>
                                <div className="fields">
                                    <label htmlFor="missing-article-editorial-ref-code">
                                        Journal <span className="required">required</span>
                                    </label>
                                    <MiniSearch
                                        defaultValue={journalSearch}
                                        onInput={handleSearch}
                                        searchResults={journalApiData}
                                        seleniumId="journalSearch-combobox-input"
                                        withSub
                                    >
                                        {journalApiData &&
                                            journalApiData.map(journal => (
                                                <div key={journal.id}>
                                                    <MiniSearchItem dataSeleniumId={`journal-${journal.id}`}>
                                                        <button
                                                            onClick={() => {
                                                                onChangeInput(inputType.journal, journal.id);
                                                                handleSearchListClick(journal.doc.title);
                                                            }}
                                                            type="button"
                                                        >
                                                            {journal.doc.title}
                                                        </button>
                                                    </MiniSearchItem>
                                                </div>
                                            ))}
                                    </MiniSearch>
                                </div>
                                <div className="fields">
                                    <label htmlFor="missing-article-article-title">
                                        Article Title <span className="required">required</span>
                                    </label>
                                    <Input2
                                        data-seleniumid="missing-article-article-title"
                                        id="missing-article-article-title"
                                        onChange={value => onChangeInput(inputType.title, value)}
                                    />
                                </div>
                                <div className="fields">
                                    <label htmlFor="missing-article-article-title">
                                        Are you a corresponding author on this paper?{' '}
                                        <span className="required">required</span>
                                    </label>
                                    <div className="AssignArticleByIdTxtSeparate">
                                        <RadioButton
                                            checked={formData.correspondingAuthor === true}
                                            data-seleniumid="assign-article_by-id-radio-corr-author"
                                            id="missing-article-article-corr-author"
                                            label="I am a corresponding author"
                                            name="missing-article-article-radio"
                                            onChange={() => {
                                                onChangeInput(inputType.correspondingAuthor, true);
                                            }}
                                            style={{ display: 'inline-flex', marginRight: '30px' }}
                                            value
                                        />
                                    </div>
                                    <div className="AssignArticleByIdTxtSeparate">
                                        <RadioButton
                                            checked={formData.correspondingAuthor === false}
                                            data-seleniumid="assign-article_by-id-radio-co-author"
                                            id="missing-article-article-co-author"
                                            label="I am a co-author"
                                            name="missing-article-article-radio"
                                            onChange={() => {
                                                onChangeInput(inputType.correspondingAuthor, false);
                                            }}
                                            style={{ display: 'inline-flex' }}
                                            value={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </WhiteBlock>
                    )}
                    {!submitted && (
                        <AdvanceSearchButtons
                            disableOnNext={!canSubmitForm}
                            nextButtonText={search.btn_next}
                            onNext={handleOnSubmit}
                            onPrevious={onBackToArticleSearch}
                            previousBtnText={search.btn_prev}
                        />
                    )}
                    {submitted && (
                        <AdvanceSearchButtons
                            hideNext
                            onPrevious={onBackToDashboard}
                            previousBtnText={search.btn_board}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default withCodes(React.memo(MissingArticleDetails), ID.SEARCH_CODES);
