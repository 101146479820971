import { keymirror } from 'app/blocks/common/utils';

const ConfirmationTypeEnum = keymirror({
    AUTHOR_LIST_IS_INCORRECT: '',
    COMBINED_CONFIRMATION: '',
    LICENSE_CONFIRMATION: '',
    OAORDER_CONFIRMATION: '',
    OOORDER_CONFIRMATION: '',
    RCA_CHANGED: '',
});

export default ConfirmationTypeEnum;
