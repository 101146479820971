import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import ContentUtils from 'app/blocks/common/content-utils';
import showDialog from 'app/blocks/common/jsx/dialogModal';
import Alerts from 'app/blocks/common/spinner';
import Layout from 'app/blocks/Layout/Layout';
import * as middlewareProfile from 'app/blocks/middleware/profile';
import AlertsPanel from './alerts-panel';

class AlertsPanelContainer extends Component {
    static propTypes = {
        closePanel: PropTypes.func.isRequired,
    };

    state = {
        alerts: {},
        errorMsg: '',
        isLoading: true,
    };

    async componentDidMount() {
        const { l } = this.props;

        try {
            Alerts.renderSpinner();

            const alerts = await middlewareProfile.getAlerts();

            this.setState({
                alerts,
                checkedSelectAll: alerts.alerts.every(alert => alert.email === true),
                isLoading: false,
            });
        } catch (error) {
            showDialog.error(error, {
                message: error.message || (await ContentUtils.getServerErrorMessage(ID.PROFILE_ACCOUNT, error)),
            });
            this.setState({
                errorMsg: l('PROFILE_ACCOUNT.errorMessages.UNABLE_TO_LOAD_CONTENT'),
                isLoading: false,
            });
        }
        Alerts.removeSpinner();
    }

    changeParam = (name, value) => {
        this.setState(state => ({ alerts: { ...state.alerts, [name]: value } }));
    };

    toggleAlert = (alertId, checked) => {
        const { alerts } = this.state;
        const newAlerts = alerts.alerts.map(alert =>
            alert.alertId === alertId ? { ...alert, email: checked } : alert,
        );

        let checkedSelectAll = false;
        if (checked && newAlerts.every(alert => alert.email === true)) {
            checkedSelectAll = true;
        }
        this.setState({
            alerts: { ...alerts, alerts: newAlerts },
            checkedSelectAll,
        });
    };

    toggleSelectionAllAlerts = e => {
        const { alerts } = this.state;
        const newAlerts = alerts.alerts.map(alert => ({ ...alert, email: e.target.checked }));
        this.setState({
            alerts: { ...alerts, alerts: newAlerts },
            checkedSelectAll: e.target.checked,
        });
    };

    cancelAlerts = () => {};

    saveAlerts = async () => {
        const { l } = this.props;
        const { alerts } = this.state;

        try {
            Alerts.renderSpinner();
            this.setState({ isLoading: true });
            const response = await middlewareProfile.saveAlerts({
                alerts: alerts.alerts,
                notificationEmail: alerts.notificationEmail,
            });
            if (response === true) {
                showDialog.success({
                    message: l('PROFILE_ACCOUNT.confirmationMessages.PROFILE_ACCNT_SAVE_ALERTS_INFO_POST_CONF_TEXT'),
                    onClose: this.cancelAlerts,
                });
            } else {
                showDialog.error(response, {
                    message:
                        response.message || (await ContentUtils.getServerErrorMessage(ID.PROFILE_ACCOUNT, response)),
                });
            }
        } catch (error) {
            showDialog.error(error);
        }
        this.setState({ isLoading: false });
        Alerts.removeSpinner();
    };

    render() {
        const { alerts, checkedSelectAll, errorMsg, isLoading } = this.state;

        return errorMsg || isLoading ? (
            <Layout className="m-top_mini" errorMsg={errorMsg} isLoading={isLoading} />
        ) : (
            <AlertsPanel
                {...this.props}
                alerts={alerts}
                cancelAlerts={this.cancelAlerts}
                changeParam={this.changeParam}
                checkedSelectAll={checkedSelectAll}
                saveAlerts={this.saveAlerts}
                toggleAlert={this.toggleAlert}
                toggleSelectionAllAlerts={this.toggleSelectionAllAlerts}
            />
        );
    }
}

export default withCodes(AlertsPanelContainer, ID.PROFILE_ACCOUNT);
