import React from 'react';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import Combobox, { Props } from 'app/ui/form/Combobox';
import './ComboboxWithChevron.scss';

function ComboboxWithChevron<T>(props: Props<T>): React.ReactElement {
    return (
        <div className="ComboboxWithChevron">
            <Combobox {...props} />
            <SvgIcon.chevron iconTitle="Chevron icon" />
        </div>
    );
}

export { Props };
export default ComboboxWithChevron;
