/* eslint-disable react/no-unused-state */
import { autobind } from 'core-decorators';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';
import { processing } from 'app/blocks/common/decorators';
import { compose } from 'app/blocks/common/utils';
import withLightPanel from 'app/blocks/Panel/advanced/withLightPanel';
import Context from 'app/pages/license-signing-process/Payment/GOA/Context';
import payment from 'app/pages/orders/orders.payment';
import validation from 'app/pages/orders/panel/payment/validation';

const PaymentMethod = payment.method;
const methodsWithPONumber = [PaymentMethod.PROFORMA.key, PaymentMethod.INVOICE.key];

const defaultState = {
    paymentDetails: {},
};

function withPaymentPanelContainer(Component) {
    @autobind
    class PaymentPanelContainer extends React.PureComponent {
        static propTypes = {
            canChangePaymentMethod: PropTypes.bool,
            isOrderExisting: PropTypes.bool,
            order: PropTypes.shape({ paymentDetails: PropTypes.shape({}) }).isRequired,
            paymentDetails: PropTypes.shape({}),
            paymentMethods: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
            updatePaymentDetails: PropTypes.func.isRequired,
            updateValidationResults: PropTypes.func.isRequired,
            setValidationHandler: PropTypes.func.isRequired,
        };

        static defaultProps = {
            ...defaultState,
            canChangePaymentMethod: true,
            isOrderExisting: false,
        };

        async componentDidMount() {
            const { order, setValidationHandler } = this.props;
            setValidationHandler(this.validate);
            this.updatePayment(order.paymentDetails);
        }

        @processing('isValidating')
        async validate() {
            const { updateValidationResults } = this.props;
            try {
                await validation(this.props.paymentDetails);
                updateValidationResults(undefined);
            } catch (error) {
                if (error.name === 'ValidationError') {
                    updateValidationResults(error.errors);
                }

                throw error;
            }
        }

        // eslint-disable-next-line react/no-unused-class-component-methods
        isChanged() {
            return Object.keys(defaultState).some(
                key => this.props[key] && !_.isEqual(defaultState[key], this.props[key]),
            );
        }

        updatePayment(newPaymentDetails) {
            const { paymentDetails, updatePaymentDetails } = this.props;
            updatePaymentDetails({ paymentDetails: { ...paymentDetails, ...newPaymentDetails } });
        }

        onPaymentMethodChange(paymentMethod) {
            const { updatePaymentMethod } = this.props;
            const skipPONumber = paymentMethod === PaymentMethod.CREDIT_CARD.key;
            updatePaymentMethod(paymentMethod, skipPONumber);
        }

        onPurchaseNumberChange(poNumber) {
            this.updatePayment({ poNumber });
        }

        onPaymentMethodFocus() {
            const { updateValidationResults, validationResults } = this.props;
            updateValidationResults({ ...validationResults, paymentMethod: undefined });
        }

        render() {
            return (
                <Component
                    {...this.props}
                    isPaymentMethodEditable={this.props.canChangePaymentMethod}
                    isPONumberVisible={methodsWithPONumber.includes(this.props?.paymentDetails?.paymentMethod)}
                    onPaymentMethodChange={this.onPaymentMethodChange}
                    onPaymentMethodFocus={this.onPaymentMethodFocus}
                    onPurchaseNumberChange={this.onPurchaseNumberChange}
                    paymentMethod={get(this.props, 'paymentDetails.paymentMethod')}
                    paymentMethodsAvailable={this.props.paymentMethods}
                    poNumber={get(this.props, 'paymentDetails.poNumber')}
                />
            );
        }
    }

    return PaymentPanelContainer;
}

export { withPaymentPanelContainer };
export default Component =>
    compose(
        Context.withContext(state => ({
            ...state.initiate.payment,
            canChangePaymentMethod: state.canChangePaymentMethod,
            isOrderExisting: state.isOrderExisting,
            isPaymentMethodSupportedByBrowser: state.isPaymentMethodSupportedByBrowser,
            paymentDetails: state.paymentDetails,
            paymentMethods:
                state.order.billingAddress.countryCode === 'CN' && state.socopayEnabled
                    ? payment.oaPaymentMethodsWithAlipay
                    : payment.oaPaymentMethods,
            register: state.registerPanel,
            updatePaymentDetails: state.updatePaymentDetails,
            updatePaymentMethod: state.updatePaymentMethod,
            updateValidationResults: state.updateValidationResults,
            unregister: state.unregisterPanel,
            order: state.order,
        })),
        withLightPanel('payment'),
        withPaymentPanelContainer,
    )(Component);
