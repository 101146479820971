import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'underscore';

const defaultRenderer = ({ sameStyle, value }) => (
    <span className={cn({ search_Item_value: !sameStyle })} dangerouslySetInnerHTML={{ __html: value }} />
);

defaultRenderer.defaultProps = {
    sameStyle: false,
};

defaultRenderer.propTypes = {
    sameStyle: PropTypes.bool,
    value: PropTypes.string.isRequired,
};

function ResultAttribute(props) {
    const { className, id, name, renderer, value } = props;

    const ValueRenderer = renderer || defaultRenderer;

    return (
        ((value !== undefined && !_.isEmpty(value)) || _.isNumber(value)) && (
            <div className={cn('search_Item_field', className)} data-seleniumid={`search-results-${name}-${id}`}>
                {`${name} `}
                {ValueRenderer(props)}
            </div>
        )
    );
}

ResultAttribute.defaultProps = {
    className: undefined,
    name: '',
    renderer: undefined,
    value: '',
};

ResultAttribute.propTypes = {
    className: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string,
    renderer: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ResultAttribute;
