import PropTypes from 'prop-types';
import React from 'react';
import { ComboboxWithChevron } from 'app/blocks/blocks';
import ErrorLabel from 'app/blocks/ErrorLabel/ErrorLabel';
import PromptAction from 'app/blocks/Prompt/_action/prompt_action';
import NewLayout from 'app/blocks/Row/NewLayout';
import OldLayout from 'app/blocks/Row/OldLayout';
import FormContext from 'app/pages/license-signing-process/context/FormContext';

function comboboxRenderer(props) {
    return (
        <ComboboxWithChevron
            {...props}
            className="lName"
            endlessScroll
            isError={!!props.error}
            seleniumid={`${props.formSeleniumid}-${props.name}`}
        />
    );
}

function RowDropdown(props) {
    const components = {
        input: props.comboboxRenderer(props),
        prompt: props.prompt && (
            <PromptAction {...props.prompt} className={props.isNew ? `${props.className}-Prompt` : ''} />
        ),
        validation: props.error && (
            <ErrorLabel
                data-seleniumid={`validation-error-${props.formSeleniumid}-${props.name}`}
                text={props.error.message}
            />
        ),
    };

    const Component = props.isNew ? NewLayout : OldLayout;

    return (
        <Component
            className={props.className}
            classNames={props.classNames}
            components={components}
            isDisabled={props.disabled}
            isOptional={props.isOptional}
            isRequired={props.isRequired}
            isVertical={props.isVertical}
            label={props.label}
        />
    );
}

RowDropdown.propTypes = {
    comboboxRenderer: PropTypes.func,
};

RowDropdown.defaultProps = {
    comboboxRenderer,
};

export default FormContext.withContext(['isNew'])(RowDropdown);
