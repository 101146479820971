import cn from 'classnames';
import get from 'lodash.get';
import React from 'react';
import './OldLayout.scss';

export default function OldLayout(props) {
    if (props.isVertical) {
        return (
            <div className={cn('m-top_normal', { disabledBlock: props.isDisabled })}>
                <div className="row m-top_normal">
                    <div className={get(props, 'classNames.label', 'col-12')}>
                        <label>{props.label}</label>
                        {props.components.prompt}
                    </div>
                </div>
                <div className="row m-top_micro">
                    <div className={get(props, 'classNames.input', 'col-12')}>{props.components.input}</div>
                </div>
                {props.components.validation && (
                    <div className="row m-top_micro">
                        <div className={get(props, 'classNames.input', 'col-12')}>{props.components.validation}</div>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className={cn('row m-top_normal', props.className, { disabledBlock: props.isDisabled })}>
            <div className={get(props, 'classNames.label', 'col-md-5')}>
                <label>{props.label}</label>
            </div>
            <div className={get(props, 'classNames.input', 'col-md-7')}>
                <div className="personinfo-field">
                    {props.components.input}
                    {props.components.prompt}
                </div>
                {props.components.validation}
                {props.components.helpText}
            </div>
        </div>
    );
}
