import React from 'react';
import AreasOfInterest from 'app/blocks/areas-of-interest/areas-of-interest';
import { ID, withCodes } from 'app/blocks/common/codes';
import CountryPicker from 'app/blocks/country-picker/country-picker';
import ErrorLabel from 'app/blocks/ErrorLabel/ErrorLabel';
import CheckboxButton from 'app/ui/form/CheckboxButton';

function TOSConfirmations({
    l,
    showCountry,
    showConsents,
    showPIPL,
    showAreas,
    country,
    onChangeCountry,
    onFocusCountry,
    wileyTermsOfUseConsent,
    onChangeWileyTermsOfUseConsent,
    chinaPIPLConsent,
    onChangeChinaPIPLConsent,
    sendEmail,
    onChangeSendEmail,
    validationErrors,
    onFocusAreas,
    onChangeAreaOfInterest,
}) {
    return (
        <>
            {showCountry && (
                <div className="RegisterRow">
                    <div className="RegisterRow-Label">
                        {l('REGISTRATION.uiLabelMessages.REGISTRATION_COUNTRY_LABEL')}
                        <span className="Required">*</span>
                    </div>
                    <div className="RegisterRow-Input">
                        <div data-seleniumid="registerCountrySelectId">
                            <CountryPicker
                                changeHandler={onChangeCountry}
                                hideNoneValue
                                isError={!!validationErrors.country}
                                onFocus={onFocusCountry}
                                placeholder={l('REGISTRATION.uiLabelMessages.REGISTRATION_COUNTRY_PLACEHOLDER')}
                                selectedItem={country}
                                seleniumid="register-country"
                            />
                        </div>
                        {validationErrors.country && <ErrorLabel text={l(validationErrors.country)} />}
                    </div>
                </div>
            )}
            {showConsents && (
                <div className="RegisterRow" data-seleniumid="register-wileyTermsOfUseConsent--block">
                    <div className="RegisterRow-Input">
                        <div data-seleniumid="register_wileyTermsOfUseConsent">
                            <CheckboxButton
                                checked={!!wileyTermsOfUseConsent}
                                data-seleniumid="register-wileyTermsOfUseConsent"
                                isError={!!validationErrors.wileyTermsOfUseConsent}
                                label={
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: l('REGISTRATION.inlineHelp.WILEY_TERMS'),
                                        }}
                                    />
                                }
                                onChange={e => onChangeWileyTermsOfUseConsent(e.target.checked)}
                            />
                        </div>
                        {validationErrors.wileyTermsOfUseConsent && (
                            <ErrorLabel text={l(validationErrors.wileyTermsOfUseConsent)} />
                        )}
                    </div>
                </div>
            )}
            {showPIPL && country?.id === 'CN' && (
                <div className="RegisterRow" data-seleniumid="register-chinaPIPLConsent--block">
                    <div className="RegisterRow-Input">
                        <div data-seleniumid="register_chinaPIPLConsent">
                            <CheckboxButton
                                checked={!!chinaPIPLConsent}
                                data-seleniumid="register-chinaPIPLConsent"
                                isError={!!validationErrors.chinaPIPLConsent}
                                label={
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: l('REGISTRATION.inlineHelp.PIPL_CONSENT'),
                                        }}
                                    />
                                }
                                onChange={e => onChangeChinaPIPLConsent(e.target.checked)}
                            />
                        </div>
                        {validationErrors.chinaPIPLConsent && (
                            <ErrorLabel text={l(validationErrors.chinaPIPLConsent)} />
                        )}
                    </div>
                </div>
            )}

            {showAreas && (
                <div className="RegisterRow" data-seleniumid="register-agree-to-receive--block">
                    <div className="RegisterRow-Input">
                        <CheckboxButton
                            checked={!!sendEmail}
                            data-seleniumid="register-terms"
                            id="registerCheckBox"
                            label={
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: l('REGISTRATION.inlineHelp.AGREE_EMAILS'),
                                    }}
                                />
                            }
                            onChange={e => onChangeSendEmail(e.target.checked)}
                        />
                    </div>

                    {!!sendEmail && (
                        <div className="RegisterRow">
                            <AreasOfInterest
                                className={validationErrors.areaOfInterest && 'error'}
                                onChange={onChangeAreaOfInterest}
                                onFocus={onFocusAreas}
                            />
                            {validationErrors.areaOfInterest && (
                                <ErrorLabel text={l(validationErrors.areaOfInterest)} />
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default withCodes(TOSConfirmations, ID.REGISTRATION);
