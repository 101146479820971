import { keymirror } from 'app/blocks/common/utils';

const HttpStatuses = keymirror({
    HTTP_CODE_404: '',
    HTTP_CODE_502: '',
    HTTP_CODE_504: '', // cloudfront
    HTTP_CODE_524: '', // cloudflare
});

export default HttpStatuses;

export const TimeoutCodes = [HttpStatuses.HTTP_CODE_504, HttpStatuses.HTTP_CODE_524];
