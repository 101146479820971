import React, { useState } from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import SvgIcon from 'app/blocks/common/svg-icon/svg-icon';
import Image from 'app/blocks/image/image';
import PanelActionsButtons from 'app/blocks/Panel/actions/buttons/panel_actions__buttons';
import { RevenueBadge, TypeBadge } from 'app/pages/search/_item/badges';
import ResultAttribute from 'app/pages/search/search_result__attribute';

const ResultJournal: React.VFC<{
    item: Search.Journal;
    l: l;
}> = ({ item, l }) => {
    const [actions, setActions] = useState(item.doc.actions);

    const actionsHandler = event => {
        if (event.toggle) {
            const newActions = actions.map(action => {
                if (action.code === event.code) {
                    return { code: event.toggle };
                }

                return action;
            });

            setActions(newActions);
        }
    };

    return (
        <div className="search_Item">
            <RevenueBadge seleniumid={item.id}>
                {item.doc.revenueModel && l(`SEARCH_CODES.oaStatus.${item.doc.revenueModel}`)}
            </RevenueBadge>

            <div className="row">
                <div className="search_Item__text col-12 col-md-8">
                    <div className="search-item__pic">
                        <Image className="search_Item__img" src={item.doc.coverImage} type={Image.TYPES.JOURNAL} />
                    </div>
                    <div className="text_bold" data-seleniumid={`search-results-journal-name-${item.id}`}>
                        <a
                            className="search_Item_journalName"
                            dangerouslySetInnerHTML={{ __html: item.doc.title }}
                            href={item.doc.wolUrl}
                            rel="noopener noreferrer"
                            target="_blank"
                        />
                    </div>

                    {item.doc.displayFormerTitle && (
                        <div
                            className="mt-two-thirds text_bold text_md search_journal_former_name"
                            data-seleniumid={`search-results-journal-former-name-${item.id}`}
                        >
                            Formerly known as &quot;
                            <span dangerouslySetInnerHTML={{ __html: item.doc.displayFormerTitle }} />
                            &quot;
                        </div>
                    )}
                    {!item.doc.suppressIsiImpactFactor && (
                        <ResultAttribute
                            className="mt-two-thirds"
                            id={item.id}
                            name="Impact Factor:"
                            value={item.doc.isiImpactFactor}
                        />
                    )}

                    <ResultAttribute
                        className="mt-two-thirds"
                        id={item.id}
                        name="Online ISSN:"
                        value={item.doc.electronicIssn}
                    />

                    <ResultAttribute
                        className="mt-two-thirds"
                        id={item.id}
                        name="Print ISSN:"
                        value={item.doc.printIssn}
                    />

                    {item.doc.societyName != null && item.doc.societyName.length > 0 && (
                        <ResultAttribute
                            className="mt-two-thirds"
                            id={item.id}
                            name="Published on behalf of: "
                            sameStyle
                            value={item.doc.societyName.join(', ')}
                        />
                    )}
                </div>

                <div className="col-md-4 mt-base mb-base">
                    <div className="text-right">
                        {actions && actions.length > 0 && (
                            <PanelActionsButtons
                                handler={actionsHandler}
                                icons={{ star: <SvgIcon.new className="search__star-icon" iconTitle="Star" /> }}
                                inline={false}
                                items={actions}
                                name="search"
                                params={{
                                    AUTHOR_GUIDELINES_URL: item.doc.authorGuidelinesUrl,
                                    JOURNAL_ID: item.id,
                                    SUBMIT_ARTICLE_URL: item.doc.onlineSubmissionUrl,
                                }}
                                seleniumid={`search-results-journal-${item.id}`}
                            />
                        )}
                    </div>
                </div>
            </div>

            <TypeBadge seleniumid={item.id}>{l('SEARCH_CODES.JOURNAL_LABEL')}</TypeBadge>
        </div>
    );
};

export default withCodes(ResultJournal, ID.SEARCH_CODES);
