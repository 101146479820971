import React, { Component } from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import validation from 'app/blocks/common/validation';
import * as middleware from 'app/blocks/middleware/middleware';
import InstitutionPickerView, { transformOptions, NONE_VALUE } from './institution-picker-view';

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_THROTTLE_TIMEOUT = 500;
const DEFAULT_NONE_LABEL = 'None';
const MIN_LENGTH_TO_SEARCH = 3;

// export for testing purposes
export function externalToInternalItem(item, config: { noneLabel: string }) {
    if (item === null || (item && (item.id === NONE_VALUE || item.name === NONE_VALUE))) {
        return {
            label: config.noneLabel,
            value: NONE_VALUE,
        };
    }

    if (!item || (item && !item.id && !item.name)) {
        return undefined;
    }

    return {
        ...item,
        custom: item.custom || !item.id,
        label: item.name,
        value: item.id,
    };
}

// todo: is it better to return just 'undefined' or 'null' in case of *nothing* or *none* is selected?
// export for testing purposes
export function internalToExternalItem(item) {
    if (item && item.value === NONE_VALUE) {
        return {
            id: NONE_VALUE,
            name: NONE_VALUE,
        };
    }

    if (!item || (item && !item.value && !item.label)) {
        return {
            id: undefined,
            name: undefined,
        };
    }

    return {
        ...item,
        custom: item.custom || !item.value,
        id: item.value,
        name: item.label,
    };
}

const INPUT_CHANGE_ACTIONS = ['input-change', 'set-value'];
const SELECT_CHANGE_ACTIONS = ['select-option', 'create-option', 'clear'];
const CLEAR_OPTIONS_ACTIONS = ['menu-close'];

function CLOSED_STATE() {
    return {
        hasMore: false,
        isLoading: false,
        offset: 0,
        options: [],
        rawOptions: [],
        searchPhrase: '',
    };
}

function RELOAD_STATE() {
    return {
        hasMore: false,
        isLoading: true,

        isLoadingError: false,
        offset: 0,
        options: [],
        rawOptions: [],
    };
}

type Props = {
    blacklist?: string[];
    institutionsIdFromProfile?: string[];
    minLengthToSearch?: number;
    pageSize?: number;

    placeholder?: string;
    seleniumid?: string;
    className?: string;
    showNone?: boolean;
    noneLabel?: string;
    displayCustom?: boolean;
    isDisabled?: boolean;
    isClearable?: boolean;
    isError?: boolean;

    selectedItem: any;
    changeHandler: (item: any) => void;
    onFocus?: () => void;
};

class InstitutionPicker extends Component<Props> {
    static defaultProps = {
        blacklist: [],
        className: '',
        displayCustom: true,
        institutionsIdFromProfile: [],

        isClearable: false,
        isDisabled: false,
        isError: false,
        minLengthToSearch: MIN_LENGTH_TO_SEARCH,
        noneLabel: DEFAULT_NONE_LABEL,
        pageSize: DEFAULT_PAGE_SIZE,
        placeholder: '',
        showNone: false,
    };

    loadFirstPageTimer;

    pageRequestId: number;

    userLocation = {};

    state = {
        inputValue: '',
        isLoadingError: false,

        ...CLOSED_STATE(),
    };

    // todo: get rid of such request for each picker component. the same for 'institutionsIdFromProfile'
    async componentDidMount() {
        try {
            const userAddresses = await middleware.profile.getAddresses();
            const userContactAddress = userAddresses.correspondenceAddress;

            if (userContactAddress) {
                this.userLocation = { userCountryCode: userContactAddress.countryCode };
            } else {
                const user = await middleware.profile.getProfileInfo();
                this.userLocation = { userCountryCode: user.countryCode };
            }
        } catch (error) {
            console.error(error);
        }
    }

    isValidToSearch = (value = '') => {
        if (!this.props.minLengthToSearch) {
            return true;
        }

        return validation.hasAnySymbol(value) && value.length >= this.props.minLengthToSearch;
    };

    getInstitutions = async () => {
        const {
            blacklist,
            institutionsIdFromProfile,
            noneLabel,

            pageSize,
            showNone,
        } = this.props;

        const { offset, rawOptions, searchPhrase } = this.state;

        const requestId = this.pageRequestId;
        let isLoadingError = false;
        let payload = null;
        try {
            payload = await middleware.institutions.getInstitutionsList(searchPhrase, pageSize, offset, {
                blacklist,
                institutionsIdFromProfile,
                userLocation: this.userLocation,
            });
        } catch (error) {
            isLoadingError = true;
            console.error(error);
        }

        if (requestId !== this.pageRequestId) {
            return; // outdated response
        }
        if (isLoadingError) {
            this.imitateClosing(isLoadingError);
            return;
        }

        const { hasMore, list } = payload;

        if (!rawOptions.length && showNone) {
            rawOptions.push(externalToInternalItem(null, { noneLabel }));
        }

        Array.prototype.push.apply(
            rawOptions,
            list.map(i => externalToInternalItem(i, { noneLabel })),
        );
        const options = transformOptions(rawOptions, { institutionsIdFromProfile });

        this.setState({
            hasMore,
            isLoading: false,
            offset: offset + list.length,
            options,
            rawOptions,
        });
    };

    onSelectChange = (value, { action }) => {
        if (SELECT_CHANGE_ACTIONS.includes(action)) {
            this.props.changeHandler(internalToExternalItem(value));
        }
    };

    onInputChange = (value, { action }) => {
        this.pageRequestId = null; // todo: we could interrupt requests here
        if (this.loadFirstPageTimer) {
            window.clearTimeout(this.loadFirstPageTimer);
            this.loadFirstPageTimer = null;
        }

        if (INPUT_CHANGE_ACTIONS.includes(action)) {
            if (this.isValidToSearch(value)) {
                this.loadFirstPage(value);
            } else {
                this.imitateClosing();
            }
        } else if (CLEAR_OPTIONS_ACTIONS.includes(action)) {
            this.imitateClosing();
        }

        this.setState({ inputValue: value });
    };

    loadFirstPage = value => {
        this.setState({
            ...RELOAD_STATE(),
            searchPhrase: value,
        });

        this.loadFirstPageTimer = window.setTimeout(() => {
            this.pageRequestId = Math.random();
            this.getInstitutions();
        }, DEFAULT_THROTTLE_TIMEOUT);
    };

    loadNextPage = () => {
        this.setState(
            {
                isLoading: true,
            },
            this.getInstitutions,
        );
    };

    imitateClosing(isLoadingError = false) {
        this.setState({
            ...CLOSED_STATE(),
            isLoadingError,
        });
    }

    render() {
        const { displayCustom, isError, noneLabel, onFocus, pageSize, placeholder, selectedItem, seleniumid } =
            this.props;
        const { hasMore, inputValue, isLoading, isLoadingError, offset, options, searchPhrase } = this.state;

        return (
            <InstitutionPickerView
                {...this.props}
                displayCustom={displayCustom}
                hasMore={hasMore}
                inputValue={inputValue}
                isError={isError}
                isLoading={isLoading}
                isLoadingError={isLoadingError}
                isLongList={hasMore || offset > pageSize}
                isValidToSearch={this.isValidToSearch}
                loadNextPage={this.loadNextPage}
                onFocus={onFocus}
                onInputChange={this.onInputChange}
                onSelectChange={this.onSelectChange}
                options={options}
                placeholder={placeholder}
                searchPhrase={searchPhrase}
                selectedItem={externalToInternalItem(selectedItem, { noneLabel })}
                seleniumid={seleniumid}
            />
        );
    }
}

export default withCodes(InstitutionPicker, ID.INSTITUTION_PICKER);
