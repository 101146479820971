import React from 'react';
import { numbers } from 'app/blocks/common/formatters';
import orderCurrency from 'app/pages/orders/orders.currency';

const getPercentages = (discountAmount, basePrice) => (discountAmount > 0 ? (100 * discountAmount) / basePrice : 0);

const OrdersValue = ({ basePrice, className, currency, discountAmount, percentView, seleniumId = '', value }) => {
    return (
        <span className={className} data-seleniumid={seleniumId}>
            {currency && <>{orderCurrency.getCurrencySign(currency)}</>}
            {percentView ? (
                <span data-seleniumid={`orders__value-percent-${seleniumId}`}>
                    {numbers.formatPercents(getPercentages(discountAmount, basePrice))}
                </span>
            ) : (
                <span data-seleniumid={`orders__value-value-${seleniumId}`}>{numbers.formatNumber(value)}</span>
            )}
        </span>
    );
};

export default OrdersValue;
