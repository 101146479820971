import React from 'react';
import { ID, withCodes } from 'app/blocks/common/codes';
import { ErrorView } from 'app/blocks/views/WidgetView';
import { useArticlePage } from '../ArticlePage.context';

function ErrorWidget({ l }: { l: l }): React.ReactElement {
    const item = useArticlePage();
    const errors = item.errors.map(error => l(error)).filter(error => !!error);
    const [showDetails, setShowDetails] = React.useState(false);

    return (
        <ErrorView icon="error" seleniumid="error">
            <div className="ErrorText">{l('WIDGETS.ERROR')}</div>
            {errors.length && (
                <>
                    {/* eslint-disable-next-line */}
                    <a className="ErrorDetailsButton" onClick={() => setShowDetails(!showDetails)}>
                        {showDetails ? l('BUTTONS.LESS_DETAILS') : l('BUTTONS.MORE_DETAILS')}
                    </a>
                    {showDetails && (
                        <ul className="ErrorDetails">
                            {errors.map(error => (
                                <li className="ErrorRow">{error}</li>
                            ))}
                        </ul>
                    )}
                </>
            )}
        </ErrorView>
    );
}

export default withCodes(ErrorWidget, ID.DASHBOARD, ID.BUTTONS, ID.WIDGETS, ID.PANEL_STATUS);
