import React, { useCallback, useEffect, useState } from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { compose } from 'app/blocks/common/utils';
import withConfirmLeaving from 'app/blocks/common/withConfirmLeaving';
import withScrollToTop from 'app/blocks/common/withScrollToTop';
import FileUploader from 'app/blocks/file-uploader/FileUploader';
import { useLicenseFlow } from 'app/pages/license-signing-process/context/Context';
import MultiAuthorsView from './MultiAuthorsView';
import OwnershipBlock from './OwnershipBlock';
import prepareLabels from '../labels';

function MultiAuthors({ l, setChangesChecker, unsetChangesChecker }) {
    const {
        all,
        isLicenseSubmissionConfirming,
        confirmPrintLicenseSubmission,
        uploadsConfig,
        article,
        getPrevStep,
        goBack,
        journal,
        listCopyrightOwnerships,
        confirmStepTracker,
    } = useLicenseFlow();

    const [isObtained, setIsObtained] = useState(!!all?.licenseSubmission?.stepTracker?.signingLicenseStep?.confirmed);
    const [currentStep, setCurrentStep] = useState(isObtained ? 1 : 0);

    const confirmStep = useCallback(async () => {
        await confirmStepTracker(0);
        setCurrentStep(1);
    }, [confirmStepTracker]);

    const ownershipList = all?.copyrightOwnership?.copyrightOwnerships || [];

    const [fileItemsUploader, setFileItemsUploader] = useState(
        ownershipList.map(o => ({ ...o, fileItems: [], canConfirm: false, refUploader: React.createRef() })),
    );

    function onFileItemsChanged(index, canConfirm, fileItems) {
        fileItemsUploader[index].fileItems = fileItems;
        fileItemsUploader[index].canConfirm = canConfirm;
        setFileItemsUploader([...fileItemsUploader]);
    }

    useEffect(() => {
        setChangesChecker(() => !fileItemsUploader.every(fi => !fi.fileItems?.length));
    }, [setChangesChecker, fileItemsUploader]);

    const submitLicense = useCallback(() => {
        const files = fileItemsUploader.map(fi => ({
            copyrightOwnership: fi.copyrightOwnership,
            copyrightOwnershipSubtype: fi.copyrightOwnershipSubtype,
            files: fi.fileItems.map(x => x.id),
        }));

        const fileNames = {};
        fileItemsUploader.forEach(f =>
            f.fileItems.forEach(fi => {
                fileNames[fi.id] = fi.file?.name;
            }),
        );

        confirmPrintLicenseSubmission(files, fileNames, corruptedIds =>
            // @ts-ignore
            fileItemsUploader.forEach(fi => fi.refUploader.current.markFilesAsCorruptedByNames(corruptedIds)),
        );

        unsetChangesChecker();
    }, [confirmPrintLicenseSubmission, fileItemsUploader, unsetChangesChecker]);

    const isSizeError =
        fileItemsUploader.reduce(
            (total, fi) => total + fi.fileItems.map(i => i.file.size).reduce((size, v) => size + v, 0),
            0,
        ) > uploadsConfig.maxTotalLicenseFilesSize;

    const canSubmit = !isSizeError && fileItemsUploader.every(fi => fi.canConfirm) && !isLicenseSubmissionConfirming;

    const ownerships = ownershipList.map((ownership, idx) => {
        const textParams = prepareLabels(l, ownership, {
            article,
            isOA: journal?.revenueModel === 'OA' || !!all?.openAccessOption?.authorSelectedOnlineOpen,
            isMultiAuthors: !all?.copyrightOwnership?.singleAuthor,
            listCopyrightOwnerships,
            uploadsConfig,
        });
        const { fileItems, refUploader } = fileItemsUploader[idx];

        return (
            <OwnershipBlock
                /* eslint-disable-next-line react/no-array-index-key */
                key={idx}
                currentStep={currentStep}
                seleniumId={`container_ownership-${ownership.copyrightOwnership}-${ownership.copyrightOwnershipSubtype}`}
                textParams={textParams}
            >
                <FileUploader
                    // @ts-ignore
                    ref={refUploader}
                    config={uploadsConfig}
                    externalError={isSizeError}
                    filesCaption={l('LICENSE_SUBMISSION.LABELS.UPLOAD.SIGNED_LABEL', {
                        document: textParams.shortName,
                    })}
                    initialFileItems={fileItems}
                    onFileItemsChanged={(...args) => onFileItemsChanged(idx, ...args)}
                />
            </OwnershipBlock>
        );
    });

    return (
        <MultiAuthorsView
            article={article}
            canSubmit={canSubmit}
            confirmStep={confirmStep}
            currentStep={currentStep}
            getPrevStep={getPrevStep}
            goBack={goBack}
            isObtained={isObtained}
            ownerships={ownerships}
            setCurrentStep={setCurrentStep}
            setIsObtained={setIsObtained}
            submitLicense={submitLicense}
        />
    );
}

export default compose(withConfirmLeaving, withScrollToTop, withCodes2(ID.LICENSE_SUBMISSION))(MultiAuthors);
