import React from 'react';
import withAuth from 'app/blocks/common/withAuth';
import { getUrlParam, invalidateUrlParam } from 'app/blocks/middleware/url-params';
import routes from 'app/pages/routes';

// fixme: an ugly hack to avoid redirection from Unauth page (it's *only* about Login page actually), but follow Login page 'navigate'
let isSuppressed = false;
export function suppressUnauthListeners(suppress = false) {
    isSuppressed = suppress;
}

function withUnauthRequiredPage<P>(Component: React.ComponentType<P>): React.ComponentType<P> {
    return function (props: P & { isAuth: boolean; location2?: Location }) {
        if (!props.isAuth) {
            return <Component {...props} />;
        }

        if (isSuppressed) {
            return null;
        }

        const { location2 = window.location } = props;
        const returnUrl = getUrlParam('returnUrl');
        // fixme - probably we should check returnUrl outside?
        if (returnUrl) {
            invalidateUrlParam('returnUrl');
            location2.replace(returnUrl);
        } else {
            routes.navigateToDashboard();
        }

        return null;
    };
}

export { withUnauthRequiredPage };
export default Component => withAuth(withUnauthRequiredPage(Component));
