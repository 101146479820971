import React from 'react';
import { MainTitle } from 'app/blocks/titles/titles';
import MissingArticle from './MissingArticle';

type Props = {
    lastSearchValue: string;
    searchWords: string[];
};

export default function EmptySearch({ lastSearchValue, searchWords }: Props): React.ReactElement {
    return (
        <div className="EmptySearch" data-seleniumid="empty-search">
            <div className="EmptySearch-Row">
                <MainTitle className="EmptySearch-Title" data-seleniumid="empty-search-text">
                    {searchWords.length
                        ? `No Article matches for "${lastSearchValue}"`
                        : 'Please enter at least one character'}
                </MainTitle>
            </div>
            <div className="EmptySearch-Row">
                <MissingArticle />
            </div>
        </div>
    );
}
