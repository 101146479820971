import PropTypes from 'prop-types';
import React from 'react';
import ErrorLabel from 'app/blocks/ErrorLabel/ErrorLabel';
import HelpText from 'app/blocks/HelpText/HelpText';
import PromptAction from 'app/blocks/Prompt/_action/prompt_action';
import NewLayout from 'app/blocks/Row/NewLayout';
import OldLayout from 'app/blocks/Row/OldLayout';
import FormContext from 'app/pages/license-signing-process/context/FormContext';
import { Input2 } from 'app/ui/form/inputs';

function Row(props) {
    function errorTranslator(error) {
        return (props.errorMessages && error.code && props.errorMessages[error.code]) || error.message;
    }

    const components = {
        input: (
            <Input2
                data-seleniumid={`${props.formSeleniumid}-${props.name}-input`}
                disabled={props.disabled}
                isError={props.error}
                maxLength={props.maxLength}
                name={props.name}
                onChange={props.onChange}
                onFocus={props.onFocus}
                placeholder={props.placeholder}
                type={props.multiline ? 'textarea' : props.type || 'text'}
                value={props.value}
            />
        ),
        prompt: props.prompt && <PromptAction {...props.prompt} />,
        validation: props.error && (
            <ErrorLabel
                data-seleniumid={`validation-error-${props.formSeleniumid}-${props.name}`}
                text={errorTranslator(props.error)}
            />
        ),
        helpText: props.helpText && (
            <HelpText data-seleniumid={`help-text-${props.formSeleniumid}-${props.name}`} text={props.helpText} />
        ),
    };

    const Component = props.isNew || props.isNewComponent ? NewLayout : OldLayout;

    return (
        <Component
            className={props.className}
            classNames={props.classNames}
            components={components}
            isDisabled={props.disabled}
            isOptional={props.isOptional}
            isRequired={props.isRequired}
            isVertical={props.isVertical}
            label={props.label}
        />
    );
}

Row.propTypes = {
    className: PropTypes.string,
    classNames: PropTypes.shape({}),
    disabled: PropTypes.bool,
    error: PropTypes.shape({ code: PropTypes.string, message: PropTypes.string }),
    errorMessages: PropTypes.shape({}),
    formSeleniumid: PropTypes.string.isRequired,
    isOptional: PropTypes.bool,
    isRequired: PropTypes.bool,
    isVertical: PropTypes.bool,
    label: PropTypes.string.isRequired,
    helpText: PropTypes.string,
    maxLength: PropTypes.number,
    multiline: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string.isRequired,
    prompt: PropTypes.shape({}),
    type: PropTypes.string,
    value: PropTypes.string,
    isNewComponent: PropTypes.bool,
};

Row.defaultProps = {
    className: undefined,
    classNames: {},
    helpText: undefined,
    disabled: false,
    errorMessages: undefined,
    isOptional: false,
    isRequired: false,
    isVertical: false,
    maxLength: undefined,
    multiline: false,
    onFocus: undefined,
    prompt: undefined,
    type: undefined,
    error: undefined,
    value: undefined,
    isNewComponent: false,
};

export default FormContext.withContext(['isNew'])(Row);
