import React, { useEffect } from 'react';
import { processPageLoad as sendAnalytics } from 'app/blocks/analytics/analytics';

function AnalyticsWrapper({ children, page }): React.ReactElement {
    useEffect(() => {
        sendAnalytics(page);
    }, [children, page]);

    return children;
}

type PageProps = { page: string };
export default function withAnalytics(page) {
    return function (Component) {
        return function WithAnalytics(props: PageProps): React.ReactElement {
            return (
                <AnalyticsWrapper page={page}>
                    <Component {...props} />
                </AnalyticsWrapper>
            );
        };
    };
}

const AnalyticsContext = React.createContext({ onLoad: e => e });
function withAnalyticsContext(page) {
    return function (Component) {
        return function (props) {
            return (
                <AnalyticsContext.Provider value={{ onLoad: error => sendAnalytics(page, { error }) }}>
                    <Component {...props} />
                </AnalyticsContext.Provider>
            );
        };
    };
}

function consumeAnalyticsContext(Component) {
    return function (props) {
        return (
            <AnalyticsContext.Consumer>
                {({ onLoad }) => <Component {...props} onLoad={onLoad} />}
            </AnalyticsContext.Consumer>
        );
    };
}

export { consumeAnalyticsContext, withAnalyticsContext };
