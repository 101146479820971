import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import AuthorRoleEnum from 'app/blocks/AuthorRoleEnum';
import { Layout, WhiteBlock } from 'app/blocks/blocks';
import { Button } from 'app/blocks/buttons/buttons';
import * as middlewareDashboard from 'app/blocks/middleware/dashboard';
import * as middlewareLicense from 'app/blocks/middleware/license';
import StaticContent from 'app/blocks/static-content/static-content';
import routes from 'app/pages/routes';
import MissingArticleSubmitSuccess from 'app/pages/search/_advanced/missing-article-details/MissingArticleSubmitSuccess';
import DashboardActionCodes from 'app/types/dashboard-action-codes';
import RcaStatusEnum from '../../dashboard/RcaStatusEnum';

type Props = {
    gotoDashboard: () => void;
    result: string;
};

function AssignmentResult({ gotoDashboard, result }: Props): React.ReactElement {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);

    const articleId = new URLSearchParams(location.search).get('articleId');
    const name = `assign-article__${result}`;

    const backToDashboard = async () => {
        try {
            const articleDetails = await middlewareDashboard.getArticle(articleId);
            setIsLoading(true);
            if (articleDetails.payload.rcaInfo.rcaStatus === RcaStatusEnum.RCA_CONFIRMATION_NOT_NEEDED) {
                if (
                    articleDetails.payload.participantRole.additionalRoles.includes(
                        AuthorRoleEnum.RESPONSIBLE_CORRESPONDING_AUTHOR,
                    )
                ) {
                    if (articleDetails.payload.flow.OOOrder) {
                        await middlewareLicense.startFlow(articleId, DashboardActionCodes.CREATE_OO_ORDER);
                    } else {
                        routes.navigateToUrl(`/article/${articleId}`);
                    }
                } else {
                    routes.navigateToUrl(`/article/${articleId}`);
                }
                setIsLoading(false);
            } else if (articleDetails.payload.rcaInfo.isEeoCaMe) {
                if (articleDetails.payload.flow.OOOrder) {
                    await middlewareLicense.startFlow(articleId, DashboardActionCodes.CREATE_OO_ORDER);
                } else {
                    routes.navigateToUrl(`/article/${articleId}`);
                }
                setIsLoading(false);
            } else {
                routes.navigateToUrl(`/article/${articleId}`);
            }
            setIsLoading(false);
        } catch (error) {
            routes.navigateToUrl(`/dashboard`);
        }
    };

    const renderManualArticleRequest = () => {
        return (
            <div>
                <MissingArticleSubmitSuccess />
                <hr className="AdvanceSearchButtonsHr" />
                <div className="m-top_md">
                    <div className="pull-left">
                        <Button data-seleniumid="back-to-dashboard-button" onClick={gotoDashboard}>
                            Go To Dashboard
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    const renderArticleAssign = () => {
        return (
            <div>
                <WhiteBlock className="p_mini">
                    <div className="row row_mini">
                        <div className="col-12">
                            <StaticContent key={name} src={`${name}.html`} />
                        </div>
                    </div>
                </WhiteBlock>
                <div className="m-top_md">
                    <div className="pull-left">
                        <Button data-seleniumid="back-to-dashboard-button" onClick={backToDashboard}>
                            Go To Dashboard
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    const renderManualArticleAssignmentRequest = () => {
        return (
            <div>
                <WhiteBlock className="p_mini">
                    <div className="row row_mini">
                        <div className="col-12">
                            <StaticContent key={name} src={`${name}.html`} />
                        </div>
                    </div>
                </WhiteBlock>
                <div className="m-top_md">
                    <div className="pull-left">
                        <Button data-seleniumid="back-to-dashboard-button" onClick={gotoDashboard}>
                            Go To Dashboard
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Layout isLoading={isLoading}>
            {result === 'MANUAL_ARTICLE_ASSIGNMENT_REQUESTED' && renderManualArticleRequest()}
            {result === 'ARTICLE_ASSIGNED' && renderArticleAssign()}
            {result !== 'MANUAL_ARTICLE_ASSIGNMENT_REQUESTED' &&
                result !== 'ARTICLE_ASSIGNED' &&
                renderManualArticleAssignmentRequest()}
        </Layout>
    );
}

export default AssignmentResult;
