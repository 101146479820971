import cn from 'classnames';
import React from 'react';
import { ID, withCodes2 } from 'app/blocks/common/codes';
import { getFormattedAmount } from 'app/blocks/common/getCurrencySymbol';
import { SubTitle } from 'app/blocks/titles/titles';
import payment from 'app/pages/orders/orders.payment';
import PaymentAmountInfo from './PaymentAmountInfo';
import './PaymentBreakdown.scss';

function PaymentBreakdown({ panelName, l, order, paymentMethod }) {
    const prices = order.pricingDetails;
    return (
        <section className={cn('payment-panel', panelName)} data-seleniumid="order-price-panel-review">
            <SubTitle>{l('ORDER_PANELS.OO_AMOUNT_PAYABLE.REVIEW_CHARGES_TITLE')}</SubTitle>
            {prices.pricesUnavailable ? (
                <div className="mt-base PricesUnavailable" data-seleniumid="price-simulation-unavailable-text">
                    {l('ORDER_PANELS.OO_AMOUNT_PAYABLE.UNAVAILABLE')}
                </div>
            ) : (
                <div className={cn('amount-rows', 'PaymentAmountInfo')}>
                    <PaymentAmountInfo
                        amount={getFormattedAmount(prices.currency, prices.basePrice)}
                        isAmountBold
                        isTitleBold
                        seleniumId="orders__value-value-order-apc-review"
                        subHeader={`${l('ORDER_PANELS.PANEL_PRICE_INFO.ARTICLE_TYPE')}: ${order.article.category}`}
                        title={`${l('ORDER_PANELS.PANEL_PRICE_INFO.CHARGE_TITLE')}, ${prices.currency}`}
                    />
                    {paymentMethod !== payment.method.SOCOPAY.key && (
                        <PaymentAmountInfo
                            amount={getFormattedAmount(prices.currency, prices.taxAmount)}
                            seleniumId="orders__value-value-order-tax-review"
                            title={l('ORDER_PANELS.PANEL_PRICE_INFO.TAX')}
                        />
                    )}
                    <PaymentAmountInfo
                        amount={`- ${getFormattedAmount(prices.currency, prices.discountAmount)}`}
                        seleniumId="orders__value-percent-order-discountAmount-basePrice"
                        title={l('ORDER_PANELS.PANEL_PRICE_INFO.DISCOUNT')}
                    />
                    <PaymentAmountInfo
                        amount={getFormattedAmount(
                            prices.currency,
                            paymentMethod !== payment.method.SOCOPAY.key
                                ? prices.totalAmount
                                : prices.totalAmountBeforeTax,
                        )}
                        bgColor="#F2F2F2"
                        isAmountBold
                        isTitleBold
                        seleniumId="orders__value-value-order-totalAmount"
                        title={`${l('ORDER_PANELS.PANEL_PRICE_INFO.FINAL_COST')}, ${prices.currency}`}
                    />
                </div>
            )}
        </section>
    );
}

export default withCodes2(ID.ORDER_PANELS)(PaymentBreakdown);
