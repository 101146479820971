import React, { Component } from 'react';
import { pageEvents, processRegistrationPageLoad, processRegistration } from 'app/blocks/analytics/analytics';
import { addLoginValue, removeRegistrationValue } from 'app/blocks/analytics/storage';
import { Button } from 'app/blocks/buttons/buttons';
import ASInfo from 'app/blocks/common/as-info';
import { ID, withCodes } from 'app/blocks/common/codes';
import Alerts from 'app/blocks/common/spinner';
import { ServiceError } from 'app/blocks/common/utils-errortypes';
import Layout from 'app/blocks/Layout/Layout';
import { getUiConnectUrl, securityKey } from 'app/blocks/middleware/authentication';
import * as RegisterMiddleware from 'app/blocks/middleware/register';
import { getUrlParam } from 'app/blocks/middleware/url-params';
import StaticContent from 'app/blocks/static-content/static-content';
import { SubTitle, MainTitle } from 'app/blocks/titles/titles';
import routes from 'app/pages/routes';
import RegistrationForm from './registration-form';
import RegistrationLoginMessage from './registration-login-message';
import SuccessfulMessage from './success-message';

import './registration.scss';

function isRegisteredAsPrimary(error: ServiceError): boolean {
    return error.code === 'REGISTRATION_INVITED_USER_ALREADY_REGISTERED';
}

function isRegisteredAsSecondary(error: ServiceError): boolean {
    return error.code === 'REGISTRATION_EMAIL_ALREADY_REGISTERED_AS_SECONDARY';
}

type State = {
    isLoading: boolean;
    invitation?: Awaited<ReturnType<typeof RegisterMiddleware.getInvitation>>;
    error?: ServiceError;
    registrationIsSuccessful: boolean;
    registeredEmail?: string;
    verificationRequired: boolean;
};

const removeNegativeIndexValue = (pos, urlValue) => {
    let position = pos;
    if (position === -1) {
        position = urlValue.length;
    }
    return position;
};

const getRedirectUrl = (url, invitation, invitationToken) => {
    if (!invitation) {
        return securityKey(url);
    }

    const indexValue = removeNegativeIndexValue(url.indexOf('&redirect_uri'), url);
    const payloadValue =
        `&firstname=${invitation?.firstName || ''}` +
        `&lastname=${invitation?.lastName || ''}` +
        `&emailaddress=${encodeURIComponent(invitation?.primaryEmailAddr) || ''}`;
    const urlPayloadValue =
        `${url.slice(0, indexValue) + payloadValue + url.slice(indexValue)}&login_hint=` +
        `${encodeURIComponent(invitation?.primaryEmailAddr) || ''}` +
        `&action=register`;
    const indexValueHostName = removeNegativeIndexValue(urlPayloadValue.indexOf('&state'), urlPayloadValue);
    const urlWithInvitationToken =
        `${urlPayloadValue.slice(0, indexValueHostName)}` +
        `?invitationToken=${invitationToken || ''}` +
        `${urlPayloadValue.slice(indexValueHostName)}`;
    return securityKey(urlWithInvitationToken);
};

class RegistrationPage extends Component<{ invitationToken?: string; l: l }, State> {
    state: State = {
        error: null,
        invitation: null,
        isLoading: !!this.props.invitationToken,
        registeredEmail: '',
        registrationIsSuccessful: false,
        verificationRequired: true,
    };

    async componentDidMount() {
        this.sendAnalytics();
        const invitation = await this.checkInviteInformation();
        const returnUrl = getUrlParam('returnUrl');

        if (!returnUrl) {
            Alerts.renderSpinner();
            const url = await getUiConnectUrl();
            const redirectUrl = getRedirectUrl(url, invitation, this.props.invitationToken);
            window.location.href = redirectUrl;
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.invitationToken !== prevProps.invitationToken) {
            this.checkInviteInformation();
        }
    }

    sendAnalytics = () => {
        const data = { 'cta-region': removeRegistrationValue() || 'registration-link', step: 'registration initiated' };
        processRegistration(data, pageEvents.REGISTRATION_INITIATED);
        processRegistrationPageLoad(!!this.props.invitationToken, null, null);
    };

    checkInviteInformation = async () => {
        const { invitationToken } = this.props;

        this.setState({ error: null, invitation: null, isLoading: !!invitationToken });

        let invitation = null;

        try {
            if (invitationToken) {
                invitation = await RegisterMiddleware.getInvitation(invitationToken);

                this.setState({ invitation });
            }
        } catch (error) {
            if (isRegisteredAsPrimary(error) || isRegisteredAsSecondary(error)) {
                this.setState({ error });
            }
        } finally {
            this.setState({ isLoading: false });
        }

        return invitation;
    };

    onSuccessfulRegistration = (verificationRequired, registeredEmail) => {
        this.setState({
            registeredEmail,
            registrationIsSuccessful: true,
            verificationRequired,
        });
    };

    onClickLogin = () => {
        addLoginValue('registration-page');
        routes.toLogin();
    };

    renderInvitationBanner() {
        const { invitation } = this.state;
        const { l } = this.props;

        if (!invitation) return null;
        const inviters = (invitation.inviters || []).map(item => `${item.name} &lt;${item.email}&gt;`).join(', ');

        const message = l(`${ID.REGISTRATION}.inlineHelp.REGISTRATION_BY_INVITATION_BY_INVITERS_LOGIN_HELP`, {
            inviters,
        });
        return (
            <div className="InvitationBlock">
                <SubTitle data-seleniumid="page-alert-title">
                    <span>Invitation</span>
                </SubTitle>
                <p
                    className="InvitationBlock-Text"
                    dangerouslySetInnerHTML={{ __html: message }}
                    data-seleniumid="alert-message"
                />
                <div className="InvitationBlock-Actions">
                    <Button data-seleniumid="page-alert-btn" name="button" onClick={this.onClickLogin}>
                        {l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_LOGIN_BUTTON_LABEL`)}
                    </Button>
                </div>
            </div>
        );
    }

    renderContent() {
        const { error, invitation } = this.state;
        const { l } = this.props;

        if (error) {
            if (isRegisteredAsPrimary(error) || isRegisteredAsSecondary(error)) {
                return (
                    <RegistrationLoginMessage
                        existsAsPrimary={isRegisteredAsPrimary(error)}
                        existsAsSecondary={isRegisteredAsSecondary(error)}
                    />
                );
            }

            return <Layout error={error} />;
        }

        const { registeredEmail, registrationIsSuccessful, verificationRequired } = this.state;
        if (registrationIsSuccessful) {
            return verificationRequired ? (
                <SuccessfulMessage email={registeredEmail} />
            ) : (
                <RegistrationLoginMessage verificationNotRequired />
            );
        }

        return (
            <>
                {invitation && this.renderInvitationBanner()}
                <div className="RegisterPage-Container">
                    <div className="RegisterBlock">
                        <div className="RegisterBlock-FormContainer" data-seleniumid="register-block" id="inputdiv">
                            <RegistrationForm
                                invitation={invitation}
                                onSuccessfulRegistration={this.onSuccessfulRegistration}
                            />
                        </div>
                    </div>

                    <div className="OtherBlock" data-seleniumid="register-other-block">
                        <div className="LogIn Information" data-seleniumid="register-login-section">
                            <SubTitle className="LogIn-Title">
                                {l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_LOGIN_TO_WILEY_LABEL`)}
                            </SubTitle>
                            <div className="LogIn-Action">
                                <Button
                                    data-seleniumid="register-login-btn"
                                    id="register-login-btn"
                                    name="button"
                                    onClick={this.onClickLogin}
                                >
                                    {l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_LOGIN_BUTTON_LABEL`)}
                                </Button>
                            </div>
                        </div>
                        <div
                            className="Register Information"
                            data-seleniumid="register-help-section"
                            id="register-help-section"
                        >
                            <StaticContent key="register_help_section" src="register_help_section.html" />
                        </div>
                    </div>
                </div>
            </>
        );
    }

    render() {
        const { l } = this.props;
        const { isLoading } = this.state;
        const pageTitle = l(`${ID.REGISTRATION}.uiLabelMessages.REGISTRATION_REGISTER_LABEL`);
        const returnUrl = getUrlParam('returnUrl');

        return !returnUrl ? (
            <div>
                <Layout isLoading />
            </div>
        ) : (
            <div className="RegisterPage container">
                <MainTitle data-seleniumid="register-blockTitle">{pageTitle}</MainTitle>

                <Layout isLoading={isLoading}>{!isLoading && this.renderContent()}</Layout>
                <ASInfo page={pageTitle} />
            </div>
        );
    }
}

export default withCodes(RegistrationPage, ID.REGISTRATION);

export { getRedirectUrl };
